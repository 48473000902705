import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Container, Card, Form, Button, Spinner, Col, Row, Alert } from 'react-bootstrap';
import { useForm, FormContext } from 'react-hook-form';
import { PROVISION_ORDER_LIST } from 'src/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { newProvisionOrder, save, cancel } from 'src/utils/label';
import ProvisionOrderForm from 'src/components/forms/provisionOrder/ProvisionOrderForm';
import GoodsAndServicesModal from 'src/components/pages/ProvisionOrder/modal/GoodsAndServicesModal';
import { isNotEmptyArray } from 'src/services/validationService';
import NumberFormat from 'react-number-format';
import { getClearObject, parseFloatOrUndefined } from 'src/utils/utils';
import { tryPostProvisionOrder } from 'src/redux/provisionOrder/provisionOrderActionCreator';
import { ProvisionOrderDataToSend } from './utils/ProvisionOrderDataToSend';
import { faExclamationTriangle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const ProvisionOrderNewPage = () => {
  const dispatch = useDispatch();
  const hookFormMethods = useForm();
  const { handleSubmit } = hookFormMethods;
  const [goodsAndServicesList, setGoodsAndServicesList] = useState([]);
  const [totalGeneral, setTotalGeneral] = useState(0);

  const buttonLabel = isNotEmptyArray(goodsAndServicesList) ? "Editar Bienes y/o Servicios" : "Añadir Bienes y/o Servicios";

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const onClickSetElementAssetsModal = () => {
    handleShow(true);
  };

  const calculateTotal = (list) => {
    setTotalGeneral(list?.map((item) => parseFloatOrUndefined(item?.totalAmount) || 0)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0)
    );
  };

  const onSubmitForm = data => {
    if (goodsAndServicesList.length > 0) {
      const dataToSend = ProvisionOrderDataToSend(data, goodsAndServicesList);
      const cleanData = getClearObject(dataToSend);
      dispatch(tryPostProvisionOrder(cleanData)).then(response => {
        if (response.status == 201) {
          dispatch(push(PROVISION_ORDER_LIST));
        }
      });
    }
  };

  useEffect(() => {
    calculateTotal(goodsAndServicesList);
  }, [goodsAndServicesList]);

  return <>
    <Container fluid>
      <Card className='mb-5'>
        <Card.Header className='h6'>
          {newProvisionOrder}
        </Card.Header>
        <Card.Body >
          <span className='text-danger d-flex mandatory-label font-weight-bold font-italic'>
            <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
            Obligatorio
          </span>
          <FormContext {...hookFormMethods}>
            <Form className='mt-4 text-black-color' onSubmit={handleSubmit(onSubmitForm)}>
              <ProvisionOrderForm />
              <GoodsAndServicesModal
                show={show}
                handleClose={handleClose}
                goodsAndServicesList={goodsAndServicesList}
                setGoodsAndServicesList={setGoodsAndServicesList}
              />
              <Form.Group as={Row} className='mt-5 mb-3'>
                <Col sm={4}></Col>
                <Col sm={4} className='paddingform d-flex justify-content-center'>
                  <Button
                    variant='success'
                    size='md'
                    className='py-1 px-4'
                    onClick={() => onClickSetElementAssetsModal()}
                  >
                    {buttonLabel}
                  </Button>
                </Col>
              </Form.Group>
              {isNotEmptyArray(goodsAndServicesList) ?
                <>
                  <Form.Group as={Row}>
                    <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                      Total de la Orden de Provision:
                    </Form.Label>
                    <Col sm='4'>
                      <NumberFormat
                        prefix={'$'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        value={totalGeneral}
                        className={'form-control text-left'}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </>
                : <Alert variant='warning' className='mb-5 col-md-4 offset-md-4'>
                  <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                  Debe cargar Bienes y/o Servicios.
                </Alert>
              }
              <div class='d-flex justify-content-around mt-4 mb-3'>
                <Button
                  variant='primary'
                  size='lg'
                  onClick={() => dispatch(push(PROVISION_ORDER_LIST))}
                >
                  Cancelar
                </Button>
                <span className={(false ? '' : 'hidden')}>
                  <Spinner className='spinner-border text-danger' animation='border' />
                </span>
                <Button
                  type='submit'
                  variant='success'
                  size='lg'
                  className='text-white-color'
                >
                  Guardar
                  <FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
                </Button>
              </div>
            </Form>
          </FormContext>
        </Card.Body>
      </Card>
    </Container>
  </>
}

export default ProvisionOrderNewPage;