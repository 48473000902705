import React, { useState, useEffect } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Container, Card, Form, Button, Spinner, Alert, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { CREDIT_EXECUTION } from 'src/utils/constants';

import { back, next, newCompensation, impactsBudgetItems } from 'src/utils/label';
import { numberNegativeRed, formatterPeso } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';

import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';

import { getCompensationCreate } from 'src/redux/subcode/subcodeReducer';
import { getPreloadState } from 'src/redux/subcode/subcodeReducer';

import NumberFormat from 'react-number-format';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportCompensationDirectDownload } from 'src/redux/reports/reportsActionCreactor';
import { getReportCompensationDirectDownloadIsFetching } from 'src/redux/reports/reportsReducer';
import AppLoading from 'src/components/common/AppLoading';

const CompensationStepThreePage = () => {

	const dispatch = useDispatch();

	const newCompensationResponseData = useSelector(state => getCompensationCreate(state))?.data;
	const hasSubcodeDetailsData = isNotEmptyArray(newCompensationResponseData);

	const reportCompensationDirectDownloadIsFetching = useSelector(state => getReportCompensationDirectDownloadIsFetching(state));

	// Period
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	// Preload
	const preloadFilter = useSelector(state => getPreloadState(state))?.data;

	const onClickGoToCreditExecution = () => {
		dispatch(push(CREDIT_EXECUTION))
	};

	const [totalAmount, setTotalAmount] = useState(0);

	const calculateTotal = (selected) => {
		setTotalAmount(selected?.map((item) => item?.amount || 0)
			.reduce((accumulator, currentValue) => {
				return accumulator + currentValue;
			}, 0)
		);
	};

	const TotalModifications = (params) => {

		const { total } = params;

		return <span className='ml-auto'>
			<span className='text-black-color mr-3'>Total importe compensaciones:</span>
			<NumberFormat
				prefix={'$'}
				thousandSeparator={'.'}
				decimalSeparator={','}
				decimalScale={2}
				value={total}
				defaultValue={0}
				className={'text-black-color text-right'}
				readOnly
			/>
		</span>
	};

	// Get compensation report
	const getReport = (format) => {

		const paramsToSend = {
			periodId: globalSelectedPeriod?.id,
			outputFormat: format,
			sub_code_detail_ids: newCompensationResponseData?.map(subcode => subcode?.id),
		};

		dispatch(tryGetReportCompensationDirectDownload(paramsToSend));
	};

	const onClickPDF = () => getReport(1);

	useEffect(() => {
		calculateTotal(newCompensationResponseData);
	}, [newCompensationResponseData]);

	return <Container fluid >
		<Card className='mb-5'>
			<PageTitle text={impactsBudgetItems} />
			<Container fluid >
				<StepIndicator steps={config.appSettings.steps.compensation} title={config.appSettings.steps.compensation} current={2} className='mt-2 mb-3' />
				<Card className='mb-3'>

					<Card.Header className='h6'>
						<div className='d-flex align-items-center mb-1'>
							{newCompensation}
							<Form.Group className='text-white-color mt-0 mb-0 ml-5 mr-0 text-left font-weight-bold font-italic'>
								<Form.Check.Input style={{ "transform": 'scale(1.5)' }} disabled checked={preloadFilter} />
								<Form.Check.Label className='ml-1'>PRECARGA</Form.Check.Label>
							</Form.Group>
						</div>
					</Card.Header>

					<Card.Body>

						<Alert variant='info' className='text-main-color text-center mt-3 mb-4'>
							¡Compensación de partida(s) generada correctamente!
						</Alert>

						{
							newCompensationResponseData?.[0]?.subCode?.code?.credit?.currentCredit &&
							<div className='text-center'>
								<Button variant='success' className='mb-4' onClick={onClickPDF}>
									<FontAwesomeIcon i icon={faFilePdf} className='mr-2' />
									Descargar PDF
								</Button>
							</div>
						}

						<hr color="black" className='mt-4' />

						{
							hasSubcodeDetailsData
							&&
							<>
								<h6 className='text-black-color mt-4 mb-3'>
									Partida(s) compensadas:
								</h6>

								<div className='d-flex align-items-center mb-3'>
									<p className='h6 text-black-color'>

									</p>
									<TotalModifications
										total={totalAmount}
									/>
								</div>

								<Table striped bordered hover size='sm'>
									<thead>
										<tr>
											<th className='text-center align-middle' width="30%">N°</th>
											<th className='text-center align-middle' width="30%">Denominación</th>
											<th className='text-center align-middle' width="13%">Importe Modificación</th>
											<th className='text-center align-middle' width="14%">Crédito Actual</th>
										</tr>
									</thead>
									<tbody className='text-black-color'>
										{
											hasSubcodeDetailsData
											&&
											newCompensationResponseData.map(subcode => (
												<tr key={subcode?.id}>
													<td className='text-center align-middle'>
														{subcode?.subCode?.completeNumber}
													</td>
													<td className='text-center align-middle'>
														{subcode?.subCode?.visibleName}
													</td>
													<td className={`${numberNegativeRed(subcode?.amount)} text-right`}>
														{formatterPeso.format(subcode?.amount)} {/*pendiente*/}
													</td>
													<td className={`${numberNegativeRed(subcode?.subCode?.code?.credit?.currentCredit)} text-right`}>
														{subcode?.subCode?.code?.credit?.currentCredit ? formatterPeso.format(subcode?.subCode?.code?.credit?.currentCredit) : '-'}
													</td>
												</tr>
											))
										}
									</tbody>
								</Table>

								<div className='d-flex align-items-center mb-3'>
									<p className='h6 text-black-color'>

									</p>
									<TotalModifications
										total={totalAmount}
									/>
								</div>
							</>
						}

						<hr color="black" className='mt-4' />

						<Form.Group className='mt-4 d-flex justify-content-around'>

							<Button
								variant='danger'
								size='lg'
								onClick={onClickGoToCreditExecution}
							>
								<FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
								Volver a ejecución de presupuesto
							</Button>

						</Form.Group>

					</Card.Body>
				</Card>
			</Container>
			<AppLoading isLoading={reportCompensationDirectDownloadIsFetching} />
		</Card>
	</Container>;
};

export default CompensationStepThreePage;