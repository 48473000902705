import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faChevronDown, faSearch, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import Multiselect from 'react-widgets/lib/Multiselect'
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getOrganizationListData, getOrganizationListIsFetching } from 'src/redux/organization/organizationReducer';
import { getCharacterListData, getCharacterListIsFetching } from 'src/redux/character/characterReducer';
import { tryGetCharacterList } from 'src/redux/character/characterActionCreator';
import { clearCharacterList } from 'src/redux/character/characterActions';
import { tryGetReportTotalOrganizationByPurpose, tryGetReportTotalOrganizationByEconomic } from 'src/redux/reports/reportsActionCreactor';
import { tryGetOrganizationList } from 'src/redux/organization/organizationActionCreator';
import { clearOrganizationList } from 'src/redux/organization/organizationActions';
import { getReportOrganizationTotalIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportOrganizationTotalData } from 'src/redux/reports/reportsActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined } from 'src/utils/utils';

const REPORT_TOTAL_ORGANISM_PURPOSE = '1';
const REPORT_TOTAL_ORGANISM_ECONOMIC = '2';
const CREDIT_ORIGINAL = 1;
const CREDIT_CURRENT = 2;
const CREDIT_MODIFICATIONS = 3;

const ReportOrganismTotalPage = props => {
	const dispatch = useDispatch();

	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	
	// Report type
	const [ selectedReportType, setSelectedReportType ] = useState(REPORT_TOTAL_ORGANISM_PURPOSE);

	// Characters
	const characterOptionsData = useSelector( state => getCharacterListData(state) )?.data
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
			?.map( character => ({
				displayName: `${character.code} - ${character.name}`,
				id: character.id,
				code: character.code,
				name: character.name
			}) );
	const characterIsFetching = useSelector( state => getCharacterListIsFetching(state) );
	const hasCharacterOptions = isNotEmptyArray(characterOptionsData);
	const [ selectedCharacters, setSelectedCharacter ] = useState();
	const hasSelectedCharacters = isNotEmptyArray(selectedCharacters);
	const onChangeCharacters = data => setSelectedCharacter(data);

	// Organization
	const organizationData = useSelector( state => getOrganizationListData(state) )?.records
		?.map( organization => ({
			displayName: `${organization?.codeBudget} - ${organization?.name} (${organization?.jurisdiction?.name}) (${organization?.organismClassifier?.shortName})`,
			id: organization?.id,
			name: organization?.name,
			nameJurisdictionGroupBy: organization?.jurisdiction?.name
		}) );
	const organizationIsFetching = useSelector( state => getOrganizationListIsFetching(state) );
	const hasOrganizationData = isNotEmptyArray(organizationData);
	const [ selectedOrganization, setSelectedOrganization ] = useState();

	// Checkboxes columns
	const [ checkedOriginal, setCheckedOriginal ] = useState(false);
	const [ checkedCurrent, setCheckedCurrent ] = useState(false);
	const [ checkedModifications, setCheckedModifications ] = useState(false);

	// Reports redux
	const reportIsFetching = useSelector( state => getReportOrganizationTotalIsFetching(state) );
	const allowGetReport = ( hasPeriodList && !reportIsFetching && hasSelectedCharacters && !!selectedOrganization && hasOrganizationData && (checkedOriginal||checkedCurrent||checkedModifications) );
	

	useEffect( () => {
		dispatch( clearReportOrganizationTotalData() );
		dispatch( clearCharacterList() );
		dispatch( clearOrganizationList() );
		dispatch( tryGetCharacterList() );
		dispatch( tryGetOrganizationList() );
	}, [] );

	const getReport = outputFormat => {
		const columns = [];
		checkedCurrent ? columns.push(CREDIT_CURRENT) : undefined;
		checkedModifications ? columns.push(CREDIT_MODIFICATIONS) : undefined;
		checkedOriginal ? columns.push(CREDIT_ORIGINAL) : undefined;

		if( globalSelectedPeriod || selectedReportType ) {
			const params = {
				periodId: globalSelectedPeriod?.id,
				organization_id: selectedOrganization.id,
				characters: selectedCharacters.map( char => char.id ),
				columns,
				outputFormat
			};

			switch( selectedReportType ) {
				case REPORT_TOTAL_ORGANISM_PURPOSE: {
					dispatch( tryGetReportTotalOrganizationByPurpose(params) );
					break;
				}
				case REPORT_TOTAL_ORGANISM_ECONOMIC: {
					dispatch( tryGetReportTotalOrganizationByEconomic(params) );
					break;
				}
				default: {
					dispatch( tryGetReportTotalOrganizationByPurpose(params) );
					break;
				}
			}
		}
	};

	const onClickPDF = () => getReport(1);
	
	const onClickXLS = () => getReport(2);

	const onChangeReportType = value => {
		switch( value ) {
			case REPORT_TOTAL_ORGANISM_PURPOSE: {
				setSelectedReportType( value );
			}
			case REPORT_TOTAL_ORGANISM_ECONOMIC: {
				setSelectedReportType( value );
			}
		}
	};

	return <Container fluid>
		<Card className='mb-5'>
			
			<PageTitle text='Reporte total por organismo' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						<Form>
						<Row>
								<Col xs={12} className='text-right font-weight-bold font-italic'>
									<span className='text-danger d-flex mandatory-label'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									Obligatorio
								</span>
								</Col>
							</Row>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
									<Form.Group>
										<Form.Label className='text-black-color'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										Ejercicio
										</Form.Label>
										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
											Organismos
										</Form.Label>
										<DropdownList filter
											placeholder='Seleccione un organismo...'
											busy={organizationIsFetching}
											data={organizationData}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											groupBy='nameJurisdictionGroupBy'
											className='text-black-color'
											onChange={ setSelectedOrganization }
											messages={ messagesDropDown }
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
										</DropdownList>
										{
											!selectedOrganization
											?
												<small class="form-text text-muted font-italic">Debe seleccionar una opción</small>
											:
												null
										}
									</Form.Group>
									
									<Form.Group>
										<Form.Label className='text-black-color'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
											Tipo
										</Form.Label>
										<Form.Control as='select' className='text-black-color' onChange={ event => onChangeReportType(event.target.value) }>
											<option value={REPORT_TOTAL_ORGANISM_PURPOSE}>Finalidad y Función</option>
											<option value={REPORT_TOTAL_ORGANISM_ECONOMIC}>Objeto del Gasto</option>
										</Form.Control>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
											Carácter
										</Form.Label>
										<Multiselect
											placeholder='Seleccione uno o más caracteres...'
											onChange={onChangeCharacters}
											disabled={!hasCharacterOptions}
											busy={characterIsFetching}
											allowCreate={false}
											data={characterOptionsData}
											textField='displayName'
											valueField='id'
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}/>
										{
											!hasSelectedCharacters
											?
												<small class="form-text text-muted font-italic">Debe seleccionar al menos una opción.</small>
											:
												null
										}
									</Form.Group>

									<Form.Group className='text-black-color mt-4 ml-2'>
										<Form.Check
											id='original-credit'
											label='Crédito original'
											checked={checkedOriginal}
											onChange={ event => setCheckedOriginal(event.target.checked) }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											id='modifications-credit'
											label='Modificaciones'
											checked={checkedModifications}
											onChange={ event => setCheckedModifications(event.target.checked) }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											id='current-credit'
											label='Crédito actual'
											checked={checkedCurrent}
											onChange={ event => setCheckedCurrent(event.target.checked) }/>
																					
										{
											(!checkedModifications&&!checkedCurrent&&!checkedOriginal)
											?
												<small class="form-text text-muted font-italic">
													<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
													Debe seleccionar al menos una opción de crédito.
												</small>
											:
												null
										}

									</Form.Group>

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2'/>
											Ver PDF
										</Button>
										{
											reportIsFetching
											?
												<Spinner animation='border'/>
											:
												null
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2'/>
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>
							
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ReportOrganismTotalPage;