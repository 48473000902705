import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Row, Col, Button, Table, Alert } from 'react-bootstrap';
import ActionIcon from 'src/components/general/ActionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faAsterisk, faMinusCircle, faExclamationTriangle, faSave, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { useForm } from 'react-hook-form';
import { WAGES_TEMPLATE_LIST } from 'src/utils/constants';
import FormFieldError from 'src/components/general/FormFieldError';
import AppLoading from 'src/components/common/AppLoading';
import { getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import DropdownList from 'react-widgets/lib/DropdownList';
import { getConceptListSelectData } from 'src/redux/conceptRequest/conceptRequestReducer';
import { tryListConcepts, tryListConceptsSelect } from 'src/redux/conceptRequest/conceptRequestActionCreator';
import {getRecruitmentMethodsData} from 'src/redux/wageTemplate/wageTemplateReducer';
import { tryPostWagesTemplate } from 'src/redux/wageTemplate/wageTemplateActionCreator';
import { AdministrativeServiceData } from '../../../utils/administrativeServiceUtils';


const WagesTemplateNewPage = () => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm({ shouldFocusError: true });
	const { cancel, token } = axios.CancelToken.source();
	const [contratacion, setContratacion] = useState();
	const [conceptoDesc, setConceptoDesc] = useState('');
	const [conceptoId, setConceptoId] = useState();
	const [subConceptoArray, setSubConceptoArray] = useState([]);
	const [subConceptoDesc, setSubConceptoDesc] = useState('');
	const [subConceptoId, setSubConceptoId] = useState();
	const [subConceptoParentId, setSubConceptoParentId] = useState();
	const [wageItem, setWageItem] = useState('');
	const [wageCode, setWageCode] = useState('');
	const [servicio, setServicio] = useState();
	const [validateConcept, setValidateConcept] = useState({valid:true, text:''});

	// Validations
	const administrativeServiceIdMsg = 'Debe seleccionar un servicio administrativo';
	const typeMsg = 'Debe ingresar el método de contratación';
	const nameMsg = 'Debe ingresar el nombre de la plantilla';
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

	// Form data
	const administrativeServiceIdObj = { required: administrativeServiceIdMsg };
	const typeObj = { required: typeMsg };
	const nameObj = { required: nameMsg };

	// Wage item selected
	const [wageItemSelected, setWageItemSelected] = useState([]);
	const onClickRemoveWageTemplateSelected = data => {
		setWageItemSelected(wageItemSelected.filter(items => {
			const isDifferentItem = items?.item !== data?.item;
			const isDifferentConcept = items?.concept?.description !== data?.concept?.description;
			const isDifferentSubConcept = items?.subConcept?.description !== data?.subConcept?.description;
			return isDifferentItem || isDifferentConcept || isDifferentSubConcept;
		}));
	};

	const recruitmentMethods = useSelector(state => getRecruitmentMethodsData(state));

	const onFormSubmit = data => {
		const params = {
			administrativeServiceId: servicio,
			type: contratacion,
			name: data?.name,
			wageTemplateItemCodes: wageItemSelected
		};
		if(wageItemSelected?.length > 0) {
			dispatch(tryPostWagesTemplate(params)).then(response => {
				if (response.status == 201) {
					dispatch(push(WAGES_TEMPLATE_LIST));
				}
			});
		} else {
			setValidateConcept({valid:false, text:'No ha cargado ningún concepto'});
		}
	};

	const administrativeServiceData = AdministrativeServiceData();
	const conceptListData = useSelector((state) => getConceptListSelectData(state)?.records);
	const conceptListDataDescription = conceptListData?.filter(item => item?.administrativeServiceId === servicio)?.map(item => item?.description);
	const subConDesc = subConceptoArray?.map(i =>  i?.description);
	const subConId = subConceptoArray?.map(i =>  i?.id);
	const handleSubConcept = value => {
		const itemSubConcept = subConceptoArray?.find(i => i.description === value)
		setSubConceptoId(itemSubConcept?.id);
		setSubConceptoDesc(itemSubConcept?.description);
		setSubConceptoParentId(itemSubConcept?.parentId);
	}

	const handleService = (service) => {
		setServicio(service?.id);
		setConceptoDesc('');
		setConceptoId('');
		setSubConceptoId('');
		setSubConceptoDesc('');
		setSubConceptoParentId('');
		setSubConceptoArray([]);
		setWageItemSelected([]);
	};

	const handleConcept = (value) => {
		const conceptSelected = conceptListData.find(item => item?.description === value);
		setConceptoDesc(value);
		setConceptoId(conceptSelected?.id);
		const objeto = conceptSelected?.subConcept;
		const objetos = objeto?.map(item => ({
			id: item.id,
			description: item.description,
			code: item.code,
			level: item.level,
			parentId: item.parentId,
			canDelete: item.canDelete,
		}));
		setSubConceptoArray(objetos);
	}

	const defaultParams = {
		number: "",
		year: "",
		description: "",
		token: token
	}

	const handleSetWageItem = e => setWageItem(e.target.value.toUpperCase());
	const handleSetWageCode = e => setWageCode(e.target.value);
	const handleSetContratacion = e => setContratacion(e.target.value);
	const onClickCancelButton = () => dispatch(push(WAGES_TEMPLATE_LIST));
	const validateString = (string) => string.replace(/\s/g, '');
	const validateDuplicates = (arrayWage, objWage) =>  arrayWage?.every(obj => {
		const isDifferentItem = objWage?.item !== obj?.item;
		const isDifferentConcept = objWage?.concept?.description !== obj?.concept?.description;
		const isDifferentSubConcept = objWage?.subConcept?.description !== obj?.subConcept?.description;
		return isDifferentItem || isDifferentConcept || isDifferentSubConcept;
	});
	const validarAtributos = (arrayWage, objWage) => {
		if(validateString(objWage?.item) === '' || validateString(objWage?.concept?.description) === '' || validateString(objWage?.subConcept?.description) === '') {
			setValidateConcept({valid:false, text:'Debe contener Concepto, Subconcepto e Item'});
		} else if( !validateDuplicates(arrayWage, objWage) ) {
			setValidateConcept({valid:false, text:'El Concepto, Subconcepto e Item ya se utilizaron'});
		} else {
			return true;
		}
	};
	const handleAddItem = () => {
		const itemParams = {
			item: wageItem,
			code: wageCode,
			concept: {
				id: conceptoId,
				description: conceptoDesc,
				level: 1,
				parentId: null
			},
			subConcept: {
				id: subConceptoId,
				description: subConceptoDesc,
				level: 2,
				parentId: subConceptoParentId
			}
		};
		if( validarAtributos(wageItemSelected, itemParams) ) {
			setWageItemSelected([...wageItemSelected, itemParams]);
			setSubConceptoDesc('');
			setWageCode('');
			setWageItem('');
			setValidateConcept({valid:true, text:''});
		};
	}

	useEffect(() => {
		dispatch(tryListConceptsSelect());
	}, []);

	return <>
		<Container fluid>
			<Card className='mb-3'>
				<Card.Header className='h6'>
					Nueva Plantilla de Liquidación de Haberes
				</Card.Header>
				<Card.Body>
					<span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
						Obligatorio
					</span>
					<Form onSubmit={handleSubmit(onFormSubmit)} className='text-black-color mt-5 mb-5'>
						<Form.Group className='text-black-color' as={Row}>
							<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								Servicio
							</Form.Label>
							<Col sm='6' id='administrativeServiceId' >
								<DropdownList
									placeholder='Seleccione un servicio...'
									data={administrativeServiceData}
									allowCreate='onFilter'
									name='administrativeServiceId'
									textField='displayName'
									valueField='id'
									filter='contains'
									value={servicio}
									className='w-100 text-black-color'
									onChange={value => handleService(value)}
									id='administrativeServiceId'
									messages={messagesDropDown}
									selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
									searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
								>
				                </DropdownList>
								<FormFieldError errors={errors?.administrativeServiceId} />
				                <Form.Control hidden ref={register(administrativeServiceIdObj)} name='administrativeServiceId' value={servicio} />
							</Col>
						</Form.Group>
						<Form.Group className='text-black-color' as={Row}>
							<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								Método de contratación
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									as='select'
									name='type'
									onChange={handleSetContratacion}
									value={contratacion}
									ref={register(typeObj)}
								>
									<option value={''} selected>Seleccione una opcion...</option>
										{
											recruitmentMethods?.map((item, idx) => (
												<option value={item} key={idx}>
													{item.replace(/_/g, ' ')}
												</option>
											))
										}
								</Form.Control>
								<FormFieldError errors={errors?.type} />
							</Col>
						</Form.Group>
						<Form.Group className='text-black-color' as={Row}>
							<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
								<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
								Nombre de plantilla
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									type='string'
									name='name'
									id='name'
									placeholder="Nombre de plantilla"
									ref={register(nameObj)}
								/>
								<FormFieldError errors={errors?.name} />
							</Col>
						</Form.Group>
						<Card className='my-3 mb-5' >
							<h6 className='px-3 py-2 d-flex text-left font-italic'>
								Concepto de liquidación
							</h6>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									Concepto
								</Form.Label>
								<Col className='d-flex align-items-center justify-content-end' sm='6' id='concept' >
									<DropdownList
										placeholder='Seleccione un concepto...'
										data={conceptListDataDescription}
										allowCreate='onFilter'
										name='concept'
										textField='displayName'
										valueField='id'
										filter='contains'
										value={conceptoDesc}
										className='w-100 text-black-color'
										onChange={value => handleConcept(value)}
										id='concept'
										messages={messagesDropDown}
										selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
										searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
									>
									</DropdownList>
								</Col>
							</Form.Group>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									Subconcepto
								</Form.Label>
								<Col className='d-flex align-items-center justify-content-end' sm='6' id='concept' >
									<DropdownList
										placeholder='Seleccione un subconcepto...'
										data={subConDesc}
										allowCreate='onFilter'
										name='concept'
										textField='displayName'
										valueField='id'
										filter='contains'
										value={subConceptoDesc}
										className='w-100 text-black-color'
										onChange={value => handleSubConcept(value)}
										id='concept'
										messages={messagesDropDown}
										selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
										searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
									>
									</DropdownList>
								</Col>
							</Form.Group>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									Item
								</Form.Label>
								<Col sm='3'>
									<Form.Control
										type="string"
										placeholder="Item"
										name="item"
										id="item"
										value={wageItem}
										onChange={handleSetWageItem}
									/>
								</Col>
								<Form.Label className='text-right d-flex mandatory-label' column sm='1'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									Código
								</Form.Label>
								<Col sm='2'>
									<Form.Control
										type='number'
										name='code'
										id='code'
										value={wageCode}
										onChange={handleSetWageCode}
										placeholder="Código"
									/>
								</Col>
								<Col sm='1'>
									<Button
										size='sm'
										className='py-2 text-white-color'
										variant='primary'
										onClick={handleAddItem}
									>
										<FontAwesomeIcon icon={faPlus} className='mx-2' />
									</Button>
								</Col>
							</Form.Group>
							{ !validateConcept?.valid &&
								<Alert variant='warning' className='mx-3 mb-3'>
									<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
									{validateConcept?.text}  
								</Alert>
							}
						</Card>
						{wageItemSelected &&
							<Table striped hover size='sm' responsive>
								<thead>
									<tr size='lg'>
										<th className='text-center align-middle text-white' width="20%">Concepto</th>
										<th className='text-center align-middle text-white' width="20%">Subconcepto</th>
										<th className='text-center align-middle text-white' width="20%">Item</th>
										<th className='text-center align-middle text-white' width="20%">Código</th>
										<th className='text-center align-middle text-white' width="20%">Acciones</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{wageItemSelected.map(item =>
										<tr>
											<td className='text-center'>
												{item?.concept?.description}
											</td>
											<td className='text-center'>
												{item?.subConcept?.description}
											</td>
											<td className='text-center'>
												{item?.item}
											</td>
											<td className='text-center maxTruncate'>
												{item?.code}
											</td>
											<td className='text-center'>
												<ActionIcon
													size='sm'
													icon={faMinusCircle}
													id='adduser'
													toolTipText='Remover pedido fondo'
													className='text-danger mr-2'
													onClick={() => onClickRemoveWageTemplateSelected(item)}
												/>
											</td>
										</tr>
									)}
									<tr>
										<td colSpan='6' className='text-center'>
											{wageItemSelected.length < 1 &&
												<Alert variant='info' className='mb-0'>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No se ha seleccionado ningún concepto
												</Alert>
											}
										</td>
									</tr>
								</tbody>
							</Table>
						}
						<Form.Group className='mt-4 d-flex justify-content-around text-white-color'>
							<Button className='text-white-color' variant='primary' size='lg' onClick={onClickCancelButton}>
								Cancelar
							</Button>{' '}
							<Button className='text-white-color' type='submit' variant='success' size='lg' >
								Guardar
								<FontAwesomeIcon className='ml-3' icon={faSave} />
							</Button>
						</Form.Group>
					</Form>
				</Card.Body>
			</Card>
		</Container>
	</>;
};

export default WagesTemplateNewPage;