import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import MultiSelect from "react-multi-select-component";
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { parseIntOrUndefined, dateNeutralFormatedToShowARG } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportMovementsStatusCreditExecution } from 'src/redux/reports/reportsActionCreactor';
import { getReportMovementsStatusCreditExecutionIsFetching } from 'src/redux/reports/reportsReducer';
import { clearGetReportMovementsStatusCreditExecution } from 'src/redux/reports/reportsActions';
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getProfileName, getEmail } from 'src/redux/login/loginReducer';
import { getlistPeriodsMonthlyData, getEnableDateMonthlyPeriodCreditExecutionData, getEnableDateMonthlyPeriodCreditExecutionIsFetching } from "src/redux/exercise/exerciseReducer";
import { tryListPeriodsMonthly, tryGetReportEnableDateMonthlyPeriodCreditExecution } from 'src/redux/exercise/exerciseActionCreator';
import { clearListPeriodsMonthlyData } from 'src/redux/exercise/exerciseActions';
import { getUserListData } from 'src/redux/user/userReducer';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import { clearListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActions';
import MaxDate from 'src/components/common/MaxDate';
import { shortlistedService } from 'src/utils/utils';
import { tryGetActiveAdminsitrativeServiceFromExercise } from 'src/redux/exercise/exerciseActionCreator';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const ReportMovementsStatusCreditExecutionPage = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	//User Id
	const email = useSelector( state => getEmail(state) );
	const listUserData = useSelector(state => getUserListData (state));
	const userId = listUserData?.records?.find(item => item.email == email);

	const reportDates = useSelector( state => getReportDatesData(state) );
	//Profile User
	const profileName = useSelector( state => getProfileName(state) );

	const profileNameHTC = profileName == 'Fiscalizador';

	const [checkPR, setCheckPR] = useState(false);

	const clickPassiveRemainingHandler = () => {
		setCheckPR(!checkPR);
	};

	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	const complementaryPeriod = globalSelectedPeriod?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0];
	const complementaryEndDate = complementaryPeriod?.endDate;

	// Exercise details data
	const periodsMonthlyDetails = useSelector((state) =>
	getlistPeriodsMonthlyData(state)
	);

	//  Get Report enabled date monthly period - Report Credit Execution 
	const enableDateMonthlyPeriodCreditExecution = useSelector((state) =>
	getEnableDateMonthlyPeriodCreditExecutionData(state)?.data
	);
	const enableDateMonthlyPeriodCreditExecutionIsFetching = useSelector((state) =>
	getEnableDateMonthlyPeriodCreditExecutionIsFetching(state)
	);

	// Period by Exercise
	const periodsMonthly = periodsMonthlyDetails?.data;
	const hasperiodsMonthly = isNotEmptyArray(periodsMonthly);

	//Services Administrative
	const administrativeService = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

	const [dateTo, setDateTo] = useState(reportDates?.dateTo);

	const [lastPeriodId, setLastPeriodId] = useState();

	const [dateHasErrors, setDateHasErrors] = useState(false);
	const onChangeSelectedPeriod = (selectedPeriod, pMonthly = periodsMonthly) => {
		// Period by Id
		const periodById = pMonthly?.filter(
			(period) => period.id == selectedPeriod
		);
		
		let periodEndDate = periodById[0]?.endDate;
		
		setDateTo(periodEndDate);
	};

	useEffect(() => {
		dispatch(tryGetUserList());
		dispatch(clearGetReportMovementsStatusCreditExecution());
		if (profileNameHTC){
			dispatch(clearListPeriodsMonthlyData());
			dispatch(tryListPeriodsMonthly(globalSelectedPeriod?.id)).then((response)=>{
               const list = response.data.data;
               onChangeSelectedPeriod(list[list.length - 1].id, list);
               setLastPeriodId(list[list.length - 1].id);
			})
			dispatch(tryGetReportEnableDateMonthlyPeriodCreditExecution(globalSelectedPeriod?.id));
		}
		dispatch(clearListAllServiceAdministrativeByUser());
		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
	}, [globalSelectedPeriod]);

	useEffect(() => {
		setSelected([]);
	}, []);

	const [alertReportGenerate, setAlertReportGenerate] = useState(false);
	
	const [reportGeneratePDF, setReportGeneratePDF] = useState(false);

	// Service Status validation
	const servicesStateValidation = async function (serviceList) {

		let i = 0;
		let validation = false;

		while(i < serviceList?.length && validation == false){
			const serviceStatus = await dispatch(tryGetActiveAdminsitrativeServiceFromExercise(globalSelectedPeriod?.id, serviceList[i]?.value)).then((response) => {
				return response?.data?.active ? response?.data?.active : false;
			});
			validation = serviceStatus;
			i++;
		};

		return validation;
	};

	const getReport = async outputFormat => {

		// HTC profile validation
		let validateStatusService = false;
		let complementaryPeriodValidation = (dateTo == complementaryEndDate);

		if(profileNameHTC && complementaryPeriodValidation){
			validateStatusService = await servicesStateValidation(selected);
		};

		// End HTC profile validation

		if(outputFormat == 1){
			setReportGeneratePDF(true);
		}else{
			setReportGeneratePDF(false);
		}
		
		if (globalSelectedPeriod) {
			if(!validateStatusService){
				const params = {
					outputFormat,
					exerciseId: globalSelectedPeriod?.id,
					serviceIds: selected?.map(administrativeService => administrativeService?.value),
					dateTo,
					passiveRemaining: checkPR
				};
				dispatch(tryGetReportMovementsStatusCreditExecution(params)).then( response => {
					if(response?.status == 200){
						setAlertReportGenerate(true)
					}
	
				});
			} else {
				setAlertReportGenerate(false);
				swal({
					title: 'Información',
					text: "No se puede generar el reporte. Uno de los servicios seleccionados se encuentra abierto para el período complementario.",
					icon: 'info',
					buttons: [false, "Aceptar"]
				});
			}
		}

	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	//// Multiselect
	// View items selector
	const [options, setOptions] = useState([]);

	useEffect(() => {
		let optionsArray = [];
		let i = 0;
		for (i in administrativeService){
			optionsArray.push({label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value:  administrativeService[i]?.id} )
		}
		setOptions(optionsArray);
	}, [administrativeService]);

	useEffect(() => {
		const uniqueService = shortlistedService(options);
		if(uniqueService){
			setSelected(options);
		}
	}, [options]);

	// Add options
	const [selected, setSelected] = useState(options);
	
	// Idiom Spanish
	const idiom = 	{
					"selectSomeItems": "Seleccionar una o más opciones...",
					"allItemsAreSelected": "Todos los elementos están seleccionados.",
					"selectAll": "Seleccionar todo",
					"search": "Buscar",
					"clearSearch": "Limpiar búsqueda."
					}

	// Load data 	
	const customValueRenderer = (selected) => {
		return selected.length
			? selected.map(({ label }) => " ✔️" + label)
			: "Seleccione una Opción...";
		};
	const hasValueCustomValueRenderer = (selected.length === 0);
	const valueSelected = (selected == 0);
	//// END Multiselect	
	
	// Reports redux
	const reportIsFetching = useSelector(state => getReportMovementsStatusCreditExecutionIsFetching(state));
	const allowGetReport = (hasPeriodList && !reportIsFetching && dateTo && !hasValueCustomValueRenderer && !valueSelected && !dateHasErrors);

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Reporte estado de movimientos de créditos</h1>
                        <a 	className='text-white'
							target="_blank"
							href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#movimiento-de-los-cr%C3%A9ditos'
						>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>
							
										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<MultiSelect
											className='text-black-color'
											options={options}
											value={selected}
											onChange={setSelected}
											labelledBy={"Select"}
											overrideStrings={idiom}
											valueRenderer={customValueRenderer}
											hasSelectAll={true}
											isLoading={administrativeServiceIsFetching}
											ClearSelectedIcon={"🧹Limpiar"}
										/>

											{
												hasValueCustomValueRenderer
													&&
													<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
													{`${'Debe seleccionar al menos un ítem.'}`}
													</div>
											}
									
									</Form.Group>
								
								{!profileNameHTC &&
									<FormContext {...hookFormMethods}>
										<MaxDate {...{ dateTo, setDateTo, setDateHasErrors }} />
									</FormContext>
								}
								<Form.Group>																										
									<Form.Check
										label="Residuos Pasivos"
										className="text-black-color"
										id="passive-remaining"
										onChange={clickPassiveRemainingHandler}
									/>
								</Form.Group>
								{
									profileNameHTC && //Profile Fiscalizador HTC
										<Form.Group>
											<Form.Label className="text-black-color">
												Al día
												</Form.Label>
											<Form.Control
												as="select"
												className="text-black-color"
												disabled={!hasperiodsMonthly}
												onChange={(event) =>
													onChangeSelectedPeriod(
														parseIntOrUndefined(event.target.value)
													)}>
												<option	option className='text-black-color' value={''} disabled={true} selected >
												Seleccione una opción...
												</option>
												{hasperiodsMonthly
													&& periodsMonthly.map((period, index) => (
														<option key={index} value={period.id} selected={period.id ==lastPeriodId? true: false}>
															{dateNeutralFormatedToShowARG(
																period?.endDate
															)}
														</option>
													))
													}
											</Form.Control>
											{
												enableDateMonthlyPeriodCreditExecutionIsFetching 
												?
												<div className='container text-center h-100 d-flex justify-content-center align-items-center'>
													<Spinner animation='border' variant='success' className='my-3' />
												</div>
												:
												<Alert key={'1'} variant={'primary'} className='mt-4'>
												<b className='text-black-color' >Importante:</b> Tenga en cuenta que para la emisión del reporte del período anterior se contemplan 17 días hábiles luego del cierre del mismo. 
												{
													enableDateMonthlyPeriodCreditExecution?.enabledDate != undefined
													&&
													<>
													{` Próxima habilitación:  ${dateNeutralFormatedToShowARG(enableDateMonthlyPeriodCreditExecution?.enabledDate)}`}
													</>
												}
												</Alert>
											}
										</Form.Group>
									}

									{
										alertReportGenerate && reportGeneratePDF && !reportIsFetching
										&&
										<Alert key={'1'} variant={'primary'} className='mt-4'>
										<b className='text-black-color' >Aviso:</b> El reporte se esta generando en segundo plano y estará disponible en el módulo de <a 
											className='font-italic text-info cursor-pointer' 
											onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
											 reportes generados.
										</a>
										</Alert>
									}


									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Generar PDF
										</Button>
										{
											reportIsFetching
											&&
											<Spinner animation='border' />
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>

						</Form>
					</Card.Body>
				</Card>
			</Container>
		</>
};

export default ReportMovementsStatusCreditExecutionPage;