import * as ACTIONS from './sectionActions';
import * as SERVICES from 'src/services/sectionServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetSectionList = ( page=1, filter={}, pageSize=100 ) => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getSectionListRequest() );

		if(accessToken){
			
			const response = await SERVICES.listSection( accessToken, page, pageSize, filter );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getSectionListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getSectionListRequestFailure(response?.error) );
				showError( dispatch, `Error al cargar los caracteres. Detalle: ${response.error}` );
			}
		}

	};
};