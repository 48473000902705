import * as ACTIONS from './serviceActions';
import * as SERVICES from 'src/services/serviceServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetServiceList = ( page=1, filter={}, pageSize=100 ) => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getServiceListListRequest() );

		if(accessToken){
			
			const response = await SERVICES.listService( accessToken, page, pageSize, filter );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getServiceListListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getServiceListListRequestFailure(response?.error) );
				showError( dispatch, `Error al cargar los servicios. Detalle: ${response.error}` );
			}
		}

	};
};

export const tryGetListServiceByJurisdictionOrganism = (params) => {
	return  async ( dispatch, getState ) => {
		const state = getState()
		const accessToken = getAccessToken(state)
		dispatch(ACTIONS.getListServiceByJurisdictionOrganismRequest())
		if(accessToken){
			const response = await SERVICES.listServiceByJurisdictionOrganism(accessToken, params)
			if( response?.status === 200 ) {
				dispatch(ACTIONS.getListServiceByJurisdictionOrganismRequestSuccess(response?.data))
				return response?.data?.data
			} else {
				dispatch(ACTIONS.getListServiceByJurisdictionOrganismRequestFailure(response?.error))
				showError(dispatch, `Error al cargar servicio, jurisdicción y organismo. Detalle: ${response.error}`)
			}
		}
	}
}