export const ProvisionOrderDataToSend = (data, goodsAndServicesList) => {
  return {
    number: parseInt(data?.orderNumber),
    year: parseInt(data?.fiscalYearId),
    administrativeServiceId: parseInt(data?.administrativeServiceId),
    provisionOrderDate: data?.orderDate,
    initiator: data?.initiator?.trimEnd(),
    destination: data?.destination?.trimEnd(),
    deliveryLocation: data?.deliveryPlace?.trimEnd(),
    legalInstrument: {
      legalInstrumentType: {
        name: data?.legalInstrumentTypeId
      },
      number: data?.legalInstrumentNumber,
      year: data?.legalInstrumentYear,
      date: data?.legalInstrumentDate
    },
    administrativeDocument: {
      year: parseInt(data?.organismYear),
      number: parseInt(data?.organismNumber),
      codeOrganism: parseInt(data?.organismCode),
      title: "Orden de pago"
    },
    acquisitionType: data?.acquisitionType,
    acquisitionNumber: parseInt(data?.acquisitionNumber),
    acquisitionYear: parseInt(data?.acquisitionYear),
    adquisitionDate: "2023-05-31",
    deadlineAmount: parseFloat(data?.deadlineAmount),
    deadlineTimeType: data?.deadlineTimeType,
    observations: "",
    beneficiary: {
      type: "PROVEEDOR",
      cuit: parseInt(data?.cuit.replace(/-/g, "")),
      persona: {
        companyName: data?.name,
      }
    },
    provisionOrderRows: goodsAndServicesList,
  };
};
