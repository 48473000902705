import * as ACTIONS from './budgetItemControlActions';
import * as SERVICES from 'src/services/budgetItemControlServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetBudgetItemControl = (params = {}) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getBudgetItemControlRequest(params));

		if(accessToken){
			
			const response = await SERVICES.getBudgetItemsControl(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.getBudgetItemControlRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getBudgetItemControlRequestFailure(response?.error || response));
				showError(dispatch, `Error al cargar los datos del item de presupuesto. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			}
			return response
		}

	};

};