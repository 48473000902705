import React, { useEffect, useRef, useState } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Col, Alert, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchDollar, faPlus, faExclamationTriangle, faFilter, faBroom, faFilePdf, faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import axios from 'axios';
import ActionIcon from 'src/components/general/ActionIcon';
import { useForm } from 'react-hook-form';
import { getUserPermissionsExecutionBudget, getUserPermissionsControlBudget } from 'src/redux/login/loginReducer';
import { tryGetSubcodeList, tryGetFileSubcode } from 'src/redux/subcode/subcodeActionCreator';
import { clearSelectedLegalInstrument, clearSubcodeAffectationsHistory, clearSubcodeDetails, clearSubcodeDetailsPageData, clearSubcodeListData, clearPreloadState } from 'src/redux/subcode/subcodeActions';
import { getSubcodeListData, getSubcodeListIsFetching } from 'src/redux/subcode/subcodeReducer';
import { clearNewAffectationDataSelectedCredits, setCreditQueryDataCreditSelected, clearCreditQueryDataCreditSelected, clearNewAffectationAdministrativeDocumentData, setAffectationHistoryDataCreditSelected, clearExtractAffectation, clearAffectationHistoryDataCreditSelected } from 'src/redux/affectation/affectationActions';
import { getGlobalDataSelectedPeriod, getFilterObject, getGlobalDataPeriodsData } from 'src/redux/globalData/globalDataReducer';
import { isNotEmptyArray, isNotEmptyObject } from 'src/services/validationService';
import { formatterPeso, numberNegativeRed, getClearObjectAlt } from 'src/utils/utils';
import { CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE, CREDIT_EXECUTION_CREDIT_QUERY, CREDIT_STEP_ONE, COMPENSATION_STEP_ONE, INCORPORATION_CREDIT_STEP_ONE  } from 'src/utils/constants';
import { setFilterObject,  clearFilterObject} from 'src/redux/globalData/globalDataActions';
import AppLoading from 'src/components/common/AppLoading';
import RCPagination from 'src/components/common/RCPagination';

import { serviceLabel, jurisdiction, purpose, sector, section, creditInitial, uOrganizaciónOrganismo, functio, characterLabel, account, code, subcode, creditPartial, serviceAbbreviation, jurisdictionAbbreviation, unidOrgAbbreviation, characterAbbreviation, purposeAbbreviation, functionAbbreviation, sectionAbbreviation, sectorAbbreviation, creditInitialAbbreviation, creditPartialAbbreviation, codeAbbreviation, subcodeAbbreviation, visibleNameAbbrevation, creditBalance, newCredit, newInvolvement, newCompensation, budgetIncorporation } from 'src/utils/label';
import { clearTotalAmountsData } from 'src/redux/credit/creditActions';
import { tryGetTotalAmounts } from 'src/redux/credit/creditActionCreator';
import { getTotalAmountsData } from 'src/redux/credit/creditReducer';
import BalanceTotalModal from 'src/components/pages/CreditExecution/BalanceTotalModal';
import { dataPeriodsContructor } from 'src/utils/utils';
import { tryGetActiveAdminsitrativeServiceFromExerciseAndUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getActiveAdministrativeServiceFromExerciseAndUserData, getActiveAdministrativeServiceFromExerciseAndUserIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { getUserGlobalSet } from 'src/redux/user/userReducer'; 
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const CreditExecutionListingPage = () => {
	const dispatch = useDispatch();
	const { handleSubmit, register, reset, getValues } = useForm();

	const creditLabelsRefContainer = useRef(null);
	const colSpanCellsNumber = creditLabelsRefContainer?.current?.cells.length;

	const [isLoading, setIsLoading] = useState(false);

	const environment = config.environment;

	// Permissions
	const controlBudgetPermissions = useSelector(state => getUserPermissionsControlBudget(state));
	const executionBudgetPermissions = useSelector(state => getUserPermissionsExecutionBudget(state));
	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	const periodsData = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const creditListingData = useSelector(state => getSubcodeListData(state));
	const isFetching = useSelector(state => getSubcodeListIsFetching(state));
	const records = creditListingData?.data;

	// Service Administrative manager
	const activeAdministrativeServiceFromExerciseAndUserData = useSelector(state => getActiveAdministrativeServiceFromExerciseAndUserData(state));
	const activeAdministrativeServiceFromExerciseAndUserIsFetching = useSelector(state => getActiveAdministrativeServiceFromExerciseAndUserIsFetching(state));

	const userGlobalSet = useSelector(state => getUserGlobalSet(state));

	//Pagination
	const page = creditListingData?.page;
	const totalItems = creditListingData?.totalCount;
	const perPage= creditListingData?.perPage;

	const hasRecords = isNotEmptyArray(records);
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();

	const loadAllDataUser = () => {
			let exerciseId = parseInt(periodSelected?.id);
			let userId = parseInt(userGlobalSet?.id);
			dispatch(tryGetActiveAdminsitrativeServiceFromExerciseAndUser(userId, exerciseId))
	}
	useEffect(loadAllDataUser, []);

	const totalAmountsData = useSelector(state => getTotalAmountsData(state));
	const loadCredits = () => {
		dispatch(clearNewAffectationDataSelectedCredits());
		dispatch(clearCreditQueryDataCreditSelected());
		dispatch(clearNewAffectationAdministrativeDocumentData());
		dispatch(clearSelectedLegalInstrument());
		dispatch(clearSubcodeDetails());
		dispatch(clearSubcodeDetailsPageData());
		dispatch(clearSubcodeAffectationsHistory());
		dispatch(clearAffectationHistoryDataCreditSelected());
		dispatch(clearPreloadState());
		reset();
		if(globalFilterObject){
			fetchList({
				filter: {...globalFilterObject},
			});
		}else{
			fetchList();
		}
	};

	useEffect(loadCredits, [periodSelected]);

	const onPageChange = (pNumber) => {
		const object = {
			filter: {...globalFilterObject},
			page: pNumber,
		};
		fetchList(object);
	}

	const onClickNewAffectation = () => {
		dispatch(clearNewAffectationDataSelectedCredits());
		dispatch(clearExtractAffectation());
		dispatch(push(CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE));
	};

	const onClickNewItemCompensation = () => {
		dispatch(push(COMPENSATION_STEP_ONE));
	};

	const onClickNewCreditModification = () => {
		dispatch(push(CREDIT_STEP_ONE));
	};

	const onClickNewIncorporation = () => {
		dispatch(push(INCORPORATION_CREDIT_STEP_ONE));
	};

	const onClickDownloadList = () => {
		setIsLoading(true);
		dispatch(tryGetFileSubcode({
			page,
			filter: globalFilterObject,
		})).finally(() => setIsLoading(false));
	};

	const onClickCreditQuery = creditData => {
		const budgetType = getValues()?.tipoDeEjercicio;
		const rpDetails = (dataPeriodsContructor(periodSelected, periodsData).filter( (item) => item.value === budgetType));
		const rpParams = {
			...rpDetails[0],
			period: periodSelected?.year
		}
		dispatch(setCreditQueryDataCreditSelected({...creditData, rpParams}));
		dispatch(setAffectationHistoryDataCreditSelected(creditData));
		dispatch(push(CREDIT_EXECUTION_CREDIT_QUERY));
	};

	const onSubmitForm = (data) => {
		if(!isFetching) {
			const objToSend = (dataPeriodsContructor(periodSelected, periodsData).filter( (item) => item.value === data.tipoDeEjercicio));
			const objToSendFilter = objToSend.map(({visibleName,...rest}) => ({...rest}));
			delete data["tipoDeEjercicio"];
			cancelSource.cancel();
			cancelSource = CancelToken.source();
			const finalData = getClearObjectAlt(data);

			const filter = {
				period_rp_id: objToSendFilter[0]?.period_rp_id, 
				is_passive_remaining: objToSendFilter[0]?.isPassiveRemaining,
				period_budget_selected: objToSendFilter[0],
				...finalData
			}
			
			const filterToSend = getClearObjectAlt(filter);

			const paramsToSend ={
				filter: filterToSend
			}
			fetchList(paramsToSend);
		}
	}

	const [dataItem, setDataItem] = useState();
	const onMouseOverItem = item => {
		setDataItem(item);
	}

	const fetchList = (params) => {

		const globalFilterParams = params ? JSON.parse(JSON.stringify(params?.filter)): {};
		delete params?.filter?.period_budget_selected;
		dispatch(clearSubcodeListData());
		dispatch(clearTotalAmountsData());
		dispatch(tryGetSubcodeList(params));
		dispatch(tryGetTotalAmounts({...params?.filter}, cancelSource.token));
		dispatch(setFilterObject(globalFilterParams))
	}


	const cleanFilters = () => {
		dispatch(clearFilterObject());
		reset();
		fetchList();
    };


	return <Container fluid className='lisrtin'>
			<Card className='mb-5'>
				<Card.Header className='d-flex justify-content-between'>
					<h1 className="h6 mt-1 mb-0">Partidas Presupuestarias</h1>
					<a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#ejecuci%C3%B3n-de-presupuesto---partidas-presupuestarias'>
						<FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
						<small>Ver manual de uso</small> 
					</a>
				</Card.Header> 
						<Card.Body>
							<div className='d-flex align-items-center mb-3'>
								{
									executionBudgetPermissions?.canCreate && (periodSelected?.exerciseState === "EXECUTION" && !activeAdministrativeServiceFromExerciseAndUserData)
										&&
										<Button size='sm' className='mb-3' variant='success' onClick={onClickNewAffectation} disabled={activeAdministrativeServiceFromExerciseAndUserIsFetching} >
											<FontAwesomeIcon icon={faPlus} className='mr-1' />
											{newInvolvement}
										</Button>
								}
								&nbsp;
								{
									controlBudgetPermissions?.canCreate && (periodSelected?.exerciseState === "EXECUTION" && !activeAdministrativeServiceFromExerciseAndUserData)
										&&
										<Button size='sm' className='mb-3' variant='success' onClick={onClickNewCreditModification} disabled={activeAdministrativeServiceFromExerciseAndUserIsFetching} >
											<FontAwesomeIcon icon={faPlus} className='mr-1' />
											{
												newCredit
											}
										</Button>
								}
								&nbsp;
								{
									controlBudgetPermissions?.canCreate && 
									(periodSelected?.exerciseState === "EXECUTION" && !activeAdministrativeServiceFromExerciseAndUserData)
										&&
										<Button size='sm' className='mb-3' variant='success' onClick={onClickNewItemCompensation}>
											<FontAwesomeIcon icon={faExchangeAlt} className='mr-1'/>
											{newCompensation}
										</Button>
								}
								&nbsp;
								{
									controlBudgetPermissions?.canCreate && environment == "dev"
										?
										<Button size='sm' className='mb-3' variant='success' onClick={onClickNewIncorporation}>
											<FontAwesomeIcon icon={faPlus} className='mr-1' />
											{
												budgetIncorporation
											}
										</Button>
										:
											null
								}

								<span  className={ ( activeAdministrativeServiceFromExerciseAndUserIsFetching ? '' : 'hidden') }>
									<Spinner className='spinner-border text-danger' animation='border' size='sm' />
								</span>

								<BalanceTotalModal 
									totalAmountsData = {totalAmountsData}
								/>
							</div>
							<div className='d-flex align-items-center justify-content-between mb-3'>
								<span className='ml-auto' >
									<Button size='sm' variant='secondary' onClick={onClickDownloadList} disabled={!isNotEmptyObject(globalFilterObject)} >
										<FontAwesomeIcon icon={faFilePdf} className='mr-1' />
										Exportar resultados
									</Button>
								</span>
							</div>	
							<tr>
								<Card.Title>
									<td className='text-black-color shadow-sm p-2 mb-5 bg-white rounded'>Referencia: {dataItem ? dataItem : '-'}</td>
								</Card.Title>
							</tr>
							<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
								{
									<>
										<Col className='col-4 px-0 d-flex align-items-center justify-content-end' >
											<Form.Control
												as="select"
												ref={register}
												name='tipoDeEjercicio'
												value={globalFilterObject?.period_budget_selected?.value}
												className="w-100 text-black-color"
												onChange={()=>onSubmitForm(getValues())}
											>
												{dataPeriodsContructor(periodSelected, periodsData ).map( (item, i) =>
													<option key={i} value={item?.value}>
														{item.visibleName}
													</option>
												)}
											</Form.Control>
											<ActionIcon
												className='ml-4'
												size='lg'
												toolTipText='Limpiar filtros '
												icon={faBroom}
												onClick={() => cleanFilters()}
											/>
										</Col>
										<br></br>
									</>
								}
								<Table striped hover className='table-responsive' size='sm'>
									<thead>
										<tr ref={creditLabelsRefContainer}>
											<th className='text-center align-middle'>{serviceAbbreviation}</th>
											<th className='text-center align-middle'>{jurisdictionAbbreviation}</th>
											<th className='text-center align-middle'>{unidOrgAbbreviation}</th>
											<th className='text-center align-middle'>{characterAbbreviation}</th>
											<th className='text-center align-middle'>Cta.</th>
											<th className='text-center align-middle'>{purposeAbbreviation}</th>
											<th className='text-center align-middle'>{functionAbbreviation}</th>
											<th className='text-center align-middle'>{sectionAbbreviation}</th>
											<th className='text-center align-middle'>{sectorAbbreviation}</th>
											<th className='text-center align-middle'>{creditInitialAbbreviation}</th>
											<th className='text-center align-middle'>{creditPartialAbbreviation}</th>
											<th className='text-center align-middle'>{codeAbbreviation}</th>
											<th className='text-center align-middle'>{subcodeAbbreviation}</th>
											<th className='text-center align-middle th-minwidth'>{visibleNameAbbrevation}</th>
											<th className='text-center align-middle' width="10%">{creditBalance}</th>
											<th className='text-center align-middle th-minwidth'>Acciones</th>
										</tr>
										<tr className='secondary'>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='service_number' ref={register}
												defaultValue={globalFilterObject?.service_number 
													? 
													globalFilterObject?.service_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='jurisdiction_number' ref={register}
												defaultValue={globalFilterObject?.jurisdiction_number
													?
													globalFilterObject?.jurisdiction_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='organization_number' ref={register}
												defaultValue={globalFilterObject?.organization_number
													?
													globalFilterObject?.organization_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='character_number' ref={register}
												defaultValue={globalFilterObject?.character_number
													? 
													globalFilterObject?.character_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='account_number' ref={register}
												defaultValue={globalFilterObject?.account_number
													? 
													globalFilterObject?.account_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='purpose_number' ref={register}
												defaultValue={globalFilterObject?.purpose_number
													? 
													globalFilterObject?.purpose_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='functionality_number' ref={register}
												defaultValue={globalFilterObject?.functionality_number
													? 
													globalFilterObject?.functionality_number : ""}
												/>
											</th>
											<th className='text-center align-middle'>
												<Form.Control className='text-center' size='sm' type='number' name='section_number' ref={register}
												defaultValue={globalFilterObject?.section_number
													?
													globalFilterObject?.section_number : ""}
												/>
											</th>
											<th className='text-center align-middle'>
												<Form.Control className='text-center' size='sm' type='number' name='sector_number' ref={register}
												defaultValue={globalFilterObject?.sector_number 
													? 
													globalFilterObject?.sector_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='principal_budget_number' ref={register}
												defaultValue={globalFilterObject?.principal_budget_number
													?
													globalFilterObject?.principal_budget_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='partial_budget_number' ref={register}
												defaultValue={globalFilterObject?.partial_budget_number
													?
													globalFilterObject?.partial_budget_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='code_number' ref={register}
												defaultValue={globalFilterObject?.code_number 
													?
													globalFilterObject?.code_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='number' name='sub_code_number' ref={register}
												defaultValue={globalFilterObject?.sub_code_number
													?
													globalFilterObject?.sub_code_number : ""}
												/>
											</th>
											<th>
												<Form.Control className='text-center' size='sm' type='text' name='visible_name' ref={register}
												defaultValue={globalFilterObject?.visible_name
													?
													globalFilterObject?.visible_name : ""}
												/>
												<Button className="d-none" type="submit">Send</Button>
											</th>
											<th>

											</th>
											<th className='text-center align-middle'>
												<div className='d-flex justify-content-around'>
													<ActionIcon
														size="lg"
														id="search-button"
														className="btn-primary search-button text-white-color"
														toolTipText="Filtrar"
														icon={faFilter}
														type='submit'
														onSubmit={onSubmitForm}	
													/>
													<ActionIcon
														size="lg"
														id="clean-filter"
														className="btn-primary clean-filter text-white-color"
														toolTipText="Limpiar filtros"
														icon={faBroom}
														type='reset'
														onClick={()=> cleanFilters()}
													/>
												</div>
											</th>
										</tr>
									</thead>
									<tbody className='text-black-color'>
										{
											hasRecords ?
												records?.map(item => {
													return (
													<tr key={item?.id}>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${serviceLabel} - `+ item?.code?.credit?.service?.name)} >
															{item?.code?.credit?.service?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${jurisdiction} - ` + item?.code?.credit?.organization?.jurisdiction?.name)}>
															{item?.code?.credit?.organization?.jurisdiction?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${uOrganizaciónOrganismo} - ` + item?.code?.credit?.organization?.name)}>
															{item?.code?.credit?.organization?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${characterLabel} - ` + item?.code?.credit?.character?.name)}>
															{item?.code?.credit?.character?.code}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${account} - ` + item?.code?.credit?.account?.name)}>
															{item?.code?.credit?.account?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${purpose} - ` + item?.code?.credit?.functionality.purpose?.name)}>
															{item?.code?.credit?.functionality.purpose?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${functio} - ` + item?.code?.credit?.functionality?.name)}>
															{item?.code?.credit?.functionality?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${section} - ` + item?.code?.credit?.partialBudget?.principalBudget?.sector?.section?.name)}>
															{item?.code?.credit?.partialBudget?.principalBudget?.sector?.section?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${sector} - ` + item?.code?.credit?.partialBudget?.principalBudget?.sector?.name)}>
															{item?.code?.credit?.partialBudget?.principalBudget?.sector?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${creditInitial} - ` + item?.code?.credit?.partialBudget.principalBudget?.name)}>
															{item?.code?.credit?.partialBudget.principalBudget?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${creditPartial} - ` + item?.code?.credit?.partialBudget?.name)}>
															{item?.code?.credit?.partialBudget?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${code} - ` + item?.code?.name)}>
															{item?.code?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(`${subcode} - ${item?.name != null ? item?.name : ''}`)}>
															{item?.number}
														</td>
														<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(" - ")}>
															{item?.visibleName}
														</td>
														<td className={`${numberNegativeRed(item?.balance?.currentBalance)} + text-right align-middle`} onMouseOver={() => onMouseOverItem()}>
															{formatterPeso.format(item?.balance?.currentBalance)}
														</td>
														<td className='text-center align-middle p-0'>
															{
																executionBudgetPermissions?.canView
																	?
																	<ActionIcon size='lg' id='credit-query' toolTipText='Consulta de créditos' icon={faSearchDollar} onClick={() => onClickCreditQuery(item)} />
																	:
																	null
															}
														</td>
													</tr>);
												})
												:
												<tr>
													<td colSpan={colSpanCellsNumber} className='text-center'>
														{
															!isFetching &&
																<Alert variant='info' className='mb-0'>
																	<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																	No hay partidas o no se posee permiso de  acceso a las mismas.
																</Alert>
														}
													</td>
												</tr>
										}
									</tbody>
								</Table>
							</Form>
							<RCPagination
								activePage={page}
								itemsCountPerPage={perPage}
								totalItemsCount={totalItems}
								totalItems={totalItems}
								thePage={page}
								onChange={onPageChange}
								innerClass="justify-content-center"
							/>
						</Card.Body>
			</Card>
		<AppLoading isLoading={isLoading || isFetching} />
	</Container>;
};

export default CreditExecutionListingPage;