import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import * as LABELS from 'src/utils/label';
import { isQualifiedValidDate, isDateBetweenRange } from 'src/utils/utils';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { setReportDates } from 'src/redux/globalData/globalDataActions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";


const MaxDate = props => {
	const dispatch = useDispatch();
	const { setDateHasErrors, dateTo, setDateTo, isRequired } = props;
	const { register, triggerValidation } = useFormContext();
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	const [enableMessageError, setEnableMessageError] = useState(false);

	const validateDatePeriod = () => {
		if( !isDateBetweenRange(globalSelectedPeriod, dateTo)){
			setEnableMessageError(true);
		}else{
			setEnableMessageError(false);
		}
	}

	// Date check, uso badInput para verificar fechas erroneas ya el input queda empty
	const errorDateTo = (dateTo && !isQualifiedValidDate(dateTo)) || document.forms[0]?.dateTo?.validity?.badInput;
	const errorsDates = ( errorDateTo );

	useEffect(() => {
		if (dateTo){
			dispatch(setReportDates({dateTo}))
		}
	}, [dateTo]);

	let hasErrors = errorsDates || enableMessageError;
	setDateHasErrors(hasErrors);
	// useEffect(() => {
	// 	let hasErrors = errorsDates || enableMessageError || messageError;
	// 	setDateHasErrors(hasErrors);
	// }, [enableMessageError, messageError]);

	useEffect(() => {
		triggerValidation();
	}, [globalSelectedPeriod]);

	let exerciseStartDate = globalSelectedPeriod?.periods[0].startDate;
	let exerciseEndDate = globalSelectedPeriod?.periods[13].endDate;

	return <>
		<Form.Group>
			{isRequired && <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' /> }
			<Form.Label className='text-black-color'>
				{LABELS.upToDay}
			</Form.Label>
			<Form.Control
					ref={register({ required: 'ingrese fecha', validate: validateDatePeriod })}
					name='dateTo'
					type='date'
					min={exerciseStartDate}
					max={exerciseEndDate}
					defaultValue={dateTo}
					onChange={event => setDateTo(event.target.value)} 
					onBlur={event => validateDatePeriod(event.target.value)} />
		</Form.Group>
		{errorDateTo &&
			<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
			{`${'Fecha inválida.'}`}
			</div>
		}

		{enableMessageError &&
			<>
			<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
			{'Error: La fecha seleccionada no se encuentran dentro de los períodos habilitados del Ejercicio.'}
			</div>
			</>
		}
			
	</>;
};

export default MaxDate;