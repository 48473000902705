import React from 'react';
import { Form, Row, Col, Container, Button } from 'react-bootstrap';
import { faSearch  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAdministrativeDocumentYearsOptions } from 'src/utils/utils';
import { config } from 'src/env.js';

const legalInstrumentForm = () => {

	const LEGAL_INSTRUMENT_TYPES = config.appSettings.LEGAL_INSTRUMENTS_TYPES;
	const yearsOptions = getAdministrativeDocumentYearsOptions();

	return <>
		<Container>
			<Form.Group className='justify-content-md-center text-nowrap text-black-color mt-5' style={{marginBottom:'4rem'}}as={Row}>
				<Col sm='6' className='d-flex mx-0 px-0 mr-5 justify-content-md-center'>
					<Form.Label className='mb-0 pr-3 d-flex align-items-center justify-content-end'>
						Intrumento Legal:
					</Form.Label>
					<Form.Control
						as='select'
						name='legalInstrumentTypeId'
						className='select-min-width'
					>
						{
							LEGAL_INSTRUMENT_TYPES?.map(item => (<option className='text-black-color' key={item.id} value={item.id}>
								{item.name}
							</option>))
						}
					</Form.Control>

					<span className='px-2' />

					<Form.Control
						type='number'
						placeholder='Número'
						name = 'number'
						className='number-width'
						min='1'
					/>

					<span className='px-2' />

					<Form.Control
						as='select'
						name = 'year'
						className='select-min-width'
					>
						<option value={undefined} selected>Año</option>
						{
							yearsOptions?.map((item, idx) => (<option value={item} key={idx}>
								{item}
							</option>))
						}
					</Form.Control>

					<Button
						type='submit'
						className='ml-3 text-white-color'
					>
						<FontAwesomeIcon icon={faSearch} />
					</Button>
				</Col>

			</Form.Group>
		</Container>
	</>
}

export default legalInstrumentForm;