import React from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Container, Card, Form, Button, Spinner} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';

import StepIndicator from 'src/components/common/StepIndicator';
import { getNewAffectationSelectedCredits } from 'src/redux/affectation/affectationReducer';

import { getSubpartialBudgetOfSelectedCreditsIsFetching } from 'src/redux/partialBudget/partialBudgetReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { setSelectedCredits } from 'src/redux/globalData/globalDataActions';
import {budgetCredits} from 'src/utils/label';
import { getFormTitle } from 'src/redux/subcode/subcodeReducer';
import { CREDIT_STEP_ONE } from 'src/utils/constants';
import {back } from 'src/utils/label';
import BudgetItems from 'src/components/pages/BudgetItems'

const CreditStepTwoPage = () => {
	const dispatch = useDispatch();
	const selectedCredits = useSelector(state => getNewAffectationSelectedCredits(state));
	const formTitle = useSelector( state => getFormTitle(state));
	const isFetchingSubpartialBudgetOfSelectedCredits = useSelector(state => getSubpartialBudgetOfSelectedCreditsIsFetching(state));

	const hasSelectedCredits = isNotEmptyArray(selectedCredits);
	const enableNextStepButton = hasSelectedCredits;
	
	const onClickNextStepButton = () => {
		dispatch(setSelectedCredits(selectedCredits));
	};
	
	return <Container fluid >
		<Card className='mb-5'>
			<PageTitle text={budgetCredits}/>
			<Container fluid >
				<StepIndicator steps={config.appSettings.steps.creditControl} current={1} className='mt-2 mb-3' />
				<Card className='mb-3'>
					<Card.Header className='h6'>
						{formTitle}
					</Card.Header>
					<Card.Body>
						<BudgetItems/>
						<Form.Group className='mt-4 d-flex justify-content-around'>
							<Button type='button' variant='danger' size='lg' disabled={isFetchingSubpartialBudgetOfSelectedCredits} onClick={() => dispatch( push(CREDIT_STEP_ONE) )} >
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2'/>
								{back}
							</Button>

							<span className={isFetchingSubpartialBudgetOfSelectedCredits ? '' : 'hidden'}>
								<Spinner animation='border' />
							</span>

							<Button variant='success' size='lg' onClick={onClickNextStepButton} disabled={!enableNextStepButton || isFetchingSubpartialBudgetOfSelectedCredits}>
								Siguiente
								<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
							</Button>
						</Form.Group>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default CreditStepTwoPage;
