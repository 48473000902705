import * as ACTIONS from './partialBudgetActions';
import * as PRINCIPAL_BUDGET_SERVICES from 'src/services/principalBudgetServices';
import * as SUB_PARTIAL_BUDGET_SERVICES from 'src/services/subPartialBudgetService';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

import { isNotEmptyArray } from 'src/services/validationService';

export const tryGetPartialBudgetIdByPrincipalBudgetId = principalBudgetId => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getPartialBudgetListByPrincipalBudgetIdRequest() );
		
		if(accessToken){

			const response = await PRINCIPAL_BUDGET_SERVICES.listPartialBudgetByPrincipalBudgetId( accessToken, principalBudgetId );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getPartialBudgetListByPrincipalBudgetIdRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getPartialBudgetListByPrincipalBudgetIdRequestFailure(response?.error) );
				showError( dispatch, `Error al cargar las partidas parciales. Detalle: ${response?.error}` );
			}
		}
	};
};

export const tryGetSubPartialBudgetList = params => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getSubPartialBudgetListRequest() );

		if(accessToken){

			const response = await SUB_PARTIAL_BUDGET_SERVICES.listSubPartialBudget( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getSubPartialBudgetListRequestSuccess( response?.data ) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getSubPartialBudgetListRequestFailure(response?.error||response) );
			}
		}

	};
};

export const getSubpartialBudgetsDataOfSelectedCredits = selectedCredits => {
	return async( dispatch, getState ) => {
		if( isNotEmptyArray(selectedCredits) ) {
			const allPromises = [];

			dispatch( ACTIONS.getSubpartialBudgetOfSelectedCreditsRequest() );

			selectedCredits.forEach( credit => {
				const params = {
					pageSize: 100,
					filter: JSON.stringify({
						partial_budget: credit?.code?.credit?.partialBudget?.id
					})
				};
				allPromises.push( dispatch( tryGetSubPartialBudgetList(params) ) );
			});

			const subpartialBudgetsData = await Promise.all( allPromises )
			
			if( isNotEmptyArray(subpartialBudgetsData) ) {
				selectedCredits = selectedCredits.map( (item, index) => {
					if( subpartialBudgetsData[index]?.totalCount>0 ) {
						item.subpartialBudgetList = subpartialBudgetsData[index]?.data;
					}
					return item;
				});
			}

			dispatch( ACTIONS.getSubpartialBudgetOfSelectedCreditsRequestSuccess() );

			return selectedCredits;
		}
	};
};