import React, { useEffect, useRef, useState } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Form, Spinner, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExclamationTriangle, faFilter, faBroom, faEdit } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ActionIcon from 'src/components/general/ActionIcon';
import { useForm } from 'react-hook-form';
import { clearSubcodeListData } from 'src/redux/subcode/subcodeActions';
import { getGlobalDataSelectedPeriod, getFilterObject } from 'src/redux/globalData/globalDataReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { getClearObjectAlt } from 'src/utils/utils';
import { FIGURATIVE_EXPEDITURES_EDIT, FIGURATIVE_EXPEDITURES_NEW  } from 'src/utils/constants';
import { setFilterObject,  clearFilterObject} from 'src/redux/globalData/globalDataActions';
import AppLoading from 'src/components/common/AppLoading';
import RCPagination from 'src/components/common/RCPagination';
import { newFigurativeExpeditures } from 'src/utils/label';
import { clearFigurativeExpedituresListData, setFigurativeExpedituresCompleteNumber, setFigurativeExpedituresToEdit } from 'src/redux/figurativeExpeditures/figurativeExpedituresActions';
import { tryGetFigurativeExpedituresDetails } from 'src/redux/figurativeExpeditures/figurativeExpedituresActionCreator';
import { getFigurativeExpedituresListData, getFigurativeExpedituresListIsFetching } from 'src/redux/figurativeExpeditures/figurativeExpedituresReducer';

const FigurativeExpedituresListPage = () => {
	const dispatch = useDispatch();
	const { handleSubmit, register, reset, getValues } = useForm();
	const figurativeExpedituresRefContainer = useRef(null);
	const colSpanCellsNumber = figurativeExpedituresRefContainer?.current?.cells.length;
	const [isLoading, setIsLoading] = useState(false);
	const environment = config.environment;

	// Permissions
	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();
	
	// Fetch figurativeExpeditures
	const figurativeExpedituresListData = useSelector(state => getFigurativeExpedituresListData(state));
	const isFetching = useSelector(state => getFigurativeExpedituresListIsFetching(state));
	const records = figurativeExpedituresListData?.records;
	const hasRecords = isNotEmptyArray(records);

	// Pagination
	const page = figurativeExpedituresListData?.metadata?.page + 1;
	const totalItems = figurativeExpedituresListData?.metadata?.filteredCount;
	const perPage= figurativeExpedituresListData?.metadata?.pageSize;
	
	const defaultParams = {
		filter: {
			page: 0,
			size: 10,
			sort: 'id,asc',
			year: periodSelected?.year
		}
	};
	const handleAscend = () => {
		if(!isFetching) {
			const paramsToSend = {
				sort: 'id,asc',
				filter: {
					year: periodSelected?.year
				}
			}
			fetchList(paramsToSend);
		}
	};
	const handleDescend = () => {
		if(!isFetching) {
			const paramsToSend = {
				sort: 'id,desc',
				filter: {
					year: periodSelected?.year
				}
			}
			fetchList(paramsToSend);
		}
	};
	const loadAllDataUser = () => {
		dispatch(tryGetFigurativeExpedituresDetails(defaultParams));
	}
	const onPageChange = (pNumber) => {
		const object = {
			filter: {...globalFilterObject},
			page: pNumber - 1
		};
		fetchList(object);
	};
	const onClickNewFigurativeExpediture = () => {
		dispatch(clearFigurativeExpedituresListData());
		dispatch(push(FIGURATIVE_EXPEDITURES_NEW));
	};
    const onClickEdit = figurativeExpeditures => {
        dispatch(setFigurativeExpedituresToEdit(figurativeExpeditures));
        dispatch(setFigurativeExpedituresCompleteNumber(figurativeExpeditures.budgetTarget));
        dispatch(push(FIGURATIVE_EXPEDITURES_EDIT));
    };
	const onSubmitForm = (data) => {
		if(!isFetching) {
			const finalData = getClearObjectAlt(data);
			const filter = {
				year: periodSelected.year, 
				...finalData
			}
			const filterToSend = getClearObjectAlt(filter);
			const paramsToSend ={
				filter: filterToSend
			}
			fetchList(paramsToSend);
		}
	}
	const fetchList = (params) => {
		dispatch(clearSubcodeListData());
		dispatch(clearFigurativeExpedituresListData());
		dispatch(tryGetFigurativeExpedituresDetails(params));
		dispatch(setFilterObject(params?.filter));
	};
	const cleanFilters = () => {
		dispatch(clearFilterObject());
		reset();
		fetchList(defaultParams);
    };

	useEffect(() => {
		loadAllDataUser();
		cleanFilters();
	}, []);

	return  	<Container fluid className='mt-0'>
					<Card className='mb-5'>
						<Card.Header>
							Erogaciones figurativas
						</Card.Header>
						<Card.Body>
							<div className='d-flex align-items-center mb-3'>
				  				<Button size='sm' className='mb-3' variant='success' onClick={onClickNewFigurativeExpediture} >
									<FontAwesomeIcon icon={faPlus} className='mr-1' />
									{newFigurativeExpeditures}
								</Button>
								<span  className={ ( isFetching ? '' : 'hidden') }>
									<Spinner className='spinner-border text-danger' animation='border' size='sm' />
								</span>
							</div>
							<Dropdown>
								<Dropdown.Toggle className='btn-sm' variant="Secondary" id="dropdown-basic">
									Ordenar vinculación: 
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => handleAscend()} >Ascendente</Dropdown.Item>
									<Dropdown.Item onClick={() => handleDescend()} >Descendente</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off' >
								<Table striped hover responsive size='sm'>
									<thead>
										<tr ref={figurativeExpedituresRefContainer}>
											<th className='text-center'>Apertura</th>
											<th className='text-center'>Vinculación</th>
											<th className='text-center'>Descripción de apertura</th>
											<th className='text-center'>Acciones</th>
										</tr>
										<tr className='secondary'>
											<th width="30%">
												<Form.Control className='text-center' type='string' name='budgetTarget' ref={register}
												defaultValue={figurativeExpedituresListData?.budgetTarget
													? globalFilterObject?.budgetTarget : ""}
												/>
											</th>
											<th width="30%">
												<Form.Control className='text-center' type='string' name='budgetFilters' ref={register}
												defaultValue={globalFilterObject?.budgetFilters
													? globalFilterObject?.budgetFilters : ""}
												/>
											</th>
											<th width="30%">
												<Form.Control className='text-center' type='string' name='budgetTargetVisibleName' ref={register}
												defaultValue={globalFilterObject?.budgetTargetVisibleName
													? globalFilterObject?.budgetTargetVisibleName : ""}
												/>
											</th>
											<th className='text-center align-middle' width="10%">
												<div className='d-flex justify-content-around'>
													<ActionIcon
														size="lg"
														id="search-button"
														className="btn-primary search-button text-white-color"
														toolTipText="Filtrar"
														icon={faFilter}
														type='submit'
														onSubmit={onSubmitForm}	
													/>
													<ActionIcon
														size="lg"
														id="clean-filter"
														className="btn-primary clean-filter text-white-color"
														toolTipText="Limpiar filtros"
														icon={faBroom}
														type='reset'
														onClick={()=> cleanFilters()}
													/>
												</div>
											</th>
										</tr>
									</thead>
									<tbody className='text-black-color'>
										{
											hasRecords ?
												records?.map(item => 
													<tr key={item?.id}>
														<td className='text-center align-middle' >
															{item?.budgetTarget}
														</td>
														<td className='text-center align-middle' >
															{item?.budgetFilters?.map(a => a?.code + ", ")}
														</td>
														<td className='text-center align-middle' >
															{ item?.budgetTargetVisibleName ? item?.budgetTargetVisibleName :" - " }
														</td>
														<td className='text-center align-middle p-0'>
															<ActionIcon
																size='lg'
																toolTipText={'Editar vinculación de la figurativa'} 
																icon={faEdit}
																onClick={()=> onClickEdit(item)}
															/>
														</td>
													</tr>
												)
												:
												<tr>
													<td colSpan={colSpanCellsNumber} className='text-center'>
														{
															!isFetching &&
																<Alert variant='info' className='mb-0'>
																	<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																	No hay partidas o no se posee permiso de acceso a las mismas.
																</Alert>
														}
													</td>
												</tr>
										}
									</tbody>
								</Table>
							</Form>
							<RCPagination
								activePage={page}
								itemsCountPerPage={perPage}
								totalItemsCount={totalItems}
								totalItems={totalItems}
								thePage={page}
								onChange={onPageChange}
								innerClass="justify-content-center"
							/>
						</Card.Body>
					</Card>
					<AppLoading isLoading={isLoading || isFetching} />
				</Container>
};

export default FigurativeExpedituresListPage;