import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { faAsterisk, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { getProvisionOrderEdit ,getProvisionOrderEditIsFetching} from 'src/redux/provisionOrder/provisionOrderReducer';
import { getClearObject, getYearsOptionsByParam, parseFloatOrUndefined } from 'src/utils/utils';
import {useForm, FormContext } from 'react-hook-form';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { PROVISION_ORDER_LIST } from 'src/utils/constants';
import ProvisionOrderForm from 'src/components/forms/provisionOrder/ProvisionOrderForm';
import { tryPutProvisionOrder } from 'src/redux/provisionOrder/provisionOrderActionCreator';
import { isNotEmptyArray } from 'src/services/validationService';
import GoodsAndServicesModal from './modal/GoodsAndServicesModal';
import NumberFormat from 'react-number-format';
import { ProvisionOrderDataToSend } from './utils/ProvisionOrderDataToSend';

const ProvisionOrderEditPage = () => {
    const hookFormMethods = useForm();
    const dispatch = useDispatch();
    const { handleSubmit, register } = hookFormMethods;
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const isFetching = useSelector(state => getProvisionOrderEditIsFetching(state));
    const minimumYear = 2018;
    const yearsOptions = getYearsOptionsByParam(minimumYear, globalSelectedPeriod?.year);
    yearsOptions.push(2024);
    const provisionOrder = useSelector( state => getProvisionOrderEdit(state));
    const buttonLabel = isNotEmptyArray(goodsAndServicesList) ? "Editar Bienes y/o Servicios" : "Añadir Bienes y/o Servicios";
    const [goodsAndServicesList, setGoodsAndServicesList] = useState([]);
    const [totalGeneral, setTotalGeneral] = useState(0);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const calculateTotal = (list) => {
      setTotalGeneral(list?.map((item) => parseFloatOrUndefined(item?.totalAmount) || 0)
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)
      );
    };
    const onClickSettlementAssetsModal = () => {
      handleShow(true);
    };

    const onSubmitForm = (data) => {
      const provisionOrderRows = goodsAndServicesList?.map((item) => {
        return {...item, idRandom: undefined}
      });
      const dataToSend = ProvisionOrderDataToSend(data, provisionOrderRows);
      const cleanData = getClearObject(dataToSend);
      dispatch(tryPutProvisionOrder(cleanData, provisionOrder?.id )).then(response => {
          if (response.status == 200) {
            dispatch(push(PROVISION_ORDER_LIST));
          }
      });
  	};
    
    useEffect(() => {
      calculateTotal(goodsAndServicesList);
    }, [goodsAndServicesList]);

    useEffect(() => {
      setGoodsAndServicesList(provisionOrder?.provisionOrderRows);
    }, []);

    return <Container fluid className='mb-5'>
        <Card className='mb-5'>
            <Card.Header className='h6'>
                Editar Orden de Provision
            </Card.Header>
            <Card.Body>
                <span className='text-danger d-flex mandatory-label font-weight-bold font-italic'>
                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                    Obligatorio
                </span>
                <FormContext {...hookFormMethods } >
                    <Form className='mt-4 text-black-color' onSubmit={handleSubmit(onSubmitForm)}>
                        <ProvisionOrderForm provisionOrder={provisionOrder} />
                        <GoodsAndServicesModal
                          show={show}
                          handleClose={handleClose}
                          goodsAndServicesList={goodsAndServicesList}
                          setGoodsAndServicesList={setGoodsAndServicesList}
                          provisionOrder={provisionOrder}
                        />
                        <Form.Group as={Row} className='mt-5 mb-5'>
                          <Col sm={4}></Col>
                          <Col sm={4} className='paddingform d-flex justify-content-center'>
                            <Button
                              variant='success'
                              size='md'
                              className='py-1 px-4'
                              onClick={() => onClickSettlementAssetsModal()}
                            >
                              {buttonLabel}
                            </Button>
                          </Col>
                        </Form.Group>
                        {isNotEmptyArray(goodsAndServicesList) &&
                          <>
                            <Form.Group as={Row}>
                              <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                                Total de la Orden de Provision:
                              </Form.Label>
                              <Col sm='4'>
                                <NumberFormat
                                  prefix={'$'}
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  decimalScale={2}
                                  value={totalGeneral}
                                  className={'form-control text-left'}
                                  readOnly
                                />
                              </Col>
                            </Form.Group>
                          </>
                        }
                        <div class='d-flex justify-content-around mt-4 mb-3'>
                          <Button
                            variant='primary'
                            size='lg'
                            onClick={() => dispatch(push(PROVISION_ORDER_LIST))}
                          >
                            Cancelar
                          </Button>
                          <span className={(false ? '' : 'hidden')}>
                            <Spinner className='spinner-border text-danger' animation='border' />
                          </span>
                          <Button
                            type='submit'
                            variant='success'
                            size='lg'
                            className='text-white-color'
                          >
                            Guardar
                            <FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
                          </Button>
                        </div>
                        </Form>
                    </FormContext>
                </Card.Body>
            </Card>
        </Container>
};

export default ProvisionOrderEditPage;