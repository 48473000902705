import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';

import { Container, Card, Form, Row, Col, Button, Table } from 'react-bootstrap';
import DropdownList from 'react-widgets/lib/DropdownList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { preventiveAccumulator, ultimateAccumulator, paidAccumulator } from 'src/utils/label';
import { formatterPeso, paginatorForData } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';
import { CREDIT_EXECUTION } from 'src/utils/constants';

import PageTitle from 'src/components/general/PageTitle';
import AppLoading from 'src/components/common/AppLoading';
import ExpedientTable from 'src/components/pages/CreditExecution/ExpedientTable';
import ActionIcon from 'src/components/general/ActionIcon';
import RCPagination from 'src/components/common/RCPagination';

import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';

import { tryGetExpedientByFilter } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { getexpedientCreditByFilterData, getexpedientCreditByFilterIsFetching, getRPSelectedData, getRPSelectedDataIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { clearRPConvertSelectedData, setRPConvertSelectedData, clearexpedientByFilterData } from 'src/redux/administrativeDocument/administrativeDocumentActions';

import { convertExpedientToPassiveRemainingByService } from 'src/redux/affectation/affectationActionCreator';
import { getExpedientToPassiveRemainingByServiceIsFetching } from 'src/redux/affectation/affectationReducer';

import RPConversionDetailModal from './RPConversionDetailModal';

const ReportConvertionRP = () => {

    const dispatch = useDispatch();
    const { handleSubmit: handleSubmitRP } = useForm();

    const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
    const confirmationMessage = `¿ Está seguro que desea convertir a Residuos Pasivos los expedientes seleccionados ?`;

    const [serviceCode, setServiceCode] = useState();
    const [checkboxFlag, setCheckboxFlag] = useState(false);
    const [ updateFilter, setUpdateFitler ] = useState(false);

    const expedients = useSelector(state => getexpedientCreditByFilterData(state));
    const isFetching = useSelector(state => getexpedientCreditByFilterIsFetching(state));

    const RPData = useSelector(state => getRPSelectedData(state));
    const isFetchingRPAllSelected = useSelector(state => getRPSelectedDataIsFetching(state));
    const conversionToPassiveRemainingIsFetching = useSelector(state => getExpedientToPassiveRemainingByServiceIsFetching(state));
    const hasSelectedExpedients = isNotEmptyArray(RPData);

    const [pagFilter, setPagFilter] = useState();
	const [activePage, setActivePage] = useState(1);
	const perPage = 10;
    const arrToMap = activePage > 1 ? pagFilter?.data?.sort((a,b) => a.code- b.code) : RPData?.filter((x, idx) => idx < perPage);

    const email = useSelector(state => getEmail(state));
    const listUserData = useSelector(state => getUserListData(state));
    const administrativeServiceIsFetching = useSelector( state => getListAllServiceAdministrativeByUserIsFetching(state));
    const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name,
            administrativeServiceId: item?.id
		}) );

    const userId = listUserData?.records?.find(item => item.email == email);
    
    const defaultParams = {
        page: 1,
        pageSize: 10,
    };

    //RP Conversion detail modal
    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
 
    const showRPConversionDetailModal = (data) => {
        setDataModal(data);
        handleShow(true);
    };


    useEffect(() => {
		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
        dispatch(clearRPConvertSelectedData());
	}, []);

    const onChangeDropService = (e) => {
        setServiceCode('');
        setCheckboxFlag(false);
        let newFilterObject = {
            availableRpByserviceId: [e.administrativeServiceId, globalSelectedPeriod?.id]
        };
        setUpdateFitler(!updateFilter);
        setServiceCode(newFilterObject);
        dispatch(clearRPConvertSelectedData());
    };

    const convertSelected = (data,e) => {

        let expedients = {
            service_id: serviceCode?.availableRpByserviceId[0],
            period_id: serviceCode?.availableRpByserviceId[1],
            administrative_document_id: []
        };

        if (hasSelectedExpedients) {
			 expedients.administrative_document_id = RPData.map(item => {
				return item?.id;
			});
		};

        if(e.nativeEvent.submitter.name === 'btn-submit'){
            swal({
                title: 'Confirmación',
                text: confirmationMessage,
                icon: 'warning',
                buttons: ["Cancelar", "Aceptar"]
            })
                .then((willDelete) => {
                    if (willDelete) {
                        dispatch(convertExpedientToPassiveRemainingByService(expedients)).then(
                            response => {
                                if (response?.status == 200) {
                                    showRPConversionDetailModal(response?.data)
                                    setCheckboxFlag(false);
                                    setUpdateFitler(!updateFilter);
                                    dispatch(clearRPConvertSelectedData());
                                    dispatch(clearexpedientByFilterData());
                                    dispatch(tryGetExpedientByFilter({...defaultParams, filter: serviceCode}));
                                }
                            }
                        );
                    }
                });
        }
    };

    const onClickRemoveCredit = (id) =>{
        dispatch(setRPConvertSelectedData(RPData.filter(obj => obj.id !== id)));
        setCheckboxFlag(false);
        onChangePage(1);
        setPagFilter('');
    };

    const cleanAllExpedientSelected = () =>{
        setCheckboxFlag(false);
        dispatch(clearRPConvertSelectedData());
        onChangePage(1);
    };

    const onChangePage = (pNumPage) => {
		setActivePage(pNumPage);
		setPagFilter(paginatorForData(RPData, pNumPage, perPage));
	};

    return (
        <Container fluid>
            <PageTitle text='Conversión a Residuo Pasivo por Servicios' />
            <Container fluid>
                <Card className='mb-3'>
                    <Card.Body>
                        <Form onSubmit={handleSubmitRP(convertSelected)} autocomplete='off'>
                            <Row className='justify-content-center'>
                                <Col sm={8}>
                                    <Form.Group>
                                        <Form.Label className='text-black-color'>
                                            Servicio
                                        </Form.Label>
                                        <DropdownList
                                            placeholder='Seleccione un servicio...'
                                            data={administrativeServiceData}
                                            textField='displayName'
                                            filter='contains'
                                            className='text-black-color'
                                            onChange={ (service) => {
                                                    onChangeDropService(service);
                                                }
                                            }
                                            messages={ messagesDropDown }
                                            selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
                                            searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
                                        </DropdownList>
                                    </Form.Group>
                                </Col>
                            </Row>
							{ serviceCode &&
                                <Row>
                                    <Col className='mx-5 mt-3'>
                                        <ExpedientTable filter={serviceCode} RPData={RPData} checkboxFlag={checkboxFlag} setCheckboxFlag={setCheckboxFlag} updateFilter={updateFilter}/>
                                          {arrToMap?.length && !isFetchingRPAllSelected ?
                                            <>
                                                <hr />
                                                <p className='h6 text-black-color'>Expedientes del servicio seleccionados:</p>
                                                <Table striped bordered hover size='sm'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center align-middle'>Expediente</th>
                                                            <th className='text-center align-middle'>{ preventiveAccumulator }</th>
                                                            <th className='text-center align-middle'>{ ultimateAccumulator }</th>
                                                            <th className='text-center align-middle'>Acumulador Obl. de pago</th>
                                                            <th className='text-center align-middle'>{ paidAccumulator }</th>
                                                            <th className='text-center align-middle'>Saldo</th>
                                                            <th className='text-center align-middle th-minwidth-selected-exp'>
                                                                <ActionIcon
                                                                    size="lg"
                                                                    id="clean-selected_expedients"
                                                                    className="btn-primary clean-filter text-white-color"
                                                                    toolTipText="Deseleccionar todos los expedientes listados"
                                                                    icon={faTrashAlt}
                                                                    onClick={()=> cleanAllExpedientSelected()}
                                                                />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-black-color'>
                                                        {arrToMap.map(item => (
                                                                <tr key={item.id}>
                                                                    <td className='text-center align-middle'>{item?.completeLabel}</td>
                                                                    <td className='text-right align-middle'>{formatterPeso.format(item?.total?.preventiveTotal)}</td>
                                                                    <td className='text-right align-middle'>{formatterPeso.format(item?.total?.definitiveTotal)}</td>
                                                                    <td className='text-right align-middle'>{formatterPeso.format(item?.total?.paymentObligationTotal)}</td>
                                                                    <td className='text-right align-middle'>{formatterPeso.format(item?.total?.paymentTotal)}</td>
                                                                    <td className='text-right align-middle'>{formatterPeso.format(item?.total?.passiveRemainingTotal)}</td>
                                                                    <td className='d-flex justify-content-around align-middle'>
                                                                        <ActionIcon 
                                                                            size='lg' 
                                                                            id='remove-selected-rp'
                                                                            toolTipText='Quitar de la selección' 
                                                                            icon={faTimes} className='text-danger' 
                                                                            onClick={() => onClickRemoveCredit(item.id)}
                                                                        />
                                                                    </td>
                                                                    <td className='text-center'></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                                <RCPagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={perPage}
                                                    totalItemsCount={RPData?.length}
                                                    totalItems={RPData?.length}
                                                    thePage={activePage}
                                                    onChange={onChangePage}
                                                    innerClass="justify-content-center"
                                                />
                                            </> : null}
                                        { expedients?.data?.length &&
                                            <Form.Group className='d-flex justify-content-end mt-4 mr-5'>
                                            <Button
                                                name='btn-submit'
                                                type='submit'
                                                variant='success'
                                                size='lg'
                                                disabled={!hasSelectedExpedients}
                                            >
                                                Convertir a RP
                                            </Button>
                                        </Form.Group>
                                        }
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </Card.Body>
                </Card>
                <RPConversionDetailModal
                    show = {show}
                    handleClose = {handleClose}
                    dataModal = { dataModal }
                />
            </Container>
            <AppLoading isLoading={administrativeServiceIsFetching || isFetching || isFetchingRPAllSelected || conversionToPassiveRemainingIsFetching} />
        </Container>
    )
}

export default ReportConvertionRP;