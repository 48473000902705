import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

import { getOrganizationListData, getOrganizationListIsFetching } from 'src/redux/organization/organizationReducer';
import { tryGetOrganizationList } from 'src/redux/organization/organizationActionCreator';


import { tryGetReportOrganismAnalytic  } from 'src/redux/reports/reportsActionCreactor';
import {getReportOrganismAnalyticIsFetching} from 'src/redux/reports/reportsReducer';
import { clearReportOrganismAnalyticData } from 'src/redux/reports/reportsActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined } from 'src/utils/utils';

const CREDIT_ORIGINAL = 1;
const CREDIT_CURRENT = 2;
const CREDIT_MODIFICATIONS = 3;

const ReportOrganismAnalyticalPage = props => {
	const dispatch = useDispatch();

	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	
	// Organization
	const organizationData = useSelector( state => getOrganizationListData(state) )?.records
		?.map( organization => ({
			displayName: `${organization?.codeBudget} - ${organization?.name} (${organization?.jurisdiction?.name}) (${organization?.organismClassifier?.shortName})`,
			id: organization?.id,
			name: organization?.name,
			nameJurisdictionGroupBy: organization?.jurisdiction?.name
		}) );
	const organizationIsFetching = useSelector( state => getOrganizationListIsFetching(state) );
	const hasOrganizationData = isNotEmptyArray(organizationData);
	const [ selectedOrganization, setSelectedOrganization ] = useState();

	
	// Checkboxes columns
	const [ checkedOriginal, setCheckedOriginal ] = useState(false);
	const [ checkedCurrent, setCheckedCurrent ] = useState(false);
	const [ checkedModifications, setCheckedModifications ] = useState(false);

	// Reports redux
	const reportIsFetching = useSelector( state => getReportOrganismAnalyticIsFetching(state) );
	const allowGetReport = ( hasPeriodList && !reportIsFetching && hasOrganizationData && !!selectedOrganization && (checkedOriginal||checkedCurrent||checkedModifications) );
	

	useEffect( () => {
		dispatch( clearReportOrganismAnalyticData() );
		dispatch( tryGetOrganizationList() );
		

	}, [] );

	const getReport = outputFormat => {
		const columns = [];
		checkedCurrent ? columns.push(CREDIT_CURRENT) : undefined;
		checkedModifications ? columns.push(CREDIT_MODIFICATIONS) : undefined;
		checkedOriginal ? columns.push(CREDIT_ORIGINAL) : undefined;

		if( globalSelectedPeriod ) {
			const params = {
				periodId: globalSelectedPeriod?.id,
				organization_id: selectedOrganization.id,
				columns,
				outputFormat
				
			};
					dispatch( tryGetReportOrganismAnalytic(params) );
			}
		
	};

	const onClickPDF = () => getReport(1);
	
	const onClickXLS = () => getReport(2);

return <Container fluid>
		<Card className='mb-5'>
			<PageTitle text='Analítico por unidad de organización/organismo' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
									<Form.Group>
										<Form.Label className='text-black-color'>
										Ejercicio
										</Form.Label>

										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>
									</Form.Group>
									
									<Form.Group>
										<Form.Label className='text-black-color'>
											Organización/Organismo
										</Form.Label>
										<DropdownList filter
											placeholder='Seleccione una unidad de organización/organismo...'
											busy={organizationIsFetching}
											data={organizationData}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											groupBy='nameJurisdictionGroupBy'
											className='text-black-color'
											onChange={ setSelectedOrganization }
											messages={ messagesDropDown }
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
										</DropdownList>
										
										{
											!selectedOrganization
											?
												<small class="form-text text-muted font-italic">Debe seleccionar una opción.</small>
											:
												null
										}
									</Form.Group> 


									<Form.Group className='text-black-color mt-4 ml-2'>
										<Form.Check
											id='original-credit'
											label='Crédito original'
											checked={checkedOriginal}
											onChange={ event => setCheckedOriginal(event.target.checked) }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											id='modifications-credit'
											label='Modificaciones'
											checked={checkedModifications}
											onChange={ event => setCheckedModifications(event.target.checked) }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											id='current-credit'
											label='Crédito actual'
											checked={checkedCurrent}
											onChange={ event => setCheckedCurrent(event.target.checked) }/>
																					
										{
											(!checkedModifications&&!checkedCurrent&&!checkedOriginal)
											?
												<small class="form-text text-muted font-italic">Debe seleccionar al menos una opción de crédito.</small>
											:
												null
										}

									</Form.Group>

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2'/>
											Ver PDF
										</Button>
										{
											reportIsFetching
											?
												<Spinner animation='border'/>
											:
												null
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2'/>
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>
							
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ReportOrganismAnalyticalPage;