import * as ACTION_TYPES from './budgetItemControlActionTypes';

export const budgetItemControlReducerInitialState = {
	params: undefined,
	data: undefined,
	isFetching: false,
	hasError: false,
	error: undefined
};

export const budgetItemControlReducer = (state=budgetItemControlReducerInitialState, action) => {
	switch( action.type ) {
		case ACTION_TYPES.GET_BUDGET_ITEM_CONTROL_REQUEST: {
			const { params } = action.payload;
			return {
				...state,
				isFetching: true,
				hasError: false,
				error: budgetItemControlReducerInitialState.error,
				params
			};
		}
		case ACTION_TYPES.GET_BUDGET_ITEM_CONTROL_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				isFetching: false,
				hasError: false,
				error: budgetItemControlReducerInitialState.error,
				data
			};
		}
		case ACTION_TYPES.GET_BUDGET_ITEM_CONTROL_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				isFetching: false,
				hasError: true,
				data: budgetItemControlReducerInitialState.data,
				error
			};
		}
		case ACTION_TYPES.CLEAR_BUDGET_ITEM_CONTROL_DATA: {
			return {
				...budgetItemControlReducerInitialState
			};
		}
		default: {
			return state;
		}
	}
};

export const getBudgetItemControlReducer = state => state?.budgetItemControlReducer;

export const getBudgetItemControlReducerParams = state => getBudgetItemControlReducer(state)?.params;
export const getBudgetItemControlReducerData = state => getBudgetItemControlReducer(state)?.data;
export const getBudgetItemControlReducerIsFetching = state => getBudgetItemControlReducer(state)?.isFetching;