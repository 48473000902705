import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getOrganizationTypesListData, getOrganizationTypesListIsFetching } from 'src/redux/organization/organizationReducer';
import { getCharacterListData, getCharacterListIsFetching } from 'src/redux/character/characterReducer';
import { tryGetCharacterList } from 'src/redux/character/characterActionCreator';
import { clearCharacterList } from 'src/redux/character/characterActions';
import { tryGetReportCrossed } from 'src/redux/reports/reportsActionCreactor';
import { tryGetListOrganizationType } from 'src/redux/organization/organizationActionCreator';
import { getReportCrossedIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportCrossed } from 'src/redux/reports/reportsActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined } from 'src/utils/utils';


const ReportTotalCrossedPurposeFunctionObjectExpenditurePage = props => {
	const dispatch = useDispatch();

	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	// Characters
	const characterOptionsData = useSelector( state => getCharacterListData(state) )?.data
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
			?.map( character => ({
				displayName: `${character.code} - ${character.name}`,
				id: character.id,
				code: character.code,
				name: character.name
			}) );
	const characterIsFetching = useSelector( state => getCharacterListIsFetching(state) );
	const hasCharacterOptions = isNotEmptyArray(characterOptionsData);
	const [ selectedCharacters, setSelectedCharacter ] = useState();
	const hasSelectedCharacters = isNotEmptyArray(selectedCharacters);
	const onChangeCharacters = data => setSelectedCharacter(data);

	// Organization types
	const organizationTypesData = useSelector( state => getOrganizationTypesListData(state) );
	const organizationTypesIsFetching = useSelector( state => getOrganizationTypesListIsFetching(state) );
	const hasOrganizationTypes = isNotEmptyArray(organizationTypesData);
	const [ selectedOrgTypes, setSelectedOrgTypes ] = useState();
	const hasSelectedOrgTypes = isNotEmptyArray(selectedOrgTypes);
	const onChangeOrgTypes = data => setSelectedOrgTypes(data);

	// Checkboxes columns
	const [ selectedColumn, setSelectedColumn ] = useState();
	const onChangeColumnRadio = event => {
		const selectedCreditId = parseIntOrUndefined(event.target.value);
		setSelectedColumn( selectedCreditId );
	};

	// Reports redux
	const reportIsFetching = useSelector( state => getReportCrossedIsFetching(state) );
	const allowGetReport = ( hasPeriodList && !reportIsFetching && hasSelectedOrgTypes && hasSelectedCharacters && selectedColumn );
	

	useEffect( () => {
		dispatch( clearReportCrossed() );
		dispatch( clearCharacterList() );
		dispatch( tryGetCharacterList() );
		dispatch( tryGetListOrganizationType() );
	}, [] );

	const getReport = outputFormat => {

		if( globalSelectedPeriod || selectedReportType ) {
			const params = {
				periodId: globalSelectedPeriod?.id,
				organizationTypeId: selectedOrgTypes.map( orgType => orgType.id ),
				characters: selectedCharacters.map( char => char.id ),
				column: selectedColumn,
				outputFormat
			};

			dispatch( tryGetReportCrossed(params) );
		}
	};

	const onClickPDF = () => getReport(1);
	
	const onClickXLS = () => getReport(2);

	return <Container fluid>
		<Card className='mb-5'>
			
			<PageTitle text='Reporte total cruzado'/>

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
									<Form.Group>
										<Form.Label className='text-black-color'>
										Ejercicio
										</Form.Label>

										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Carácter
										</Form.Label>
										<Multiselect
											placeholder='Seleccione uno o más caracteres...'
											onChange={onChangeCharacters}
											disabled={!hasCharacterOptions}
											busy={characterIsFetching}
											allowCreate={false}
											data={characterOptionsData}
											textField='displayName'
											valueField='id'
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}/>
										{
											!hasSelectedCharacters
											?
												<small class="form-text text-muted font-italic">Debe seleccionar al menos una opción.</small>
											:
												null
										}
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Tipo de unidad
										</Form.Label>
										<Multiselect
											placeholder='Seleccione uno o más tipos de unidad...'
											onChange={onChangeOrgTypes}
											disabled={!hasOrganizationTypes}
											busy={organizationTypesIsFetching}
											allowCreate={false}
											data={organizationTypesData}
											textField={ item => `(${item?.shortName}) - ${item?.name} `}
											valueField='id'
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}/>
										
										{
											!hasSelectedOrgTypes
											?
												<small class="form-text text-muted font-italic">Debe seleccionar al menos una opción.</small>
											:
												null
										}
									</Form.Group>

									<Form.Group className='text-black-color mt-4 ml-2'>
										<Form.Check
											name='credit'
											type='radio'
											value='1'
											label='Crédito original'
											id='original-credit'
											onChange={ onChangeColumnRadio }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											name='credit'
											type='radio'
											value='3'
											label='Modificaciones'
											id='modification-credit'
											onChange={ onChangeColumnRadio }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											name='credit'
											type='radio'
											value='2'
											label='Crédito actual'
											id='current-credit'
											onChange={ onChangeColumnRadio }/>

{
											(!selectedColumn)
											?
												<small class="form-text text-muted font-italic">Debe seleccionar una opción de crédito.</small>
											:
												null
										}
									</Form.Group>


									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2'/>
											Ver PDF
										</Button>
										{
											reportIsFetching
											?
												<Spinner animation='border'/>
											:
												null
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2'/>
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>							
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ReportTotalCrossedPurposeFunctionObjectExpenditurePage;