import React from 'react';
import { useDispatch } from 'react-redux';
import { Container, Card, Form, Row, Col } from 'react-bootstrap';
import { useForm, FormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { required, newAlert, alert } from 'src/utils/label';

import { tryAddNewAlert } from 'src/redux/alert/alertActionCreator';
import AlertForm  from 'src/components/forms/alert/alertForm';
import { convertStringToBoolean } from 'src/utils/utils';
import { config } from 'src/env.js';

const AlertNewPage = () => {

	const dispatch = useDispatch();

	const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;

	const onFormSubmit = data => {

		const active = convertStringToBoolean(data?.active);
		const level = data?.level;
		const message = data?.message.trim();
		const messageEnd = data?.dateUntil;
		const messageStart = data?.dateSince;
		const profile = data?.profile == "Todos" ? null : data?.profile

		const params = {
			active: active,
			level: level || null,
			message: message,
			messageEnd: messageEnd,
			messageStart: messageStart,
			profile: profile || null,
			applicationId: config.apis.authApi.APPLICATION_ID
		}

		dispatch(tryAddNewAlert(params));
	};


	return <Container fluid className='mb-5'>
		<Card>

			<PageTitle text={alert} />

			<Container fluid>
				<Card className='mb-3'>

					<Card.Header>
						<h6 className='mb-0'>{newAlert}</h6>
					</Card.Header>

					<Card.Body >

						<Row>
							<Col xs={8}>
								<h6 className='mb-4 text-black-color'>
								</h6>
							</Col>

							<Col xs={4} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col>
						</Row>
						<FormContext {...hookFormMethods} >
							<Form onSubmit={handleSubmit(onFormSubmit)} autocomplete='off'>
								
								<AlertForm />

							</Form>
						</FormContext>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default AlertNewPage;