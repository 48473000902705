import React, {useState} from 'react';
import { Form, Table, Row, Col } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import { checkIsEmptyObj, containsObject, paginatorForData } from 'src/utils/utils.js';
import RCPagination from 'src/components/common/RCPagination';

const ServiceAdminOrgCodeBox = props => {
	const { serviceOrganismCodeSelected, setServiceOrganismCodeSelected} = props;
	const [pagFilter, setPagFilter] = useState();
	const [activePage, setActivePage] = useState(1);
	const perPage = 20;

	const arrToMap = activePage > 1 ? pagFilter?.data?.sort((a,b) => a.code- b.code) : serviceOrganismCodeSelected?.filter((x, idx) => idx < perPage);

	const formik = useFormik({
        initialValues: {
            ...serviceOrganismCodeSelected.sort((a,b) => a.code- b.code),
        },
        validationSchema: Yup.object({
            serviceCode: Yup.number().required("Ingrese un código de organismo")
				.min(1000, 'El código de organismo no debe ser menor a 1000')
				.max(9999, 'El código de organismo no debe ser mayor a 9999')
				.integer('Debe ingresar un entero')
				.test('serviceCode', 'Ya se asoció el código de organismo al servicio',
				(value) => !containsObject(serviceOrganismCodeSelected,"code", value))
			}),
    });

	const serviceValCheckUndefined = formik.values.serviceCode !== undefined;

	const addServiceCodeToSelectionList = () => {
		if(Object.keys(formik.errors).length === 0 && serviceValCheckUndefined){
			const service = {
				code: parseInt(formik.values.serviceCode),
			};
			setServiceOrganismCodeSelected(serviceOrganismCodeSelected => [...serviceOrganismCodeSelected, service].sort((a, b) => a.code > b.code ? 1 : -1));
			formik.resetForm();
		}
	};

	const onChangePage = (pNumPage) => {
		setActivePage(pNumPage);
		setPagFilter(paginatorForData(serviceOrganismCodeSelected, pNumPage, perPage));
	};

	const removeServiceCodeFromSelectionList = service => {
			setServiceOrganismCodeSelected(serviceOrganismCodeSelected?.filter(item => item.code !== service.code));
			onChangePage(1);
			setPagFilter('');
	};

	return (
		<>
			<h6 className='text-black-color mb-3'> Codigos de Organismos</h6>
			<Form.Group as={Row} controlId='formHorizontalEmail'>
				<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
					Código a incorporar
				</Form.Label>
				<Col sm={4}>
					<Form.Control
						type='number'
						name='serviceCode'
						id='serviceCode'
						onChange={formik.handleChange}
						value={serviceValCheckUndefined ? formik.values.serviceCode : ''}
						onKeyDown={(e) => e.key === 'Enter' && (e.preventDefault(), addServiceCodeToSelectionList())}
					/>
					{ !checkIsEmptyObj(formik.errors) &&
						<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>{ formik.errors.serviceCode || formik.errors.serviceLastCheck}</div>
					}
				</Col>
					<ActionIcon
						className='ml-2 align-self-baseline pt-1'
						size='lg'
						toolTipText='Agregar código de organismo'
						disabled={!serviceValCheckUndefined || !checkIsEmptyObj(formik.errors)}
						icon={faPlus}
						onClick={() => addServiceCodeToSelectionList()}
					/>
			</Form.Group>
			{ serviceOrganismCodeSelected?.length ?
				<>
					<Table striped bordered hover size='sm'>
						<thead>
							<tr className='secondary'>
								<th className='text-center align-middle text-white'>Códigos</th>
								<th className='text-center align-middle text-white' width='25%'>Acciones</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							{  arrToMap.map((item,i) => (
									<tr key={i}>
										<td className='text-center align-middle'>
											{item?.code}
										</td>
										<td className='text-center align-middle p-0'>
											<ActionIcon
												size='lg'
												id='remove-organism-code'
												toolTipText='Quitar de la selección'
												icon={faTimes} className='text-danger ml-2'
												onClick={() => removeServiceCodeFromSelectionList(item)}
											/>
										</td>
									</tr>
								))
							}
						</tbody>
					</Table>
					<RCPagination
						activePage={activePage}
						itemsCountPerPage={perPage}
						totalItemsCount={serviceOrganismCodeSelected?.length}
						totalItems={serviceOrganismCodeSelected?.length}
						thePage={activePage}
						onChange={onChangePage}
						innerClass="justify-content-center"
					/>
				</>
				:null
			}

		</>
	);
};

export default ServiceAdminOrgCodeBox;