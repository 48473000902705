import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Commons from './controls/Commons';
import { Form, Modal, Table, Button } from 'react-bootstrap';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { objectHasValue } from 'src/utils/utils';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import ExpedienteContainer from './controls/ExpedienteContainer';
import { getProveedoresListData } from 'src/redux/proveedores/proveedoresReducer'
import { clearNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationActions';
import { clearListOrderPayLast } from 'src/redux/orderPay/orderPayActions'
import ModalReceiptGenerator from 'src/components/pages/OrderPay/ModalReceiptGenerator';
import { isNotEmptyArray } from 'src/services/validationService';
import AppLoading from 'src/components/common/AppLoading';
import TotalAmountReceiptValidationForm from './TotalAmountReceiptValidationForm';

const ProvidersPaymentOrderForm = () => {
    const dispatch = useDispatch();
    const { 
        fundRequest, orderPay, register, setProveedorSelected, setValue, errors, triggerValidation,
        expedientesAsociados, setExpedientesAsociados, formValuesState, setFormValuesState,
        administrativeServiceData, importe, setAllowSaveAmountValidation, expedientesSeleccionados,
        orderType, admCode, admNumber, admYear
    } = useFormContext();

	const _receipts = [];

    const [receipts, setReceipts] = useState(_receipts);
    const [ticketData, setTicketData] = useState([]);
    const [amountData, setAmountData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [personaFound, setPersonaFound] = useState(false);
    const [administrativeDocument, setAdministrativeDocument] = useState([]);
    const [admServiceId, setAdmServiceId] = useState();

    const [proveedorTerm, setProveedorTerm] = useState("");
    const [showProveedoresModal, setShowProveedoresModal] = useState(false);
    const proveedoresList = useSelector(state => getProveedoresListData(state))?.data || [];
    const [removeState, setRemoveState] = useState(false);

    const totalPenaltyFee = receipts?.length < 2 ? receipts?.[0]?.penaltyFee : receipts?.map((item) => item?.penaltyFee)?.flat()?.reduce((prev, next) => Number(prev) + Number(next));
    const totalAmountReceipts = isNotEmptyArray(receipts) &&
        receipts?.map((item) => item.tickets.map((v) => v.amount))
            .flat()?.reduce((prev, next) => Number(prev) + Number(next)) - totalPenaltyFee;
    const selectedExpedientAssociated = expedientesAsociados?.filter(i => i.selected);

    const applyFilterBankAccounts = (administrativeServiceId) => {
        setAdmServiceId(administrativeServiceId);
    }

    useEffect(() => {
            setFormValuesState({
            ...formValuesState,
            beneficiaries: [{
                type: 'PROVEEDOR',
                receipts: receipts
            }]
        });
    }, [receipts]);



    //
    // TODO: Este useEffect se renderiza 8 veces, reveerlo...
    //
    useEffect(() => {
        if (orderPay) {
            setAdmServiceId(orderPay.administrativeServiceId);
        }
        if (fundRequest) {
            setAdmServiceId(fundRequest.administrativeServiceId);
        }
        dispatch(clearListOrderPayLast());
        dispatch(clearListAllAdministrativeServicesData());
        dispatch(clearNewAffectationAdministrativeDocumentData());
        if (orderPay) {
            setAdmServiceId(orderPay.administrativeServiceId);
        };

    }, []);

    const openModalProveedores = () => {
        setShowProveedoresModal(true);
    };
    const onChangeFilterProveedor = event => {
        setProveedorTerm(event.target.value);
    };
    const handleCloseModalProveedor = () => setShowProveedoresModal(false);

    const setAcreedorObject = (proveedor) => {
        setProveedorSelected(proveedor);
        setValue("cuit", proveedor?.cuit);
        setValue('acreedor_denominacion', proveedor?.name);
        setValue('beneficiaryValidator', proveedor?.beneficiaryValidator);
        handleCloseModalProveedor();
        setPersonaFound(!!proveedor?.name);
        if (proveedor) {
            setRemoveState(true);
        }
    }

    useEffect(() => {
		orderPay?.beneficiaries?.map(beneficiarie => {
            beneficiarie?.receipts?.map(receipt => {
                let receitCuit = {
                    ...receipt,
                    cuit: beneficiarie?.persona?.cuit?.toString(),
                    companyName: beneficiarie?.persona?.companyName,
                    beneficiaryValidator: beneficiarie?.persona?.beneficiaryValidator,
                    providerNumber: beneficiarie?.persona?.providerNumber
                };
                _receipts.push(receitCuit);
            });
        });
	}, []);

    useEffect(() => {
        if(!orderPay?.id) setReceipts([])
    }, [admServiceId]);

    return <>
        <Commons
            administrativeServiceData={administrativeServiceData}
            admServiceId={admServiceId}
            setAdmServiceId={setAdmServiceId}
            applyFilterBankAccounts={applyFilterBankAccounts}
            disabledImporte={true}
        />

            <Modal
                dialogClassName="modal-lg"
                show={showProveedoresModal}
                onHide={handleCloseModalProveedor}>
                <Modal.Header closeButton>
                    <h5>Proveedor</h5>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" placeholder="Buscar..." onChange={onChangeFilterProveedor} />
                    <br />
                    <Table striped bordered hover size='sm' responsive>
                        <thead>
                            <tr>
                                <th className='text-center align-middle'>Proveedor</th>
                                <th className='text-center align-middle'>Nombre de Fantasia</th>
                                <th className='text-center align-middle'>CUIT</th>
                                <th className='text-center align-middle'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody className='text-black-color'>
                            {proveedoresList?.filter(item => objectHasValue(item, proveedorTerm))
                                .map((item, index) => (
                                    <tr key={item?.id}>
                                        <td className='text-center align-middle'>
                                            {item.companyName}
                                        </td>
                                        <td className='text-center align-middle'>
                                            {item.fantasyName}
                                        </td>
                                        <td className='text-center align-middle'>
                                            {item.cuit}
                                        </td>
                                        <td className='text-center align-middle'>
                                            <ActionIcon size='lg' id={'select-rubro-' + index} toolTipText='Seleccionar' icon={faCheck} onClick={() => setAcreedorObject(item)} />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalProveedor}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        <ModalReceiptGenerator
            errors={errors}
            register={register}
            triggerValidation={triggerValidation}
            receipts={receipts}
            setReceipts={setReceipts}
            ticketObj={{ ticketData, setTicketData }}
            breakDownWorkCertificateObj={{ amountData, setAmountData }}
        />

        {admServiceId &&
            <ExpedienteContainer
                expedientesAsociados={expedientesAsociados}
                setExpedientesAsociados={setExpedientesAsociados}
                administrativeDocument={administrativeDocument}
                setAdministrativeDocument={setAdministrativeDocument}
                receipts={receipts}
            />
        }

        <TotalAmountReceiptValidationForm 
            setAllowSaveAmountValidation={setAllowSaveAmountValidation}
            expedientesSeleccionados={expedientesSeleccionados} 
            selectedExpedientAssociated={selectedExpedientAssociated} 
            totalAmountReceipts={totalAmountReceipts} 
            importe={importe} 
            receipts={receipts}
        />

        <AppLoading isLoading={isLoading} />
    </>
};

export default ProvidersPaymentOrderForm;