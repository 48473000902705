import * as ACTIONS from './deliveryOrderActions';
import * as SERVICES from 'src/services/deliveryOrderServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

// Try list delivery order
export const tryListDeliveryOrder = (params = {}) => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.listDeliveryOrderRequest(params));
		
		if (accessToken) {
			const response = await SERVICES.listDeliveryOrder(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.listDeliveryOrderRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listDeliveryOrderRequestFailure(response?.response?.data));
				showError(dispatch, `Error al obtener las ordenes de entrega. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}
	};
}

export const tryDeliveryOrderById = (deliveryOrderId = '') => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getDeliveryOrderRequestId());
		
		if (accessToken) {
			const response = await SERVICES.getDeliveryOrderById(accessToken, deliveryOrderId);
			if (response?.status == 200) {
				dispatch(ACTIONS.getDeliveryOrderRequestIdSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getDeliveryOrderRequestIdFailure(response?.response?.data));
				showError(dispatch, `Error al obtener las ordenes de entrega. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}
	};
}

// Try Set  delivery order
export const tryGetDeliveryOrder = ( administrativeServicesId = '', exerciseId = '') => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getDetailDeliveryOrderRequest());
		if (accessToken) {
			const response = await SERVICES.getDeliveryOrderId(accessToken, administrativeServicesId, exerciseId);
			if (response?.status === 200) {
				dispatch(ACTIONS.getDetailDeliveryOrderRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getDetailDeliveryOrderRequestFailure(response?.error || response));
				showError(dispatch, `Error al cargar la orden de entrega. Detalle: ${response?.error || response}`);
			}
		}
	};
};

//Create
export const tryPostDeliveryOrder = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getAddDeliveryOrderRequest());
		if (accessToken) {
			const response = await SERVICES.newDeliveryOrder(accessToken, params);
			if (response?.status == 201) {
				dispatch(ACTIONS.getAddDeliveryOrderRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getAddDeliveryOrderRequestFailure(response?.data?.error));
			}
			return response
		}
	};
};

//Create MASSIVE
export const tryPostDeliveryOrderMassive = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getAddDeliveryOrderMassiveRequest());
		if (accessToken) {
			const response = await SERVICES.newDeliveryOrderMassive(accessToken, params);
			if (response?.status == 201) {
				dispatch(ACTIONS.getAddDeliveryOrderMassiveRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getAddDeliveryOrderMassiveRequestFailure(response?.data?.error));
			}
			return response
		}
	};
};

//Edit
export const tryPutDeliveryOrder = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getEditDeliveryOrderRequest());
		if (accessToken) {
			const response = await SERVICES.editDeliveryOrder(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getEditDeliveryOrderRequestSucces(response?.data));
				showSuccess(dispatch, 'Orden de entrega editado correctamente.');
			}
			else {
				dispatch(ACTIONS.getEditDeliveryOrderRequestFailure(response?.data?.error));
				showError(dispatch, `Error al editar la orden de entrega. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Delete
export const tryDeleteDeliveryOrder = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getDeleteDeliveryOrderRequest());

		if (accessToken) {

			const response = await SERVICES.deleteDeliveryOrder(accessToken, id);
			if (response?.status == 204) {
				dispatch(ACTIONS.getDeleteDeliveryOrderRequestSuccess(response?.data));
				showSuccess(dispatch, `Orden de entrega eliminado correctamente.`);
			}
			else {
				dispatch(ACTIONS.getDeleteDeliveryOrderRequestFailure(response?.data?.error));
				showError(dispatch, `Error al eliminar la orden de entrega. `);
			}
			return response
		}

	};
};