import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Dropdown } from 'react-bootstrap';

import { INDEX_PAGE } from 'src/utils/constants';
import { tryGetPeriodsList } from 'src/redux/globalData/globalDataActionCreator';
import { setSelectedPeriod, setFilterObject } from 'src/redux/globalData/globalDataActions';
import { clearCreditQueryDataCreditSelected } from 'src/redux/affectation/affectationActions';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getFilterObject } from 'src/redux/globalData/globalDataReducer';
import { isNotEmptyArray } from 'src/services/validationService';

const DropdownPeriods = props => {

	const dispatch = useDispatch();
	const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const periodsData = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 )?.filter( item => item?.year > 2021 );
	const hasPeriods = isNotEmptyArray(periodsData);

	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);

	useEffect( () => {
		dispatch( tryGetPeriodsList() );
	}, []);

	const onChangePeriodSelected = periodId => {

		const selectedPeriodObject = periodsData?.find( period => period.id == periodId );

		delete globalFilterObject?.period_rp_id;
		delete globalFilterObject?.period_budget_selected;
		dispatch(setFilterObject(globalFilterObject));

		dispatch(setSelectedPeriod(selectedPeriodObject) );
		dispatch(clearCreditQueryDataCreditSelected());

		dispatch( push(INDEX_PAGE) );
	};

	return <Dropdown {...props} alignRight onSelect={onChangePeriodSelected}>
		<Dropdown.Toggle id='user-menu'>
			{
				selectedPeriod ?
					`Ejercicio ${selectedPeriod.year}`
				: null
			}
		</Dropdown.Toggle>

		<Dropdown.Menu>
			{
				hasPeriods ?
					periodsData.map( period => (
						<Dropdown.Item eventKey={period.id}>
							{`Ejercicio ${period.year}`}
						</Dropdown.Item>
					))
				:
					null
			}
		</Dropdown.Menu>
	</Dropdown>;
};

export default DropdownPeriods;