import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faChevronDown, faSearch, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';

import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportDetailServiceDirectionFundRequest } from 'src/redux/reports/reportsActionCreactor';
import { getReportDetailServiceDirectionFundRequestIsFetching, getReportServiceDirectionFundRequestIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportCreditModifyCreditExecution } from 'src/redux/reports/reportsActions';
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { getJurisdictionsByServicesAndExerciseData, getJurisdictionsByServicesAndExerciseIsFetching } from 'src/redux/jurisdiction/jurisdictionReducer';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { tryGetJurisdictionsByServicesAndExercise } from 'src/redux/jurisdiction/jurisdictionActionCreator';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import RangeDates from 'src/components/common/RangeDates';
import AppLoading from 'src/components/common/AppLoading';
import Multiselect from 'react-widgets/lib/Multiselect'

const ReportServiceDirectionFundRequestPage = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

	//User Id
	const email = useSelector(state => getEmail(state));
	const listUserData = useSelector(state => getUserListData(state));
	const userId = listUserData?.records?.find(item => item.email == email);

	const reportDates = useSelector(state => getReportDatesData(state));
	// Periods
	const periodList = useSelector(state => getGlobalDataPeriodsData(state))?.data?.sort((a, b) => a.year < b.year ? 1 : -1);
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	// Admnistrative Service
	const administrativeServiceData = useSelector(state => getListAllServiceAdministrativeByUserData(state))
		?.sort((a, b) => a.code > b.code ? 1 : -1)
		?.map(item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
		}));
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));
	const [selectedAdministrativeService, setSelectedAdministrativeService] = useState();

	const [dateFrom, setDateFrom] = useState(reportDates?.dateFrom);
	const [dateTo, setDateTo] = useState(reportDates?.dateTo);
	const [dateHasErrors, setDateHasErrors] = useState(false);

	//Jurisdiction
	const [selectedJurisdiction, setSelectedJurisdiction] = useState();
	const jurisdictionsList = useSelector(state => getJurisdictionsByServicesAndExerciseData(state));
	const jurisdictionsListIsFetching = useSelector(state => getJurisdictionsByServicesAndExerciseIsFetching(state));

	const handleServiceOnChange = (administrativeService) => {
		setSelectedAdministrativeService(administrativeService);
		setSelectedJurisdiction();
		dispatch(tryGetJurisdictionsByServicesAndExercise(administrativeService?.id, globalSelectedPeriod?.id))
	};

	const handleJurisdictionOnChange = (jurisdictionData) => {
		setSelectedJurisdiction(jurisdictionData);
	};

	useEffect(() => {
		dispatch(tryGetUserList());
		dispatch(clearReportCreditModifyCreditExecution());
		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
	}, [globalSelectedPeriod]);

	const getReport = outputFormat => {

		let jurisdictionsIds = [];
		selectedJurisdiction?.map(item => {
			jurisdictionsIds?.push(item?.id)
		});

		if (globalSelectedPeriod) {
			const params = {
				desiredOutput: outputFormat == 1 ? "pdf" : "xlsx",
				desiredFileName: "Desglose de Pedidos de fondos",
				administrativeServicesIds: [selectedAdministrativeService?.id],
				dateFrom,
				dateTo,
				jurisdictionsIds: jurisdictionsIds
			};
			dispatch(tryGetReportDetailServiceDirectionFundRequest(params));
		}

	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	// Reports redux
	const reportIsFetching = useSelector(state => getReportServiceDirectionFundRequestIsFetching(state));
	const reportDetailServiceDirectionFundRequestIsFetching = useSelector(state => getReportDetailServiceDirectionFundRequestIsFetching(state));
	const allowGetReport = (hasPeriodList && !reportIsFetching && !reportDetailServiceDirectionFundRequestIsFetching && dateFrom && dateTo && selectedAdministrativeService && !dateHasErrors && !jurisdictionsListIsFetching);

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
						<h1 className="h6 mt-1 mb-0">Reporte de Pedido de Fondos por Servicio</h1>
					</Card.Header>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={8} className='offset-md-2' >
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>
										<Form.Control
											type='number'
											name='globalSelectedPeriod'
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<DropdownList
											placeholder='Seleccione un servicio...'
											busy={administrativeServiceIsFetching}
											data={administrativeServiceData}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											className='text-black-color'
											onChange={value => handleServiceOnChange(value)}
											messages={messagesDropDown}
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />} >
										</DropdownList>
										{
											!selectedAdministrativeService
											&&
											<small class="form-text text-muted font-italic">Debe seleccionar una opción.</small>
										}
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Jurisdicción
										</Form.Label>
										<Multiselect
											placeholder='Seleccionar una o más Jurisdicciones...'
											value={selectedJurisdiction}
											onChange={data => handleJurisdictionOnChange(data)}
											disabled={!selectedAdministrativeService}
											busy={jurisdictionsListIsFetching}
											allowCreate={false}
											data={jurisdictionsList}
											textField={item => (`${item?.code} - ${item?.name || ''}`)}
											valueField='id'
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
										/>
										{
											!selectedJurisdiction
											&&
											<small class="form-text text-muted font-italic mb-3">Opcional.</small>
										}
									</Form.Group>

									<FormContext {...hookFormMethods}>
										<RangeDates
											{...{ dateFrom, setDateFrom, dateTo, setDateTo, setDateHasErrors }}
										/>
									</FormContext>

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Ver PDF
										</Button>
										<div></div>
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Ver XLS
										</Button>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</Container>
			<AppLoading isLoading={jurisdictionsListIsFetching || reportIsFetching || reportDetailServiceDirectionFundRequestIsFetching} />
		</>
};

export default ReportServiceDirectionFundRequestPage;