import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import MultiSelect from "react-multi-select-component";
import PageTitle from 'src/components/general/PageTitle';
import FormFieldError from 'src/components/general/FormFieldError';
import { tryGetListUserProfile, tryGetListUserXApplicationXProfile, tryPutUser, tryGetUserList } from 'src/redux/user/userActionCreator';
import { clearUserList, clearProfileToEdit } from 'src/redux/user/userActions';
import { getUserToEdit, getUserProfileListData, getUserProfileListIsFetching, getUserXApplicationXProfileByUserId, getUserProfileListByUserId, getUserEditIsFetching } from 'src/redux/user/userReducer';
import { validatePasswordFormat, PASSWORD_INVALID_MSG, isNotEmptyArray } from 'src/services/validationService';
import { USER_LIST } from 'src/utils/constants';
import { editUser, required, lastName, name, email, group, cancel, save } from 'src/utils/label';
import { setAssignProfiles } from 'src/utils/usersProfiles';
import { userNameData, joinLastNameName } from 'src/utils/utils';
import { tryAddAllUsersAdministrativeService, tryRemoveAllUsersAdministrativeService, tryListAllAdministrativeServices, tryListAllServiceAdministrativeByUser, tryAddNewUserAdministrativeService } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getAdministrativeServiceListAllData, getAdministrativeServiceListAllIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { clearListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActions'
import { getUserPermissionsSecurity } from 'src/redux/login/loginReducer';

const UserEditPage = () => {
	const [disableafterclick, setDisableafterclick] = useState(false);
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, getValues } = useForm();
	const loadAllData = () => {
		dispatch(clearUserList());
		dispatch(clearProfileToEdit());
		dispatch(clearListAllServiceAdministrativeByUser());

		dispatch(tryGetListUserProfile());
		dispatch(tryListAllAdministrativeServices());

		dispatch(tryGetListUserXApplicationXProfile());
		dispatch(tryGetListUserProfile());
		dispatch(tryGetUserList());

		dispatch(tryListAllServiceAdministrativeByUser(userToEdit?.id));
	}
	useEffect(loadAllData, []);

	// Form validation
	const lastNameRequiredMsg = 'Debe ingresar apellido.';
	const nameRequiredMsg = 'Debe ingresar nombre.';
	const passwordConfirmRequiredMsg = 'Debe ingresar una confirmación de contraseña.';
	const passwordConfirmSameMsg = 'Las contraseñas ingresadas no coinciden.';
	const emailRequiredMsg = 'Debe ingresar un correo electrónico.';
	const profileRequiredMsg = 'Debe seleccionar un grupo.';
	const nameConfirmBlankMsg = "No se debe ingresar solo espacios en blanco en el nombre";
	const lastNameConfirmBlankMsg = "No se debe ingresar solo espacios en blanco en el apellido";
	const samePasswordValidation = value => {
		const passwordInputValue = getValues('password');
		if (passwordInputValue) {
			return (value === passwordInputValue ? undefined : passwordConfirmSameMsg);
		}
		return true;
	};
	const nameValidationSpaceBlank = value => !!value.trim() ? undefined : nameConfirmBlankMsg;
	const lastNameValidationSpaceBlank = value => !!value.trim() ? undefined : lastNameConfirmBlankMsg;
	const lastNameValidationObj = { required: lastNameRequiredMsg ,validate: lastNameValidationSpaceBlank  };
	const nameValidationObj = { required: nameRequiredMsg, validate: nameValidationSpaceBlank };
	const passwordValidationObj = { validate: value => (validatePasswordFormat(value) ? undefined : PASSWORD_INVALID_MSG) };
	const passwordConfirmValidationObj = { required: passwordConfirmRequiredMsg, validate: samePasswordValidation };
	const emailValidationObj = { required: emailRequiredMsg };

	//Services Administrative
	const administrativeService = useSelector(state => getAdministrativeServiceListAllData(state))?.records?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const administrativeServiceIsFetching = useSelector(state => getAdministrativeServiceListAllIsFetching(state));

	//List all administrative services by User 
	const listAllServiceAdministrativeByUserData = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.map(item => ({ label: item?.code + " - " + item?.shortName, value: item?.id }));
	
	// User to edit data
	const userToEdit = useSelector(state => getUserToEdit(state));
	const userProfile = useSelector(state => getUserXApplicationXProfileByUserId(state, userToEdit?.id));
	const userEditIsFetching = useSelector(state => getUserEditIsFetching(state));

	// List profiles
	const listProfileData = useSelector(state => getUserProfileListData(state));
	const hasListProfileData = isNotEmptyArray(listProfileData);
	const listProfileIsFetching = useSelector(state => getUserProfileListIsFetching(state));

	const listProfileDataIdUser = useSelector(state => getUserProfileListByUserId(state, userProfile?.profileId));
	const userProfileType = listProfileData?.find(item => item.id == userProfile?.profileId)

	const valuelistProfileDataIdUser = listProfileDataIdUser?.id;

	// Find List Users Asignated
	const serviceUserListRecords = listProfileData?.filter(value => {
		return !listProfileData?.find(value2 => value.id == valuelistProfileDataIdUser);
	});
	const valueUserProfile = listProfileDataIdUser?.name ? listProfileDataIdUser?.name : undefined;
	const [selectedProfile, setSelectedProfile] = useState(valueUserProfile);

	/* const idProfileUser = 22;  //ID 22 Operador de Servicio de Prespuesto */
	const allowedProfilesChecker = (selectedProfile) => {
		const profilesAllowApplyUserServices = [
			'Operador de Servicio de Presupuesto', 'Fiscalizador', 'Director de SA',
			'Operador de Tesoreria', 'Jefe de Tesoreria de SA', 'Jefe de Contabilidad de SA', 'Delegado Fiscal de SA',
			'Operador de Dirección de Contabilidad', 'Responsable de transferencia de fondos', 'Operador de Suministros'
		];
		return profilesAllowApplyUserServices?.some((item)=>item === selectedProfile);
	};

	/* const nameProfileUser = (listProfileDataIdUser?.name == idProfileOperadorServicioPresupuesto || listProfileDataIdUser?.name == idProfileFiscalizador) */
	
	// Set config Users By Profile
	const profileNameArray = setAssignProfiles();

	// Filter for type profile
	const profileNameArrayRecords = listProfileData?.filter(item => profileNameArray?.includes(item?.name));

	// Find List Profile Filter Asignated
	const profileNameArrayRecordsFilter = profileNameArrayRecords?.filter(value => {
		return !listProfileData?.find(value2 => value.id == valuelistProfileDataIdUser);
	})?.sort((a, b) => a.name > b.name ? 1 : -1);
	
	// Form submit
	const onFormSubmit = data => {
		setDisableafterclick(true);
		const userXApplicationXProfile = userProfile;
		let parseProfileId = Number.isInteger(parseInt(data?.profileId))
		const fundProfileId = listProfileData?.find(item => item?.name == data?.profileId);
		userXApplicationXProfile.profileId = parseProfileId ? data?.profileId : fundProfileId?.id;
		
/* 		let profileOperatorServiceBudget;
		profileOperatorServiceBudget = data?.profileId == idProfileUser; */
		const userData = {
			id: userToEdit?.id,
			//<-Se quito del formulario el campo de nombre de Usuario por este motivo se envia el nombre com Alias
			//Se respeta implementacion previa			
			name: joinLastNameName(data?.alias, data?.name).toUpperCase(),
			alias: data?.name?.toUpperCase(),
			email: data?.email,
			valid: userToEdit?.valid,
			userXApplicationXProfile
		};

		dispatch(tryPutUser(userData)).then(
			response => {
				if (response?.status == 200) {
					let userID = [response?.data?.id];
					if (allowedProfilesChecker(selectedProfile)) { //Remove all services ID Operador de Servicio de Prespuesto
						dispatch(tryRemoveAllUsersAdministrativeService(response?.data?.id)).then(
							response => {
								setDisableafterclick(false);
								if (response?.status == 200) {
									let selectedAdministrativeService = selected?.map( item => item.value );
									dispatch( tryAddNewUserAdministrativeService( userID, selectedAdministrativeService ) );
								}
							}
						);
					} else { //Add all services
						dispatch(tryAddAllUsersAdministrativeService(response?.data?.id));
					}
				}
			}
		);
		setDisableafterclick(false);
	};

	const onChangeProfile = item => {
		setSelectedProfile(item.target.options[item.target.selectedIndex].text);
	}

	const allowMultiselectService = (allowedProfilesChecker(selectedProfile));
	const allowMultiselectServiceSelected = (allowedProfilesChecker(selectedProfile) && !allowMultiselectService);

	// Checkboxes columns
	const [checkedOriginal, setCheckedOriginal] = useState(false);

	//// Multiselect
	// View items selector
	let options = [];
	let i = 0;
	for (i in administrativeService) {
		options.push({ label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value: administrativeService[i]?.id })
	}

	// Add options
	const [selected, setSelected] = useState([]);

	// Idiom Spanish
	const idiom = {
		"selectSomeItems": "Seleccionar una o más opciones...",
		"allItemsAreSelected": "Todos los elementos están seleccionados.",
		"selectAll": "Seleccionar todo",
		"search": "Buscar",
		"clearSearch": "Limpiar búsqueda."
	}

	// Load data
	const customValueRenderer = (selected, _options) => {
		return selected?.length
			? selected?.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
	};
	const hasValueCustomValueRenderer = (selected?.length === 0);
	//// END Multiselect	

	const allowGet = (userEditIsFetching || (allowedProfilesChecker(selectedProfile) && hasValueCustomValueRenderer));

	useEffect(() => {
		if (listAllServiceAdministrativeByUserData){
			setSelected(listAllServiceAdministrativeByUserData);
		}
	}, [checkedOriginal]);

	const securityPermissionsActionsProfiles = useSelector(state => getUserPermissionsSecurity(state))?.actions?.adminServices;
	return <Container fluid className='mb-5'>
		<Card>
			<PageTitle text='Usuarios' />
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header>
						<h6 className='mb-0'>{editUser}</h6>
					</Card.Header>
					<Card.Body >
						<Row>
							<Col xs={8}>
								<h6 className='mb-4 text-black-color'>

								</h6>
							</Col>
							<Col xs={4} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col>
						</Row>
						<Form onSubmit={handleSubmit(onFormSubmit)}>
							<Form.Group as={Row} controlId='alias'>
								<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{lastName}:
								</Form.Label>
								<Col sm={4}>
									<Form.Control 
										ref={register(lastNameValidationObj)} 
										type='text' 
										placeholder={lastName} 
										name='alias' 
										id='alias' 
										defaultValue={userNameData(userToEdit?.name)?.lastName} 
										disabled={userEditIsFetching}
										maxLength={50}
										minLength={3}  
									/>
									<FormFieldError errors={errors?.alias} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId='alias'>
								<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{name}:
								</Form.Label>
								<Col sm={4}>
									<Form.Control 
										ref={register(nameValidationObj)} 
										type='text' 
										placeholder={name} 
										name='name' 
										defaultValue={userNameData(userToEdit?.name)?.firstName} 
										disabled={userEditIsFetching}
										maxLength={50}
										minLength={3}  
									/>
									<FormFieldError errors={errors?.name} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId='email'>
								<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{email}:
								</Form.Label>
								<Col sm={4}>
									<Form.Control 
										ref={register(emailValidationObj)} 
										type='email' 
										placeholder={email} 
										name='email' 
										id='email' 
										defaultValue={`${userToEdit?.email}`} 
										disabled='true'
									/>
									<FormFieldError errors={errors?.email} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId='group'>
								<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{group}:
									</Form.Label>
								<Col sm={4}>
									<Form.Control
										as='select'
										name='profileId'
										ref={register}
										defaultValue={valueUserProfile}
										disabled={userEditIsFetching}
										onChange={event => onChangeProfile(event)}
									>
										<option className='text-black-color' value={valueUserProfile} disabled={userEditIsFetching} selected >
											{`${listProfileDataIdUser?.name || ''}`}
										</option>
										{
											!userEditIsFetching
												?
												profileNameArrayRecordsFilter?.map((item, index) =>
													<option key= {index} className='text-black-color' value={item.id}>
														{`${item.name || ''}`}
													</option>)
												:
												null
										}
									</Form.Control>
								</Col>
								{
									listProfileIsFetching
										?
										<Spinner className='spinner-border text-danger' animation='border' size='sm' />
										:
										null
								}
							</Form.Group>
							{/*
								(allowMultiselectServiceSelected && securityPermissionsActionsProfiles) //<- ID Operador de Servicio de Prespuesto
								?
									<Form.Group as={Row} controlId='email'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										Servicio:
									</Form.Label>
										<Col sm={4}>
											<MultiSelect
												className='text-black-color'
												options={options}
												value={selected}
												onChange={setSelected}
												labelledBy={"Select"}
												overrideStrings={idiom}
												valueRenderer={customValueRenderer}
												hasSelectAll={true}
												isLoading={administrativeServiceIsFetching}
												ClearSelectedIcon={"🧹Limpiar"}
											/>
											{
												hasValueCustomValueRenderer
													?
													<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
														{`${'Debe seleccionar al menos un ítem.'}`}
													</div>

													:
													null
											}
										</Col>
										<FormFieldError errors={errors?.service} />
									</Form.Group>
									:
									null
							*/}
							{
								allowMultiselectService
									?
									<>
										<Form.Group as={Row} controlId='email'>
											<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>

											</Form.Label>
											<Col sm={4}>
												<Form.Check
													className='text-black-color'
													id='original-credit'
													label='Ver/Editar servicios asociados al usuario'
													checked={checkedOriginal}
													onChange={event => setCheckedOriginal(event.target.checked)}
												/>
											</Col>
										</Form.Group>
										{
											checkedOriginal ?
											<Form.Group as={Row} controlId='email'>
												<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
													<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
												Servicio:
											</Form.Label>
												<Col sm={4}>
													<MultiSelect
														className='text-black-color'
														options={options}
														value={selected}
														onChange={setSelected}
														labelledBy={"Select"}
														overrideStrings={idiom}
														valueRenderer={customValueRenderer}
														hasSelectAll={true}
														isLoading={administrativeServiceIsFetching}
														ClearSelectedIcon={"🧹Limpiar"}
														disabled={!checkedOriginal}
													/>
													{
														hasValueCustomValueRenderer ?
															<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
																{`${'Debe seleccionar al menos un ítem.'}`}
															</div>
															: null
													}
												</Col>
												<FormFieldError errors={errors?.service} />
											</Form.Group>
											: null
										}
									</>
									: null
							}
							<div className='d-flex justify-content-around mt-4 mb-3'>
								<Button variant='danger' size='lg' disabled={userEditIsFetching} onClick={() => dispatch(push(USER_LIST))}>
									{cancel}
								</Button>
								<span className={(userEditIsFetching ? '' : 'hidden')}>
									<Spinner className='spinner-border text-danger' animation='border' />
								</span>
								<Button type='submit' variant='success' size='lg' disabled={allowGet || disableafterclick} >
									{save}
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default UserEditPage;