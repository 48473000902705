import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import 'react-widgets/dist/css/react-widgets.css';
import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';
import DropdownList from 'react-widgets/lib/DropdownList'
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportFundRequestBankAccount } from 'src/redux/reports/reportsActionCreactor';
import { getReportBankAccountFundRequestIsFetching } from 'src/redux/reports/reportsReducer';
import { tryGetListBankAccountFundRequest } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestActionCreator';
import { getBankAccountFundRequestReducerData, getBankAccountFundRequestReducerIsFetching } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer'
import { getListAllServiceAdministrativeByUserIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import RangeDates from 'src/components/common/RangeDates';
import { AdministrativeServiceData } from 'src/utils/administrativeServiceUtils';

const ReportBankAccountFundRequestPage = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	const reportDates = useSelector( state => getReportDatesData(state) );

	// Periods
	const periodList = useSelector(state => getGlobalDataPeriodsData(state))?.data?.sort((a, b) => a.year < b.year ? 1 : -1);
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	// Admnistrative Service
	const administrativeServiceData = AdministrativeServiceData();
	const administrativeServiceIsFetching = useSelector( state => getListAllServiceAdministrativeByUserIsFetching(state) );
	const [ selectedAdministrativeService, setSelectedAdministrativeService ] = useState();

	const [dateFrom, setDateFrom] = useState(reportDates?.dateFrom);
	const [dateTo, setDateTo] = useState(reportDates?.dateTo);

	const [dateHasErrors, setDateHasErrors] = useState(false);

	const bankAccountListData = useSelector(state => getBankAccountFundRequestReducerData(state))
		?.records?.sort((a, b) => a.number > b.number ? 1 : -1)
		?.map(item => ({
			displayName: `${item?.number} - ${item?.description}`,
			number: item?.number,
			description: item?.description,
			administrativeServiceId: item?.administrativeServiceId,
			id: item?.id
		}));

	const applyFilterBankAccounts = (administrativeService) => {
		setSelectedAdministrativeService(administrativeService);
		setSelectedBankAccount(false)
		const params = {
			year: globalSelectedPeriod?.year,
			administrativeServiceId: administrativeService?.id
		};
		dispatch(tryGetListBankAccountFundRequest(params));
	}
	
	const bankAccountIsFetching = useSelector(state => getBankAccountFundRequestReducerIsFetching(state));
	const [selectedBankAccount, setSelectedBankAccount] = useState();
	const messagesDropDown = { emptyFilter: 'Sin resultados', emptyList: 'Sin resultados' };
	const messagesDropDownService = { emptyFilter: 'El Servicio buscado no se encontró', emptyList: 'El Servicio seleccionado no posee cuentas bancarias asociadas' };
	

	const getReport = outputFormat => {
		if (globalSelectedPeriod) {
			const params = {
				outputFormat,
				exerciseId: globalSelectedPeriod?.id,
				bankAccountId: selectedBankAccount?.id,
				administrativeServiceId: selectedAdministrativeService?.id,
				dateTo,
				dateFrom
			};
			dispatch(tryGetReportFundRequestBankAccount(params));
		}
	};

	const onClickPDF = () => getReport(1);
	const onClickXLS = () => getReport(2);
	const bankAccountListDataFilter = isNotEmptyArray(bankAccountListData);
	// Reports redux
	const reportIsFetching = useSelector(state => getReportBankAccountFundRequestIsFetching(state));
	const allowGetReport = (hasPeriodList && !reportIsFetching && dateTo && dateFrom && selectedBankAccount && !dateHasErrors);

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
						<h1 className="h6 mt-1 mb-0">Reporte de Pedido de Fondos por Cuenta Bancaria</h1>
					</Card.Header>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={8} className='offset-md-2' >
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>
										<Form.Control
											type='number'
											name='globalSelectedPeriod'
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<DropdownList
											placeholder='Seleccione un servicio...'
											busy={administrativeServiceIsFetching}
											data={administrativeServiceData}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											className='text-black-color'
											onChange={ value => applyFilterBankAccounts(value) }
											messages={ messagesDropDown }
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
										</DropdownList>
									</Form.Group> 

									<Form.Group>
										<Form.Label className='text-black-color'>
											Cuenta Bancaria
										</Form.Label>
										<Row>
											<Col sm={bankAccountIsFetching ? 10 : 12}>
												<DropdownList
													placeholder={bankAccountListDataFilter ? "Selecciona una opción" : "El Servicio seleccionado no posee cuentas bancarias asociadas"}
													busy={bankAccountIsFetching}
													data={bankAccountListData}
													allowCreate='onFilter'
													textField='displayName'
													filter='contains'
													className='text-black-color'
													value={selectedBankAccount}
													onChange={setSelectedBankAccount}
													messages={messagesDropDownService}
													disabled={bankAccountIsFetching || !selectedAdministrativeService}
													selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
													searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />} >
												</DropdownList>
											</Col>
											{bankAccountIsFetching
												&&
												<Col sm={2}>
													{
														bankAccountIsFetching
															&&
															<Spinner animation='border' />
													}
												</Col>
											}
										</Row>
										{
											!bankAccountListData
												&&
												<small class="form-text text-muted font-italic">Debe seleccionar una opción.</small>
										}
									</Form.Group>

									<FormContext {...hookFormMethods}>
										<RangeDates
											{...{dateFrom, setDateFrom, dateTo, setDateTo, setDateHasErrors}}
										/>
									</FormContext>

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Ver PDF
										</Button>
										{
											reportIsFetching
												&&
												<Spinner animation='border' />
										}
										<div></div>
									{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</>
};

export default ReportBankAccountFundRequestPage;