import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import * as LABELS from 'src/utils/label';
import { isQualifiedValidDate, isInExerciseRange } from 'src/utils/utils';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { setReportDates } from 'src/redux/globalData/globalDataActions';

const RangeDates = props => {
	const dispatch = useDispatch();
	const { setDateHasErrors, dateFrom, setDateFrom, dateTo, setDateTo } = props;
	const { register, triggerValidation } = useFormContext();
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	const [messageError, setMessageError] = useState(false);
	const [enableMessageError, setEnableMessageError] = useState(false);

	const validateDatePeriod = () => {

		if(!isInExerciseRange(globalSelectedPeriod, dateFrom, dateTo)){
			setEnableMessageError(true);
		}else{
			setEnableMessageError(false);
		}
		//Muestra mensaje de error especifico
		setMessageError((dateFrom && dateTo && dateFrom > dateTo)?true: false);
	}

	// Date check, uso badInput para verificar fechas erroneas ya el input queda empty
	const errorDateFrom = (dateFrom && !isQualifiedValidDate(dateFrom)) || document.forms[0]?.dateFrom?.validity?.badInput;
	const errorDateTo = (dateTo && !isQualifiedValidDate(dateTo)) || document.forms[0]?.dateTo?.validity?.badInput;
	const errorsDates = ( errorDateFrom || errorDateTo );

	useEffect(() => {
		if ( dateFrom || dateTo ){
			dispatch(setReportDates({dateFrom, dateTo}))
		}
	}, [dateFrom, dateTo]);

	let hasErrors = errorsDates || enableMessageError || messageError;
	setDateHasErrors(hasErrors);
	// useEffect(() => {
	// 	let hasErrors = errorsDates || enableMessageError || messageError;
	// 	setDateHasErrors(hasErrors);
	// }, [enableMessageError, messageError]);

	useEffect(() => {
		triggerValidation();
	}, [globalSelectedPeriod]);

	let exerciseStartDate = globalSelectedPeriod?.periods[0].startDate;
	let exerciseEndDate = globalSelectedPeriod?.periods[13].endDate;

	return <>
			<Form.Group>
				<Form.Label className='text-black-color'>
					{LABELS.dateSince}
				</Form.Label>
				<Form.Control
					ref={register({ required: 'ingrese fecha', validate: validateDatePeriod })}
					name='dateFrom'
					type='date'
					min={props.fechas ? '' : exerciseStartDate}
					max={props.fechas ? '' : exerciseEndDate}
					defaultValue={dateFrom}
					onChange={event => setDateFrom(event.target.value)}
					onBlur={event => validateDatePeriod(event.target.value)} />
			</Form.Group>
			{errorDateFrom &&
				<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
				{`${'Fecha inválida.'}`}
				</div>
			}
			
			<Form.Group>
				<Form.Label className='text-black-color'>
					{LABELS.dateUntil}
				</Form.Label>
				<Form.Control
					ref={register({ required: 'ingrese fecha', validate: validateDatePeriod })}
					name='dateTo'
					type='date'
					min={props.fechas ? '' : exerciseStartDate}
					max={props.fechas ? '' : exerciseEndDate}
					defaultValue={dateTo}
					onChange={event => setDateTo(event.target.value)} 
					onBlur={event => validateDatePeriod(event.target.value)} />
			</Form.Group>
			{errorDateTo &&
				<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
				{`${'Fecha inválida.'}`}
				</div>
			}

			{enableMessageError &&
				<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
				{'Error: Las fechas seleccionadas no se encuentran dentro de los períodos habilitados del Ejercicio.'}
				</div>
			}

			{messageError &&
				<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
				{'Error: La fecha desde es superior a la fecha hasta'}
				</div>
			}
			
	</>;
};

export default RangeDates;