import * as ACTION_TYPES from './budgetItemControlActionTypes';

// Get budget item control request actions
export const getBudgetItemControlRequest = params => ({
	type: ACTION_TYPES.GET_BUDGET_ITEM_CONTROL_REQUEST,
	payload: {
		params
	}
});
export const getBudgetItemControlRequestSuccess = data => ({
	type: ACTION_TYPES.GET_BUDGET_ITEM_CONTROL_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getBudgetItemControlRequestFailure = error => ({
	type: ACTION_TYPES.GET_BUDGET_ITEM_CONTROL_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearBudgetItemControlData = () => ({
	type: ACTION_TYPES.CLEAR_BUDGET_ITEM_CONTROL_DATA
});
// END Get budget item control request actions





