import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Table, Modal, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faEquals } from '@fortawesome/free-solid-svg-icons';

import { formatterPeso, numberNegativeRed } from 'src/utils/utils';

import { getTotalAmountsIsFetching } from 'src/redux/credit/creditReducer';
import { getFilterObject } from 'src/redux/globalData/globalDataReducer';

const CreditExecutionBalanceTotalModalComponent = props => {

	const { show, handleClose } = props;

	const dispatch = useDispatch();

	//Cancel Request
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();

	// Credit details
	const totalAmountsData = props?.data;
	const totalAmountsIsFetching = useSelector(state => getTotalAmountsIsFetching(state));

	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);

	const currentCredit = totalAmountsData?.actualCredit;
	const accumulatorPreventive = totalAmountsData?.preventive;
	const accumulatorDefinitive = totalAmountsData?.definitive;
	const accumulatorPaymentObligation = totalAmountsData?.paymentObligation;
	const accumulatorPayment = totalAmountsData?.payment;

	// Amount Credit
	const originalCredit = totalAmountsData?.originalCredit;
	const modificationCredit = totalAmountsData?.modificationCredit;
	const balanceBudget = totalAmountsData?.balance;


	// Calculator
	//Crédito actual - Acumulador Preventiva = Saldo Preventiva
	const balancePreventive = currentCredit - accumulatorPreventive;
	const balancePreventiveFormatter = formatterPeso.format(balancePreventive);

	// Acumulador Preventiva - Acumulador Definitiva = Saldo Definitiva
	const balanceDefinitive = accumulatorPreventive - accumulatorDefinitive;
	const balanceDefinitiveFormatter = formatterPeso.format(balanceDefinitive);

	//Acumulador Definitiva - Acumulador obl. de pago = Saldo obl. de pago
	const balancePaymentObligation = accumulatorDefinitive - accumulatorPaymentObligation;
	const balancePaymentObligationFormatter = formatterPeso.format(balancePaymentObligation);

	//Acumulador obl. de pago - Acumulador pago = Saldo pago
	const balancePayment = accumulatorPaymentObligation - accumulatorPayment;
	const balancePaymentFormatter = formatterPeso.format(balancePayment);
	// End Calculator

	// Amount Credit
	const currentCreditAmountFormatter = formatterPeso.format(currentCredit);

	const totalsPreventiveCreditAmountFormatter = formatterPeso.format(accumulatorPreventive);
	const totalsDefinitiveCreditAmountFormatter = formatterPeso.format(accumulatorDefinitive);
	const totalsPaymentObligationCreditAmountFormatter = formatterPeso.format(accumulatorPaymentObligation);
	const totalsPaymentCreditAmountFormatter = formatterPeso.format(accumulatorPayment);

	const originalCreditAmountValue = formatterPeso.format(originalCredit);
	const modificationCreditAmountValue = formatterPeso.format(modificationCredit);
	const currentCreditAmountValue = formatterPeso.format(currentCredit);
	const balanceBudgetAmountValue = formatterPeso.format(balanceBudget);


	return <Container fluid>
		<Modal show={show} onHide={handleClose} size={'xl'}>

			<Modal.Header closeButton>
				<Modal.Title >{props?.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='p-0'>
				<Card>
					<Card.Header>
						<h5 className='text-center mb-0'>{props?.titleCredit}</h5>
					</Card.Header>

					<Card.Body className='p-0'>
						<Col className='text-black-color shadow-sm p-2 mb-1 bg-white rounded'>
							<Table striped borderless hover>
								{
									!totalAmountsIsFetching ?
										<tbody>
											<tr>
												<td className='text-left' md={4}>{'Crédito Original'}:</td>
												<td className={`${numberNegativeRed(originalCredit)} text-right`} md={4} md-offset={8}>
													{originalCreditAmountValue}
												</td>
												<td className='text-left' md={4}>{'Modificaciones'}:</td>
												<td className={`${numberNegativeRed(modificationCredit)} text-right`} md={4} md-offset={8}>
													{modificationCreditAmountValue}
												</td>
											</tr>
											<tr>
												<td className='text-left' md={4}>{'Crédito Actual'}:</td>
												<td className={`${numberNegativeRed(currentCredit)} text-right`} md={4} md-offset={8}>
													{currentCreditAmountValue}
												</td>
												<td className='text-left' md={4}>{'Total de Saldo'}:</td>
												<td className={`${numberNegativeRed(balanceBudget)} text-right`} md={4} md-offset={8}>
													{balanceBudgetAmountValue}
												</td>
											</tr>
										</tbody>
									:
										<div className='text-center py-3' >
											<Spinner animation='border' variant='success'/>
										</div>
								}
							</Table>
						</Col>
					</Card.Body>
				</Card>

				<Card className='p-0'>
					<Card.Header>
						<h5 className='text-center mb-0'>{props?.titleBalance}</h5>
					</Card.Header>
					<Card.Body className='p-0'>
						<Table striped borderless hover >
							{
								!totalAmountsIsFetching ?
									<tbody>
										<tr>
											<Row>
												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
														<Form.Label className="text-black-color" column sm="5">
															Crédito actual:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(currentCredit)} text-right px-0 py-2`}>
															{currentCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="accumulatorPreventive">
														<Form.Label className="text-black-color" column sm="5">
															Acumulador Preventiva:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(accumulatorPreventive)} text-right px-0 py-2`}>
															{totalsPreventiveCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={3} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="preventiveBalance">
														<Form.Label className="text-black-color" column sm="5">
															Saldo Preventiva:
														</Form.Label>
														<Col sm="7" className={`${numberNegativeRed(balancePreventive)} text-right px-0 py-2`}>
															{balancePreventiveFormatter}
														</Col>
													</Form.Group>
												</Col>
											</Row>
										</tr>

										<tr>
											<Row>
												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="accumaltorPreventive2">
														<Form.Label className="text-black-color" column sm="5">
															Acumulador Preventiva:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(accumulatorPreventive)} text-right px-0 py-2`}>
															{totalsPreventiveCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive">
														<Form.Label className="text-black-color" column sm="5">
															Acumulador Definitiva:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(accumulatorDefinitive)} text-right px-0 py-2`}>
															{totalsDefinitiveCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={3} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="definitiveBalance">
														<Form.Label className="text-black-color" column sm="5">
															Saldo Definitiva:
														</Form.Label>
														<Col sm="7" className={`${numberNegativeRed(balanceDefinitive)} text-right px-0 py-2`}>
															{balanceDefinitiveFormatter}
														</Col>
													</Form.Group>
												</Col>
											</Row>
										</tr>

										<tr>
											<Row>
												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive2">
														<Form.Label className="text-black-color" column sm="5">
															Acumulador Definitiva:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(accumulatorDefinitive)} text-right px-0 py-2`}>
															{totalsDefinitiveCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation">
														<Form.Label className="text-black-color" column sm="5">
															Acumulador Obl. Pago:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(accumulatorPaymentObligation)} text-right px-0 py-2`}>
															{totalsPaymentObligationCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={3} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="balancePaymentObligation">
														<Form.Label className="text-black-color" column sm="5">
															Saldo Obl. Pago:
														</Form.Label>
														<Col sm="7" className={`${numberNegativeRed(balancePaymentObligation)} text-right px-0 py-2`}>
															{balancePaymentObligationFormatter}
														</Col>
													</Form.Group>
												</Col>
											</Row>
										</tr>

										<tr>
											<Row>
												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation3">
														<Form.Label className="text-black-color" column sm="5">
															Acumulador Obl. Pago:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(accumulatorPaymentObligation)} text-right px-0 py-2`}>
															{totalsPaymentObligationCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={4} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="accumulatorPayment">
														<Form.Label className="text-black-color" column sm="5">
															Acumulador pago:
														</Form.Label>
														<Col sm="6" className={`${numberNegativeRed(accumulatorPayment)} text-right px-0 py-2`}>
															{totalsPaymentCreditAmountFormatter}
														</Col>
													</Form.Group>
												</Col>

												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
												</Form.Group>

												<Col xs={3} className='text-right text-black-color'>
													<Form.Group className='mb-0' as={Row} controlId="paymentBalance">
														<Form.Label className="text-black-color" column sm="5">
															Saldo pago:
														</Form.Label>
														<Col sm="7" className={`${numberNegativeRed(balancePayment)} text-right px-0 py-2`}>
															{balancePaymentFormatter}
														</Col>
													</Form.Group>
												</Col>
											</Row>
										</tr>
									</tbody>
									:
									<div className='text-center py-3' >
										<Spinner animation='border' variant='success'/>
									</div>
							}	
						</Table>
					</Card.Body>
				</Card>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="danger" onClick={handleClose}>
					Cerrar
				</Button>
			</Modal.Footer>

		</Modal>
	</Container>;
};

export default CreditExecutionBalanceTotalModalComponent;