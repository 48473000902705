import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import { Table, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFilter, faBroom } from '@fortawesome/free-solid-svg-icons';
import { preventiveAccumulator, ultimateAccumulator, paidAccumulator } from 'src/utils/label';
import { formatterPeso, getClearObject } from 'src/utils/utils';
import moment from 'moment';
import { CREDIT_EXECUTION } from 'src/utils/constants';
import { tryGetExpedientByFilter } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { convertExpedientToPassiveRemaining, reversePassiveRemaining } from 'src/redux/affectation/affectationActionCreator';
import { getExpedientToPassiveRemainingIsFetching, getReversePassiveRemainingIsFetching } from 'src/redux/affectation/affectationReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getExercisesListData } from 'src/redux/exercise/exerciseReducer';
import { getexpedientCreditByFilterData, getexpedientCreditByFilterIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { clearexpedientByFilterData} from 'src/redux/administrativeDocument/administrativeDocumentActions'
import ActionIcon from 'src/components/general/ActionIcon';
import RCPagination from 'src/components/common/RCPagination';
import ExpedientModalReportDetails from 'src/components/pages/Reports/ExpedientModalReportDetails';
import AppLoading from 'src/components/common/AppLoading';

const ExpedientTableCreditQuery = ({filter, details, cleanAffectationHystoryFilter, subcodeData}) => {
    const dispatch = useDispatch();
    const { subCodeId, periodRpId } = filter;
    const { periodName, periodRpName} = details;
    const partialBudget = subcodeData?.code?.credit?.partialBudget?.number;
    const principalBudget = subcodeData?.code?.credit?.partialBudget?.principalBudget?.number;
    const filterToSend = getClearObject(filter);
    const { handleSubmit, register } = useForm();
    const [completeLabel, setCompleteLabel] = useState('');
    const [isPassiveRemaining, setIsPassiveRemaining] = useState(false);
    const [isPassiveRemainingConverted, setIsPassiveRemainingConverted] = useState(false);
    const passiveRemainingPeriod = useState(periodRpId ? periodRpName : periodName);

    const defaultFilter = {
        page: 1,
        pageSize: 10,
    };
    
    const [ filterObject, setFilterObject ] = useState({
        ...defaultFilter,
        filter: { ...filterToSend }
    });

    //Modal
    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const expedients = useSelector(state => getexpedientCreditByFilterData(state));
    const isFetching = useSelector(state => getexpedientCreditByFilterIsFetching(state));
    const convertToPassiveRemainingIsFetching = useSelector(state => getExpedientToPassiveRemainingIsFetching(state));
    const reversePassiveRemainingIsFetching = useSelector(state => getReversePassiveRemainingIsFetching(state));

    const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
    const selectedPeriod = globalSelectedPeriod?.year;
    const enabledPeriodRP = selectedPeriod - 1;
    const totalItemsCount = expedients?.totalCount ? expedients?.totalCount : 1;
    const totalItems = expedients?.totalCount ? expedients?.totalCount : '0';
    const messageAlert = "asociados";
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const dateTo = '2024-12-01';

    //TODO: Available buttons date complementary exercise 
	const exercisesListData = useSelector(state => getExercisesListData(state));
	const dateToComplementary = exercisesListData?.records?.filter(item => item?.year === globalSelectedPeriod?.year)[0]?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0]?.startDate;
    const isAvailableShowActionsRp = value => {
		let isAvailableYearActionsRP = globalSelectedPeriod?.year == value && (todayDate >=  moment(dateToComplementary).format('YYYY-MM-DD'));
		return isAvailableYearActionsRP;
	} //TODO: END

    const passiveRemainingTotalToHideRPActions = 0;
    const hideRPButtonByYearValidation = () => {
        const restrictedPartialBudget = "02020";
        const restrictedPrincipalBudget = "999";
        const period2022 = 2022;
        if(selectedPeriod == period2022){
            if(partialBudget == restrictedPartialBudget && principalBudget == restrictedPrincipalBudget){
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const hideRPActionsByDateValidation = () => {
        if(periodName === 2024 && todayDate <= dateTo){
            return true;
        } else {
            return false;
        }
    };

    const hideRPActions = hideRPActionsByDateValidation();
    const hideRPButton = hideRPButtonByYearValidation();

    const onPageChange = (pNumber) => {
        const params = {
            ...defaultFilter,
            filter: { ...filterToSend, completeLabel },
            page: pNumber
        }
        if(isPassiveRemaining) {
            delete params.filter.subCodeId;
            params.filter.availableRpBySubCode = [filterToSend?.subCodeId, null];
        }
        if(isPassiveRemainingConverted) {
            params.filter.administrativeDocumentsConvertedBySubCode = [filterToSend?.subCodeId]
        }
        setFilterObject(params);
        fetchList(params);
    };

    const fetchList = (params) => {
        dispatch(clearexpedientByFilterData());
        dispatch(tryGetExpedientByFilter(params));
    };

    //Convert
	const onClickConvertToPassiveRemaining = (expedient) => {
        const expedientId = expedient?.id;
        const expedientLabel = expedient?.completeLabel;
		const message = `¿ Está seguro que desea convertir el expediente ${expedientLabel} a Residuos Pasivos ?`;
        const paramsToSend = {
            administrativeDocuments: [{
                administrative_document_id: expedientId,
                sub_code_ids: [subCodeId],
                period_id: globalSelectedPeriod?.id
            }]
        };

		swal({
            title: 'Confirmación',
            text: message,
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        }).then((willDelete) => {
                if (willDelete) {
					dispatch(convertExpedientToPassiveRemaining(paramsToSend)).then(
						response => {
							if (response?.status == 200) {
								fetchList(filterObject);
                                dispatch(cleanAffectationHystoryFilter());
							}
						}
					);
				}
		});
	};

    // Revertir Residuo Pasivo
	const onClickRevertPassiveRemaining = (expedient) => {
        const url = expedient?.revertRpUrl;
        const expedientLabel = expedient?.completeLabel;
		const message = `¿ Está seguro que desea revertir residuos pasivos del expediente ${expedientLabel} ?`;
        const paramsToSend = { url };

		swal({
            title: 'Confirmación',
            text: message,
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
					dispatch(reversePassiveRemaining(paramsToSend)).then(
						response => {
							if (response?.status == 200) {
								dispatch(push(CREDIT_EXECUTION));
							};
						}
					);
				}
			});

	};

    const handlePassiveRemainingCondition = () => {
        handleFilter({ completeLabel, passiveRemainingCondition: true, passiveRemainingConverted: false });
    };
    
    const handlePassiveRemainingConverted = () => {
        handleFilter({ completeLabel, passiveRemainingCondition: false, passiveRemainingConverted: true });
    };

    const handleFilter = ({completeLabel, passiveRemainingCondition, passiveRemainingConverted}) => {
        const noSpaceValue = completeLabel.replace(/ /g, "");
        setCompleteLabel(noSpaceValue);
        setIsPassiveRemaining(passiveRemainingCondition);
        setIsPassiveRemainingConverted(passiveRemainingConverted);
        const params = {
            ...filterToSend,
            completeLabel: noSpaceValue
        };
        if (passiveRemainingCondition) {
            params.subCodeId =  null;
            params.availableRpBySubCode = [filterToSend?.subCodeId, null]
        }
        if (passiveRemainingConverted) {
            params.subCodeId =  filterToSend?.subCodeId;
            params.administrativeDocumentsConvertedBySubCode = [filterToSend?.subCodeId]
        }
        const paramsToSend = getClearObject(params);
        setFilterObject({...defaultFilter, filter: paramsToSend});
        fetchList({
            ...defaultFilter,
            filter: paramsToSend
        });
    };

    const cleanFilters = () => {
        setCompleteLabel('');
        setIsPassiveRemaining(false);
		fetchList({
            ...defaultFilter,
            filter: {
                ...filterToSend
            }
        });
    };

    useEffect(() => {
        fetchList({
            ...defaultFilter,
            filter: {
                ...filterToSend
            }
        });
	}, []);

    return (
        <>
            <Form onSubmit={handleSubmit(handleFilter)}>
                <Form.Group hidden={hideRPActions || !isAvailableShowActionsRp(2023)}>																										
					<Form.Check
						label="Ver solo expedientes en condición de convertir a Residuos Pasivos"
						className="text-black-color"
                        id="rpCondition"
						name="passiveRemainingCondition"
                        ref={register}onChange={handlePassiveRemainingCondition}
					/>
                    <Form.Check
						label="Ver solo expedientes que fueron convertidos a Residuos Pasivos"
						className="text-black-color"
                        id="rpConverted"
						name="passiveRemainingConverted"
                        ref={register}
                        onChange={handlePassiveRemainingConverted}
					/>
				</Form.Group>
                <Table bordered striped hover size='sm'>
                    <thead>
                        <tr>
                            <th className='text-center align-middle' width ='15%'>Expediente</th>
                            <th className='text-center align-middle' width ='15%'>{ preventiveAccumulator }</th>
                            <th className='text-center align-middle' width ='15%'>{ ultimateAccumulator }</th>
                            <th className='text-center align-middle' width ='15%'>Acumulador Obl. de pago</th>
                            <th className='text-center align-middle' width ='15%'>{ paidAccumulator }</th>
                            <th className='text-center align-middle' width ='15%'>Saldo</th>
                            <th className='text-center align-middle' width ='10%'>Acciones</th>
                        </tr>
                        <tr className='secondary'>
                            <th className='text-center'>
                                <Form.Control
                                    className='text-center text-black-color'
                                    name='completeLabel'
                                    ref={register}
                                    size='sm'
                                    defaultValue={completeLabel}
                                />
                            </th>
                            <th className='text-center'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'>
								<div className='d-flex justify-content-around'>
                                    <ActionIcon
                                        size="lg"
                                        id="search-button"
                                        className="btn-primary search-button text-white-color"
                                        toolTipText="Filtrar"
                                        icon={faFilter}
                                        type='submit'
                                    />
                                    <ActionIcon
                                        size="lg"
                                        id="clean-filter"
                                        className="btn-primary clean-filter text-white-color"
                                        toolTipText="Limpiar filtros"
                                        icon={faBroom}
                                        type='reset'
                                        onClick={()=> cleanFilters()}
                                    />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        {expedients?.data?.length ? 
                            expedients?.data?.map((expedient) =>
                                <tr key={expedient.id}>
                                    <td className='text-center align-middle'>{expedient?.completeLabel}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.preventiveTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.definitiveTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.paymentObligationTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.paymentTotal)}</td>
                                    <td className='text-right align-middle'>{formatterPeso.format(expedient?.total?.passiveRemainingTotal)}</td>
                                    <td className='text-center align-middle'>
                                        { (expedient?.revertRpUrl) ? 
                                            <Button
                                                variant='danger'
                                                title='Revertir Residuo Pasivo'
                                                disabled={reversePassiveRemainingIsFetching}
                                                onClick={() => onClickRevertPassiveRemaining(expedient)}
                                            >
                                                Revertir RP
                                            </Button>
                                            : <>
                                                {((isAvailableShowActionsRp(2023) && 
                                                    (expedient?.total?.passiveRemainingTotal != passiveRemainingTotalToHideRPActions)) && 
                                                    !hideRPButton && !hideRPActions
                                                ) &&
                                                    <>
                                                        { expedient?.availableToRP &&
                                                                <Button
                                                                    variant='danger'
                                                                    title='Convertir a Residuos Pasivos'
                                                                    onClick={() => onClickConvertToPassiveRemaining(expedient)}
                                                                >
                                                                    Convertir RP
                                                                </Button>
                                                        }
                                                    </>
                                                }
                                                { expedient?.isConverted && 
                                                    <p className='mb-0'>RESIDUOS PASIVOS - {passiveRemainingPeriod} </p>
                                                }
                                            </>
                                        }
                                    </td>
                                </tr>
                            ) : !isFetching ?
                            <tr>
                                <td colSpan='7' className='text-center'>
                                    <Alert variant='info' className='mb-0'>
										<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
										{`Sin expedientes ${messageAlert} para mostrar`}
									</Alert>
                                </td>
                            </tr> :
                            <td colSpan='7' className='text-center'>
                                <div className={`text-center text-black-color my-4`}>
                                    <Spinner animation="border" />
                                    <span className="visually-hidden h4 ml-4 pb-3">Cargando...</span>
                                </div>
                            </td>
                        }
                    </tbody>
                </Table>
            </Form>
            {show &&
                <ExpedientModalReportDetails
                    show = {show}
                    handleClose = {handleClose}
                    dataModal = { dataModal }
                />
            }
            {expedients?.data?.length &&
                <RCPagination
                    activePage={expedients?.page}
                    itemsCountPerPage={expedients?.perPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={7}
                    thePage={expedients?.page}
                    totalItems={totalItems}
                    innerClass="justify-content-center"
                    onChange={onPageChange}
                /> 
            }
            <AppLoading isLoading={convertToPassiveRemainingIsFetching}/>
        </>
    );
};

export default ExpedientTableCreditQuery;