import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Button, Table} from 'react-bootstrap';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { WAGES_TEMPLATE_LIST } from 'src/utils/constants';
import { getWageTemplateDetail } from 'src/redux/wageTemplate/wageTemplateReducer';
import { AdministrativeServiceData, administrativeService } from 'src/utils/administrativeServiceUtils';

const WagesTemplateDetailsPage = () => {
    const dispatch = useDispatch();
    const [wageItemSelected, setWageItemSelected] = useState([]);
	const WageTemplateDetail = useSelector((state) => getWageTemplateDetail(state));
	const administrativeServiceData = AdministrativeServiceData();

	const onClickBackButton = () => {
		dispatch(push(WAGES_TEMPLATE_LIST));	
	};
		
    useEffect(()=>{
		const wageTemplateDetailList = WageTemplateDetail?.wageTemplateItemCodes?.map(i => i);
		let wageTemplateDetailOrdered = wageTemplateDetailList.sort((a, b) => {
			return a.concept.description.localeCompare(b.concept.description);
		});
		setWageItemSelected(wageTemplateDetailOrdered);
    }, []);

	return <>
		<Container fluid>
			<Card className='mb-3'>
				<Card.Header className='h6'>
					Detalle Plantilla de Liquidación de Haberes
				</Card.Header>
                <Card.Body>
                    <Table className='text-black-color' striped borderless hover responsive>
						<tbody>
							<tr>
								<td className='text-center font-weight-bold' width="40%">
									Servicio:
								</td>
								<td className='text-center maxTruncate'>
									{administrativeService(WageTemplateDetail?.administrativeServiceId, administrativeServiceData)}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width="40%">
									Metodo de contratación:
								</td>
								<td className='text-center maxTruncate'>
									{WageTemplateDetail?.type}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width="40%">
									Nombre de plantilla:
								</td>
								<td className='text-center maxTruncate'>
									{WageTemplateDetail?.name.replace(/_/g, ' ')}
								</td>
							</tr>
						</tbody>
					</Table>
					<p className='text-black-color h6 mt-5' >
						Concepto de liquidación:
					</p>
					{wageItemSelected &&
						<Table striped hover size='sm' responsive>
							<thead>
								<tr size='lg'>
									<th className='text-center text-white' width="25%">Concepto</th>
									<th className='text-center text-white' width="25%">Subconcepto</th>
									<th className='text-center text-white' width="25%">Item</th>
									<th className='text-center text-white' width="25%">Código</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{wageItemSelected?.map(item =>
									<tr>
										<td className='text-center maxTruncate'>
											{item?.concept?.description}
										</td>
										<td className='text-center maxTruncate'>
											{item?.subConcept?.description}
										</td>
										<td className='text-center maxTruncate'>
											{item?.item}
										</td>
										<td className='text-center maxTruncate'>
											{item?.code}
										</td>
									</tr>
								)}
							</tbody>
						</Table>                             
					}

                    <div className='text-center'>
						<Button variant='primary' size='lg' className='my-3' onClick={onClickBackButton}>
							<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
							Volver
						</Button>
					</div>
                </Card.Body>
            </Card>
	    </Container>
    </>;
};
 
export default WagesTemplateDetailsPage;