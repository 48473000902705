import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm, FormContext } from 'react-hook-form';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import { parseIntOrUndefined } from 'src/utils/utils'
import { ADMINISTRATIVE_ORGANISM_LIST } from 'src/utils/constants';
import * as  LABELS from 'src/utils/label';

import { tryPostAdministrativeOrganism, tryClassifierAdministrativeOrganism } from 'src/redux/AdministrativeOrganism/administrativeOrganismActionCreator';
import { getAdministrativeOrganismNewIsFetching } from 'src/redux/AdministrativeOrganism/administrativeOrganismReducer';

import { tryGetJurisdictionList } from 'src/redux/jurisdiction/jurisdictionActionCreator';
import AdministrativeOrganismFormComponent  from 'src/components/forms/administrativeOrganism/AdministrativeOrganismFormComponent';

const AdministrativeOrganismNewPage = () => {

    const dispatch = useDispatch();
  
    const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;

    //New Organism Administrative
    const administrativeOrganismNewIsFetching = useSelector(state => getAdministrativeOrganismNewIsFetching(state));

    const onFormSubmit = data => {

        let codeAdministrative = parseIntOrUndefined(data?.organismAdministrativeCode);
        let codeBudget = parseIntOrUndefined(data?.budgetCode);
        let organismClassifier = parseIntOrUndefined(data?.organismClassifier);
        let idJurisdiction = parseIntOrUndefined(data?.jurisdiction);

        //Trim data
        let name = data?.nameOrganism?.replace(/^\s+|\s+$/g, '');
        let shortName = data?.shortNameOrganism?.replace(/^\s+|\s+$/g, '');
        let address = data?.direction?.replace(/^\s+|\s+$/g, '');
        let phone = data?.phone?.replace(/^\s+|\s+$/g, '');
        let mail = data?.email?.replace(/^\s+|\s+$/g, '');

        const paramsToSend = {
            name,
            shortName,
            codeAdministrative,
            codeBudget,
            jurisdiction:
            {
                id: idJurisdiction
            },
            organismClassifier:
            {
                id: organismClassifier
            },
            contact:
            {
                mail,
                phone,
                address
            }
        }

        dispatch(tryPostAdministrativeOrganism(paramsToSend)).then(response => {
            if (response.status == 200) {
                dispatch(push(ADMINISTRATIVE_ORGANISM_LIST));
            }
        });

    };

    useEffect(() => {
        dispatch(tryClassifierAdministrativeOrganism());
        dispatch(tryGetJurisdictionList());
    }, []);

    return <Container className='mb-5'>
        <Card>
            <PageTitle text={LABELS.organisms} />
            <Container fluid>
                <Card className='mb-3'>
                    <Card.Header>
                        <h6 className='mb-0'>
                            {LABELS.organismNew}
						</h6>
                    </Card.Header>
                    <Card.Body >
                        <Row>
                            <Col xs={8}>
                                <h6 className='mb-4 text-black-color'>
                                   {LABELS.detailsOfTheNeworganism}
								</h6>
                            </Col>
                            <Col xs={4} className='text-right font-weight-bold font-italic'>
                                <span className='text-danger d-flex mandatory-label'>
                                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{LABELS.required}
								</span>
                            </Col>
                        </Row>
                        <FormContext {...hookFormMethods} >
                            <Form className='mt-4' onSubmit={handleSubmit(onFormSubmit)}>
                        
                                <AdministrativeOrganismFormComponent />

                                <div className='d-flex justify-content-around mt-4 mb-3'>
                                    
                                    <Button 
                                        type='button' 
                                        variant='danger' 
                                        size='lg' 
                                        disabled={administrativeOrganismNewIsFetching} 
                                        onClick={() => dispatch(push(ADMINISTRATIVE_ORGANISM_LIST))}
                                    >
                                        {LABELS.cancel}
                                    </Button>

                                    <span className={administrativeOrganismNewIsFetching ? '' : 'hidden'}>
                                        <Spinner className='spinner-border text-danger' animation='border' />
                                    </span>

                                    <Button
                                        type='submit'
                                        variant='success'
                                        size='lg'
                                        disabled={administrativeOrganismNewIsFetching}
                                    >
                                        {LABELS.save}
                                    <FontAwesomeIcon icon={faSave} className='ml-2' />
                                    </Button>
                                    
                                </div>
                            </Form>
                        </FormContext>
                    </Card.Body>
                </Card>
            </Container>
        </Card>
    </Container>
};
export default AdministrativeOrganismNewPage;