import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormControl , Table, Button, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faFilter } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getClearObject } from 'src/utils/utils';
import { tryListFundRequests } from 'src/redux/fundRequests/fundRequestsActionCreator';
import { clearListFundRequestsData } from 'src/redux/fundRequests/fundRequestsActions';
import { getFundRequestsListData } from 'src/redux/fundRequests/fundRequestsReducer';
import ActionIcon from 'src/components/general/ActionIcon';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const ColumnSearchBox = props => {
    const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useForm();
    const { setselectedFundRequest, isFetching } = props;
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [filterObject, setFilterObject] = useState({});

	const creditLabelsRefContainer = useRef(null);
	const colSpanCellsNumber = creditLabelsRefContainer?.current?.cells.length;

    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const fundRequestsList = useSelector(state => getFundRequestsListData(state)?.records);

    let newFilterObject;

    const defaultParams = {
        page: 0,
        size: 10
    };
    const clearFilters = () => {
        setFilterObject({});
        reset()
        handlerResponsePage(defaultParams);
    };

    const onSubmitForm = (data) => {
        if (!isFetching) {
            newFilterObject = {
                ...defaultParams,
                ...data,
            };
            newFilterObject = getClearObject(newFilterObject);
            setFilterObject(newFilterObject);
            handlerResponsePage(newFilterObject);
        }
    } 

    const loadMoreItems = () => {
         const params = {
            page: pageNumber + 1,
            size: 10,
            ...filterObject
        };
        handlerResponsePage(params);
    };
    const loadLessItems = () => {
        const params = {
            page: pageNumber -1,
            size: 10,
            ...filterObject
        };
        handlerResponsePage(params);
    };

    const handlerResponsePage = (params)=>{
        setselectedFundRequest(null);
        dispatch(clearListFundRequestsData());
        params.year = globalSelectedPeriod.year;
        let promise = dispatch(tryListFundRequests(params));
        promise.then((response)=>{
            //para controlar mejor hasMore, ya que redux lo setea en estadoInicial al inicio del fetch
            let metadata = response.data.metadata;
            setHasMoreItems(metadata?.filteredCount > ((metadata?.page + 1) * metadata?.pageSize));
            setPageNumber(metadata.page);
        })
    };

    useEffect(() => {
        handlerResponsePage(defaultParams);
    }, [globalSelectedPeriod]);

    return <>
        <Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
            <Table striped bordered hover size='sm' >
                <thead>
                    <tr ref={creditLabelsRefContainer}>
                        <th className='text-center align-middle'>Número</th>
                        <th className='text-center align-middle'>Descripción</th>
                        <th className='text-center align-middle'>Estado</th>
                        <th className='text-center align-middle'>Caracter</th>
                        <th className='text-center align-middle th-minwidth'>Acciones</th>
                    </tr>
                    <tr className='secondary'>
                        <th>
                            <FormControl type='number' name="number" ref={register}/>
                        </th>
                        <th>
                            <FormControl type='text' name="description" ref={register}/>
                        </th>
                        <th>
                            <FormControl type='text' name="state" ref={register}/>
                        </th>
                        <th>
                            <FormControl type='number' name="character" ref={register}/>
                        </th>
                        <th className='text-center align-middle'>
                            <div className='d-flex justify-content-around'>
                                <ActionIcon
                                    size="lg"
                                    id="search-button"
                                    className="btn-primary search-button text-white-color"
                                    toolTipText="Filtrar"
                                    icon={faFilter}
                                    type='submit'
                                />
                                <ActionIcon
                                    size="lg"
                                    id="clean-filter"
                                    className="btn-primary clean-filter text-white-color"
                                    toolTipText="Limpiar filtros"
                                    icon={faBroom}
                                    type='reset'
                                    onClick={()=> clearFilters()}
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className='text-black-color'>{
                    fundRequestsList?.length? <>
                        {fundRequestsList?.map((item) => (

                            <tr key={item.id}>

                                <td className='text-center align-middle'>
                                    {item?.number}
                                </td>
                                <td className='text-center align-middle'>
                                    {item?.description}
                                </td>
                                <td className='text-center align-middle'>
                                    {item?.state}
                                </td>
                                <td className='text-center align-middle'>
                                    {item?.character}
                                </td>

                                <td className='text-center align-middle p-0 m-0' >
                                    <label style={{"width":"100%"}}>
                                        <Form.Check
                                            name="groupOptions"
                                            type="radio"
                                            onChange={() => setselectedFundRequest(item)}
                                        />
                                    </label>
                                </td>

                            </tr>

                        ))}
                    </>
                    :
                    <tr>
                        <td colSpan={colSpanCellsNumber} className='text-center'>
                            {
                                !isFetching &&
                                    <Alert variant='info' className='mb-0'>
                                        <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                    No hay registros
                                    </Alert>
                            }
                        </td>
                    </tr>
                }
                </tbody>
            </Table>
        </Form>
        <Row>
            <Col className={'text-right ' + (pageNumber >0? '':'hidden')}>
                <Button size='sm' className='px-5' disabled={isFetching || (pageNumber <1)} onClick={loadLessItems}>
                    <div style={{"position":"relative"}}>
                        <Spinner animation='border' size='sm' className={'stick-on-left ' + (!isFetching?'hidden': '')} />Ver menos
                    </div>
                </Button>
            </Col>
            <Col className={'text-left ' + (hasMoreItems?'': 'hidden') }>
                <Button size='sm' className='px-5' disabled={isFetching || !hasMoreItems} onClick={loadMoreItems}>
                    <div style={{"position":"relative"}}>
                        <Spinner animation='border' size='sm' className={'stick-on-left ' + (!isFetching?'hidden': '')} />Ver más
                    </div>
                </Button>
            </Col>
        </Row>
    </>;
};

export default ColumnSearchBox;