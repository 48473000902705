import React, { useEffect, useState } from 'react';
import { Container, Card, Form, Col, Table, Row } from 'react-bootstrap';
import PageTitle from 'src/components/general/PageTitle';
import AppLoading from 'src/components/common/AppLoading';
import ActionIcon from 'src/components/general/ActionIcon';
import { faFilter, faBroom } from '@fortawesome/free-solid-svg-icons';
import { parseIntOrUndefined, formatterPeso, getClearObject } from 'src/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import { isNotEmptyArray } from 'src/services/validationService';
import { nroCredit, amountNumber, visibleName, dateOfOperation, typesOfAffectation, subpartialDeparture, actions, xFile, total } from 'src/utils/label';
import moment from 'moment';
import { clearAdministrativeDocumentModifPreloadDetailsData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { tryGetAdministrativeDocumentModifPreloadDetails } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getAdministrativeDocumentModifPreloadData, getAdministrativeDocumentModifPreloadIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import AdministrativeDocumentForm from 'src/components/forms/administrativeDocument/administrativeDocumentForm';
import LegalInstrumentForm from 'src/components/forms/legalInstrument/legalInstrumentForm'

const ReportAdministrativeDocumentVoucher = () => {

	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	const { handleSubmit } = hookFormMethods;

    //Temporal for Demo
    const preCargaJson = [
        {
           "id":1,
           "denom":"Personal Permanente",
           "date":"2022-02-03T10:49:42.000000Z",
           "completeLabel":"1234-5678\/2021",
           "relatedLegalInstrument":"128 / 2022",
           "importe":10000,
           "numPartida":"04-03-01-0-00-1-90-1-01-011-01110-0-00",
           "partidaSubParcial":"0155",
           "type":"Modificación",
           "typeAffectation":"6. Preventiva/Definitiva/Obligación de Pago"
        },
        {
           "id":2,
           "denom":"Asignaciones Familiares",
           "date":"2022-03-13T14:33:42.000000Z",
           "completeLabel":"1234-5678\/2021",
           "relatedLegalInstrument":"430 / 2022",
           "importe":20000,
           "numPartida":"02-02-01-0-00-1-90-1-01-011-01140-0-00",
           "partidaSubParcial":"0135",
           "type":"Precarga",
           "typeAffectation":"3. Obligación de Pago"
        },
        {
           "id":4,
           "denom":"Red de Agua,Cloacas y Perforaciones en Varios Municipios",
           "date":"2022-04-25T18:19:42.000000Z",
           "completeLabel":"1234-5678\/2021",
           "relatedLegalInstrument":null,
           "importe":5000,
           "numPartida":"03-02-13-2-03-4-20-2-05-052-05220-0-02",
           "partidaSubParcial":"0125",
           "type":"Modificación",
           "typeAffectation":"5. Preventiva/Definitiva"
        },
        {
           "id":5,
           "denom":"Personal Temporario",
           "date":"2022-06-05T05:31:42.000000Z",
           "completeLabel":"1234-5678\/2021",
           "relatedLegalInstrument":"625 / 2022",
           "importe":5000,
           "numPartida":"04-03-01-0-00-1-90-1-01-011-01120-0-00",
           "partidaSubParcial":"0110",
           "type":"Precarga",
           "typeAffectation":"1. Preventiva"
        }
    ]

	const [showExpedient, setShowExpedient] = useState(true);

    const handleFormTypeToShow = (type) =>{
        type === 'expedient' ? setShowExpedient(true) : setShowExpedient(false);
    };

    const [data, setData] = useState();
    const hasAdministrativeDocumentList = isNotEmptyArray(data);

	// Search administrative document modified data
	const administrativeDocumentModifPreloadDetails = useSelector(state => getAdministrativeDocumentModifPreloadData(state));
	const administrativeDocumentModifPreloadDetailsIsFetching = useSelector(state => getAdministrativeDocumentModifPreloadIsFetching(state));
	/* const hasAdministrativeDocumentList = isNotEmptyArray(administrativeDocumentModifPreloadDetails); */

    //Temporal state for expedient
    const [organismCode, setOrganismCode] = useState();
    const [number, setNumber] = useState();
    const [year, setYear] = useState();

	const onClickSearchAdministrativeDocument = event => {
        setOrganismCode(event?.organismCode);
        setNumber(event?.number);
        setYear(event?.year);
		const administrativeDocumentParams = {
            organismCode: event?.organismCode,
            number: parseIntOrUndefined(event?.number),
            year: parseIntOrUndefined(event?.year),
		};
        const obj = {
            key_prueba1:"",
            key_prueba2:"asd"
        };
        const clearObj = getClearObject({...obj, ...administrativeDocumentParams});
        const objToSend = {
            filter:clearObj
        };
		fetchList(objToSend);
	};

    const fetchList = (params) =>{

        //Temporal for Demo
        setData(preCargaJson);
        /* dispatch(clearAdministrativeDocumentModifPreloadDetailsData()); */
        /* dispatch(tryGetAdministrativeDocumentModifPreloadDetails(params)); */
    };

	// Clear old data
	useEffect(() => {
        //Temporal for Demo
        setData();
		dispatch(clearAdministrativeDocumentModifPreloadDetailsData());
	}, [showExpedient]);

    return (
        <Container fluid>
            <PageTitle text='Reporte de modificaciones de Crédito' />
            <Container fluid>
                <Card className='mb-3'>
                    <Card.Body>
                        <FormContext {...hookFormMethods}>
                            <Form onSubmit={handleSubmit(onClickSearchAdministrativeDocument)}>
                            <Container>
									<Form.Group className='justify-content-md-center text-nowrap text-black-color mt-5' as={Row}>
										<Col sm='10' className='d-flex mx-0 px-0 mr-5 justify-content-md-center'>
											<Form.Label className='mb-0 d-flex align-items-center justify-content-end'>
												Filtrar por:
											</Form.Label>

											<span className='px-3'/>

											<div className='d-flex justify-content-around checkbox-container-width'>
												<Form.Check
													label="Instrumento Legal"
													value="instrument"
													name='group1'
													type='radio'
													onClick={(e)=>handleFormTypeToShow(e.target.value)}
												/>
												<Form.Check
													label={xFile}
													value="expedient"
													name='group1'
													type='radio'
													checked={showExpedient}
													onClick={(e)=>handleFormTypeToShow(e.target.value)}
												/>
											</div>

										</Col>
									</Form.Group>
								</Container>

								{ showExpedient ?
									<AdministrativeDocumentForm
										type = 'reportAdministrativeDocument'
									/> :
									<LegalInstrumentForm />
								}
                                {/* !administrativeDocumentModifPreloadDetailsIsFetching && hasAdministrativeDocumentList && */
                                hasAdministrativeDocumentList && showExpedient &&
                                <Container className='px-5 mb-5'>
                                    <Table bordered hover size='sm'>
                                        <thead>
                                            <tr>
                                                <th className='text-center' width='20%'>{xFile}</th>
                                                <th className='text-center' width='20%'>{total}</th>
                                                <th className='text-center' width='20%'>Total Precarga</th>
                                                <th className='text-center' width='20%'>Total Modificación</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-black-color'>
                                            <tr>
                                                <td className='text-center'>{`${organismCode}  - ${number} / ${year}`}</td>
                                                <td className='text-right'>{formatterPeso.format(40000)}</td>
                                                <td className='text-right'>{formatterPeso.format(25000)}</td>
                                                <td className='text-right'>{formatterPeso.format(15000)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Container>
                                }
                                {
                                    hasAdministrativeDocumentList &&
                                        <Col>
                                            <Table striped bordered hover size='sm'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center align-middle'>Precarga / Modificación</th>
                                                        <th className='text-center align-middle'>{dateOfOperation}</th>
                                                        <th className='text-center align-middle'>{typesOfAffectation}</th>
                                                        <th className='text-center align-middle th-minwidth-nro-credit' width='25%'>{nroCredit}</th>
                                                        <th className='text-center align-middle' width='5%'>{subpartialDeparture}</th>
                                                        <th className='text-center align-middle th-minwidth'>{visibleName}</th>
                                                        <th className='text-center align-middle' width='10%'>Instrumento Legal</th>
                                                        <th className='text-center align-middle' width='10%'>{amountNumber}</th>
                                                        <th className='text-center align-middle th-minwidth'>{actions}</th>
                                                    </tr>
                                                    <tr className='secondary'>
                                                        <th className='text-center'>
                                                            <Form.Control
                                                                as="select"
                                                                name="type"
                                                                className='p-0 select-type-filter'
                                                            >
                                                                <option value={''} className='text-center align-middle text-black-color' selected>Filtrar por...</option>
                                                                {
                                                                    ['Precarga', 'Modificacion'].map((item, idx) => (
                                                                        <option className='text-center align-middle text-black-color' value={item} key={idx}>
                                                                            {item}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </Form.Control>
                                                        </th>
                                                        <th className='text-center'>
                                                            <Form.Control
                                                                className='text-center text-black-color'
                                                                name='completeLabel'
                                                            /*  ref={register} */
                                                                size='sm'
                                                            /*   defaultValue={completeLabel} */
                                                            />
                                                        </th>
                                                        <th className='text-center'>
                                                            <Form.Control
                                                                className='text-center text-black-color'
                                                                name='completeLabel'
                                                            /*  ref={register} */
                                                                size='sm'
                                                            /*   defaultValue={completeLabel} */
                                                            />
                                                        </th>
                                                        <th className='text-center'>
                                                            <Form.Control
                                                                className='text-center text-black-color'
                                                                name='completeLabel'
                                                            /*  ref={register} */
                                                                size='sm'
                                                            /*   defaultValue={completeLabel} */
                                                            />
                                                        </th>
                                                        <th className='text-center'>
                                                            <Form.Control
                                                                className='text-center text-black-color'
                                                                name='completeLabel'
                                                            /*  ref={register} */
                                                                size='sm'
                                                            /*   defaultValue={completeLabel} */
                                                            />
                                                        </th>
                                                        <th className='text-center'>
                                                            <Form.Control
                                                                className='text-center text-black-color'
                                                                name='completeLabel'
                                                            /*  ref={register} */
                                                                size='sm'
                                                            /*   defaultValue={completeLabel} */
                                                            />
                                                        </th>
                                                        <th className='text-center'></th>
                                                        <th className='text-center'></th>
                                                        <th className='text-center align-middle'>
                                                            <div className='d-flex justify-content-around'>
                                                                <ActionIcon
                                                                    size="lg"
                                                                    id="search-button"
                                                                    className="btn-primary search-button text-white-color"
                                                                    toolTipText="Filtrar"
                                                                    icon={faFilter}
                                                                    type='submit'
                                                                />
                                                                <ActionIcon
                                                                    size="lg"
                                                                    id="clean-filter"
                                                                    className="btn-primary clean-filter text-white-color"
                                                                    toolTipText="Limpiar filtros"
                                                                    icon={faBroom}
                                                                    type='reset'
                                                                    /* onClick={()=> cleanFilters()} */
                                                                />
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className='text-black-color'>
                                                    { data?.map((item) =>
                                                        <tr key={item.id}>
                                                            <td className='text-center align-middle'>
                                                                {item?.type}
                                                            </td>
                                                            <td className='text-center align-middle'>
                                                                {moment(item?.date).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td className="text-left align-middle">
                                                                {item?.typeAffectation}
                                                            </td>
                                                            <td className='text-center align-middle'>
                                                                {item?.numPartida}
                                                            </td>
                                                            <td className='text-center align-middle'>
                                                                {item?.partidaSubParcial}
                                                            </td>
                                                            <td className='text-center align-middle'>
                                                                {item?.denom}
                                                            </td>
                                                            <td className='text-center align-middle'>
                                                                {item?.relatedLegalInstrument}
                                                            </td>
                                                            <td className='text-right align-middle'>
                                                                {formatterPeso.format(item?.importe)}
                                                            </td>
                                                            <td className='text-center align-middle'></td>
                                                            <td className='text-center align-middle'></td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                }
                            </Form>
                        </FormContext>
                    </Card.Body>
                </Card>
            </Container>
           <AppLoading isLoading={administrativeDocumentModifPreloadDetailsIsFetching} />
        </Container>
    )
}

export default ReportAdministrativeDocumentVoucher;