import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import ExpedienteControl from './ExpedienteControl';
import { getAdministrativeDocumentYearsOptions, isValidateOrganismCode } from 'src/utils/utils';
import { tryGetAdministrativeDocument, tryGetAdministrativeDocumentByOrderPay } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import ExpedienteListBox from '../../ExpedienteListBox';
import { showSuccess, showWarning } from 'src/redux/globalData/globalDataActionCreator';
import { setNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationActions';
import AppLoading from 'src/components/common/AppLoading';
import { getlistAffectationByOrderTypeData, getlistAffectationByOrderTypeIsFetching, getOrderType } from 'src/redux/orderPay/orderPayReducer';
import { clearOneListAdministrativeDocumentData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { getGlobalDataSelectedPeriod } from '../../../../../redux/globalData/globalDataReducer';
import { tryGetSubPartialBudgetList } from 'src/redux/partialBudget/partialBudgetActionCreator';

const ExpedienteContainer = props => {
    const dispatch = useDispatch();
    const { expedientesAsociados, setExpedientesAsociados, disableAdmExpedientSearch, receipts, showSubpartItemSelector } = props;
    const { getValues, orderPay, admServiceId, admServiceCode } = useFormContext();

    const haberesAffectationList = useSelector(state => getlistAffectationByOrderTypeData(state))?.data;
    const haberesAffectationListIsFetching = useSelector (state => getlistAffectationByOrderTypeIsFetching(state));
    const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));

    const [responseData, setResponseData] = useState({});
    const [invalidRangeCode, setInvalidRangeCode] = useState(false);
    const [expedienteList, setExpedienteList] = useState([]);
    const yearsOptions = getAdministrativeDocumentYearsOptions();
    const [isLoading, setIsLoading] = useState(false);

    const orderType = useSelector( state => getOrderType(state) );
    const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

    const OPHaberes = orderPay?.type == "ORDEN_DE_PAGO_DE_HABERES";

    const onSearchAdministrativeDocument = () => {
        const isValidCode = isValidateOrganismCode(getValues().codigo);
        const code = getValues().codigo;
        const number = getValues().correlativo;
        const year = getValues().anio;

        if( code && number && year && isValidCode ){
            setIsLoading(true);
            const params = {
                codeOrganism: parseInt(code),
                number: parseInt(number),
                year: parseInt(year),
                paymentOrderType: orderType,
                affectation_status_id: 2,
                period_id: periodSelected.id,
                serviceCode: admServiceCode
            };
            dispatch(tryGetAdministrativeDocumentByOrderPay(1, 10, params))
                .then((response) => {
                    setExpedienteList(response?.data);
                    administrativeDocumentLoaded(response);
                    setResponseData(response);
                })
                .finally(() => setIsLoading(false));
            dispatch(setNewAffectationAdministrativeDocumentData(params));
        }
        setInvalidRangeCode(!isValidCode);
    };

    const administrativeDocumentLoaded = responseData => {
        if (!responseData?.data?.length) {
            showWarning(dispatch, `No existe el expediente para el servicio seleccionado.`);
            return;
        }
        const findBudget = responseData?.data[0]?.movements.find(element => (element?.affectationStatus?.name === 'Definitiva' || element?.affectationStatus?.name === 'Obligación de Pago'));
        if (findBudget) {
            showSuccess(dispatch, `El expediente posee afectaciones para órdenes de pago.`);
        }
    }

    const onBlurSearch = (codigo, correlativo, anio ) => {
        const isValidCode = isValidateOrganismCode(codigo);
        if(!codigo || !correlativo || !anio || !isValidCode ) {
            setInvalidRangeCode(true)
        }
        else{
            setInvalidRangeCode(false)
                const params = {
                    year: anio,
                    number: correlativo,
                    organismCode: codigo
                };
                dispatch(tryGetAdministrativeDocument(params))
        }
    };

    useEffect(() => {
        if (orderPay) {
            if(OPHaberes){
                orderPay?.details?.map((item) => {
                    if(item?.presupuestoResponse?.subPartialBudget?.id){
                        let subpartialList;
                        const params = {
                            pageSize: 100,
                            filter: JSON.stringify({
                                partial_budget: item?.presupuestoResponse?.subPartialBudget?.partialBudget?.id
                            })
                        };
                        dispatch(tryGetSubPartialBudgetList(params)).then(response => {
                            subpartialList = response?.data || [];
                            item.presupuestoResponse.subpartialList = subpartialList;
                        });
                        return item;
                    };
                })
            } else {
                setExpedientesAsociados(orderPay.details);
            }
            disableAdmExpedientSearch && onBlurSearch(orderPay.administrativeDocument.number, orderPay.administrativeDocument.codeOrganism, orderPay.administrativeDocument.year);
            !disableAdmExpedientSearch && onSearchAdministrativeDocument();
        }
        return () => {
            dispatch(clearOneListAdministrativeDocumentData());
        }
    }, []);

    useEffect(() => {
        if(!orderPay?.id && !OPHaberes && admServiceId){
            setExpedienteList([]);
        }
    }, [admServiceId]);

return <>
            <ExpedienteControl
                onSearchAdministrativeDocument={onSearchAdministrativeDocument}
                disableAdmExpedientSearch={disableAdmExpedientSearch}
                invalidRangeCode={invalidRangeCode}
                setInvalidRangeCode={setInvalidRangeCode}
                yearsOptions={yearsOptions}
                onBlurSearch={onBlurSearch}
            />

            {<ExpedienteListBox
                expedienteListParams={haberesAffectationList ? haberesAffectationList : expedienteList}
                responseData={responseData}
                expedientesAsociados={expedientesAsociados}
                setExpedientesAsociados={setExpedientesAsociados}
                disableAdmExpedientSearch={disableAdmExpedientSearch}
                receipts={receipts}
                admServiceId={admServiceId}
                showSubpartItemSelector={showSubpartItemSelector}
            />}
            <AppLoading isLoading={isLoading || haberesAffectationListIsFetching} />
       </>
};

export default ExpedienteContainer;