import React from 'react';
import { Row, Col }  from 'react-bootstrap';

const CCPMConnectivityStatusAlert = props => {
	
	const { message, alertType } = props;

	return (
		<Row className='justify-content-center'>
             <Col sm={8} className='text-right'>
                <>
                    <div className={`alert alert-${alertType} text-center py-1 my-2`} role='alert'>
                         {message}
                    </div>
                </>
            </Col>
        </Row>
	);
};

export default CCPMConnectivityStatusAlert;