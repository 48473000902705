import React, { useRef, useState } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight, faAsterisk } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';
import { tryGetAdministrativeDocument } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { setNewAffectationAdministrativeDocumentData, clearExtractAffectation } from 'src/redux/affectation/affectationActions';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { CREDIT_EXECUTION, CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO } from 'src/utils/constants';
import { getYearsOptionsByParam, parseIntOrUndefined } from 'src/utils/utils';
import { isNotEmptyArray, isNumeric } from 'src/services/validationService';
import FormFieldError from 'src/components/general/FormFieldError';
import swal from 'sweetalert';
import { clearLatestAffectationTransactionData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import {impactsBudgetItems} from 'src/utils/label';

import { tryGetAdministrativeDocumentByPeriodId } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';

const CreditExecutionNewAffectationStepOnePage = props => {

	const dispatch = useDispatch();

	// Administrative document data from endpoint
	const administrativeDocumentDataFromEndpointIsFetching = useSelector(state => getAdministrativeDocumentIsFetching(state));

	// Administrative document data
	const selectedAdministrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

	// Period
	const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	// Year field
	const minimumYear = 2000;
	const yearsOptions = getYearsOptionsByParam(minimumYear, selectedPeriod?.year);
	const yearInputRef = useRef();

	// Number field
	const numberInputRef = useRef();
	const maxNumber = 2147483647;

	// Organism code field
	const organismCodeInputRef = useRef();

	// Title field
	const titleInputRef = useRef();

	const [isNewAdministrativeDocument, setIsNewAdministrativeDocument] = useState(false);
	const onChangeTitle = event => {
		const value = event?.target?.value;
		if (value && typeof value == 'string' && value.length > 0) {
			setEnableNextStepButton(true);
		}
		else {
			setEnableNextStepButton(false);
		}
	};

	// Clear form
	const clearForm = () => {
		yearInputRef.current.value = '';
		numberInputRef.current.value = '';
		organismCodeInputRef.current.value = '';
		titleInputRef.current.value = '';
	};

	// Administrative document loaded from endpoint
	const administrativeDocumentLoaded = responseData => {
		if (responseData) {
			if (responseData?.number) {
				clearForm();
				setIsNewAdministrativeDocument(false);
				dispatch(setNewAffectationAdministrativeDocumentData(responseData));
				setEnableNextStepButton(true);
				dispatch(clearLatestAffectationTransactionData());
			}
			else {
				setIsNewAdministrativeDocument(true);
			}
		}
	};

	const [dataLengthCharacter, setDataLengthCharacter] = useState(false);
	const [dataYearSelected, setDataYearSelected] = useState(false);
	const [dataNumberSelected, setDataNumberSelected] = useState(false);



	// onBlurInputs
	const onBlurDocumentDataInputs = () => {
		const yearSelected = yearInputRef.current.value;
		const numberSelected = numberInputRef.current.value;
		const organismCodeSelected = organismCodeInputRef.current.value;

		let lengthCharacter = (organismCodeSelected.length > 4 || organismCodeSelected.length < 4) || (parseIntOrUndefined(organismCodeSelected) < 1000 && parseIntOrUndefined(organismCodeSelected) != 0);
		setDataLengthCharacter(lengthCharacter);

		let lengthYearSelected = yearSelected.length >= 4;
		setDataYearSelected(lengthYearSelected)

		let lengthNumberSelected = numberSelected.length >= 1;
		setDataNumberSelected(lengthNumberSelected)



		if (organismCodeSelected && numberSelected && isNumeric(yearSelected) && !lengthCharacter) {
			const params = {
				year: parseIntOrUndefined(yearSelected),
				number: parseIntOrUndefined(numberSelected),
				organismCode: organismCodeSelected,
				periodId: selectedPeriod?.id
			};
			dispatch(tryGetAdministrativeDocumentByPeriodId(params))
				.then(administrativeDocumentLoaded);
		}
	};

	//
	const onClickRemoveSelectedLegalInstrument = () => {
		setEnableNextStepButton(false);
		dispatch(setNewAffectationAdministrativeDocumentData(undefined));
		dispatch(clearExtractAffectation());
	};

	// Cancel button click
	const onClickCancelButton = () => { 
		dispatch(clearExtractAffectation());
		dispatch(push(CREDIT_EXECUTION)) 
	};

	// Next step button click
	const onClickNextStepButton = () => {
		if (isNewAdministrativeDocument) {
			dispatch(setNewAffectationAdministrativeDocumentData({
				year: yearInputRef.current.value,
				number: numberInputRef.current.value,
				organizationCode: organismCodeInputRef.current.value,
				title: titleInputRef.current.value
			}));
		}
		dispatch(push(CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO));
	};
	const [enableNextStepButton, setEnableNextStepButton] = useState(!!selectedAdministrativeDocument);

	const disableButton = (dataYearSelected && dataNumberSelected && !dataLengthCharacter && enableNextStepButton || selectedAdministrativeDocument);
	return <Container fluid >
		<Card className='mb-5'>

			<PageTitle text={impactsBudgetItems} />

			<Container fluid >

				<StepIndicator steps={config.appSettings.steps.creditExecution} current={0} className='mt-2 mb-3' />

				<Card className='mb-3'>
					<Card.Header className='h6'>
						Nueva afectación
					</Card.Header>
					<Card.Body>
						<h6 className='text-black-color mb-3'>Datos del expediente asociado</h6>
						<span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
							<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									Obligatorio
								</span>
						{
							selectedAdministrativeDocument
								?
								<>
									<div className='d-flex justify-content-center'>
										<Card className='text-black-color shadow bg-white rounded mt-3 mb-3 p-3' style={{ width: '20rem' }}>
											<FontAwesomeIcon icon={faTimes} className='text-black-color cursor-pointer close-button' onClick={onClickRemoveSelectedLegalInstrument} />
											<Row className='my-2'>
												<Col className='text-right' xs='5'>
													Expediente:
												</Col>
												<Col>
													{`${selectedAdministrativeDocument?.organizationCode}-${selectedAdministrativeDocument?.number}/${selectedAdministrativeDocument?.year}`}
												</Col>
											</Row>
											<Row className='my-2'>
												<Col className='text-right' xs='5'>
													Asunto:
												</Col>
												<Col>
													{selectedAdministrativeDocument?.title}
												</Col>
											</Row>
										</Card>
									</div>
									<hr />
								</>
								:
								undefined
						}

						<Form onSubmit={onClickNextStepButton} className='text-black-color mt-5 mb-5'>
							<Form.Group as={Row}>
								<Col sm='3' className='mb-0 d-flex align-items-center justify-content-end'>
									<Form.Label htmlFor='expediente-data' className='mb-0 d-flex align-items-center justify-content-end'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										Expediente:
									</Form.Label>
								</Col>
								<Col sm='7' className='mx-0 px-0  d-flex align-items-center justify-content-end'>
									<Form.Control
										type='number'
										id='expediente-data'
										className='form-control w-100 d-flex align-items-center justify-content-end'
										placeholder='Código'
										disabled={!!selectedAdministrativeDocument}
										ref={organismCodeInputRef}
										onBlur={onBlurDocumentDataInputs}
										tabindex='1'
										title='Código de Organismo'
										min={'0'}
										required
									/>
									<span className='px-2'>
										-
										</span>
									<Form.Control
										type='number'
										placeholder='Correlativo'
										className='form-control w-100 d-flex align-items-center justify-content-end'
										disabled={!!selectedAdministrativeDocument}
										ref={numberInputRef}
										onBlur={onBlurDocumentDataInputs}
										tabindex='2'
										min='1'
										max={maxNumber}
										required 
									/>
									<span className='px-3'>
										/
										</span>
									<Form.Control
										as='select'
										disabled={!!selectedAdministrativeDocument}
										onBlur={onBlurDocumentDataInputs}
										ref={yearInputRef}
										tabindex='3'
										required >
										<option value={undefined} selected>Año</option>
										{
											yearsOptions?.map(item => (<option value={item}>
												{item}
											</option>))
										}
									</Form.Control>
								</Col>
							</Form.Group>
							<Form.Group as={Row}>
								<Col sm='3' className='d-flex align-items-center justify-content-end'>
									<Form.Label htmlFor='asunto' className='mb-0'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										Asunto:
									</Form.Label>
								</Col>
								<Col sm='7' className='px-0 d-flex align-items-center justify-content-end'>
									<Form.Control
										className='form-control w-100 d-flex align-items-center justify-content-end'
										placeholder='Asunto'
										id='asunto'
										ref={titleInputRef}
										onChange={onChangeTitle}
										disabled={!!selectedAdministrativeDocument}
										tabindex='4'
										maxLength={'250'}
										required
									/>
								</Col>
							</Form.Group>
							{
								dataLengthCharacter
									?
									<>
										<div class="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
											<strong>¡Importante!</strong> El código de organismo del expediente debe estar entre los rangos 1000 y 9999.
									</div>
									</>
									:
									null
							}

							<Form.Group className='mt-4 d-flex justify-content-around text-white-color'>
								<Button className='text-white-color' variant='danger' size='lg' onClick={onClickCancelButton} tabindex='6'>
									Cancelar
								</Button>

								<Spinner
									animation='border'
									className={administrativeDocumentDataFromEndpointIsFetching ? '' : 'hidden'} />

								<Button className='text-white-color' type='submit' variant='success' size='lg' disabled={!disableButton} tabindex='5'>
									Siguiente
									<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
								</Button>
							</Form.Group>

						</Form>

					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default CreditExecutionNewAffectationStepOnePage;