// Remove Preload
export const DELETE_PRELOAD_REQUEST_SUCCESS = 'DELETE_PRELOAD_REQUEST_SUCCESS';
export const DELETE_PRELOAD_REQUEST = 'DELETE_PRELOAD_REQUEST';
export const DELETE_PRELOAD_REQUEST_FAILURE = 'DELETE_PRELOAD_REQUEST_FAILURE';

// Get Preload request
export const GET_PRELOAD_DATA_REQUEST = 'GET_PRELOAD_DATA_REQUEST';
export const GET_PRELOAD_DATA_REQUEST_SUCCESS = 'GET_PRELOAD_DATA_REQUEST_SUCCESS';
export const GET_PRELOAD_DATA_REQUEST_FAILURE = 'GET_PRELOAD_DATA_REQUEST_FAILURE';
export const CLEAR_PRELOAD_DATA = 'CLEAR_PRELOAD_DATA';

// Edit Preload
export const EDIT_PRELOAD_REQUEST = 'EDIT_PRELOAD_REQUEST';
export const EDIT_PRELOAD_REQUEST_SUCCESS = 'EDIT_PRELOAD_REQUEST_SUCCESS';
export const EDIT_PRELOAD_REQUEST_FAILURE = 'EDIT_PRELOAD_REQUEST_FAILURE';
// Attach Legal Instrument request (edit)
export const ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST = 'ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST';
export const ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST_SUCCESS = 'ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST_SUCCESS';
export const ATTACH_LEGAL_INSTRUMENT_REQUESTS_FAILURE = 'ATTACH_LEGAL_INSTRUMENT_REQUESTS_FAILURE';
export const CLEAR_ATTACH_LEGAL_INSTRUMENT_DATA = 'CLEAR_ATTACH_LEGAL_INSTRUMENT_DATA';

// Get Preload XLS File
export const GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST = 'GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST';
export const GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_SUCCESS = 'GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_SUCCESS';
export const GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_FAILURE = 'GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_FAILURE';
export const CLEAR_PRELOAD_DOWNLOAD_XLS_FILE_DATA = 'CLEAR_PRELOAD_DOWNLOAD_XLS_FILE_DATA';