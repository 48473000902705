import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import MultiSelect from "react-multi-select-component";
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';

import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportDelayedFilesCreditExecution } from 'src/redux/reports/reportsActionCreactor';
import { getReportDelayedFilesCreditExecutionIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportDelayedFilesCreditExecution } from 'src/redux/reports/reportsActions';
import { getListAllServiceAdministrativeByUserData, getListAllServiceAdministrativeByUserIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getProfileName, getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import { clearListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActions';
import MaxDate from 'src/components/common/MaxDate';
import { shortlistedService } from 'src/utils/utils';

const ReportDelayedFilesCreditExecutionPage = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	//User Id
	const email = useSelector( state => getEmail(state) );
	const listUserData = useSelector(state => getUserListData (state));
	const userId = listUserData?.records?.find(item => item.email == email);

	const reportDates = useSelector( state => getReportDatesData(state) );
	//Profile User
	const profileName = useSelector( state => getProfileName(state) );

	
	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	//Services Administrative
	const administrativeService = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

	const [dateTo, setDateTo] = useState(reportDates?.dateTo);

	const [dateHasErrors, setDateHasErrors] = useState(false);

	useEffect(() => {
		dispatch(tryGetUserList());
		dispatch(clearReportDelayedFilesCreditExecution());
		 dispatch(clearListAllServiceAdministrativeByUser());
		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
	}, [globalSelectedPeriod]);

	useEffect(() => {
		setSelected([]);
	}, []);

	const getReport = outputFormat => {
		
		if (globalSelectedPeriod) {
			const params = {
				outputFormat,
				exerciseId: globalSelectedPeriod?.id,
				serviceIds: selected?.map(administrativeService => administrativeService?.value),
				dateTo
			};
			dispatch(tryGetReportDelayedFilesCreditExecution(params));
		}

	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	//// Multiselect
	// View items selector
	const [options, setOptions] = useState([]);

	useEffect(() => {
		let optionsArray = [];
		let i = 0;
		for (i in administrativeService){
			optionsArray.push({label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value:  administrativeService[i]?.id} )
		}
		setOptions(optionsArray);
	}, [administrativeService]);

	useEffect(() => {
		const uniqueService = shortlistedService(options);
		if(uniqueService){
			setSelected(options);
		}
	}, [options]);

	// Add options
	const [selected, setSelected] = useState(options);
	
	// Idiom Spanish
	const idiom = 	{
					"selectSomeItems": "Seleccionar una o más opciones...",
					"allItemsAreSelected": "Todos los elementos están seleccionados.",
					"selectAll": "Seleccionar todo",
					"search": "Buscar",
					"clearSearch": "Limpiar búsqueda."
					}

	// Load data 	
	const customValueRenderer = (selected) => {
		return selected.length
			? selected.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
		};
	const hasValueCustomValueRenderer = (selected.length === 0);		
	//// END Multiselect	
	
	// Reports redux
	const reportIsFetching = useSelector(state => getReportDelayedFilesCreditExecutionIsFetching(state));
	const allowGetReport = (hasPeriodList && !reportIsFetching && dateTo && !hasValueCustomValueRenderer && !dateHasErrors);

	return <Container fluid>
		<Card className='mb-5'>
			<PageTitle text='Reporte de expedientes demorados' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>

										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<MultiSelect
											className='text-black-color'
											options={options}
											value={selected}
											onChange={setSelected}
											labelledBy={"Select"}
											overrideStrings={idiom}
											valueRenderer={customValueRenderer}
											hasSelectAll={true}
											isLoading={administrativeServiceIsFetching}
											ClearSelectedIcon={"🧹Limpiar"}
										/>

											{/* {
												hasValueCustomValueRenderer
													?
													<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
													{`${'Debe seleccionar al menos un ítem.'}`}
													</div>
													
													:
													null
											} */}
									
									</Form.Group>

									<FormContext {...hookFormMethods}>
										<MaxDate {...{dateTo, setDateTo, setDateHasErrors}} />
									</FormContext>

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Ver PDF
										</Button>
										{
											reportIsFetching
											&&
											<Spinner animation='border' />
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>

						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ReportDelayedFilesCreditExecutionPage;