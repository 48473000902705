import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Row, Col } from 'react-bootstrap';

import PageTitle from 'src/components/general/PageTitle';
import BudgetItemControlFilter from 'src/components/forms/budgetItemControl/BudgetItemControlFilter';
import BudgetItemControlTree from 'src/components/forms/budgetItemControl/BudgetItemControlTree';
import { tryGetBudgetItemControl } from 'src/redux/budgetItemControl/budgetItemControlActionCreator';
import { getBudgetItemControlReducerData, getBudgetItemControlReducerIsFetching } from 'src/redux/budgetItemControl/budgetItemControlReducer';
import { clearBudgetItemControlData } from 'src/redux/budgetItemControl/budgetItemControlActions';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getCreditAttributeName, formatterPeso, numberNegativeRed } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';
import AppLoading from 'src/components/common/AppLoading';

const BudgetItemControlPage = props => {

	const dispatch = useDispatch();
	const [inputs, setInputs] = useState('');

	const emptyItemMsg = [{
		isExpanded: true,
		id: -1,
		name: <div>
			<Container fluid className='none'>
				<Row>
					No hay items para el valor especificado en el filtro de búsqueda.
				</Row>
			</Container>
		</div>
	}];

	// Format data from API to "tree" form for "super-tree" library.
	const transformDataToTree = dataToTransform => {
		const proccessNode = item => {

			if (item.id != -1) {
				item.id = item.number;
				item.name = <div title={`${getCreditAttributeName(item) || ''} ${item.number || ''} - ${item?.name || item?.code || ''}`}>
					<span id="creditHeader">{`${getCreditAttributeName(item) || ''} ${item.number || ''} - ${item?.name || item?.code || ''}`}</span>
					<Container fluid className="creditDetail">
						<Row>
							<Col sm={8} className='font-weight-bold text-right px-0' > Crédito original:</Col>
							<Col sm={4} className={`${numberNegativeRed(item?.totals?.originalCredit)} + font-weight-bold text-right px-2`}> {formatterPeso.format(item?.totals?.originalCredit)}</Col>
						</Row>
						<Row>
							<Col sm={8} className='font-weight-bold text-right px-0' > Modificaciones:</Col>
							<Col sm={4} className={`${numberNegativeRed(item?.totals?.modificationCredit)} + font-weight-bold text-right px-2`}> {formatterPeso.format(item?.totals?.modificationCredit)}</Col>
						</Row>
						<Row>
							<Col sm={8} className='font-weight-bold text-right px-0' > Crédito actual:</Col>
							<Col sm={4} className={`${numberNegativeRed(item?.totals?.currentCredit)} + font-weight-bold text-right px-2`}> {formatterPeso.format(item?.totals?.currentCredit)}</Col>
						</Row>
					</Container>
				</div>;

			}

			// Jurisdictions
			if (isNotEmptyArray(item.jurisdictions)) {
				item.children = item.jurisdictions;
				item.isExpanded = !!inputs?.jurisdiction_number;
			}
			else if (Array.isArray(item.jurisdictions) && item.jurisdictions.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.jurisdiction_number;
			}

			// Organizations
			if (isNotEmptyArray(item.organizations)) {
				item.children = item.organizations;
				item.isExpanded = !!inputs?.organization_number;
			}
			else if (Array.isArray(item.organizations) && item.organizations.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.organization_number;
			}

			// Characters
			if (isNotEmptyArray(item.characters)) {
				item.children = item.characters;
				item.isExpanded = !!inputs?.character_number;
			}
			else if (Array.isArray(item.characters) && item.characters.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.character_number;
			}

			// Accounts
			if (isNotEmptyArray(item.accounts)) {
				item.children = item.accounts;
				item.isExpanded = !!inputs?.account_number;
			}
			else if (Array.isArray(item.accounts) && item.accounts.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.account_number;
			}

			// Purposes
			if (isNotEmptyArray(item.purposes)) {
				item.children = item.purposes;
				item.isExpanded = !!inputs?.purpose_number;
			}
			else if (Array.isArray(item.purposes) && item.purposes.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.purpose_number;
			}

			// Functionalities
			if (isNotEmptyArray(item.functionalities)) {
				item.children = item.functionalities;
				item.isExpanded = !!inputs?.functionality_number;
			}
			else if (Array.isArray(item.functionalities) && item.functionalities.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.functionality_number;
			}

			// Sections
			if (isNotEmptyArray(item.sections)) {
				item.children = item.sections;
				item.isExpanded = !!inputs?.section_number;
			}
			else if (Array.isArray(item.sections) && item.sections.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.section_number;
			}

			// Sectors
			if (isNotEmptyArray(item.sectors)) {
				item.children = item.sectors;
				item.isExpanded = !!inputs?.sector_number;
			}
			else if (Array.isArray(item.sectors) && item.sectors.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.sector_number;
			}

			// Principal budget
			if (isNotEmptyArray(item.principalBudgets)) {
				item.children = item.principalBudgets;
				item.isExpanded = !!inputs?.principal_budget_number;
			}
			else if (Array.isArray(item.principalBudgets) && item.principalBudgets.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.principal_budget_number;
			}

			// Partial budget
			if (isNotEmptyArray(item.partialBudgets)) {
				item.children = item.partialBudgets;
				item.isExpanded = !!inputs?.partial_budget_number;
			}
			else if (Array.isArray(item.partialBudgets) && item.partialBudgets.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.partial_budget_number;
			}

			// Codes
			if (isNotEmptyArray(item.codes)) {
				item.children = item.codes;
				item.isExpanded = !!inputs?.code_number;
			}
			else if (Array.isArray(item.codes) && item.codes.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.code_number;
			}

			// Subcodes
			if (isNotEmptyArray(item.subCodes)) {
				item.children = item.subCodes;
				item.isExpanded = !!inputs?.sub_code_number;
			}
			else if (Array.isArray(item.subCodes) && item.subCodes.length == 0) {
				item.children = emptyItemMsg;
				item.isExpanded = !!inputs?.sub_code_number;
			}

			delete item.jurisdictions;
			delete item.organizations;
			delete item.characters;
			delete item.accounts;
			delete item.purposes;
			delete item.functionalities;
			delete item.sections;
			delete item.sectors;
			delete item.principalBudgets;
			delete item.partialBudgets;
			delete item.codes;
			delete item.subCodes;

			item.children?.map(proccessNode);
			// item.isExpanded = !!item.jurisdictions;
			return item;
		};

		if (dataToTransform) {
			dataToTransform = dataToTransform?.jurisdictions ? [dataToTransform] : undefined;
		}
		dataToTransform = dataToTransform?.map(proccessNode);

		return dataToTransform;
	};

	// Selected period
	const selectedPeriodId = useSelector(state => getGlobalDataSelectedPeriod(state))?.id;

	// Filter to send
	const defaultFilter = { period_id: selectedPeriodId }
	const [filterObject, setFilterObject] = useState(defaultFilter);

	// Budget item control data
	const budgetItemControlData = useSelector(state => getBudgetItemControlReducerData(state));
	const budgetItemControlDataTransformed = transformDataToTree(budgetItemControlData);
	const budgetItemControlDataIsFetching = useSelector(state => getBudgetItemControlReducerIsFetching(state));

	const onClickSearch = filterData => {
		if(!budgetItemControlDataIsFetching){
			setInputs(filterData)
			const newFilterObject = { ...defaultFilter, ...filterData };
			setFilterObject(newFilterObject);
			dispatch(tryGetBudgetItemControl({ filter: newFilterObject }))
		}
	};
	const onClickClean = () => {
		setInputs('')
	};

	useEffect(() => {
		dispatch(clearBudgetItemControlData());
	}, []);

	return <Container fluid className='mb-5'>
		<Card>

			<PageTitle text='Control de partida' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						<BudgetItemControlFilter
							onClickSearch={onClickSearch}
							onClickClean={onClickClean}
							/>
						<BudgetItemControlTree
							data={budgetItemControlDataTransformed}
							isFetching={budgetItemControlDataIsFetching} />
					</Card.Body>
				</Card>
			</Container>
		</Card>
		<AppLoading isLoading={budgetItemControlDataIsFetching} />
	</Container>
};

export default BudgetItemControlPage;