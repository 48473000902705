import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { notModifications, servicesLabel, periods } from 'src/utils/label';

import PageTitle from 'src/components/general/PageTitle';
import { getExerciseDetailsData, getServicesByExerciseIdData, getServicesByExerciseIdIsFetching } from 'src/redux/exercise/exerciseReducer';
import { tryGetServicesByExerciseId } from 'src/redux/exercise/exerciseActionCreator';
import { trySearchLegalInstrumentExercise, tryEditLegalInstrumentExercise } from 'src/redux/exercise/exerciseActionCreator';
import { clearServicesByExerciseIdData } from 'src/redux/exercise/exerciseActions';
import { getEditLegalInstrumentExerciseData, getEditLegalInstrumentExerciseIsFetching } from 'src/redux/exercise/exerciseReducer';
import { dateNeutralFormatedToShowARG } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';
import { EXERCISES_LIST } from 'src/utils/constants';
import ActionIcon from 'src/components/general/ActionIcon';

const ExerciseDetailsPage = props => {

	const dispatch = useDispatch();

	// Exercise details data
	const exerciseDetailsData = useSelector(state => getExerciseDetailsData(state));
	const dataEditExercise = useSelector(state => getEditLegalInstrumentExerciseData(state));
	const dataEditExerciseIsFetching = useSelector(state => getEditLegalInstrumentExerciseIsFetching(state));
	const hasEditExercise = isNotEmptyArray(dataEditExercise);


	// Exercise
	const exercisePeriods = exerciseDetailsData?.periods;
	const hasExercisePeriods = isNotEmptyArray(exercisePeriods);

	// Services by exercise ID
	const services = useSelector(state => getServicesByExerciseIdData(state))?.sort( (a,b) => a.administrativeService?.code>b.administrativeService?.code ? 1 : -1 );
	const servicesIsFetching = useSelector(state => getServicesByExerciseIdIsFetching(state));
	const hasServices = isNotEmptyArray(services);

	// Back button
	const onClickBackButton = () => {
		dispatch(push(EXERCISES_LIST));
	};

	useEffect(() => {
		dispatch(clearServicesByExerciseIdData());

		if (exerciseDetailsData?.id) {
			dispatch(tryGetServicesByExerciseId(exerciseDetailsData?.id));
			dispatch(tryEditLegalInstrumentExercise(exerciseDetailsData?.id));


		}
	}, []);

	const onClickDownloadLegalInstrumentFile = (id) => {
		dispatch(trySearchLegalInstrumentExercise(id));
	}

	return <Container className='mb-5'>
		<Card>
			<PageTitle text='Ejercicios' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Detalle ejercicio
					</Card.Header>
					<Card.Body>
						<Table className='text-black-color' striped borderless hover>
							<tbody>
								<tr>
									<td className='text-center font-weight-bold'>
										ID:
									</td>
									<td className='text-center'>
										{exerciseDetailsData?.id}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Año:
									</td>
									<td className='text-center'>
										{exerciseDetailsData?.year}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Fecha de inicio:
									</td>
									<td className='text-center'>
										{dateNeutralFormatedToShowARG(exerciseDetailsData?.startDate)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Fecha de fin:
									</td>
									<td className='text-center'>
										{dateNeutralFormatedToShowARG(exerciseDetailsData?.endDate)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Fecha complementaria:
									</td>
									<td className='text-center'>
										{dateNeutralFormatedToShowARG(exerciseDetailsData?.complementaryDate)}
									</td>
								</tr>
							</tbody>
						</Table>

						<hr />

						<Card className='mb-3'>
							<Card.Header className='h6'>
								{periods}:
							</Card.Header>
							<Table className='mb-0' striped bordered hover size='sm'>
								<thead>
									<tr>
										<th className='text-center font-weight-bold'>Nombre</th>
										<th className='text-center font-weight-bold'>Fecha de inicio</th>
										<th className='text-center font-weight-bold'>Fecha de fin</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										hasExercisePeriods
											?
											exercisePeriods?.map(period => (
												<tr>
													<td className='text-center py-1'>
														{period?.name || '-'}
													</td>
													<td className='text-center py-1'>
														{dateNeutralFormatedToShowARG(period?.startDate)}
													</td>
													<td className='text-center py-1'>
														{dateNeutralFormatedToShowARG(period?.endDate)}
													</td>
												</tr>
											))
											:
											<tr>
												<td className='text-center p-1' colSpan='2'>
													<Alert variant='info' className='mb-0'>
														<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
														No hay periodos
													</Alert>
												</td>
											</tr>

									}
								</tbody>
							</Table>
						</Card>

						<hr />

						<Card className='mb-3'>
							<Card.Header className='h6'>
								{servicesLabel}:
							</Card.Header>
							<Table className='mb-0' striped bordered hover size='sm'>
								<thead>
									<tr>
										<th className='text-center font-weight-bold'  style={{ "width": "10%" }}>Código</th>
										<th className='text-center font-weight-bold'  style={{ "width": "90%" }}>Descripción</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										hasServices ?
											services?.map(service => 
												<tr>
													<td className='text-center'>{service?.administrativeService?.code}</td>
													<td className='text-center maxTruncate'>{service?.administrativeService?.name}</td>
												</tr>
											)
										:
											<tr>
												<td className='text-center p-1' colSpan='2'>
													{
														servicesIsFetching
															?
															<Spinner animation='border' variant='success' className='my-1' />
															:
															<Alert variant='info' className='mb-0'>
																<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																No hay servicios
															</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>
						</Card>
						
						<hr />

						<Card className='mb-3'>
							<Card.Header className='h6'>
								Modificaciones del Ejercicio:
							</Card.Header>
							<Table className='mb-0' striped bordered hover size='sm'>
								<thead>
									<tr>
										<th className='text-center'>Descripción</th>
										<th className='text-center'>Número</th>
										<th className='text-center'>Tipo</th>
										<th className='text-center'>Fecha</th>
										<th className='text-center'>Archivo</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										hasEditExercise
											?
											<>
												{
													dataEditExercise?.map(item => {
														return (<tr>
															<td className='text-center'>{item?.description}</td>
															<td className='text-center'>{item?.number}</td>
															<td className='text-center'>{item?.legalInstrumentType?.name}</td>
															<td className='text-center'>{dateNeutralFormatedToShowARG(item?.date)}</td>
															<td className='text-center'>
																{
																	item?.fileName != null
																		?
																		<ActionIcon size='lg' id='download' toolTipText='Descargar instrumento legal' icon={faDownload} onClick={() => onClickDownloadLegalInstrumentFile(item?.id)} />
																		:
																		null
																}
															</td>
														</tr>);
													})

												}
											</>
											:
											<tr>
												<td colSpan='6' className='text-center'>
													{
														dataEditExerciseIsFetching
															?
															<Spinner animation='border' size='sm' />
															:
															<Alert variant='info' className='mb-0'>
																<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																{notModifications}
															</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>
						</Card>
						
						<div className='text-center'>
							<Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
						</div>

					</Card.Body>
				</Card>

			</Container>
		</Card>
	</Container>;
};
export default ExerciseDetailsPage;