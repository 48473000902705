import * as ACTIONS from './auditActions';
import * as SERVICES from 'src/services/auditServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showError } from 'src/redux/globalData/globalDataActionCreator';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

export const tryGetAuditList = (params) => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getAuditListRequest() );

		if(accessToken){
			
			const filter = params?.filter || {};
			const page = params?.page || 1;
			const pageSize = params?.pageSize || 10;

			let filterToSend = {
				periodId: getGlobalDataSelectedPeriod( state )?.id,
				...filter
			};

			let paramsToSend = {
				filter: filterToSend,
				page,
				pageSize
			};

			const response = await SERVICES.listAudits( accessToken, paramsToSend);

			if( response?.status === 200 ) {
				dispatch( ACTIONS.getAuditListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getAuditListRequestFailure(response?.error) );
				showError( dispatch, `Error al cargar las auditorias. Detalle: ${response.error}` );
			}
		}

	};
};

// list audits users 
export const tryGetAuditsUsersList = ( params ) => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getAuditsUsersListRequest() );

		if(accessToken){
			
			const response = await SERVICES.listAuditsUsers( accessToken, params );

			if( response?.status === 200 ) {
				dispatch( ACTIONS.getAuditsUsersListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getAuditsUsersListRequestFailure(response?.error) );
				showError( dispatch, `Error al cargar las auditorias de usuarios. Detalle: ${response.error}` );
			}
		}

	};
};