import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Alert } 
from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faChevronDown, faSearch, faBackward } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import DropdownList from 'react-widgets/lib/DropdownList'
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { getReportpassiveWasteIsFetching, getCCPMConnectivityStatusIsFetching } from 'src/redux/reports/reportsReducer';
import { reportImport, reportRevert, tryGetCCPMConnectivityStatus } from 'src/redux/reports/reportsActionCreactor';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import AppLoading from 'src/components/common/AppLoading';
import { config } from 'src/env.js';

import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';

import { verifyConnectivity } from 'src/utils/label';
import CCPMConnectivityStatusAlert from 'src/components/common/CCPMConnectivityStatusAlert';

const ReportImport = () => {
    const environment = `${config.environment}`;
    const isProd = (environment === 'prod');
    const [status, setStatus] = useState({
        message: null,
        success: false
    });
    const dispatch = useDispatch();
    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
    const [ selectedAdministrativeService, setSelectedAdministrativeService ] = useState();

    const administrativeServiceIsFetching = useSelector( state => getListAllServiceAdministrativeByUserIsFetching(state) );
    const reportIsFetching = useSelector(state => getReportpassiveWasteIsFetching(state));
    const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name,
            code: item?.code
		}) );

    const email = useSelector(state => getEmail(state));
    const listUserData = useSelector(state => getUserListData(state));
    const userId = listUserData?.records?.find(item => item.email == email);

    const [ CCPMConnectivityStatus, setCCPMConnectivityStatus ] = useState({});
    const connectivityStatusIsFetching = useSelector(state => getCCPMConnectivityStatusIsFetching(state));

    useEffect(() => {
		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
		
	}, []);
    const runImport = () => {
        const {code} = selectedAdministrativeService;
        let promise = dispatch(reportImport({serviceCode:code}));
        promise.then((response)=>{
            setStatus(response)
        })  
    }
    const runRevert = () => {
        const {code} = selectedAdministrativeService;
        dispatch(reportRevert({serviceCode:code}));
    }

    const verifyCCPMConnectivity = () => {
        dispatch(tryGetCCPMConnectivityStatus()).then(
            response => {
                setCCPMConnectivityStatus(response);
            }
        );
    }

    return (
        <Container fluid>
            <PageTitle text='Importación / Eliminación de residuos pasivos' />
            <Container fluid>
                <Card className='mb-3'>
                    <Card.Body>
                        <Form>
                            {isProd && (
                                <Row className='justify-content-center'>
                                    <Col sm={8}>
                                    <Alert show variant="danger">
                                        <Alert.Heading>Precaución: Está en un ambiente productivo!</Alert.Heading>
                                    </Alert>
                                    </Col>
                                </Row>
                            )}

                            <Row className='justify-content-center'>
                                <Col sm={8} className='text-right'>
                                        <Button
                                             size='md'
                                             onClick={verifyCCPMConnectivity}
                                             disabled={connectivityStatusIsFetching}
                                        >
                                           {verifyConnectivity}
                                        </Button>
                                </Col>
                            </Row>
                            
                            {
                                CCPMConnectivityStatus?.success == true
                                    ?
                                    <CCPMConnectivityStatusAlert
                                        message = {CCPMConnectivityStatus?.message}
                                        alertType = {'success'}
                                    />
                                    : 
                                    CCPMConnectivityStatus?.success == false
                                    &&
                                    <CCPMConnectivityStatusAlert
                                        message = {CCPMConnectivityStatus?.message}
                                        alertType = {'danger'}
                                    />
                            }
                            
                            <Row className='justify-content-center'>
                                <Col sm={8}>
                                    <Form.Group>
                                        <Form.Label className='text-black-color'>
                                            Servicio
                                        </Form.Label>
                                        <DropdownList
                                            disabled={reportIsFetching}
                                            placeholder='Seleccione un servicio...'
                                            busy={administrativeServiceIsFetching}
                                            data={administrativeServiceData}
                                            textField='displayName'
                                            filter='contains'
                                            className='text-black-color'
                                            onChange={ (e) => {
                                                    setSelectedAdministrativeService(e);
                                                    setStatus({message: null, success: false})
                                                }
                                            }
                                            messages={ messagesDropDown }
                                            selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
                                            searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
                                        </DropdownList>
                                    </Form.Group>
                                    <p className='text-black-color'>{status?.message}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className='d-flex justify-content-around mt-4'>
                                        <Button 
                                            variant='danger'
                                            size='lg'
                                            onClick={runRevert}
                                            disabled={selectedAdministrativeService === undefined || isProd}>
                                            <FontAwesomeIcon icon={faBackward} className='mr-2' />
                                                Revertir
                                        </Button>
                                        <Button
                                            variant='success'
                                            size='lg'
                                            onClick={runImport}
                                            disabled={selectedAdministrativeService === undefined || status.success}>
                                            <FontAwesomeIcon icon={faPlay} className='mr-2' />
                                                Importar
                                        </Button>
                                    </Form.Group>        
                                </Col>   
                            </Row>
                        </Form>  
                    </Card.Body>
                </Card>
            </Container>
            <AppLoading isLoading={reportIsFetching} />
            <AppLoading isLoading={connectivityStatusIsFetching} />
        </Container>
    )
}

export default ReportImport;
