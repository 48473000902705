
import * as ACTIONS from './subcodeActions';
import * as SERVICES from 'src/services/subcodeServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError, showWarning } from 'src/redux/globalData/globalDataActionCreator';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { downloadStreamFile } from 'src/utils/utils';

export const tryGetSubcodeList = (params) => {

	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		if(accessToken){
			const filter = params?.filter || {};
			const page = params?.page || 1;
			const pageSize = params?.pageSize || 10;
	
			let filterToSend = {
				period_id: getGlobalDataSelectedPeriod( state )?.id,
				...filter
			};
			
			let paramsToSend = {
				filter: filterToSend,
				page,
				pageSize
			};

			dispatch( ACTIONS.getSubcodeListRequest() );
			const response = await SERVICES.listSubcode( accessToken, paramsToSend )
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getSubcodeListRequestSuccess(response?.data) );
				return response?.data;
			}
			else if ( response?.status == 204 ) {
				dispatch( ACTIONS.getSubcodeListRequestFailure(response?.response?.data || response) );
				if(!params?.incorporation){
					showWarning( dispatch, `No se encontraron partidas presupuestarias.` );
				}
			}
			else {
				
				dispatch( ACTIONS.getSubcodeListRequestFailure(response?.response?.data || response) );
				showError( dispatch, `Error al cargar las partidas presupuestarias. Detalle: ${response?.response?.data?.message || response}` );
				
			}
		}

	};
};

export const tryGetSubcodeDetails = (params) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getSubcodeDetailsRequest() );

		if(accessToken){
			
			const response = await SERVICES.getSubcodeDetails( accessToken, params );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getSubcodeDetailsRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getSubcodeDetailsRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar los detalles del subcódigo. Detalle: ${response?.error||response}` );
			}
		}

	};
};

export const tryGetSubcodeData = params => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getSubcodeDataRequest() );

		if(accessToken){
			
			const response = await SERVICES.getSubcodeData( accessToken, params );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getSubcodeDataRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getSubcodeDataRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar los datos del subcódigo. Detalle: ${response?.error||response}` );
			}
		}

	};
};

export const tryPostSubcode = (subcodeData, creditListingData) => {
	return async( dispatch, getState ) => {

		const successMsg = creditListingData ? 'La partida presupuestaría se editó correctamente.' : 'La partida presupuestaría se creó correctamente.';
		const errorMsg = creditListingData ? 'Error al editar la partida presupuestaría.' : 'Error al crear la partida presupuestaría.';

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.postSubcodeRequest(subcodeData) );

		if(accessToken){
			
			const response = await SERVICES.postSubcode( accessToken, subcodeData );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.postSubcodeRequestSuccess(response?.data) );
				showSuccess( dispatch, successMsg );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.postSubcodeRequestFailure(response?.error||response) );
				showError( dispatch, `${errorMsg} Detalle: ${response?.response?.data?.message||response}` );
			}
		}

	};
};

// Subcode Affectatios
export const tryGetSubcodeAffectations = subcodeId => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getSubcodeAffectationsRequest() );

		if(accessToken){
			
			const response = await SERVICES.getSubcodeAffectations( accessToken, subcodeId );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getSubcodeAffectationsRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
	
				dispatch( ACTIONS.getSubcodeAffectationsRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar las afectaciones del subcódigo. Detalle: ${response?.error||response}` );
			}
		}

	};
};

export const tryGetSubcodeAffectationsHistory = (params) => {
    return async ( dispatch, getState ) => {
        const state = getState();
        const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getSubcodeAffectationsHistoryRequest() );

		if(accessToken){
			
			const response = await SERVICES.getSubcodeAffectationsHistory( accessToken, params );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getSubcodeAffectationsHistoryRequestSuccess(response?.data) );
				return response?.data;
			}
			else  if (response?.status === 204){
				dispatch( ACTIONS.getSubcodeAffectationsHistoryRequestFailure(response?.error||response) );
				showWarning( dispatch, `Error al cargar el historial de afectaciones del subcódigo. Detalle: No se encontraron afectaciones.` );
			} else {
				dispatch( ACTIONS.getSubcodeAffectationsHistoryRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar el historial de afectaciones del subcódigo. Detalle: ${response?.error||response}` );
			}
		}
	}
};

export const tryGetFileSubcode = subcodeData => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		if(accessToken){
			const filter = subcodeData?.filter || {};
			const page = subcodeData?.page || 1;
			const pageSize = subcodeData?.pageSize || 10;
	
			let filterToSend = {
				period_id: getGlobalDataSelectedPeriod( state )?.id,
				...filter
			};
			
			let paramsToSend = {
				filter: filterToSend,
				page,
				pageSize
			};

			dispatch( ACTIONS.getFileSubcodeRequest() );
			const response = await SERVICES.getFileSubcode( accessToken, paramsToSend );
			if( response?.status == 200 ) {
				downloadStreamFile(response, 'resultados');
				dispatch( ACTIONS.getFileSubcodeRequestSuccess(response?.data) );
			}
			else if ( response?.status == 204 ) {
				dispatch( ACTIONS.getFileSubcodeRequestFailure(response?.response?.data || response) );
				showError( dispatch, `Error al cargar las partidas presupuestarias. Detalle: No se encontraron partidas.` );
			}
			else {
				
				dispatch( ACTIONS.getFileSubcodeRequestFailure(response?.response?.data || response) );
				showError( dispatch, `Error al cargar las partidas presupuestarias. Detalle: ${response?.response?.data?.message || response}` );
				
			}
		}

	};
};

//Compensation
//Add New compensation
export const tryPostCompensation = (dataToSend) => {

	return async ( dispatch, getState ) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.postCompensationRequest());

		if(accessToken){

			const response = await SERVICES.postCompensation( accessToken, dataToSend );

			if( response?.status == 200 ) {
				dispatch(ACTIONS.postCompensationRequestSuccess(response?.data));
				showSuccess(dispatch, 'Compensaciones creadas correctamente.');
				return response;
			}
			else {
				dispatch(ACTIONS.postCompensationRequestFailure(response?.error||response));
				showError(dispatch, `Error al crear las compensaciones: ${response?.response?.data?.message||response}`);
				return response?.response;
			}
		}

	};
};