import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { push } from 'connected-react-router';
import { Table, Button, Form, Row, Alert  } from 'react-bootstrap';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import { clearWhiteSpaces } from 'src/utils/utils';
import { tryGetPreloadData } from 'src/redux/preload/preloadActionCreator';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { clearPreloadData, clearPreloadXLS } from 'src/redux/preload/preloadActions';
import { getClearObject } from 'src/utils/utils';
import { USER_NEW } from 'src/utils/constants';
import { tryGetListServiceByJurisdictionOrganism } from 'src/redux/service/serviceActionCreator';
import { getUserNewJurisdiction } from 'src/redux/user/userReducer';
import { getServiceJurisdictionOrganismListData } from 'src/redux/service/serviceReducer';

const UserNewJurisdictionTable = (props) => {
	const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useFormContext();
    const [affectationsSelect, setAffectationsSelect] = useState({
        expedient: {},
        subCodePreload: []
    });

    //Selected period
    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const periodId = selectedPeriod?.id;

    // TODO : Lista de servicios selecconados
    const servicesList = useSelector(state => getUserNewJurisdiction(state));
    const serviceJurisdictionOrganismListData = useSelector(state => getServiceJurisdictionOrganismListData(state));

    //Comportamiento de tabla
    const [flagOne, setFlagOne] = useState({ idActive: null });
    const [flagTwo, setFlagTwo] = useState({ idActive: null });
    const [jurisdictionItem, setJurisdictionItem] = useState([]);
    const handleChangeFlagOne = (index) => {
        flagOne.idActive === index ?  setFlagOne({ idActive: null }) : setFlagOne({ idActive: index });
    };
    const handleChangeFlagTwo = (index) => {
        flagTwo.idActive === index ?  setFlagTwo({ idActive: null }) : setFlagTwo({ idActive: index });
    };
    const handleItemsCheckBox = ( item, level ) => {
        // TODO: Accion del checkBox de Jurisdiccion 
        setJurisdictionItem([...jurisdictionItem, item.id])
    };
    const handleCheckJurisdiction = (item) => {
        const algo = serviceJurisdictionOrganismListData?.jurisdictions?.some(obj => obj.id === item.id);
        return algo
    };
    const onClickSelection = () => {
        // TODO: Guardar seleccionados
	}; 
    const toolTipTextMessage = (intrLegalId, accountNumber, type) =>{
        return intrLegalId || accountNumber ? `Instrumento legal asociado Nro ${intrLegalId ? intrLegalId : ""}` : `${type} Precarga`;
    };
    const attributeFilter = props.checkedPreload ? "hasSubCodePreload" : "subCodePreloadWithoutConversion";
    const defaultParams = {
        page: 1,
        pageSize: 10,
    };

    const fetchList = (params) => {
        dispatch(clearPreloadData());
        dispatch(clearPreloadXLS());
        dispatch(tryGetPreloadData(params));
        dispatch(tryGetListServiceByJurisdictionOrganism(servicesList))
        setFlagOne({ idActive: null });
    };

    const loadDefaultData = () => {
        reset();
        props.setFilterObject();
		fetchList({
            ...defaultParams,
            filter: {[attributeFilter]: 1}
        });
    };

    const onSubmitForm = (data) => {
        const allowToClearWhiteSpaces = ['completeLabel', 'subCodeCompleteLabel']
        const paramsToSend = clearWhiteSpaces(getClearObject(data), allowToClearWhiteSpaces);
        const clearParamsToSend = getClearObject(paramsToSend);
        props.setFilterObject(clearParamsToSend);
        fetchList({
            ...defaultParams,
            filter: {...clearParamsToSend, [attributeFilter]: 1}
        });

    };

    useEffect(() => {
        loadDefaultData();
    }, [])

    return <>
        <div className='mt-4'>
            <Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
                <Table bordered hover size='sm'>
                    <thead>
                        <tr>
                            <th className='text-center align-middle' width='70%'>Nro de Expediente</th>
                            <th className='text-center align-middle th-minwidth'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        { serviceJurisdictionOrganismListData?.map( (item, index) => {
                                return <>
                                    <tr key={item?.id}>
                                        <td className='text-left align-middle'>{ item?.shortName }</td>
                                        <td className='text-center align-middle'>
                                            <ActionIcon
                                                size="lg"
                                                className="text-black-color"
                                                toolTipText={flagOne.idActive == index ? "Contraer jurisdicciones" : "Expandir jurisdicciones" }
                                                icon={flagOne.idActive == index ? faAngleUp : faAngleDown }
                                                onClick={ ()=> handleChangeFlagOne(index) }
                                            />
                                        </td>
                                    </tr>
                                    { flagOne.idActive === index &&
                                        item?.jurisdictions?.map( (jurisd) =>
                                            <>
                                                <tr key={ jurisd?.id }>
                                                    <td className='text-left pl-4 align-middle'>{jurisd?.name}</td>
                                                    <td className='text-center align-middle'>
                                                        <div className='d-flex justify-content-center align-middle'>
                                                            {/* <Form.Check
                                                                className ='text-center mx-3 align-self-center expedient-table-check'
                                                                name="selectedExpedient"
                                                                // disabled={name?.legalInstrumentNumber || name?.subCodeDetail?.accountNumber}
                                                                onClick={() => handleItemsCheckBox(jurisd, 1)}
                                                                // checked={ handleCheckJurisdiction(jurisd) }
                                                            /> */}
                                                            <ActionIcon
                                                                size="lg"
                                                                className="text-black-color"
                                                                toolTipText={flagTwo.idActive == index ? "Contraer unidad org." : "Expandir unidad org." }
                                                                icon={flagTwo.idActive == index ? faAngleUp : faAngleDown }
                                                                onClick={ ()=> handleChangeFlagTwo(index) }
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>      
                                                { flagTwo.idActive === index &&
                                                    jurisd?.organisms?.map( org =>
                                                        <tr key={ org?.id }>
                                                            <td className='text-left pl-5 align-middle'>{org?.name}</td>
                                                            <td className='text-center align-middle'>
                                                                <div className='d-flex justify-content-center align-middle'>
                                                                    <Form.Check
                                                                        className ='text-center mx-2 align-self-center expedient-table-check'
                                                                        name="selectedExpedient"
                                                                        // disabled={name?.legalInstrumentNumber || name?.subCodeDetail?.accountNumber}
                                                                        onClick={() => handleItemsCheckBox(org, 2)}
                                                                        checked={handleCheckJurisdiction(org)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )
                                    }                                  
                                </>
                            })
                        }
                    </tbody>
                </Table>
                <div className='d-flex justify-content-around'>
                    <Button
                        variant='primary'
                        size='lg'
                        onClick={ () => dispatch(push(USER_NEW)) }
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant='success'
                        size='lg'
                        onClick={ () => onClickSelection() }
                        disabled={ !affectationsSelect?.subCodePreload?.length }
                    >
                        Guardar selección
                    </Button>
                </div>
            </Form>
        </div>
    </>
}

export default UserNewJurisdictionTable;