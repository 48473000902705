import React, { useState } from 'react';
import Pagination from "react-js-pagination";
import { Form, Button, Row, Col, InputGroup, Container } from 'react-bootstrap';

const RCPagination = props => {
		props.itemClass = (props.itemClass || '').concat(' page-item') ;
		props.linkClass = (props.linkClass || '').concat(' page-link') ;
		props.innerClass = (props.innerClass || '').concat(' pagination') ;
		const [numberPage, setNumberPage] = useState(1);
		const changePage = (numero) => props.onChange(numero);
		const onChangeNumberPage = (v) => {
			setNumberPage(v.target.value);
		}
		const totalPages = props.totalItemsCount/props.itemsCountPerPage + 1;
		const paginas = 'Página: ' + props?.thePage + ' de ' + Math.ceil(props.totalItemsCount/props.itemsCountPerPage)
		const TotalContenido = ' Total Ítems: ' + props?.totalItems;

		const onClickNumberPage = () => {
			if(numberPage < (props.totalItemsCount/props.itemsCountPerPage + 1)){
				changePage(numberPage);
			}else{
				changePage(1);
			}
		}

		return <>
			<Container>
				<Row>
					<Col xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }} lg={{ span: 3, offset: 2 }}>
						<Pagination {...props} />
					</Col>
					<Col xs={{ span: 8, offset: 2 }} sm={{ span: 6, offset: 3 }} md={{ span: 3, offset: 2 }} lg={{ span: 2, offset: 1 }}>
						<InputGroup className="mb-3" >
							<InputGroup.Text className="bg-white">Pág. </InputGroup.Text>
							<Form.Control
								style={{"width":"26%"}}
								placeholder=""
								type="number"
								name="numero"
								id="numero"
								onChange={onChangeNumberPage}
							/>
							<Button 
								style={{"width":"20%"}}
								variant="light"
								className="pr-2 pl-2" 
								size="sm"
								onClick={onClickNumberPage}
							>
								Ir
							</Button>
						</InputGroup>
					</Col>
					<Col xs={{ span: 8, offset: 2 }} sm={{ span: 8, offset: 2 }} md={{ span: 3, offset: 1 }} lg={{ span: 4, offset: 0 }}>
						<InputGroup className="container-fluid d-flex justify-content-center" >
							<InputGroup.Text className="bg-white">
								{props?.thePage && paginas} - {props?.totalItems && TotalContenido}
							</InputGroup.Text>
						</InputGroup>
					</Col>
				</Row>
			</Container>			
		</>
}

export default RCPagination;