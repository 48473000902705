import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { Table } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

import ActionIcon from 'src/components/general/ActionIcon';
import { faExclamationTriangle, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { selectedBudgetCredits, modifications, denomination, creditCurrent, modifAmount, resultingCredit, actions, numberAbbreviation } from 'src/utils/label';

import { formatterPeso, numberNegativeRed, parseIntOrUndefined } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';

import { tryGetAffectationSubcode } from 'src/redux/affectation/affectationActionCreator';

import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { setNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';

import NumberFormat from 'react-number-format';

import DetailModal from 'src/components/pages/CreditExecution/DetailModal';

const SelectedCompensationBudgetItems = (props) => {

    const dispatch = useDispatch();

    const {selectedCreditsToCompensate, hasSelectedCreditsToCompensate} = props;

    const { control } = useForm();

    const selectedCredits = useSelector(state => getNewAffectationSelectedCredits(state));
    
    //Administrative document
    const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
    const administrativeDocumentData = {...administrativeDocument, organizationCode: parseIntOrUndefined(administrativeDocument?.organizationCode)};

    //Validations

    const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		const maxValue = 999999999999.99;
		const minValue = -999999999999.99;
		if (value <= maxValue && value >= minValue || value == '-') return inputObj;
	};

    //onChangeAmount

	const onChangeAffectationAmount = (inputObject, subCodeData) => {

		let newCredits = selectedCreditsToCompensate.map(item => {
			if (item?.id == subCodeData?.id) {
				item.compensationData.amount = inputObject?.floatValue;
                item.compensationData.resultingCredit = subCodeData?.credit?.currentCredit + (inputObject?.floatValue || 0);
			}
			return item;
		});

        calculateTotal(selectedCreditsToCompensate);

		dispatch(setNewAffectationDataSelectedCredits(newCredits));
	};
    
    //calculate total

	const [totalAmount, setTotalAmount] = useState(0);

    const calculateTotal = (selected) => {
		setTotalAmount(selected?.map((item) => item?.compensationData?.amount || 0)
        	.reduce((accumulator, currentValue) => {
          		return accumulator + currentValue;
       		}, 0)
		);
	};

    //totalModifications

    const TotalModifications = () => {
        return <span className='ml-auto'>
			<span className='text-black-color mr-3'>Total Importe Compensaciones:</span>
			<NumberFormat 
				prefix={'$'}
				thousandSeparator={'.'} 
				decimalSeparator={','} 
				decimalScale={2} 
				value={totalAmount}
                defaultValue={0}
				className={'text-black-color text-right'} 
				readOnly 
			/>
		</span>
    };

    //Detail modal credit

    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onClickModalCredit = (creditData) => {

		const params = {
			filter: {
				sub_code_id: creditData?.id,
				administrative_document_organism_code: administrativeDocumentData?.organizationCode,
				administrative_document_number: administrativeDocumentData?.number,
				administrative_document_year: administrativeDocumentData?.year
			}
		};
		dispatch(tryGetAffectationSubcode(params)).then(
			response => {
				if (response?.status == 200 && isNotEmptyArray(response?.data?.data)) {
					creditData.balances = response?.data?.data[0]?.balance;
				}
				setDataModal(creditData);
				handleShow(true);
			}
		);
	};

    //remove credit

    const onClickRemoveCredit = creditData => {
		const newSelectedCredits = (selectedCredits || []).filter(item => item?.id != creditData?.id);
		dispatch(setNewAffectationDataSelectedCredits(newSelectedCredits));
	};

    //useEffects

    useEffect(() => {
        calculateTotal(selectedCreditsToCompensate);
    }, [selectedCreditsToCompensate]);

    return <>
        {
            hasSelectedCreditsToCompensate &&
                <>
                    <hr />
                    <p className='h6 text-black-color'>{selectedBudgetCredits}:</p>
                    <div className='d-flex align-items-center mb-3'>
			            <p className='h6 text-black-color'>{modifications}:</p>
                        <TotalModifications />
                    </div>
                    <Table striped bordered hover size='sm'>
                        <thead>
                            <tr>
                                <th className='text-center' style={{"width":"10%"}}>Tipo de partida</th>
                                <th className='text-center' style={{"width":"20%"}}>{numberAbbreviation}</th>
							    <th className='text-center' style={{"width":"15%"}}>{denomination}</th>
						        <th className='text-center' style={{"width":"15%"}}>{creditCurrent}</th>
								<th className='text-center' style={{"width":"15%"}}>{modifAmount}</th>
                                <th className='text-center' style={{"width":"15%"}}>{resultingCredit}</th>
								<th className='text-center' style={{"width":"10%"}}>{actions}</th>
                            </tr>
                        </thead>
                        <tbody className='text-black-color'>
                            {
                                selectedCreditsToCompensate?.map(subCodeData => 
                                    (
                                        <tr key={subCodeData?.id}>
                                            <td className='text-center'>
                                                {subCodeData?.budgetItemType?.label}
                                            </td>
                                            <td className='text-center'>
                                                {(subCodeData?.balance?.currentBalance == 0) &&
                                                    <ActionIcon size='lg' id='balance-warning' toolTipText='El saldo de la partida es $0,00.' icon={faExclamationTriangle} className='text-warning mr-3' />
                                                }
                                                {subCodeData?.completeNumber}
                                            </td>
                                            <td className='text-center'>
                                                {subCodeData?.visibleName}
                                            </td>
                                            <td className={`${numberNegativeRed(subCodeData?.credit?.currentCredit)} text-right`}>
                                                {formatterPeso.format(subCodeData?.credit?.currentCredit)}
                                            </td>
                                            <td className='text-center'>
                                                <Controller
                                                    name={`amount-${subCodeData?.id}`}
                                                    id={`amount-${subCodeData?.id}`}
                                                    control={control}
                                                    as={
                                                        <NumberFormat
                                                            className='form-control'
                                                            decimalScale={2}
                                                            thousandSeparator={'.'}
                                                            decimalSeparator={','}
                                                            inputMode='numeric'
                                                            value={subCodeData?.compensationData?.amount}
                                                            defaultValue={subCodeData?.compensationData?.amount}
                                                            allowLeadingZeros = {false}
                                                            isAllowed={(inputObj)=> withValueLimit(inputObj)}
                                                            onValueChange={ (inputObject) => onChangeAffectationAmount(inputObject, subCodeData)}
                                                            required
                                                        />
                                                    }
                                                />
                                            </td>
                                            <td className={`${numberNegativeRed(subCodeData?.compensationData?.resultingCredit)} text-right`}>
                                                {formatterPeso.format(subCodeData?.compensationData?.resultingCredit)}
                                            </td>
                                            <td className='text-center'>
                                                <ActionIcon size='lg' id='details-credit' toolTipText='Detalle de la partida' icon={faSearch} onClick={() => onClickModalCredit(subCodeData)} />
                                                <ActionIcon size='lg' id='remove-credit' toolTipText='Quitar de la selección' icon={faTimes} className='text-danger' onClick={() => onClickRemoveCredit(subCodeData)} />
                                            </td>
                                        </tr>
                                    )
                                )
                            }
                        </tbody>
                    </Table>

                    <div className='d-flex align-items-center mb-3'>
			            <p className='h6 text-black-color'>
                            
                        </p>
                        <TotalModifications />
                    </div>

                    <DetailModal
                        show = {show}
                        handleClose = {handleClose}
                        dataModal = { dataModal }
                    />
                    
                </>
            }
    </>
    
};

export default SelectedCompensationBudgetItems;