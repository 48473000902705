import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';

import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryListAllServiceAdministrativeByUser } from "src/redux/administrativeService/administrativeServiceActionCreator";
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryGetReportProviderFundRequest } from 'src/redux/reports/reportsActionCreactor';
import { getReportProviderFundRequestIsFetching } from 'src/redux/reports/reportsReducer';
import RangeDates from 'src/components/common/RangeDates';
import DropdownList from 'react-widgets/lib/DropdownList'

const ReportProviderFundRequestPage = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();

	const reportDates = useSelector( state => getReportDatesData(state) );
	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	
	const [dateFrom, setDateFrom] = useState(reportDates?.dateFrom);
	const [dateTo, setDateTo] = useState(reportDates?.dateTo);

	const [dateHasErrors, setDateHasErrors] = useState(false);

	const [cuit, setCuit] = useState();
	// Admnistrative Service
	const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
		}) );
	const listado_ejercicios = ['Ejercicio ' + globalSelectedPeriod?.year, 'Residuos Pasivos ' + (globalSelectedPeriod?.year - 1), 'Residuos Pasivos ' + (globalSelectedPeriod?.year - 2)] ; 
	const administrativeServiceIsFetching = useSelector( state => getListAllServiceAdministrativeByUserIsFetching(state) );
	const [ selectedAdministrativeService, setSelectedAdministrativeService ] = useState();
	const [ selectedfundClass, setfundClass ] = useState();

	const applyfundClass = (fundClass) => {
		setfundClass(fundClass.replace(/[^0-9\.]+/g, ""))
	}
	const messagesDropDown = { emptyFilter: 'Sin resultados', emptyList: 'Sin resultados' };
	const getReport = outputFormat => {
		
		if (globalSelectedPeriod) {
			const params = {
				/* outputFormat, */
				cuit,
				dateFrom,
				dateTo,
				fundClass: selectedfundClass,
				administrativeServiceId: selectedAdministrativeService,
			};
			dispatch(tryGetReportProviderFundRequest(params));
		}

	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	// Reports redux
	const reportIsFetching = useSelector(state => getReportProviderFundRequestIsFetching(state));
	const allowGetReport = (hasPeriodList && !reportIsFetching && dateFrom && dateTo && cuit && !dateHasErrors );

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
						<h1 className="h6 mt-1 mb-0">Reporte de Pedido de Fondos por Proveedor</h1>
					</Card.Header>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={8} className='offset-md-2' >
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>			
										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Clase de Fondo
										</Form.Label>
										<DropdownList
											placeholder='Seleccione una Clase de Fondo...'
											busy={administrativeServiceIsFetching}
											data={listado_ejercicios}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											className='text-black-color'
											onChange={ value => applyfundClass(value) }
											messages={ messagesDropDown }
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
										</DropdownList>
									</Form.Group>	

									<Form.Group>
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<DropdownList
											placeholder='Seleccione un servicio...'
											busy={administrativeServiceIsFetching}
											data={administrativeServiceData}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											className='text-black-color'
											onChange={ value => setSelectedAdministrativeService(value.id) }
											messages={ messagesDropDown }
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
										</DropdownList>
									</Form.Group> 

									<Form.Group>
										<Form.Label className='text-black-color'>
											CUIT
										</Form.Label>			
										<Form.Control 
											type='number' 
											name='cuit' 
											id='cuit'
											onChange={event => setCuit(event.target.value)}
											maxLength='11'
											minLength='11'
										/>
									</Form.Group>

									<FormContext {...hookFormMethods}>
										<RangeDates
											{...{dateFrom, setDateFrom, dateTo, setDateTo, setDateHasErrors}}
										/>
									</FormContext>
									
									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Ver PDF
										</Button>
										{
											reportIsFetching
											&&
											<Spinner animation='border' />
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</>
};

export default ReportProviderFundRequestPage;