import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const AlertMessageComponent = props => {
	
	const { hasListAlerts, listAlertData, setAlertActiveValue } = props;

	return (
		<>
				{
					hasListAlerts 
					?
					listAlertData?.map(item => <tr key={item.id}>
							<div className={`alert alert-${item?.level?.toLowerCase()} m-1`}  role='alert'>
							<FontAwesomeIcon className={`text-${item?.level?.toLowerCase()} mr-1`} icon={faBell} />
								{item?.message}
							</div>
						</tr>)
					:
					setAlertActiveValue?.records?.map(item => <tr key={item.id}>
							<div className={`alert alert-${item?.level?.toLowerCase()} m-1`}  role='alert'>
							<FontAwesomeIcon className={`text-${item?.level?.toLowerCase()} mr-1`} icon={faBell} />
								{item?.message}
							</div>
						</tr>)
				}
		</>
	);
};

export default AlertMessageComponent;