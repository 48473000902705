import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Form, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSearch, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from "formik";
import * as Yup from "yup";
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { push } from 'connected-react-router';
import { BANK_ACCOUNT_LIST } from 'src/utils/constants';
import { tryGetCharacterList } from 'src/redux/character/characterActionCreator';
import { getCharacterListData, getCharacterListIsFetching } from 'src/redux/character/characterReducer';
import { tryGetAccountList } from 'src/redux/account/accountActionCreator';
import { getAccountListData, getAccountListIsFetching } from 'src/redux/account/accountReducer';
import DropdownList from 'react-widgets/lib/DropdownList';
import AppLoading from 'src/components/common/AppLoading';
import { clearAccountList } from 'src/redux/account/accountActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { tryOrganismsByJurisdictionsIdByServicesIdAndExerciseId } from 'src/redux/organization/organizationActionCreator';
import { tryGetJurisdictionsByServicesAndExercise } from 'src/redux/jurisdiction/jurisdictionActionCreator';
import { getJurisdictionsByServicesAndExerciseData, getJurisdictionsByServicesAndExerciseIsFetching } from 'src/redux/jurisdiction/jurisdictionReducer';
import { clearJurisdictionsByServicesAndExerciseRequest } from 'src/redux/jurisdiction/jurisdictionActions';
import { jurisdictionPlaceholder, organizationPlaceholder } from 'src/utils/label';
import { getOrganismsByJurisdictionsServicesAndExerciseData } from 'src/redux/organization/organizationReducer';
import { clearOrganismsByJurisdictionsIdByServicesIdAndExerciseId } from 'src/redux/organization/organizationActions';
import { removeUnuselessObjPropertiesFromArray, removeUnuselessObjPropertiesFromArrayNestedObj } from 'src/utils/utils';
import ActionIcon from 'src/components/general/ActionIcon';
import { showError } from 'src/redux/globalData/globalDataActionCreator';
import { AdministrativeServiceData } from 'src/utils/administrativeServiceUtils';

const BankAccountFormAM = props => {
    const dispatch = useDispatch();
    const { bankAccountToEdit, isFetching } = props;
    const redirectToBankAccountList = () => { dispatch(push(BANK_ACCOUNT_LIST)) };

    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const jurisdictionsList = useSelector(state => getJurisdictionsByServicesAndExerciseData(state));
    const jurisdictionsListIsFetching = useSelector(state => getJurisdictionsByServicesAndExerciseIsFetching(state));
    const uOrgList = useSelector(state => getOrganismsByJurisdictionsServicesAndExerciseData(state));
    const [orderUOrgList, setOrderUOrgList] = useState(uOrgList);
    const isFetchingBudgetAccounts = useSelector(state => getAccountListIsFetching(state));

    const charactersData = useSelector(state => getCharacterListData(state))?.data?.sort((a, b) => a.code > b.code ? 1 : -1);
    const isFetchingCharactersData = useSelector(state => getCharacterListIsFetching(state));

    const [numberLength, setNumberLength] = useState();
    const [number, setNumber] = useState(bankAccountToEdit?.number);
    const [description, setDescription] = useState(bankAccountToEdit?.description);

    const [admServiceId, setAdmServiceId] = useState(bankAccountToEdit?.administrativeServiceId);
    const [selectedJurisdiction, setSelectedJurisdiction] = useState();
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [character, setCharacter] = useState();
    const [account, setAccount] = useState();
    const [budgetAccountsList, setBudgetAccountsList] = useState(bankAccountToEdit?.budgetAccounts ? bankAccountToEdit?.budgetAccounts : [])
    const [budgetAccountsListOrder, setBudgetAccountsListOrder] = useState(budgetAccountsList)
    const hasBudgetAccountList = budgetAccountsList?.length > 0;
    const principalDontAllowCond = !hasBudgetAccountList || !number || !description;

    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

    const administrativeServiceData = AdministrativeServiceData();
    const budgetAccountsData = useSelector(state => getAccountListData(state))?.data;

    const defaultValues = {
        service_id: admServiceId,
        period_id: globalSelectedPeriod?.id
    };

    const standardDTOStructure = {
        number: Number(number),
        description: description,
        year: bankAccountToEdit?.year || globalSelectedPeriod?.year || '',
        administrativeServiceId: admServiceId,
        budgetAccounts: budgetAccountsList,
        hasFundRequests: false
    };

    const formik = useFormik({
        initialValues: {
            ...bankAccountToEdit,
            year: bankAccountToEdit?.year || globalSelectedPeriod?.year || ''
        },
        validationSchema: Yup.object({
            number: Yup.number().required("Ingrese un número de Cuenta Bancaria")
                .min(100000000000000, 'El número de cuenta bancaria debe tener 15 números y actualmente tiene')
                .max(999999999999999, 'El número de cuenta bancaria debe tener 15 números y actualmente tiene')
                .typeError('El valor debe ser numérico'),
            description: Yup.string().required("Este campo es obligatorio")
                .test('len', "Este campo no puede tener más  de 256 caracteres", val => val?.length <= 256),
            year: Yup.number().required("Este campo es obligatorio"),
        }),
        onSubmit: (data) => {
            removeUnuselessObjPropertiesFromArray(standardDTOStructure?.budgetAccounts, ["unidadDeOrganizacionShortName"])
            removeUnuselessObjPropertiesFromArrayNestedObj(standardDTOStructure?.budgetAccounts, ["id", "shortName"])
            props.onFormSubmit({
                ...data,
                ...standardDTOStructure
            }, false);
        }
    });

    const handleSaveBankAccountAndCreateOther = () => {
        removeUnuselessObjPropertiesFromArray(standardDTOStructure?.budgetAccounts, ["id", "unidadDeOrganizacionShortName"])
        removeUnuselessObjPropertiesFromArrayNestedObj(standardDTOStructure?.budgetAccounts, ["id", "shortName"])
        props.onFormSubmit({
            ...formik.values,
            ...standardDTOStructure
        }, true);
    };

    const handleNumberOnChange = (e) => {
        setNumberLength(e.target.value.length);
        setNumber(e.target.value);
        formik.handleChange(e);
    };

    const handleDescriptionOnChange = (e) => {
        setDescription(e.target.value);
        formik.handleChange(e);
    }

    const cleanUpDropDowns = () => {
        setSelectedJurisdiction();
        setSelectedOrganization();
        setCharacter();
        setAccount();
    };

    const handleServiceOnChange = (administrativeServiceId) => {
        setAdmServiceId(administrativeServiceId);
        cleanUpDropDowns();
        dispatch(clearJurisdictionsByServicesAndExerciseRequest());
        dispatch(clearAccountList());
        dispatch(tryGetJurisdictionsByServicesAndExercise(administrativeServiceId, globalSelectedPeriod?.id))
    };

    const handleJurisdictionOnChange = (jurisdiction) => {
        setSelectedJurisdiction(jurisdiction);
        setSelectedOrganization();
        setCharacter();
        setAccount();
        dispatch(clearAccountList());
        dispatch(tryOrganismsByJurisdictionsIdByServicesIdAndExerciseId(admServiceId, globalSelectedPeriod?.id, jurisdiction.id));
    }

    const handleUorgOnChange = (uOrg) => {
        setCharacter();
        setAccount();
        setSelectedOrganization(uOrg);
    }

    const handleCharacterAccountOnChange = (caracterId) => {
        setCharacter(caracterId);
        setAccount();
        dispatch(tryGetAccountList(undefined, { ...defaultValues, character_id: caracterId.id, organization_id: selectedOrganization.id }));
    };

    const listBudget = {
        servicio: admServiceId,
        jurisdiccion: { code: selectedJurisdiction?.code, name: selectedJurisdiction?.name },
        unidadDeOrganizacion: { code: selectedOrganization?.codeBudget, name: selectedOrganization?.name },
        caracter: { code: character?.code, name: character?.name },
        cuenta: { code: account?.number, name: account?.name },
        unidadDeOrganizacionShortName: selectedOrganization?.organismClassifier?.shortName,
    };

    const addBudgetToList = () => {
        let idBudget = `${listBudget.jurisdiccion.code}-${listBudget.caracter.code}-${listBudget.unidadDeOrganizacion.code}-${listBudget.cuenta.code}`;
        let idListBudget = [];
        budgetAccountsList?.map(item =>
            idListBudget.push(`${item.jurisdiccion?.code}-${item.caracter?.code}-${item.unidadDeOrganizacion?.code}-${item.cuenta?.code}`));

        idListBudget?.find(item => item === idBudget)
            ? showError(dispatch, 'La cuenta presupuestaria ya se encuentra asociada')
            : setBudgetAccountsList((budgetAccountsList) => [...budgetAccountsList, listBudget]);

        cleanUpDropDowns();
    }

    const handleDeleteBudget = (item) => {
        let idItem = `${item.jurisdiccion.code}-${item.caracter.code}-${item.unidadDeOrganizacion.code}-${item.cuenta.code}`;
        const filtered = budgetAccountsList?.filter((value) =>
            (`${value.jurisdiccion?.code}-${value.caracter?.code}-${value.unidadDeOrganizacion?.code}-${value.cuenta?.code}`) !== idItem);
        setBudgetAccountsList(filtered)
    };

    useEffect(() => {
        if (bankAccountToEdit) dispatch(tryGetJurisdictionsByServicesAndExercise(admServiceId, globalSelectedPeriod?.id));
        dispatch(tryGetCharacterList());
        return () => {
            dispatch(clearJurisdictionsByServicesAndExerciseRequest());
            dispatch(clearOrganismsByJurisdictionsIdByServicesIdAndExerciseId());
        }
    }, []);
    useEffect(() => {
        setOrderUOrgList(uOrgList?.sort(((a, b) => a.codeBudget - b.codeBudget)));
    }, [uOrgList]);
    useEffect(() => {
        setBudgetAccountsListOrder(budgetAccountsList
            ?.sort(((a, b) => a.jurisdiccion.code - b.jurisdiccion.code
                || a.unidadDeOrganizacion.code - b.unidadDeOrganizacion.code
                || a.caracter.code - b.caracter.code
                || a.cuenta.code - b.cuenta.code)));
    }, [budgetAccountsList]);

    return (
        <Container fluid>
            <Form onSubmit={formik.handleSubmit}>

                <span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                    Obligatorio
                </span>


                <Row className='my-2 text-black-color'>
                    <Col sm='3'>
                        <p className='text-right'>
                            <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                            N° de Cuenta Bancaria:
                        </p>
                    </Col>
                    <Col sm='8'>
                        <input
                            name="number"
                            type="number"
                            placeholder="Número"
                            className='form-control'
                            onChange={handleNumberOnChange}
                            value={formik.values.number}
                        />
                        {formik.errors.number &&
                            <div className='text-danger'>{`${formik.errors.number} ${numberLength > 0 ? numberLength : ""}`}</div>
                        }
                    </Col>
                </Row>

                <Row className='my-2 text-black-color'>
                    <Col sm='3'>
                        <p className='text-right'>
                            <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                            Nombre de la Cuenta:
                        </p>
                    </Col>
                    <Col sm='8'>
                        <textarea
                            name="description"
                            maxLength="256"
                            className='form-control'
                            onChange={handleDescriptionOnChange}
                            value={formik.values.description}
                        />
                        {formik.errors.description &&
                            <div className='text-danger'>{formik.errors.description}</div>
                        }
                    </Col>
                </Row>

                <Row className='my-2 text-black-color'>
                    <Col sm='3'>
                        <p className='text-right'>
                            <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                            Ejercicio:
                        </p>
                    </Col>
                    <Col sm='8'>
                        <input
                            name="year"
                            className='form-control'
                            onChange={formik.handleChange}
                            value={formik.values.year}
                            disabled
                        />
                        {formik.errors.year &&
                            <div className='text-danger'>{formik.touched.year && formik.errors.year}</div>
                        }
                    </Col>
                </Row>
                <Row className='my-2 text-black-color'>
                    <Col sm='3'>
                        <p className='text-right'>
                            <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                            Servicio:
                        </p>
                    </Col>
                    <Col sm='8'>
                        <DropdownList
                            filter='contains'
                            as='select'
                            placeholder='Seleccione un servicio...'
                            data={administrativeServiceData}
                            allowCreate='onFilter'
                            name='administrativeServiceId'
                            textField='displayName'
                            valueField='id'
                            disabled={hasBudgetAccountList}
                            value={admServiceId}
                            className='w-100'
                            onChange={value => handleServiceOnChange(value.id)}
                            messages={messagesDropDown}
                            selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                            searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
                        >
                        </DropdownList>
                    </Col>
                </Row>

                <Card>
                    <Card.Header className='h6'>
                        Nueva cuenta Presupuestaria
                    </Card.Header>
                    <Card.Body>
                        <Row className='my-2 text-black-color'>
                            <Col sm='3'>
                                <p className='text-right'>
                                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                    Jurisdicción:
                                </p>
                            </Col>
                            <Col sm='8'>
                                <DropdownList
                                    disabled={!admServiceId}
                                    filter='contains'
                                    as='select'
                                    placeholder={isNotEmptyArray(jurisdictionsList) ? "Seleccione una jurisdiccion" : jurisdictionPlaceholder}
                                    data={jurisdictionsList}
                                    allowCreate='false'
                                    name='jurisdiccionId'
                                    textField={item => (`${item?.code} - ${item?.name || ''}`)}
                                    valueField='id'
                                    value={selectedJurisdiction}
                                    className='text-black-color'
                                    onChange={value => handleJurisdictionOnChange(value)}
                                    messages={messagesDropDown}
                                    selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                                    searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}>
                                </DropdownList>
                            </Col>
                        </Row>

                        <Row className='my-2 text-black-color'>
                            <Col sm='3'>
                                <p className='text-right'>
                                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                    U. Org:
                                </p>
                            </Col>
                            <Col sm='8'>
                                <DropdownList
                                    disabled={!admServiceId || !selectedJurisdiction}
                                    filter='contains'
                                    as='select'
                                    placeholder={isNotEmptyArray(uOrgList) ? "Seleccione una unidad de Organización/Organismo" : organizationPlaceholder}
                                    data={orderUOrgList}
                                    allowCreate='onFilter'
                                    name='uOrgId'
                                    textField={item => (`${item?.codeBudget} - ${item?.name || ''} (${item?.jurisdiction?.shortName || ''})  (${item?.organismClassifier?.shortName || ''})`)}
                                    valueField='id'
                                    value={selectedOrganization}
                                    className='w-100'
                                    onChange={value => handleUorgOnChange(value)}
                                    messages={messagesDropDown}
                                    selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                                    searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
                                >
                                </DropdownList>
                            </Col>
                        </Row>

                        <Row className='my-2 text-black-color'>
                            <Col sm='3'>
                                <p className='text-right'>
                                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                    Carácter:
                                </p>
                            </Col>
                            <Col sm='8'>
                                <DropdownList
                                    disabled={!admServiceId || !selectedJurisdiction || !selectedOrganization}
                                    filter='contains'
                                    as='select'
                                    placeholder={isNotEmptyArray(charactersData) ? "Seleccione un caracter" : "No hay caracteres habilitados"}
                                    data={charactersData?.sort()}
                                    allowCreate='onFilter'
                                    name='caracterId'
                                    valueField='id'
                                    textField={item => (`${item?.code} - ${item?.name || ''}`)}
                                    value={character}
                                    className='w-100'
                                    onChange={(e) => handleCharacterAccountOnChange(e)}
                                    messages={messagesDropDown}
                                    selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                                    searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
                                >
                                </DropdownList>
                            </Col>
                        </Row>

                        {admServiceId && selectedJurisdiction && selectedOrganization && character &&
                            <Row className='my-2 text-black-color'>
                                <Col sm='3' className='pl-0'>
                                    <p className='text-right'>
                                        <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                        Cta. Presupuestaria:
                                    </p>
                                </Col>
                                <Col sm='8'>
                                    <DropdownList
                                        disabled={!admServiceId || !selectedJurisdiction || !selectedOrganization || !character}
                                        filter='contains'
                                        as='select'
                                        placeholder={"Seleccione un Cta. Presupuestaria"}
                                        data={budgetAccountsData}
                                        allowCreate='onFilter'
                                        name='caracterId'
                                        valueField='id'
                                        textField={item => (`${item?.number} - ${item?.name}`)}
                                        value={account}
                                        className='w-100'
                                        onChange={(e) => setAccount(e)}
                                        messages={messagesDropDown}
                                        selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
                                        searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
                                    >
                                    </DropdownList>
                                </Col>
                            </Row>
                        }

                        <div className="container mt-5">
                            <Row className='my-3 d-flex'>
                                <Col className="text-center d-flex justify-content-end">
                                    <Button type='button' disabled={!account} variant='success' size='lg' onClick={() => addBudgetToList()}>
                                        Asociar
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                    </Card.Body>
                </Card>

                {hasBudgetAccountList &&
                    <Card className="my-5">
                        <Card.Header>Cuentas presupuestarias asociadas</Card.Header>
                        <Card.Body>
                            <Table striped borderless hover responsive>
                                <thead className='font-weight-bold'>
                                    <tr>
                                        <th className='text-center align-middle' width='23%'>Jurisdicción</th>
                                        <th className='text-center align-middle' width='23%'>Unidad Organizacional</th>
                                        <th className='text-center align-middle' width='23%'>Caracter</th>
                                        <th className='text-center align-middle' width='23%'>Cuentas</th>
                                        <th className='text-center align-middle' width='8%'>Accion</th>
                                    </tr>
                                </thead>

                                <tbody className='text-black-color'>
                                    {budgetAccountsListOrder?.map((budgetItem) => {
                                        return (
                                            <tr key={budgetItem}>
                                                <td className='text-center align-middle py-1'>
                                                    {`${budgetItem?.jurisdiccion?.code} - ${budgetItem?.jurisdiccion?.name} `}
                                                </td>
                                                <td className='text-center align-middle py-1'>
                                                    {`${budgetItem?.unidadDeOrganizacion?.code} - ${budgetItem?.unidadDeOrganizacion?.name} (${budgetItem?.jurisdiccion?.name}) (${budgetItem?.unidadDeOrganizacion?.shortName || budgetItem?.unidadDeOrganizacionShortName}) `}
                                                </td>
                                                <td className='text-center align-middle py-1'>
                                                    {`${budgetItem?.caracter?.code} - ${budgetItem?.caracter?.name}`}
                                                </td>
                                                <td className='text-center align-middle py-1'>
                                                    {`${budgetItem?.cuenta?.code} - ${budgetItem?.cuenta?.name} `}
                                                </td>
                                                <td className='text-center align-middle py-1'>
                                                    <ActionIcon
                                                        size='lg'
                                                        id='delete'
                                                        toolTipText='Eliminar cta. presupuestaria'
                                                        icon={faTrash}
                                                        onClick={() => handleDeleteBudget(budgetItem)}
                                                    />
                                                </td>
                                            </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                }
                <div className="container mt-5">
                    <Row className='my-3'>
                        <Col className='d-flex justify-content-around'>
                            <Button type='button' variant='primary' size='lg' onClick={redirectToBankAccountList}>
                                Cancelar
                            </Button>
                            <Button type='submit' disabled={principalDontAllowCond} variant='success' size='lg'>
                                Guardar
                            </Button>
                            <Button hidden={bankAccountToEdit} type='button' disabled={principalDontAllowCond} variant='success' size='lg' onClick={handleSaveBankAccountAndCreateOther}>
                                Guardar y crear otro
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
            <AppLoading isLoading={isFetchingBudgetAccounts || isFetchingCharactersData || isFetching || jurisdictionsListIsFetching} />
        </Container>
    )
};

export default BankAccountFormAM;
