import React, { useEffect, useState } from 'react';
import { Form, Table, Button, Modal, Col } from 'react-bootstrap';
import { formatterPeso, mFormat } from 'src/utils/utils';
import ActionIcon from 'src/components/general/ActionIcon';
import { faEdit, faSave, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { tryGetOrganismAdministrativeServices } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import ModalReceptionAct from 'src/components/pages/OrderPay/modal/ModalReceptionAct';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { FormContext, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ReceptionActTable = (props) => {
	const dispatch = useDispatch();
	const admServiceId = useFormContext()?.admServiceId;
	const [index, setIndex] = useState();
	const [showEditModal, setShowEditModal] = useState(false);
	const { tipo, checkKeyDown, hookFormMethodsModal, hookFormMethodsModal: { handleSubmit } = {}, stopPropagate,
		onSubmitForm, ticketObj, handleOrganismOnChange, handleModalAdmServiceIdOnChange, codeOrganism, organismList
	} = props?._hookFormMethodsModal || {};

	const {admCode, admNumber, admYear} = props;

	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state))?.id;

	useEffect(() => {
		//detailData o del form de alta
		let serviceId = props?.orderPayDetailData?.administrativeServiceId || admServiceId;
		if (serviceId !== undefined) {
			dispatch(tryGetOrganismAdministrativeServices(serviceId, periodSelected))
		}
	}, [])

	const [showPayOrderDetailModal, setShowPayOrderDetailModal] = useState(false);
	const [data, setData] = useState();

	const onClickRemove = index => {
		props.setReceipts(props.receipts?.filter((item, i) => i !== index));
	};
	const _onSubmitForm = (arg) => {
		onSubmitForm(arg, index);
		setShowEditModal(false);
	}

	const showOrganismName = (organismId) => {
		const selectedOrganism = organismList?.find((item) => item.id == organismId);
		return selectedOrganism?.codeBudget + ' - ' + selectedOrganism?.name + ' (' + selectedOrganism?.jurisdiction?.shortName + ') (' + selectedOrganism?.organismClassifier?.shortName + ')'
	};

	const handleModal = (item) => {
		setData(item);
		setShowPayOrderDetailModal(true);
	};

	const editarRecibo = (item, index) => {
		ticketObj?.setTicketData(item.tickets);
		handleOrganismOnChange(item.codeOrganism);
		setData(item);
		setIndex(index);
		setShowEditModal(true);
	}

	return <Col className='contailner-fluid px-0' >
		<Table striped hover responsive>
			<thead className='text-white-color'>
				<tr>
					<th className='text-center align-middle' width="15%">Fecha</th>
					<th className='text-center align-middle' width="25%">Organismo</th>
					<th className='text-center align-middle' width='15%'>Factura</th>
					<th className='text-center align-middle' width='15%'>Importe</th>
					<th className='text-center align-middle' width='15%'>Multa</th>
					<th className='text-center align-middle' width="15%">Acciones</th>
				</tr>
			</thead>
			<tbody className='text-black-color'>
				{ props?.receipts?.map((item, i) =>
					<tr key={i}>
						<td className='text-center align-middle'>
							{mFormat(item?.receiptDate)}
						</td>
						<td className='text-center align-middle'>
							{showOrganismName(item?.codeOrganism)}
						</td>
						<td className='text-center align-middle'>
							{item?.tickets?.map((v) => v.ticket).flat()?.join(', ')}
						</td>
						<td className='text-right align-middle'>
							{formatterPeso.format(item?.tickets?.map((v) => v.amount)
								.flat()?.reduce((prev, next) => Number(prev) + Number(next)))
							}
						</td>
						<td className='text-right align-middle'>
							{formatterPeso.format(item?.penaltyFee)}
						</td>
						<td className='text-center align-middle px-2 m-0 table-responsive btn-group'>
							<ActionIcon size='lg' toolTipText='Detalle' icon={faSearch} onClick={() => handleModal(item)} />
							{handleSubmit &&
								<ActionIcon size='lg' toolTipText='Editar' icon={faEdit} onClick={() => editarRecibo(item, i)} />
							}
							<ActionIcon size='lg' hidden={props?.orderPayDetailData} toolTipText='Remover' icon={faTimes} className='text-danger' onClick={() => onClickRemove(i)} />
						</td>
					</tr>
				)}
			</tbody>
		</Table>
		<Modal show={showPayOrderDetailModal} onHide={() => setShowPayOrderDetailModal(false)} dialogClassName="modal-xl">
			<Modal.Header closeButton>
				<Modal.Title>Acta Recepción</Modal.Title>
			</Modal.Header>

			<Modal.Body className='mt-4 text-black-color'>
				<ModalReceptionAct
					organismList={organismList}
					data={data}
					onlyRead
					admCode={admCode}
					admNumber={admNumber}
					admYear={admYear}
				/>
				<div className='d-flex justify-content-end mt-4 mb-3'>
					<Button type='button' variant='primary' size='lg' onClick={() => setShowPayOrderDetailModal(false)}>
						Cerrar
					</Button>
				</div>
			</Modal.Body>
		</Modal>

		{handleSubmit &&
			<Modal show={showEditModal} onHide={() => setShowEditModal(false)} dialogClassName="modal-xl">
				<Modal.Header closeButton>
					<Modal.Title>Edición Acta Recepción</Modal.Title>
				</Modal.Header>

				<Modal.Body className='mt-4 text-black-color'>
					<FormContext {...hookFormMethodsModal} >
						<Form className='mt-4 text-black-color' onKeyDown={(e) => checkKeyDown(e)} onSubmit={stopPropagate(handleSubmit(_onSubmitForm))} >
							<ModalReceptionAct
								organismList={organismList}
								data={data}
								ticketObj={ticketObj}
								codeOrganism={codeOrganism}
								handleOrganismOnChange={handleOrganismOnChange}
								handleModalAdmServiceIdOnChange={handleModalAdmServiceIdOnChange}
								tipo={tipo}
								admCode={admCode}
								admNumber={admNumber}
								admYear={admYear}
							/>
							<div className='d-flex justify-content-around mt-4 mb-3'>
								<Button type='button' variant='primary' size='lg' onClick={() => setShowEditModal(false)}>
									Cancelar
								</Button>
								<Button
									type='submit'
									variant='success'
									size='lg'>
									Guardar
									<FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
								</Button>
							</div>
						</Form>
					</FormContext>
				</Modal.Body>
			</Modal>
		}
	</Col>
};

export default ReceptionActTable;