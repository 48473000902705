import * as ACTIONS from './organizationActions';
import * as SERVICES from 'src/services/organizationServices';
import * as JURISDICTION_SERVICES from 'src/services/jurisdictionServices';
import * as ADMINISTRATIVE_SERVICE_SERVICES from 'src/services/administrativeServiceServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetOrganizationListByJurisdictionId = jurisdictionId => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getOrganizationListByJurisdictionIdRequest(jurisdictionId) );

		if(accessToken){

			const response = await JURISDICTION_SERVICES.listOrganizationsByJurisdictionId( accessToken, jurisdictionId );
	
			if( response?.status == 200) {
				dispatch( ACTIONS.getOrganizationListByJurisdictionIdRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getOrganizationListByJurisdictionIdRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar las organizaciones. Detalle: ${response?.error||response}` );
			}
		}

	};
};

export const tryGetListOrganizationType = () => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getOrganizationTypesRequest() );

		if(accessToken){

			const response = await SERVICES.listOrganizationTypes( accessToken );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getOrganizationTypesRequestSuccess(response?.data) );
			}
			else {
				dispatch( ACTIONS.getOrganizationTypesRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar los tipos de organizaciones. Detalle: ${response?.error||response}` );
			}
		}

	};
};

//Oragnization
export const tryGetOrganizationList = ( page=0, size=1000) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getOrganizationListRequest() );

		if(accessToken){

			const response = await SERVICES.listOrganizations( accessToken, page, size );
	
			if( response?.status == 200) {
				dispatch( ACTIONS.getOrganizationListRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getOrganizationListRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar las organizaciones. Detalle: ${response?.error||response}` );
			}
		}

	};
};


// Organisms By Jurisdictions, Services and Exercise
export const tryOrganismsByJurisdictionsIdByServicesIdAndExerciseId = ( servicesId, exerciseId, jurisdictionsId) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getOrganismsByJurisdictionsIdByServicesIdAndExerciseIdRequest() );

		if(accessToken){

			const response = await ADMINISTRATIVE_SERVICE_SERVICES.getOrganismsByJurisdictionsIdByServicesIdAndExerciseId( accessToken, servicesId, exerciseId, jurisdictionsId );
	
			if( response?.status == 200) {
				
				dispatch( ACTIONS.getOrganismsByJurisdictionsIdByServicesIdAndExerciseIdRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getOrganismsByJurisdictionsIdByServicesIdAndExerciseIdRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar las organizaciones por jurisdicción y ejercicio. Detalle: ${response?.error||response}` );
			}
		}

	};
};