import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Form, Spinner, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import * as  LABELS from 'src/utils/label';
import { getAdministrativeOrganismClassifierData, getAdministrativeOrganismClassifierIsFetching } from 'src/redux/AdministrativeOrganism/administrativeOrganismReducer';

import { getJurisdictionListData, getJurisdictionListIsFetching } from 'src/redux/jurisdiction/jurisdictionReducer';

const AdministrativeOrganismFormComponent = () => {

    const { register, errors, administrativeOrganismToEdit } = useFormContext();

    // Validations
    let minLength = 3;
	let maxLength = 100;

	let minNumber = 1;
	let maxNumber = 9999;

	let minNumberCodeOrganismAdministrative = 1000;
	let maxNumberCodeOrganismAdministrative = 9999;
	
	const minLengthObj = {
		value: minLength,
		message: `El número mínimo de caracteres es ${minLength}.`
	};
	const maxLengthObj = {
		value: maxLength,
		message: `El número máximo de caracteres es ${maxLength}.`
	};
	const minNumberObj = {
		value: minNumber,
		message: `El valor mínimo es ${minNumber}.`
	};
	const maxNumberObj = {
		value: maxNumber,
		message: `El valor máximo es ${maxNumber}.`
	};

	const minNumberCodeOrganismAdministrativeObj = {
		value: minNumberCodeOrganismAdministrative,
		message: `El valor mínimo es ${minNumberCodeOrganismAdministrative}.`
	};
	const maxNumberCodeOrganismAdministrativeObj = {
		value: maxNumberCodeOrganismAdministrative,
		message: `El valor máximo es ${maxNumberCodeOrganismAdministrative}.`
	};

	const patternWithoutSpaceWhiteObj = {
		value: /^[^\s]+(?:$|.*[^\s]+$)/,
		message: "El valor ingresado no puede comenzar/finalizar o contener solo espacios en blanco."
	};
	const patternInvalidEmailObj = {
		value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
		message: "El formato del correo electrónico es incorrecto (debe contener el signo '@' y todo en minúscula)."
	};

	const nameRequiredMsg = 'Debe ingresar el nombre del organismo.';
    const nameShortRequiredMsg = 'Debe ingresar el nombre corto del organismo.';
    const organismClassifierRequiredMsg = 'Debe seleccionar el clasificador de organismo.';
    const codeOrganismAdministrativeRequiredMsg = 'Debe ingresar el código adminsitrativo.';
    const emailRequiredMsg = 'Debe ingresar el correo electrónico oficial del organismo.';
    const addressRequiredMsg = 'Debe ingresar la dirección del organismo.';
    const phoneRequiredMsg = 'Debe ingresar el número de teléfono del organismo.';
	const codeBudgetRequiredMsg = 'Debe ingresar el código presupuestario.';
	const jurisdictionRequiredMsg = 'Debe seleccionar una jurisdicción.';

    const organismNameValidationObj = { 
		required: nameRequiredMsg,
		minLength: minLengthObj,
		maxLength: maxLengthObj,
		pattern: patternWithoutSpaceWhiteObj 
	};
    const organismNameShortValidationObj = { 
		required: nameShortRequiredMsg,
		minLength: minLengthObj,
		maxLength: maxLengthObj,
		pattern: patternWithoutSpaceWhiteObj 
	};
    const organismClassifierValidationObj = { 
		required: organismClassifierRequiredMsg 
	};
	const jurisdictionValidationObj = { 
		required: jurisdictionRequiredMsg 
	};
    const codeOrganismAdministrativeValidationObj = { 
		required: codeOrganismAdministrativeRequiredMsg,
		min: minNumberCodeOrganismAdministrativeObj,
		max: maxNumberCodeOrganismAdministrativeObj
	};
    const emailValidationObj = { 
		required: emailRequiredMsg,
		minLength: minLengthObj,
		maxLength: maxLengthObj,
		pattern: patternInvalidEmailObj 
	};
    const addressValidationObj = { 
		required: addressRequiredMsg,
		minLength: minLengthObj,
		maxLength: maxLengthObj,
		pattern: patternWithoutSpaceWhiteObj 
	};
    const phoneValidationObj = { 
		required: phoneRequiredMsg,
		minLength: minLengthObj,
		maxLength: maxLengthObj,
		pattern: patternWithoutSpaceWhiteObj 
	};
	const codeBudgetValidationObj = { 
		required: codeBudgetRequiredMsg,
		min: minNumberObj,
		max: maxNumberObj
	};
	
    // Classifier Administrative Organism
    const classifierOrganizationAllData = useSelector(state => getAdministrativeOrganismClassifierData(state));
    const classifierOrganizationIsFetching = useSelector(state => getAdministrativeOrganismClassifierIsFetching(state));

    //Jurisdiction
    const jurisdictionAllData = useSelector(state => getJurisdictionListData(state))?.records?.sort((a, b) => a.code > b.code ? 1 : -1);
    const jurisdictionIsFetching = useSelector(state => getJurisdictionListIsFetching(state));

	return <>
       
		<Form.Group as={Row}>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4} >
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				{LABELS.organismClassifier}:
			</Form.Label>
			<Col sm='5'>
				<Form.Control
					as='select'
					className='text-black-color'
					name='organismClassifier'
					disabled={classifierOrganizationIsFetching}
					ref={register(organismClassifierValidationObj)}
					defaultValue={administrativeOrganismToEdit?.organismClassifier?.id} 
				>
					<option value={''} selected>Seleccionar</option>
					{
						classifierOrganizationAllData?.map(item =>
							<option key={item?.id} value={item.id} selected={ administrativeOrganismToEdit?.organismClassifier?.id == item.id ? true : false } >
								({`${item.shortName || ''}`}) - {`${item.name || ''}`}
							</option>)
					}
				</Form.Control>
				<FormFieldError errors={errors?.organismClassifier} />
			</Col>
			<span className={classifierOrganizationIsFetching ? '' : 'hidden'}>
				<Spinner className='spinner-border text-danger' animation='border' size='sm' />
			</span>
		</Form.Group>

		<Form.Group as={Row}>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4} >
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				{LABELS.jurisdiction}:
			</Form.Label>
			<Col sm='5'>
				<Form.Control
					as='select'
					className='text-black-color'
					name='jurisdiction'
					disabled={jurisdictionIsFetching}
					ref={register(jurisdictionValidationObj)}
					defaultValue={administrativeOrganismToEdit?.jurisdiction?.id}
				>
					<option value={''} selected>Seleccionar</option>
					{
						jurisdictionAllData?.map(item =>
							<option key={item?.id} value={item.id} selected={ administrativeOrganismToEdit?.jurisdiction?.id == item.id ? true : false }>
								{item.code} - {item.name}
							</option>)
					}
				</Form.Control>
				<FormFieldError errors={errors?.jurisdiction} />
			</Col>
			<span className={jurisdictionIsFetching ? '' : 'hidden'}>
				<Spinner className='spinner-border text-danger' animation='border' size='sm' />
			</span>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalCodOrganism'>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				{LABELS.budgetCode}:
			</Form.Label>
			<Col sm={5}>
				<Form.Control
					ref={register(codeBudgetValidationObj)}
					type='number'
					placeholder={LABELS.budgetCode}
					name='budgetCode'
					id='budgetCode'
					defaultValue={administrativeOrganismToEdit?.codeBudget}
				/>
				<FormFieldError errors={errors?.budgetCode} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalCodOrganism'>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				{LABELS.administrativeCode}:
			</Form.Label>
			<Col sm={5}>
				<Form.Control
					ref={register(codeOrganismAdministrativeValidationObj)}
					type='number'
					placeholder={LABELS.administrativeCode}
					name='organismAdministrativeCode'
					id='organismAdministrativeCode'
					defaultValue={administrativeOrganismToEdit?.codeAdministrative}
				/>
				<FormFieldError errors={errors?.organismAdministrativeCode} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalName'>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				{LABELS.nameOrganism}:
			</Form.Label>
			<Col sm={5}>
				<Form.Control
					ref={register(organismNameValidationObj)}
					type='text'
					placeholder={LABELS.nameOrganism}
					name='nameOrganism'
					id='nameOrganism'
					defaultValue={administrativeOrganismToEdit?.name}
				/>
				<FormFieldError errors={errors?.nameOrganism} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalName'>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				{LABELS.nameShortOrganism}:
			</Form.Label>
			<Col sm={5}>
				<Form.Control
					ref={register(organismNameShortValidationObj)}
					type='text'
					placeholder={LABELS.nameShortOrganism}
					name='shortNameOrganism'
					id='shortNameOrganism'
					defaultValue={administrativeOrganismToEdit?.shortName}
				/>
				<FormFieldError errors={errors?.shortNameOrganism} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalDirection'>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Dirección:
			</Form.Label>
			<Col sm={5}>
				<Form.Control
					ref={register(addressValidationObj)}
					type='text'
					placeholder={LABELS.direction}
					name='direction'
					id='direction'
					defaultValue={administrativeOrganismToEdit?.contact?.address}
				/>
				<FormFieldError errors={errors?.direction} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalphone'>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Teléfono:
			</Form.Label>
			<Col sm={5}>
				<Form.Control
					ref={register(phoneValidationObj)}
					type='text'
					placeholder={LABELS.phone}
					name='phone'
					id='phone'
					defaultValue={administrativeOrganismToEdit?.contact?.phone}
				/>
				<FormFieldError errors={errors?.phone} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalEmail'>
			<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Correo electrónico oficial:
			</Form.Label>
			<Col sm={5}>
				<Form.Control
					ref={register(emailValidationObj)}
					type='email'
					placeholder={'Correo electrónico oficial'}
					name='email'
					id='email'
					defaultValue={administrativeOrganismToEdit?.contact?.mail}
				/>
				<FormFieldError errors={errors?.email} />
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalEmail'>
				<Form.Control
					ref={register}
					type='hidden'
					name='id'
					id='id'
					defaultValue={administrativeOrganismToEdit?.id}
				/>
		</Form.Group>

		<Form.Group as={Row} controlId='formHorizontalEmail'>
				<Form.Control
					ref={register}
					type='hidden'
					name='contactID'
					id='contactID'
					defaultValue={administrativeOrganismToEdit?.contact?.id}
				/>
		</Form.Group>
                     
    </>
};
export default AdministrativeOrganismFormComponent;