import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import { push } from 'connected-react-router';
import { Container, Card, Form, Spinner, Col, Row, Button, FormGroup, FormLabel  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import  OPFormFactory  from 'src/components/forms/paymentOrder/OPFormFactory';
import { showWarning } from 'src/redux/globalData/globalDataActionCreator';

import { clearDTOFromEmptyData, getOrderPayTypeValueToShow } from 'src/utils/utils';
import { tryOrderPayById, tryPutOrderPay, tryListPayOrderTypes } from 'src/redux/orderPay/orderPayActionCreator';
import { setSelectedTypeOrderPay, clearSelectedTypeOrderPay } from 'src/redux/orderPay/orderPayActions';
import { getOrderType, getOrderPayEditIsFetching, getOrderPayToEdit, getPayOrderTypeListData } from 'src/redux/orderPay/orderPayReducer';
import { ORDER_PAY_LIST } from 'src/utils/constants';
import { isNotEmptyArray } from 'src/services/validationService';
import { clearOneListAdministrativeDocumentData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { setOrderPayToEdit } from '../../../redux/orderPay/orderPayActions';
import { AdministrativeServiceByUserAndExerciseData } from 'src/utils/administrativeServiceUtils';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const OrderPayEditPage = () => {
    const dispatch = useDispatch();
    const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;
    const orderPay = useSelector( state => getOrderPayToEdit(state));
    const [formValuesState, setFormValuesState] = useState({legalInstruments: [], legalInstrument:{}});
    const [expedientesSeleccionados, setExpedientesSeleccionados] = useState([]);
    const [expedientesAsociados, setExpedientesAsociados] = useState([]);
    const [settlementOfAssets, setSettlementOfAssets] = useState(orderPay?.wageManager || {});
    const [proveedorSelected, setProveedorSelected] = useState();
    const [admServiceId, setAdmServiceId] = useState();
    const [importe, setImporte] = useState(0); //trae el importe total de la suma de las partidas
    const [admCode, setAdmCode] = useState('');
    const [admNumber, setAdmNumber] = useState('');
    const [admYear, setAdmYear] = useState('');
    const isFetching = useSelector(state => getOrderPayEditIsFetching(state));
    let orderType= useSelector( state => getOrderType(state) );
    const payOrderTypeList = useSelector(state => getPayOrderTypeListData(state)) || [];

    const OPHaberes = orderType == 'ORDEN_DE_PAGO_DE_HABERES';

    const [allowSaveAmountValidation, setAllowSaveAmountValidation] = useState();
    const allowSaveByOrderType = orderType === 'ORDEN_DE_PAGO_DE_PROVEEDORES';
    const hasReceipts = isNotEmptyArray(formValuesState?.beneficiaries?.[0]?.receipts);
    
    const allowSaveButton = isFetchingAdministrativeDocument || isFetching || (allowSaveByOrderType && (!hasReceipts || !allowSaveAmountValidation));
    const validationSettlementOfAssets = ((orderType === 'ORDEN_DE_PAGO_DE_HABERES') && !allowSaveAmountValidation) ? false : true;

    const isFetchingAdministrativeDocument = useSelector(state => getAdministrativeDocumentIsFetching(state));

    const amountsShowValidation = (totalReceiptAmount, importe) => {
        if(totalReceiptAmount){
            const resultCondition = totalReceiptAmount.toFixed(2) === importe?.toFixed(2)
            setAllowSaveAmountValidation(resultCondition);
            return resultCondition;
        }
        return false;
    };
    const _expedientesAsociados = expedientesAsociados?.filter(i => i.selected);
    const isEmptyAnySelection = !(isNotEmptyArray(expedientesSeleccionados) || isNotEmptyArray(_expedientesAsociados));

    const administrativeServiceData = AdministrativeServiceByUserAndExerciseData();
    const admServiceCode = administrativeServiceData?.filter(item => item?.id == orderPay?.administrativeServiceId)?.[0]?.code;

    const validateOrderPay = (listParam) => {

        const receiptType = formValuesState?.beneficiaries?.[0]?.receipts?.[0]?.type;
        const validate = orderType == "ORDEN_DE_PAGO_DE_PROVEEDORES" && receiptType == "CERTIFICADO_DE_OBRAS_PUBLICAS";
    
        if (validate){
            const isValid = isNotEmptyArray(listParam?.filter(item => (item?.mappedCompleteNumber?.partidaPrincipal == "052") || (item?.presupuestoResponse?.completeNumber?.partidaPrincipal == "052")));
            if (!isValid) showWarning(dispatch, `Error editar la orden de pago. Existe un certificado de obra sin una Partida Principal 052 seleccionada.`);
            return isValid;
        }else{
            return true;
        }
    };

    const onSubmitForm = (data) => {

        if(data.codigo.length !== 4) return false;

        let params;

        let wageManager;

        if(orderType == 'ORDEN_DE_PAGO_DE_HABERES'){
            wageManager = {
                id: settlementOfAssets?.id,
                netAmount: settlementOfAssets?.netAmount,
                wageManagerDetails: settlementOfAssets?.wageManagerDetails?.map(item => {
                    return {
                        id: !item?.isNew ? item?.id : undefined,
                        concept: item?.concept,
                        subConcept: item?.subConcept,
                        item: item?.item,
                        code: item?.code,
                        periodAmount: item?.periodAmount,
                        changeAmount: item?.changeAmount
                    };
                })
            };
        };

        if(orderType == 'ORDEN_DE_PAGO_DEPOSITO_INDEBIDO'){
             params = [{
                "amount": data.importe,
            }]
        }else{
            const selectedExpedientObj = expedientesSeleccionados.map((item)=> ({
                'presupuestoResponse':{
                    "amount": item.amount,
                    "subCodeId": orderType == 'ORDEN_DE_PAGO_DE_HABERES' ? item.id : item.subCodeId,
                    "subPartialBudget": (OPHaberes && item?.subPartialBudgetSelected?.id) ? {"id": item?.subPartialBudgetSelected?.id} : null
                }
            }));

            const asossiateExpedientObj = _expedientesAsociados.map((item)=> ({
                'presupuestoResponse':{
                    "amount": item.presupuestoResponse.amount,
                    "subCodeId": item.presupuestoResponse.subCodeId,
                    "subPartialBudget": (OPHaberes && item?.presupuestoResponse?.subPartialBudget?.id) ? {"id": item?.presupuestoResponse?.subPartialBudget?.id} : null
                }
            }));
            params = selectedExpedientObj.concat(asossiateExpedientObj);
        }
        const details = params;

        const administrativeDocument = {
            year: data.anio,
            number: data.correlativo,
            codeOrganism: data.codigo,
            title: data.title || "Orden de pago"
        }
        const legalInstrument = [...formValuesState.legalInstruments].shift();
        const legalInstruments = [...formValuesState.legalInstruments];
        legalInstruments.shift();
       
        let newFormValues = {};

        if(orderType == "ORDEN_DE_PAGO_DE_PROVEEDORES"){
            const type = formValuesState?.beneficiaries?.[0]?.type;
            newFormValues = {
                legalInstrument,
                legalInstruments,
                beneficiaries: formValuesState?.beneficiaries?.[0]?.receipts?.map(receiptObj => {
                    let receiptFormat = {
                        type: type,
                        cuit: receiptObj?.cuit,
                        persona: {companyName: receiptObj?.companyName},
                        receipts: [
                            {...receiptObj}
                        ]
                    };
                    delete receiptFormat?.receipts?.[0]?.persona;
                    delete receiptFormat?.receipts?.[0]?.cuit;
                    delete receiptFormat?.receipts?.[0]?.companyName;
                    delete receiptFormat?.receipts?.[0]?.beneficiaryValidator;
                    return receiptFormat;
                })
            };
        }else {
            newFormValues = {
                legalInstrument,
                legalInstruments,
                beneficiaries: formValuesState.beneficiaries
            };
        };

        const clearInstruments = clearDTOFromEmptyData(newFormValues);
        clearInstruments.beneficiaries ?clearInstruments.beneficiaries[0].id = orderPay?.beneficiaries?.[0]?.id:null
        const paymentOrderDto = {
            administrativeServiceId: orderPay.administrativeServiceId,
            administrativeDocument,
            datePaymentOrder: data.date,
            description: data.description,
            observations: data?.observations?.trim(),
            details,
            year: data.fiscalYearId,
            number: data.number,
            type: orderType,
            wageManager,
            ...clearInstruments
        };

        if (validateOrderPay(expedientesSeleccionados?.concat(_expedientesAsociados))){
            dispatch(tryPutOrderPay(orderPay.id, paymentOrderDto)).then(response => {
                if ( !(response?.data?.hasError) ) {
                    dispatch(push(ORDER_PAY_LIST));
                }
            });
        }
    }

    Object.assign(hookFormMethods, { orderPay, expedientesAsociados, setExpedientesAsociados, 
        expedientesSeleccionados, setExpedientesSeleccionados,
        settlementOfAssets, setSettlementOfAssets,
        setProveedorSelected,  importe, setImporte,
        formValuesState, setFormValuesState, amountsShowValidation, setAllowSaveAmountValidation,
        orderType, admServiceId, setAdmServiceId, admServiceCode, administrativeServiceData,
        admCode, admNumber, admYear, setAdmCode, setAdmNumber, setAdmYear
    });

    useEffect(() => {
        dispatch(clearSelectedTypeOrderPay());
        dispatch(tryListPayOrderTypes());
        dispatch(clearOneListAdministrativeDocumentData());
        dispatch(setSelectedTypeOrderPay(orderPay?.type));
        setAdmServiceId(orderPay?.administrativeServiceId);
    //     dispatch(setOrderPayToEdit(response));
        dispatch(tryOrderPayById(orderPay?.id)).then((response) => {
            const formattedBeneficiaries = [];
            orderPay?.beneficiaries?.map(beneficiarie => {
                beneficiarie?.receipts?.map(receipt => {
                    let receitCuit = {
                        ...receipt,
                        cuit: beneficiarie?.persona?.cuit?.toString(),
                        companyName: beneficiarie?.persona?.companyName,
                        beneficiaryValidator: beneficiarie?.persona?.beneficiaryValidator,
                        providerNumber: beneficiarie?.persona?.providerNumber
                    };
                    formattedBeneficiaries.push(receitCuit);
                });
            });
            setFormValuesState({
                legalInstruments: [orderPay?.legalInstrument, ...orderPay?.legalInstruments], 
                legalInstrument:{},
                beneficiaries: [{
                    type: 'PROVEEDOR',
                    receipts: formattedBeneficiaries
                }]
            });
            dispatch(setOrderPayToEdit(response));
            // response?.legalInstruments?.push(response?.legalInstrument)
        });
    }, []);

    return <Container fluid className='mb-5'>
         <Card className='mb-5'>
            <Card.Header className='d-flex justify-content-between'>
                <h1 className="h6 mt-1 mb-0">Editar Orden de Pago</h1>
                <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#ejecuci%C3%B3n-de-presupuesto---orden-de-pago'>
                    <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                    <small>Ver manual de uso</small> 
                </a>
            </Card.Header>
            <Card.Body>
                <span className='text-danger d-flex mandatory-label font-weight-bold font-italic'>
                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
					Obligatorio
				</span>

                <FormContext {...hookFormMethods } >
                    <Form className='mt-4 text-black-color' onSubmit={handleSubmit(onSubmitForm)}>

                    <FormGroup as={Row}>
						<FormLabel className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
							<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
							Tipo de Orden de Pago
						</FormLabel>
						<Col sm={4}>
							<Form.Control
								as="select" disabled
								name="payOrderType"
								className={"text-black-color"}
								required>
								<option value={''} selected>Seleccione una opcion...</option>
								{
									payOrderTypeList?.map(item => (
                                        <option className='text-black-color' value={item} key={item.id} selected={item == orderPay?.type}>
                                            {getOrderPayTypeValueToShow(item)}
                                        </option>
									))
								}
							</Form.Control>
						</Col>
					</FormGroup>

                        <OPFormFactory formName={orderPay?.type}/>

                        <div className='d-flex justify-content-around mt-4 mb-3'>
                            <Button type='button' variant='primary' size='lg' onClick={() => dispatch(push(ORDER_PAY_LIST))}>
                                Cancelar
                            </Button>
                            <span className={isFetching ? '' : 'hidden'}>
                                <Spinner animation='border' />
                            </span>
                            <Button
                                type='submit'
                                variant='success'
                                size='lg'
                                disabled={ isEmptyAnySelection || allowSaveButton  || !validationSettlementOfAssets}
                                >
                                    Guardar
                                <FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
                            </Button>
                        </div>

                    </Form>
                </FormContext>
            </Card.Body>
        </Card>
    </Container>
};

export default OrderPayEditPage;