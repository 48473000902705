import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faTrash, faExclamationTriangle, faArrowLeft, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import ActionIcon from 'src/components/general/ActionIcon';
import { dateToStringFormatedToShowARG } from 'src/utils/utils'

import { getServicesManagementSelectedExercise, getServicesByExerciseIdData, getServicesByExerciseIdIsFetching, getActiveServiceFromExerciseIsFetching } from 'src/redux/exercise/exerciseReducer';
import { tryGetServicesByExerciseId, tryAddServiceToExercise, tryDeleteServiceFromExercise, tryActiveServiceFromExercise } from 'src/redux/exercise/exerciseActionCreator';
import { tryListAllAdministrativeServices } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getAdministrativeServiceListAllData, getAdministrativeServiceListAllIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { EXERCISES_LIST } from 'src/utils/constants';

import AppLoading from 'src/components/common/AppLoading';

const ExerciseServiceAsociation = props => {

	const dispatch = useDispatch();

	// Exercise data
	const selectedExercise = useSelector( state => getServicesManagementSelectedExercise(state) );

	// All services list
	const allAdministrativeServices = useSelector( state => getAdministrativeServiceListAllData(state) )?.records;
	const hasAllAdministrativeServices = isNotEmptyArray(allAdministrativeServices);
	const allAdministrativeServicesIsFecthin = useSelector( state => getAdministrativeServiceListAllIsFetching(state) );
	const onChangeService = serviceData => {
		swal({
			title: 'Confirmación',
			text: '¿Seguro desea agregar el servicio?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((userAccept) => {
				if (userAccept) {
					dispatch( tryAddServiceToExercise( selectedExercise?.id , serviceData?.id ) )
						.then( response => {
							if( response?.status == 200 ) {
								getServicesByCurrentExercise();
							}
						});
				}
				else {
					dispatch( tryListAllAdministrativeServices() );
				}
			});
		
	};
	
	// Services by exercise
	const servicesData = useSelector( state => getServicesByExerciseIdData(state) );
	const hasServicesData = isNotEmptyArray(servicesData);
	const servicesDataIsFetching = useSelector( state => getServicesByExerciseIdIsFetching(state) );
	const serviceStatusChangeIsFetching = useSelector( state => getActiveServiceFromExerciseIsFetching(state) );

	//Message
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
	const loadingPlaceholder = 'Cargando...';
	const servicePlaceholder = 'Seleccione un servicio...';
	const noServicePlaceholder = 'No hay servicios seleccionables.';

	// Remove service from exercise
	const onClickRemoveServiceFromExercise = serviceData => {

		swal({
			title: 'Confirmación',
			text: '¿Seguro desea remover el servicio?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((userAccept) => {
				if (userAccept) {
					dispatch( tryDeleteServiceFromExercise( selectedExercise.id, serviceData?.administrativeService?.id ) )
						.then( response => {
							if( response?.status == 200 ) {
								getServicesByCurrentExercise();
							}
						});
				}
			});
		
	};

	const onClickActiveServiceFromExercise = serviceData => {

		const serviceCode = serviceData?.administrativeService?.code
		const serviceName = serviceData?.administrativeService?.name;
		const open = serviceData?.dateClose ? true : false; 

		const activeServiceMessage = `¿Está seguro que desea activar el servicio ${serviceCode}: ${serviceName}?`;
		const closeServiceMessage = `¿Está seguro que desea cerrar el servicio ${serviceCode}: ${serviceName}?`;

		const actionMessage = open ? activeServiceMessage : closeServiceMessage;

		swal({
            title: 'Confirmación',
            text: actionMessage,
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
					dispatch(tryActiveServiceFromExercise( selectedExercise?.id , serviceData?.administrativeService?.id ) ).then(response => {
						loadAllData();
					});
				}
			});
		
	};

	const loadAllData = () => {
		dispatch( tryListAllAdministrativeServices() );
		dispatch( tryGetServicesByExerciseId(selectedExercise.id) );
    }

    useEffect(loadAllData, []);

	// Back button
	const onClickBackButton = () => {
		dispatch( push(EXERCISES_LIST) );
	};

	const getServicesByCurrentExercise = () => {
		if( selectedExercise ) {
			dispatch( tryGetServicesByExerciseId(selectedExercise.id) );
		}
	};

	useEffect( getServicesByCurrentExercise, [selectedExercise]);

	

	return <Container fluid>
		<Card>

			<PageTitle text='Ejercicios'/>

			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Gestión de servicios
					</Card.Header>
					<Card.Body>
						<h6 className='text-black-color mt-2 mb-3'>
							Ejercicio {selectedExercise?.year||''}
						</h6>
						<Table striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center'>Código</th>
									<th className='text-center'>Descripción</th>
									<th className='text-center'>Estado</th>
									<th className='text-center'>Acción</th>
								</tr>
							</thead>
							<tbody>
								{
									hasServicesData
									?
										<>
											{
												servicesData.map( service => (
													<tr>
														<td className='text-center text-black-color align-middle'>
															{service?.administrativeService?.code}
														</td>
														<td className='text-center text-black-color align-middle'>
														{service?.administrativeService?.name}
														</td>
														<td className='text-center text-black-color align-middle'>
														{
															service?.dateClose 
															?
															<>Cerrado</>
															:
															<>Activo</>
														}
														</td>
														<td className='text-center text-black-color align-middle'> 
															{
																service?.dateClose 
																?
																<ActionIcon size='lg' id='activar' toolTipText='Activar' icon={faCheckCircle} onClick={() =>  onClickActiveServiceFromExercise(service)}/>
																:	
																<ActionIcon size='lg' id='desactivar' toolTipText='Cerrar' icon={faTimesCircle} onClick={() => onClickActiveServiceFromExercise(service)}/>
															}
															<ActionIcon size='lg' id='remove' toolTipText='Remover' icon={faTrash} onClick={() => onClickRemoveServiceFromExercise(service)}/>
														</td>
													</tr>
												))
											}
										</>
									:
										<tr>
											<td colSpan='4' className='text-center'>
												{
													servicesDataIsFetching
													?
														<Spinner animation='border' variant='success' className='my-3'/>
													:
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3'/>
															No hay servicios asociados
														</Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>


						<div class='d-flex justify-content-around mt-4 mb-3'>
							<Button type='submit' variant='danger' size='lg' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2'/>
								Volver
							</Button>
						</div>


					</Card.Body>
				</Card>
				<AppLoading isLoading={serviceStatusChangeIsFetching} />
			</Container>
		</Card>
	</Container>;
};

export default ExerciseServiceAsociation;
