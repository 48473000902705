import * as ACTIONS from './administrativeDocumentActions';
import * as SERVICES from 'src/services/administrativeDocumentServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showError, showWarning } from 'src/redux/globalData/globalDataActionCreator';

// Try list administrative document
export const tryGetAdministrativeDocument = (params = {}) => {
	return async (dispatch, getState) => {

		if (!params?.year || !params?.number || !params?.organismCode) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listOneAdministrativeDocumentRequest());

		if(accessToken){

			const response = await SERVICES.getAdministrativeDocument(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.listOneAdministrativeDocumentRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.listOneAdministrativeDocumentRequestFailure(response?.error || response));
				showError(dispatch, `Error al buscar el expediente. Detalle: ${response?.response?.data?.message || response?.error || response}`);
				return response;
			}
		}

	};
};

// Try list administrative document by period id v2
export const tryGetAdministrativeDocumentByPeriodId = (params = {}) => {
	return async (dispatch, getState) => {

		if (!params?.year || !params?.number || !params?.organismCode) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listOneAdministrativeDocumentRequest());

		if(accessToken){

			const response = await SERVICES.getAdministrativeDocumentByPeriodId(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.listOneAdministrativeDocumentRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.listOneAdministrativeDocumentRequestFailure(response?.error || response));
				showError(dispatch, `Error al buscar el expediente. Detalle: ${response?.response?.data?.message || response?.error || response}`);
				return response;
			}
		}

	};
};

export const tryListGetAdministrativeDocument = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listAllAdministrativeDocumentRequest());

		if(accessToken){

			const response = await SERVICES.getAllAdministrativeDocument(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.listAllAdministrativeDocumentRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.listAllAdministrativeDocumentRequestFailure(response?.error || response));
				showError(dispatch, `Error al cargar los expediente. Detalle: ${response?.response?.data?.message || response?.error || response}`);
				return response;
			}
		}

	};
};

// Try list administrative document by Order Pay 
export const tryGetAdministrativeDocumentByOrderPay = (page=1, pageSize=10, params = {}) => {
	return async (dispatch, getState) => {

			const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listOneAdministrativeDocumentByOrderPayRequest());

		const response = await SERVICES.getAdministrativeDocumentByOrderPay(accessToken, page, pageSize, params);

		if (response?.status == 200) {
			dispatch(ACTIONS.listOneAdministrativeDocumentByOrderPayRequestSuccess(response?.data));
			return response?.data;
		}
		else {
			dispatch(ACTIONS.listOneAdministrativeDocumentByOrderPayRequestFailure(response?.error || response));
			showError(dispatch, `Error al buscar el expediente para Orden de Pago. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			return response;
		}
	};
};

// Try list administrative document history
export const tryGetAdministrativeDocumentHistory = (params = {}) => {
	return async (dispatch, getState) => {

		if (!params?.year || !params?.number || !params?.organismCode || !params?.period_id) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listAdministrativeDocumentHistoryRequest());

		if(accessToken){
			
			const response = await SERVICES.getAdministrativeDocumentHistory(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.listAdministrativeDocumentHistoryRequestSuccess(response?.data));
				return response?.data;
			} else if (response?.status == 204) {
				dispatch(ACTIONS.listAdministrativeDocumentHistoryRequestSuccess(response?.data));
				showWarning(dispatch, 'El expediente ingresado no existe.');
				return response?.status;
			}
			else {
				dispatch(ACTIONS.listAdministrativeDocumentHistoryRequestFailure(response?.error || response));
				showError(dispatch, `Error al buscar el historial del documento administrativo. Detalle: ${response?.response?.data?.message || response?.error || response}`);
				return response;
			}
		}

	};
};

// Try latest affectation transaction
export const tryGetLatestAffectationTransaction = (params = {}) => {
	return async (dispatch, getState) => {

		if (!params?.year || !params?.number || !params?.organismCode) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listLatestAffectationTransactionRequest());

		if(accessToken){
			const response = await SERVICES.getLatestAffectationTransaction(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.listLatestAffectationTransactionRequestSuccess(response?.data));
				return response?.data;
			} else if (response?.status == 204) {
				dispatch(ACTIONS.listLatestAffectationTransactionRequestSuccess(response?.data));
				/* showWarning(dispatch, `Error al buscar la última transacción de la Afectación. Detalle: ${response?.response?.data?.message || response?.error || response}`); */
				return response?.data;
			}
			else {
				dispatch(ACTIONS.listLatestAffectationTransactionRequestFailure(response?.error || response));
				showError(dispatch, `Error al buscar la última transacción de la Afectación. Detalle: ${response?.response?.data?.message || response?.error || response}`);
				return response;
			}
		}

	};
};

// Try expedient by id budget credits actions
export const tryGetExpedientByFilter = (params = {}) => {
	
	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.expedientByFilterRequest ());

		const response = await SERVICES.getExpedientByFilters(accessToken, params);

		if (response?.status == 200) {
			dispatch(ACTIONS.expedientByFilterSuccess(response?.data));
			return response?.data;
		}
		else {
			dispatch(ACTIONS.expedientByIdSubCodeFailure(response?.error || response));
			showError(dispatch, `Error al listar expedientes de la partida. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			return response;
		}
	};
};

// Try administrative document subcode related actions
export const tryGetAdministrativeDocumentSubcodeDetails = (params = {}) => {

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.administrativeDocumentSubcodeRelatedRequest ());

		const response = await SERVICES.getAdministrativeDocumentSubcodeRelated(accessToken, params);

		if (response?.status == 200) {
			dispatch(ACTIONS.administrativeDocumentSubcodeRelatedSuccess(response?.data));
			return response?.data;
		}
		else {
			dispatch(ACTIONS.administrativeDocumentSubcodeRelatedFailure(response?.error || response));
			showError(dispatch, `Error al listar expedientes de la partida. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			return response;
		}
	};
};

// Try administrative document RP actions
export const tryAllExpedientByServiceRP = (params = {}) => {

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.administrativeDocumentRPConvertSelectedData ());

		const response = await SERVICES.getAllExpedientByServiceRP(accessToken, params);

		if (response?.status == 200) {
			dispatch(ACTIONS.administrativeRPConvertSelectedDataSuccess(response?.data?.data));
			return response?.data?.data;
		}
		else {
			dispatch(ACTIONS.administrativeDocumentRPConvertSelectedDataFailure(response?.error || response));
			showError(dispatch, `Error al listar expedientes de la partida. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			return response;
		}
	};
};

// Try administrative documents modifications and preload details actions
export const tryGetAdministrativeDocumentModifPreloadDetails = (params = {}) => {

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.administrativeDocumentModifPreloadDetailsRequest ());

		const response = await SERVICES.getAdministrativeDocumentModifPreloadDetails(accessToken, params);

		if (response?.status == 200) {
			/* dispatch(ACTIONS.administrativeDocumentModifPreloadDetailsSuccess(response?.data)); */
			dispatch(ACTIONS.administrativeDocumentModifPreloadDetailsSuccess(response?.data[0]?.data));
			return response?.data[0]?.data;
		}
		else {
			dispatch(ACTIONS.administrativeDocumentModifPreloadDetailsFailure(response?.error || response));
			showError(dispatch, `Error al listar expedientes de la partida. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			return response;
		}
	};
};

// Try get administrative document availables
export const tryGetAdministrativeDocumentAvailables= (params) => {
	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.administrativeDocumentAvailableRequest());

		const response = await SERVICES.getAdministrativeDocumentAvailables(accessToken, params);

		if (response?.status == 200) {
			dispatch(ACTIONS.administrativeDocumentAvailableRequestSuccess(response?.data));
			return response?.data;
		}
		else {
			dispatch(ACTIONS.administrativeDocumentAvailableRequestFailure(response?.error || response));
			showError(dispatch, `Error al buscar el expediente para Orden de Pago. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			return response;
		}
	};
};