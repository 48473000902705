import React, { useState } from 'react';
import { Container, Image, Modal, Button, Table } from 'react-bootstrap';
import { useEffect } from 'react';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { getUserListData } from 'src/redux/user/userReducer';
import { getEmail } from 'src/redux/login/loginReducer';
import banner1 from 'src/assets/images/image-vector-1.svg';
import banner2 from 'src/assets/images/image-vector-2.png';
import banner3 from 'src/assets/images/image-vector-3.svg';
import { config } from 'src/env.js'
import { tryNotificationByUserListRequest, tryPostNotificationByUser } from 'src/redux/notification/notificationActionCreator';
import { getNotificationByUserListData } from 'src/redux/notification/notificationReducer';
import { dateNeutralFormatedToShowARG } from 'src/utils/utils';
import swal from 'sweetalert';
import { clearNotificationByUserList } from 'src/redux/notification/notificationActions';

const IndexPage = props => {
	const dispatch = useDispatch();
	const email = useSelector(state => getEmail(state));
	const listUserData = useSelector(state => getUserListData(state));
	const userFound = listUserData?.records?.find(item => item.email == email);  
	
	console.log("ENV: ", config);

	let idUser = listUserData?.records[0]?.id
	const notificationByUser = useSelector(state => getNotificationByUserListData(state))
	const [showNotificationModal,setShowNotificationModal] = useState(true)
	const onClickDelete = notification => {
		let idNotif = notification.id;
		swal({
			title: 'Confirmación',
			text: '¿Desea marcar como leído el mensaje: ' + notification?.subject + '?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((willAccept) => {
			if(willAccept) {
				dispatch(tryPostNotificationByUser(idUser, idNotif)).then(() => {
					dispatch(clearNotificationByUserList());
					dispatch(tryNotificationByUserListRequest(idUser));
				});
			}
		});
	}
	
	useEffect(() => {
		if (userFound) {
			dispatch(tryListAllServiceAdministrativeByUser(userFound.id));
			dispatch(tryNotificationByUserListRequest(idUser))
		}
	}, [userFound]);

	// useEffect(() => {
	// 	dispatch(tryNotificationByUserListRequest(idUser))
	// }, [notificationByUser])

	return (<>
		{(notificationByUser?.length > 0) &&
			<Modal
				show={showNotificationModal}
				dialogClassName="modal-xl"
			>
				<Modal.Header>
					<Modal.Title className='text-uppercase'>NOTIFICACIONES</Modal.Title>
				</Modal.Header>
				<Table striped hover className="py-2">
					<thead>
						<tr>
							<th className='text-center' width='15%'>Fecha</th>
							<th className='text-center' width='25%'>Asunto</th>
							<th className='text-center' width='50%'>Mensaje</th>
							<th className='text-center' width='10%'>Acción</th>
						</tr>
					</thead>
					<tbody className='text-black-color'>
						{notificationByUser.map(item =>
							<tr >
								<td className='text-center py-1'>
									{dateNeutralFormatedToShowARG(item?.createdDate.split("T")[0])}
								</td>
								<td className='text-center py-1'>
									{item?.subject}
								</td>
								<td className='text-center py-1'>
									{item?.body}
								</td>
								<td className='text-center py-1'>
									<Button className="btn btn-success btn-sm" onClick={() => onClickDelete(item)}>
										Aceptar
									</Button>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowNotificationModal(false)}>
						Cerrar
					</Button>
				</Modal.Footer>
			</Modal>
		}

		<Container fluid className='IndexPage__container rounded mt-3' >
			<Image src={banner1} className='IndexPage__img' />
			<Image src={banner2} className='IndexPage__img' />
			<Image src={banner3} className='IndexPage__img' /> 
		</Container>

	</>);
};

export default IndexPage;