import * as ACTIONS from './affectationActions';
import * as SERVICES from 'src/services/affectationServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

import { isNotEmptyArray } from 'src/services/validationService';
import { setRevertAffectation } from 'src/redux/affectation/affectationActions';

export const tryGetAffectationTypesList = (params={}) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getAffectationTypesRequest() );

		if(accessToken){
			
			const response = await SERVICES.listAffectationTypes( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getAffectationTypesRequestSuccess(response?.data) );
			}
			else {
				dispatch( ACTIONS.getAffectationTypesRequestFailure(response?.error||response) );
				showError( dispatch, `Error al cargar los tipos de afectación. Detalle: ${response?.response?.data?.message||response?.error||response}` );
			}
		}

	};
};

export const createAffectation = dataToSend => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.createAffectationRequest() );

		if(accessToken){

			const response = await SERVICES.postAffectation( accessToken, dataToSend );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.createAffectationRequestSuccess(response?.data) );
				return response;
			}
			else {
				dispatch( ACTIONS.createAffectationRequestFailure(response?.error||response) );
				return response?.response;
			}
		}

	};
};

//reverse affectation by url
export const reverseAffectation = (url, params) => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.createDisaffectionRequest());

		if (accessToken) {
			const response = await SERVICES.postDisaffection(accessToken, url, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.createDisaffectionRequestSuccess(response?.data));
				showSuccess(dispatch,  `¡Reversión de la afectación de la partida generada correctamente!`);
				dispatch(setRevertAffectation(response));
				return response;
			}
			else {
				dispatch(ACTIONS.createDisaffectionRequestFailure(response?.response?.data||response));
				showError(dispatch, `Error al revertir la afectación. Detalle: ${response?.response?.data?.message}`);
			}
			return response
		}
	};
};

//convert expedient to passive remaining
export const convertExpedientToPassiveRemaining = (params) => {

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.createConversionToPassiveRemainingRequest());

		if (accessToken) {

			const response = await SERVICES.postConvertionToPassiveRemaining(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.createConversionToPassiveRemainingRequestSuccess(response?.data));
				showSuccess(dispatch,  `¡Conversión a Residuos Pasivos realizada correctamente!`);
				return response;
			}
			else {
				dispatch(ACTIONS.createConversionToPassiveRemainingRequestFailure(response?.response?.data||response));
				showError(dispatch, `Error al convertir a Residuos Pasivos. Detalle: ${response?.response?.data?.message}`);
			}
			return response
		}
	};
};

// Reverse passive remaining
export const reversePassiveRemaining = (params) => {

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.reversePassiveRemainingRequest());

		if (accessToken) {

			const response = await SERVICES.postReversePassiveRemaining(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.reversePassiveRemainingRequestSuccess(response?.data));
				showSuccess(dispatch,  `¡Reversión de Residuos Pasivos realizada correctamente!`);
				return response;
			}
			else {
				dispatch(ACTIONS.reversePassiveRemainingRequestFailure(response?.response?.data||response));
				showError(dispatch, `Error al revertir Residuos Pasivos. Detalle: ${response?.response?.data?.message}`);
			}
			return response
		}
	};
};

//generate passive remaining by service
export const convertExpedientToPassiveRemainingByService = (params) => {

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.createConversionToPassiveRemainingByServiceRequest());

		if (accessToken) {

			const response = await SERVICES.postConvertionToPassiveRemainingByService(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.createConversionToPassiveRemainingByServiceRequestSuccess(response?.data));
				showSuccess(dispatch,  `¡Generación de Residuos Pasivos realizada correctamente!`);
				return response;
			}
			else {
				dispatch(ACTIONS.createConversionToPassiveRemainingByServiceRequestFailure(response?.response?.data||response));
				showError(dispatch, `Error al generar Residuos Pasivos. Detalle: ${response?.response?.data?.message}`);
			}
			return response
		}
	};
};

// Revert passive remaining
export const tryRevertPassiveRemainingByService = (params) => {

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.revertPassiveRemainingByServiceRequest());

		if (accessToken) {

			const response = await SERVICES.postRevertPassiveRemainingByService(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.revertPassiveRemainingByServiceRequestSuccess(response?.data));
				showSuccess(dispatch,  `Reversión de Residuos pasivos realizada correctamente.`);
				return response;
			}
			else {
				dispatch(ACTIONS.revertPassiveRemainingByServiceRequestFailure(response?.response?.data||response));
				showError(dispatch, `Error al revertir los residuos pasivos. Detalle: ${response?.response?.data?.message}`);
			}
			return response
		}
	};
};

// Try get affectation transactions
export const tryGetAffectationTransactions = (params={}) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getAffectationTransactionsRequest() );

		if(accessToken){

			const response = await SERVICES.getAffectationTransactions( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getAffectationTransactionsRequestSuccess(response?.data) );
				return response;
			}
			else {
				dispatch( ACTIONS.getAffectationTransactionsRequestFailure(response?.response?.data||response) );
				showError(dispatch, `Error en comprobantes por expediente. Detalle: ${response?.response?.data?.message}`);
				return response?.response;
			}
		}

	};
};



// Try get availables affectation status
export const tryGetAvailablesAffectationStatus = params => {
	return async( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getAvailablesAffectationStatusRequest() );

		if(accessToken){

			const response = await SERVICES.getAvailablesAffectationsStatus( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getAvailablesAffectationStatusRequestSuccess(response?.data) );
			}
			else {
				dispatch( ACTIONS.getAvailablesAffectationStatusRequestFailure(response?.response?.data||response) );
			}
	
			return response;
		}

	};
};

// Try get availables affectation history transactions by Id
export const tryGetAvailableAffectationHistoryTransactionsById = params => {
	return async( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getAvailablesAffectationsHistoryTransactionsByIdRequest () );

		if(accessToken){

			const response = await SERVICES.getAvailablesAffectationsHistoryTransactionsById( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getAvailablesAffectationsHistoryTransactionsByIdRequestSuccess(response?.data) );
			}
			else {
				dispatch( ACTIONS.getAvailablesAffectationsHistoryTransactionsByIdRequestFailure(response?.response?.data||response) );
			}
	
			return response;
		}

	};
};



// Try get availables affectations status of selected credits
export const tryGetAvailablesAffectationStatusOfSelectedCredits = (selectedCredits, administrativeDocumentData) =>  {
	return async( dispatch, getState ) => {
		if( isNotEmptyArray(selectedCredits) ) {
			const allPromises = [];

			dispatch( ACTIONS.getAvailablesAffectationStatusOfSelectedCreditsRequest() );

			selectedCredits.forEach( credit => {
				const params = {
					sub_code_id: credit?.id,
					administrative_document_organism_code: administrativeDocumentData?.organizationCode,
					administrative_document_number: administrativeDocumentData?.number,
					administrative_document_year: administrativeDocumentData?.year
				};
				allPromises.push( dispatch( tryGetAvailablesAffectationStatus(params) ) );
			});

			const availablesAffectationsStatus = await Promise.all( allPromises )
			
			if( isNotEmptyArray(availablesAffectationsStatus) ) {
				selectedCredits = selectedCredits.map( (item, index) => {
					item.availablesAffectationStatus = availablesAffectationsStatus[index]?.data;
					return item;
				});
			}

			dispatch( ACTIONS.getAvailablesAffectationStatusOfSelectedCreditsRequestSuccess() );

			return selectedCredits;
		}
	};
};

// Try get affectation subcode
export const tryGetAffectationSubcode = params => {
	return async( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.getAffectationSubcodeRequest() );

		if(accessToken){

			const response = await SERVICES.getAffectationSubcode( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getAffectationSubcodeRequestSuccess(response?.data) );
			}
			else {
				dispatch( ACTIONS.getAffectationSubcodeRequestFailure(response?.response?.data||response) );
			}
	
			return response;
		}

	};
};



// Try get balances of selected credits
export const tryGetAffectationBalancesOfSelectedCredits = (selectedCredits, administrativeDocumentData) =>  {
	return async( dispatch, getState ) => {
		if( isNotEmptyArray(selectedCredits) ) {
			const allPromises = [];

			dispatch( ACTIONS.getBalancesOfSelectedCreditsRequest() );

			selectedCredits.forEach( credit => {
				const params = {
					filter: {
						sub_code_id: credit?.id,
						administrative_document_organism_code: administrativeDocumentData?.organizationCode,
						administrative_document_number: administrativeDocumentData?.number,
						administrative_document_year: administrativeDocumentData?.year
					}
				};
				allPromises.push( dispatch( tryGetAffectationSubcode(params) ) );
			});

			const balances = await Promise.all( allPromises );
			
			if( isNotEmptyArray(balances) ) {
				selectedCredits = selectedCredits.map( (item, index) => {
					const balancesArray = balances[index]?.data?.data;
					item.balances = isNotEmptyArray(balancesArray) ? balancesArray[0]?.balance : undefined ;
					return item;
				});
			}

			dispatch( ACTIONS.getBalancesOfSelectedCreditsRequestSuccess() );

			return selectedCredits;
		}
	};
};