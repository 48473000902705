import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Button, Form, Row, Col, Table } from 'react-bootstrap';
import Commons from './controls/Commons';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import ExpedienteContainer from './controls/ExpedienteContainer';
import { clearNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationActions';
import { clearListOrderPayLast } from 'src/redux/orderPay/orderPayActions';
import ModalLiquidacionHaberes from '../../../pages/OrderPay/modal/ModalLiquidacionHaberes';
import { isNotEmptyArray } from '../../../../services/validationService';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import TotalAmountReceiptValidationForm from './TotalAmountReceiptValidationForm';

const NewHaberesLiquidationForm = () => {

	const dispatch = useDispatch();

	const { fundRequest, orderPay, expedientesAsociados, setExpedientesAsociados, administrativeServiceData,
		settlementOfAssets, importe, expedientesSeleccionados, setAllowSaveAmountValidation, register,
		errors } = useFormContext();

	const [administrativeDocument, setAdministrativeDocument] = useState([]);
	const [admServiceId, setAdmServiceId] = useState();

	const buttonLabel = settlementOfAssets?.generalAmount ? "Editar" : "Cargar";

	const applyFilterBankAccounts = (administrativeServiceId) => {
		setAdmServiceId(administrativeServiceId);
	};

	const selectedExpedientAssociated = expedientesAsociados?.filter(i => i.selected);

	//Modal settlement of assets
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const onClickSettlementAssetsModal = () => {
		handleShow(true);
	};

	const liquidationsValidationObj = { required: 'Debe cargar una Liquidación.' };

	useEffect(() => {
		if (orderPay) {
			setAdmServiceId(orderPay.administrativeServiceId);
		}
		if (fundRequest) {
			setAdmServiceId(fundRequest.administrativeServiceId);
		}
		dispatch(clearListOrderPayLast());
		dispatch(clearListAllAdministrativeServicesData());
		dispatch(clearNewAffectationAdministrativeDocumentData());
	}, []);

	return <>
		<Commons
			administrativeServiceData={administrativeServiceData}
			admServiceId={admServiceId}
			setAdmServiceId={setAdmServiceId}
			applyFilterBankAccounts={applyFilterBankAccounts}
			disabledImporte={true}
			disableAdmExpedientSearch={true}
			obligatoryLegalInstrument={true}
		/>

		<Form.Group as={Row} className='mt-5 mb-1'>
			<Form.Label className='justify-content-end text-black-color d-flex' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Liquidación de Haberes:
			</Form.Label>
			<Col sm='1' className='paddingform'>
				<Button
					variant='success'
					size='md'
					className='py-1 px-4'
					onClick={() => onClickSettlementAssetsModal()}
					disabled={!admServiceId}
				>
					{buttonLabel}
				</Button>
			</Col>
		</Form.Group>

		{
			settlementOfAssets?.generalAmount &&
			<>
				<Form.Group as={Row} className='mt-3' >
					<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
						Método de contratación:
					</Form.Label>
					<Col sm='4'>
						<Form.Control
							type='text'
							name='recruitmentMethod'
							value={settlementOfAssets?.wageLiquidationType.replace(/_/g, ' ')}   
							disabled
						/>
					</Col>
				</Form.Group>

				<Form.Group as={Row}>
					<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
						Total:
					</Form.Label>
					<Col sm='4'>
						<NumberFormat
							prefix={'$'}
							thousandSeparator={'.'}
							decimalSeparator={','}
							decimalScale={2}
							value={settlementOfAssets?.generalAmount}
							className={'form-control text-left'}
							disabled
						/>

					</Col>
				</Form.Group>
			</>
		}

		<Form.Group as={Row}>
			<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
			</Form.Label>
			<Col sm='4'>
				<Form.Control
					value={settlementOfAssets?.generalAmount ? 'true' : ''}
					type='hidden'
					name='liquidationListValidation'
					ref={register(liquidationsValidationObj)} />
				<FormFieldError errors={errors?.liquidationListValidation} />
			</Col>
		</Form.Group>

		{
			admServiceId &&
			<ExpedienteContainer
				expedientesAsociados={expedientesAsociados}
				setExpedientesAsociados={setExpedientesAsociados}
				administrativeDocument={administrativeDocument}
				setAdministrativeDocument={setAdministrativeDocument}
				disableAdmExpedientSearch={true}
				showSubpartItemSelector={true}
			/>
		}

        <TotalAmountReceiptValidationForm 
            setAllowSaveAmountValidation={setAllowSaveAmountValidation}
            expedientesSeleccionados={expedientesSeleccionados} 
            selectedExpedientAssociated={selectedExpedientAssociated}
            totalAmountReceipts={settlementOfAssets?.generalAmount}
            importe={importe} 
            receipts={settlementOfAssets?.wageManagerDetails}
        />
		
		<ModalLiquidacionHaberes
			show={show}
			handleClose={handleClose}
			serviceId={admServiceId}
		/>
	</>
};

export default NewHaberesLiquidationForm;