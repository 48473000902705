import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { mFormat, formatterPeso, getFundRequestTypeValueToShow } from 'src/utils/utils';
import { DELIVERY_ORDER_LIST } from 'src/utils/constants';
import { SumCollectionAmountsOP } from '../../../utils/utils';
import ActionIcon from '../../general/ActionIcon';
import DetailModal from '../FundRequests/modal/DetailModal';

const DeliveryOrderDetailsPage = () => {
	const dispatch = useDispatch();
	const {data : deliveryOrderDetailsData} = useSelector(state => state?.deliveryOrderReducer?.searchDeliveryOrderId)??{};
    const [show, setShow] = useState(false);
    const [activeOP, setActiveOP] = useState(false);
	const onClickBackButton = () => {
		dispatch(push(DELIVERY_ORDER_LIST))
	};
	const onClickDetail = item => {
		setActiveOP(item.paymentOrder);
		setShow(true);
	};

	return <Container fluid>
				<Card className='mb-5'>
					<Card.Header className='h6'>
						Detalles de la Orden de entrega
					</Card.Header>
					<Card.Body>
						<Table className='text-black-color' striped borderless hover responsive>
							<tbody>
								<tr>
									<td className='text-center font-weight-bold' width="40%" >
										Número:
									</td>
									<td className='text-center'>
										{deliveryOrderDetailsData?.number}/{deliveryOrderDetailsData?.year}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width="40%" >
										Expediente:
									</td>
									<td className='text-center'>
										{`${deliveryOrderDetailsData?.administrativeDocument?.codeOrganism} - ${deliveryOrderDetailsData?.administrativeDocument?.number}/${deliveryOrderDetailsData?.administrativeDocument?.year}`}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width="40%" >
										Fecha:
									</td>
									<td className='text-center'>
										{mFormat(deliveryOrderDetailsData?.deliveryOrderDate)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width="40%" >
										Descripción:
									</td>
									<td className='text-center'>
										{deliveryOrderDetailsData?.fundRequest?.description} - {getFundRequestTypeValueToShow(deliveryOrderDetailsData?.fundRequest?.fundRequestType)}
									</td>
								</tr>
							</tbody>
						</Table>
						<p className='text-black-color h6 mt-5' >
							Detalles del Pedido de Fondo
						</p>
						<Table striped hover size='sm' responsive>
							<thead>
								<tr>
									<th className='text-center py-3' width="30%" minWidth="150px" >Pedido de fondo Nro</th>
									<th className='text-center py-3' width="55%" minWidth="150px" >Descripción</th>
									<th className='text-center py-3' width="15%" minWidth="150px" >Importe</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{deliveryOrderDetailsData &&
										<tr>
											<td className='text-center'>
												{deliveryOrderDetailsData?.fundRequest?.number}/{deliveryOrderDetailsData?.fundRequest?.year}
											</td>
											<td className='text-center'>
												{deliveryOrderDetailsData?.fundRequest?.description}
											</td>
											<td className='text-right'>
												{formatterPeso.format(deliveryOrderDetailsData?.amount)}
											</td>
										</tr>
								}
							</tbody>
						</Table>
						{deliveryOrderDetailsData?.fundRequest?.details?.length
									?
						<>			
						<p className='text-black-color mt-5' >
							Detalle de Órdenes de pago:
						</p>
						<Table striped borderless hover responsive>
							<thead>
								<tr>
									<th className='text-center' minWidth="100px">Expediente</th>
									<th className='text-center' minWidth="100px" >Orden de Pago</th>
									<th className='text-center' minWidth="100px" >Descripción</th>
									<th className='text-center' minWidth="100px" >Importe</th>
									<th className='text-center' minWidth="100px" >Acciones</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
										{deliveryOrderDetailsData?.fundRequest?.details.map((item, key) => {
											const {paymentOrder} = item;
											return (
												<tr key={key}>
													<td className='text-center'>
														{paymentOrder?.administrativeDocument?.codeOrganism}-{paymentOrder?.administrativeDocument?.number}/{paymentOrder?.administrativeDocument?.year}
													</td>
													<td className='text-center'>
														{paymentOrder?.number}/{paymentOrder?.year}
													</td>
													<td className='text-center'>
														{paymentOrder?.description}
													</td>
													<td className='text-right'>
														{formatterPeso.format(SumCollectionAmountsOP(paymentOrder.details))}
													</td>
													<td className='text-center'>
														<ActionIcon toolTipText='Ver detalle' icon={faSearch} onClick={()=>onClickDetail(item)} />
													</td>
												</tr>
											)
										})}								
							</tbody>
						</Table>
						</>
						:
						<>
						 <p className='text-black-color mt-4' >
							 Detalle de los conceptos asociados:
						 </p>
						 <Table striped hover size="sm" responsive>
							 <thead>
								 <tr className='text-white-color'>
									 <th className='text-center py-3' width="40%">Concepto</th>
									 <th className='text-center py-3' width="30%">Tipo de Afectación</th>
									 <th className='text-center py-3' width="30%">Importe</th>
								 </tr>
							 </thead>
							 {deliveryOrderDetailsData?.fundRequest?.manualInputs?.map(item => {
								 return (
									 <tbody className='text-black-color'>
										 <tr >
											 <td className='text-center'>
												 {item?.description}
											 </td>
											 <td className='text-center'>
												 {item?.affectationYear}
											 </td>
											 <td className='text-right'>
												 {formatterPeso.format(item?.amount)}
											 </td>
										 </tr>
									 </tbody>
								 )
							 })}
						 </Table> 
						</>}
						<div className='text-center'>
							<Button variant='primary' size='lg' className='my-3' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
						</div>
					</Card.Body>
				</Card>
				<DetailModal
					showPayOrderDetailModal={show}
					setShowPayOrderDetailModal={setShow}
					activePayOrder={activeOP}
					selectedFundRequestType={deliveryOrderDetailsData?.fundRequest?.type}
				/>
			</Container>
};

export default DeliveryOrderDetailsPage;