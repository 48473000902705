import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, FormControl, Row, Col, Spinner, Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faEdit, faExclamationTriangle, faBroom, faFilter, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import ActionIcon from 'src/components/general/ActionIcon';
import { getUserPermissionsControlBudget } from 'src/redux/login/loginReducer';
import { tryGetSubcodeList, tryGetFileSubcode } from 'src/redux/subcode/subcodeActionCreator';
import { tryGetTotalAmounts } from 'src/redux/credit/creditActionCreator';
import { clearTotalAmountsData } from 'src/redux/credit/creditActions';
import { getSubcodeListData, getSubcodeListIsFetching } from 'src/redux/subcode/subcodeReducer';
import { getTotalAmountsData } from 'src/redux/credit/creditReducer';
import { clearSubcodeListData, setSubcodeDetailsPageData, setSubcodeToEdit, clearSubcodeToEdit, setSubcodeProcessInProgress, clearSelectedLegalInstrument } from 'src/redux/subcode/subcodeActions';
import { getGlobalDataSelectedPeriod, getFilterObject } from 'src/redux/globalData/globalDataReducer';
import { isNotEmptyArray, isNotEmptyObject } from 'src/services/validationService';
import { formatterPeso, getClearObject, numberNegativeRed } from 'src/utils/utils';
import { setFilterObject,  clearFilterObject} from 'src/redux/globalData/globalDataActions';
import { CREDIT_EXECUTION_CREDIT_QUERY, CREDIT_STEP_ONE } from 'src/utils/constants';
import { characterLabel, unOrgAbbreviation, seeMore, emptyCredit, actions, creditCurrentAbbreviation, creditCurrent, creditOriginAbbreviation, creditOrigin, subcodeAbbreviation, subcode, codeAbbreviation, code, creditPartialAbbreviation, creditPartial, creditInitialAbbreviation, creditInitial, functionAbbreviation, functio, purposeAbbreviation, purpose, accountAbbreviation, account, characterAbbreviation, jurisdictionAbbreviation, jurisdiction, serviceAbbreviation, serviceLabel, newCredit, budgetCredits, sectorAbbreviation, sectionAbbreviation, section, sector, creditModifyAbbreviation, creditModify } from 'src/utils/label';
import { setCreditQueryDataCreditSelected,  clearNewAffectationAdministrativeDocumentData, setAffectationHistoryDataCreditSelected } from 'src/redux/affectation/affectationActions';
import AppLoading from 'src/components/common/AppLoading';
import BalanceTotalModal from 'src/components/pages/CreditExecution/BalanceTotalModal';

const CreditListingPage = () => {

	const dispatch = useDispatch();
	//Cancel Request
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();
	const { handleSubmit, register, reset } = useForm();

	// Permissions
	const controlBudgetPermissions = useSelector(state => getUserPermissionsControlBudget(state));
	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	const creditListingData = useSelector(state => getSubcodeListData(state));
	const isFetching = useSelector(state => getSubcodeListIsFetching(state));
	const records = creditListingData?.data;
	const hasMoreCredits = creditListingData?.hasMore;
	const page = creditListingData?.page;
	const hasRecords = isNotEmptyArray(records);
	const totalAmountsData = useSelector(state => getTotalAmountsData(state));

	const [isLoading, setIsLoading] = useState(false);

	const loadCredits = () => {
		dispatch(clearSubcodeToEdit());
		fetchList(globalFilterObject);
	};

	useEffect(loadCredits, [periodSelected]);

	useEffect(() => {
		dispatch(setSubcodeProcessInProgress(false));
		dispatch(clearSelectedLegalInstrument());
		dispatch(clearNewAffectationAdministrativeDocumentData());
	}, []);

	const onClickLoadMoreCredits = () => {
		dispatch(tryGetSubcodeList({
			page: page + 1,
			filter: globalFilterObject
		}));
	};

	const onClickSeeDetails = creditData => {
		dispatch(setAffectationHistoryDataCreditSelected(creditData));
		dispatch(setCreditQueryDataCreditSelected(creditData));
		dispatch(setSubcodeDetailsPageData(creditData));
		dispatch(push(CREDIT_EXECUTION_CREDIT_QUERY));
	};

	const onClickEdit = creditData => {
		dispatch(setSubcodeToEdit(creditData));
		dispatch(push(CREDIT_STEP_ONE));
	};

	const onClickDownloadList = () => {
		setIsLoading(true);
		dispatch(tryGetFileSubcode({
			page,
			filter: globalFilterObject,
		})).finally(() => setIsLoading(false));
	};

	const cleanFilters = () => {
		dispatch(clearFilterObject());
		reset();
		fetchList();
    };

	const onSubmitForm = (data) => {
			cancelSource.cancel();
			cancelSource = CancelToken.source();
			const resul = getClearObject(data);
			fetchList(resul);
		}

	const fetchList = (params) => {
		dispatch(clearSubcodeListData());
		dispatch(tryGetSubcodeList({
			filter: params
		}))
		dispatch(clearTotalAmountsData());
		dispatch(tryGetTotalAmounts(params, cancelSource.token));
		dispatch(setFilterObject(params));
	}

	//CalculationBalance

	const [dataItem, setDataItem] = useState();
	const onMouseOverItem = item => {
		setDataItem(item);
	}

	return <Container fluid>
		<Card className='mb-5'>
			<PageTitle text={budgetCredits} />
			
			<Container fluid className='mt-0'>

				<Card className='mb-3'>
					<Card.Body>
						<div className='d-flex align-items-center mb-3'>
							{
								controlBudgetPermissions?.canCreate
									?
									<Button size='sm' variant='success' onClick={() => dispatch(push(CREDIT_STEP_ONE))}>
										<FontAwesomeIcon icon={faPlus} className='mr-1' />
										{newCredit}
									</Button>
									:
									null
							}
							
							<BalanceTotalModal 
								totalAmountsData = {totalAmountsData}
							/>
						</div>
						<div className='d-flex align-items-center justify-content-between mb-3'>
							<span className='ml-auto' >
								<Button size='sm' variant='secondary' onClick={onClickDownloadList} disabled={!isNotEmptyObject(globalFilterObject)} >
									<FontAwesomeIcon icon={faFilePdf} className='mr-1' />
									Exportar resultados
								</Button>
							</span>
						</div>						
						<tr>
							<Card.Title>
								<td className='text-black-color shadow-sm p-2 mb-5 bg-white rounded'>Referencia: {dataItem ? dataItem : '-'}</td>
							</Card.Title>
						</tr>
					<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
						<Table striped bordered hover size='sm' className='table-responsive'>
							<thead>
								<tr>
									<th className='text-center align-middle' title={serviceLabel}>{serviceAbbreviation}</th>
									<th className='text-center align-middle' title={jurisdiction}>{jurisdictionAbbreviation}</th>
									<th className='text-center align-middle' title={unOrgAbbreviation}>{unOrgAbbreviation}</th>
									<th className='text-center align-middle' title={characterLabel}>{characterAbbreviation}</th>
									<th className='text-center align-middle' title={account}>{accountAbbreviation}</th>
									<th className='text-center align-middle' title={purpose}>{purposeAbbreviation}</th>
									<th className='text-center align-middle' title={functio}>{functionAbbreviation}</th>
									<th className='text-center align-middle' title={section}>{sectionAbbreviation}</th>
									<th className='text-center align-middle' title={sector}>{sectorAbbreviation}</th>
									<th className='text-center align-middle' title={creditInitial}>{creditInitialAbbreviation}</th>
									<th className='text-center align-middle' title={creditPartial}>{creditPartialAbbreviation}</th>
									<th className='text-center align-middle' title={code}>{codeAbbreviation}</th>
									<th className='text-center align-middle' title={subcode}>{subcodeAbbreviation}</th>
									<th className='text-center align-middle' title={creditOrigin}>{creditOriginAbbreviation}</th>
									<th className='text-center align-middle' title={creditModify}>{creditModifyAbbreviation}</th>
									<th className='text-center align-middle' title={creditCurrent}>{creditCurrentAbbreviation}</th>
									<th className='text-center align-middle' width='10%' title={actions}>{actions}</th>
								</tr>
								
								<tr className='secondary'>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='service_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.service_number 
												? 
												globalFilterObject?.service_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='jurisdiction_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.jurisdiction_number
											?
											globalFilterObject?.jurisdiction_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='organization_number' size='sm' type='number' ref={register} 
										defaultValue={globalFilterObject?.organization_number
										?
										globalFilterObject?.organization_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='character_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.character_number
											?
											globalFilterObject?.character_number : "" }
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='account_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.account_number
											?
											globalFilterObject?.account_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='purpose_number' size='sm' type='number'  ref={register} 
											defaultValue={globalFilterObject?.purpose_number
											?
											globalFilterObject?.purpose_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='functionality_number' size='sm' type='number'  ref={register} 
											defaultValue={globalFilterObject?.functionality_number
											?
											globalFilterObject?.functionality_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='section_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.section_number
											?
											globalFilterObject?.section_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='sector_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.sector_number
											?
											globalFilterObject?.sector_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='principal_budget_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.principal_budget_number
											?
											globalFilterObject?.principal_budget_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='partial_budget_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.partial_budget_number
											?
											globalFilterObject?.partial_budget_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='code_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.code_number
											?
											globalFilterObject?.code_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<FormControl className='text-center' name='sub_code_number' size='sm' type='number' ref={register} 
											defaultValue={globalFilterObject?.sub_code_number
											?
											globalFilterObject?.sub_code_number : ""}
										/>
									</th>
									<th className='text-center align-middle'>
										<Button className="d-none" type="submit">Send</Button>
									</th>
									<th className='text-center align-middle'>

									</th>
									<th className='text-center align-middle'>

									</th>
									<th className='text-center align-middle custom-button'>
										{
                                                isFetching ?
                                                    <Spinner animation='border' size='md' />
                                                    :
                                                    <>
                                                        <ActionIcon
                                                            size="lg"
                                                            className="text-white-color"
                                                            toolTipText="Filtrar"
                                                            icon={faFilter}
															type='submit'
                                                            onSubmit={onSubmitForm}															
                                                        />
                                                    </>
										}
                                            {
                                                isFetching ?
                                                    <Spinner animation='border' size='md' />
                                                    :
                                                    <>
                                                        <ActionIcon
                                                            size="lg"
                                                            className="text-white-color"
                                                            toolTipText="Limpiar filtros"
                                                            icon={faBroom}
															onClick={()=> cleanFilters()}
                                                        />
                                                    </>
                                            }
									</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{
									hasRecords ?
										records?.map(item => {
											// Amount
											const originalCreditAmount = formatterPeso.format(item?.credit?.originalCredit);
											const currentCreditAmount = formatterPeso.format(item?.credit?.currentCredit);
											const modifyCreditAmount = formatterPeso.format(item?.credit?.modificationCredit);

											return (
											<tr key={item?.id}>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.service?.name)}>
													{item?.code?.credit?.service?.number}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.organization?.jurisdiction?.name)}>
													{item?.code?.credit?.organization?.jurisdiction?.number}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.organization?.name)}>
													{item?.code?.credit?.organization?.number}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.character?.name)}>
													{item?.code?.credit?.character?.code}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.account?.name)}>
													{item?.code?.credit?.account?.number}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.functionality?.purpose?.name)}>
													{item?.code?.credit?.functionality?.purpose?.number}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.functionality?.name)}>
													{item?.code?.credit?.functionality?.number}
												</td>

												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget?.principalBudget?.sector?.section?.name)}>
													{item?.code?.credit?.partialBudget?.principalBudget?.sector?.section?.number}
												</td>

												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget?.principalBudget?.sector?.name)}>
													{item?.code?.credit?.partialBudget?.principalBudget?.sector?.number}
												</td>

												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget?.principalBudget?.name)}>
													{item?.code?.credit?.partialBudget?.principalBudget?.number}
												</td>

												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.credit?.partialBudget?.name)}>
													{item?.code?.credit?.partialBudget?.number}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.code?.name)}>
													{item?.code?.number}
												</td>
												<td className='text-center align-middle' onMouseOver={() => onMouseOverItem(item?.name)}>
													{item?.number}
												</td>
												<td className={`${numberNegativeRed(item?.credit?.originalCredit)} text-right align-middle`} onMouseOver={() => onMouseOverItem()}>
													{originalCreditAmount}
												</td>

												<td className={`${numberNegativeRed(item?.credit?.modificationCredit)} text-right align-middle`} onMouseOver={() => onMouseOverItem()}>
													{modifyCreditAmount}
												</td>

												<td className={`${numberNegativeRed(item?.credit?.currentCredit)} text-right align-middle`}  onMouseOver={() => onMouseOverItem()}>
													{currentCreditAmount}
												</td>
												<td className='text-center align-middle custom-button'>
													{
														controlBudgetPermissions?.canView
															?
															<ActionIcon size='lg' id='details' toolTipText='Ver detalles' icon={faSearch} mr='2' onClick={() => onClickSeeDetails(item)} />
															:
															null
													}
													{
														controlBudgetPermissions?.canUpdate
															?
															<ActionIcon size='lg' id='search' toolTipText='Modificar crédito' icon={faEdit} onClick={() => onClickEdit(item)} />
															:
															null
													}
												</td>
											</tr>);
										})
										:
										<tr>
											<td colSpan='17' className='text-center'>
												{
													isFetching ?
														<Spinner animation='border' variant='success' className='my-3' />
														:
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
															{emptyCredit}
														</Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>
					</Form>
						<Row>
							<Col className='text-center'>
								{
									hasMoreCredits ?
										<Button size='sm' className='px-5' onClick={onClickLoadMoreCredits} disable={isFetching}>
											{
												isFetching ?
													<Spinner animation='border' size='sm' />
													:
													<>{seeMore}</>
											}
										</Button>
										: null
								}
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		</Card>
		<AppLoading isLoading={isLoading} />
	</Container>;
};

export default CreditListingPage;