import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getYearsOptionsByParam } from 'src/utils/utils';
import { tryAcquisitionTypeList } from '../../redux/provisionOrder/provisionOrderActionCreator';
import { getAcquisitionTypesListData } from '../../redux/provisionOrder/provisionOrderReducer';

const InputContratacionForm = ({ adquisition, handleAdquisitionType, handleAdquisitionNumber, handleAdquisitionYear }) => {
    const dispatch = useDispatch();
    const { register, errors } = useFormContext();
    const legalInstrumentTypeRequiredMsg = 'Debe ingresar el tipo de contratación.';
    const legalInstrumentNumberRequiredMsg = 'Debe ingresar el Número de contratación.';
    const legalInstrumentYearRequiredMsg = 'Debe ingresar el año de contratación.';
    const legalInstrumentTypeValidationObj = { required: legalInstrumentTypeRequiredMsg };
    const legalInstrumentNumberValidationObj = { required: legalInstrumentNumberRequiredMsg };
    const legalInstrumentYearValidationObj = { required: legalInstrumentYearRequiredMsg };
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const minimumYear = 2018;
    const yearsOptions = getYearsOptionsByParam(minimumYear, globalSelectedPeriod?.year);
    const acquisitionTypesListData = useSelector(state => getAcquisitionTypesListData(state));
    const engagement = acquisitionTypesListData.map((type, index) => {
        return {
            id: index + 1,
            name: type
        };
    });
    function getNames(engagementArray) {
        return engagementArray.map(item => item.name);
    }
    const engagementList = getNames(engagement);

    useEffect(() => {
        dispatch(tryAcquisitionTypeList());
    }, []);

    return <>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                Contratación:
            </Form.Label>
            <Col sm='4'>
                <div className="input-group" >
                    <Form.Control
                        as='select'
                        value={adquisition?.acquisitionType}
                        className='mr-2'
                        onChange={e => handleAdquisitionType(e?.target?.value)}
                    >
                        <option value={undefined} selected>Tipo</option>
                        {engagementList?.map(item => (
                            <option value={item}>
                                {item.replace(/_/g, ' ')}
                            </option>))
                        }
                    </Form.Control>
                    <Form.Control
                        type='number'
                        name='acquisitionNumber'
                        value={adquisition?.acquisitionNumber}
                        className='mr-2'
                        max={99999}
                        min={1}
                        placeholder={'Número'}
                        onChange={e => handleAdquisitionNumber(e.target.value)}
                        ref={register(legalInstrumentNumberValidationObj)}
                    />
                    <Form.Control
                        as='select'
                        value={adquisition?.acquisitionYear}
                        className='select-min-width'
                        onChange={e => handleAdquisitionYear(e?.target?.value)}
                    >
                        <option value={undefined} selected>Año</option>
                        {yearsOptions?.map(item => (
                            <option value={item}>
                                {item}
                            </option>))
                        }
                    </Form.Control>
                </div>
                <Form.Control hidden ref={register(legalInstrumentTypeValidationObj)} 
                    name='acquisitionType' 
                    value={adquisition?.acquisitionType}
                />
                <FormFieldError errors={errors?.acquisitionType} />
                <FormFieldError errors={errors?.acquisitionNumber} />
                <Form.Control hidden ref={register(legalInstrumentYearValidationObj)} 
                    name='acquisitionYear' 
                    value={adquisition?.acquisitionYear}
                />
                <FormFieldError errors={errors?.acquisitionYear} />
            </Col>
        </Form.Group>
    </>
}

export default InputContratacionForm;