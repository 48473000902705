import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';
import { LOGOUT } from 'src/redux/login/loginActionTypes';

import { loginReducer } from 'src/redux/login/loginReducer';
import { globalDataReducer, globalDataReducerInitialState } from 'src/redux/globalData/globalDataReducer';
import { creditReducer, creditReducerInitialState } from 'src/redux/credit/creditReducer';
import { serviceReducer, serviceReducerInitialState } from 'src/redux/service/serviceReducer';
import { conceptsReducer, conceptsReducerInitialState } from 'src/redux/conceptRequest/conceptRequestReducer';
import { jurisdictionReducer, jurisdictionReducerInitialState } from 'src/redux/jurisdiction/jurisdictionReducer';
import { organizationReducer, organizationReducerInitialState } from 'src/redux/organization/organizationReducer';
import { characterReducer, characterReducerInitialState } from 'src/redux/character/characterReducer';
import { accountReducer, accountReducerInitialState } from 'src/redux/account/accountReducer';
import { purposeReducer, purposeReducerInitialState } from 'src/redux/purpose/purposeReducer';
import { functionalityReducer, functionalityReducerInitialState } from 'src/redux/functionality/functionalityReducer';
import { sectionReducer, sectionReducerInitialState } from 'src/redux/section/sectionReducer';
import { sectorReducer, sectorReducerInitialState } from 'src/redux/sector/sectorReducer';
import { principalBudgetReducer, principalBudgetReducerInitialState } from 'src/redux/principalBudget/principalBudgetReducer';
import { partialBudgetReducer, partialBudgetReducerInitialState } from 'src/redux/partialBudget/partialBudgetReducer';
import { subcodeReducer, subcodeReducerInitialState } from 'src/redux/subcode/subcodeReducer';
import { userReducer, userReducerInitialState } from 'src/redux/user/userReducer';
import { reportsReducer, reportsReducerInitialState } from 'src/redux/reports/reportsReducer';
import { legalInstrumentReducer, legalInstrumentReducerInitialState } from 'src/redux/legalInstrument/legalInstrumentReducer';
import { affectationReducer, affectationReducerInitialState } from 'src/redux/affectation/affectationReducer';
import { administrativeDocumentReducer, administrativeDocumentReducerInitialState } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { exerciseReducer, exerciseReducerInitialState } from 'src/redux/exercise/exerciseReducer';
import { administrativeServiceReducer, administrativeServiceReducerInitialState } from 'src/redux/administrativeService/administrativeServiceReducer';
import { administrativeOrganismReducer, administrativeOrganismReducerInitialState } from 'src/redux/AdministrativeOrganism/administrativeOrganismReducer';
import { budgetItemControlReducer, budgetItemControlReducerInitialState } from 'src/redux/budgetItemControl/budgetItemControlReducer';
import { orderPayReducer, orderPayReducerInitialState } from 'src/redux/orderPay/orderPayReducer';
import { fundRequestsReducer, fundRequestsReducerInitialState } from 'src/redux/fundRequests/fundRequestsReducer';
import { bankAccountReducer, bankAccountReducerInitialState } from 'src/redux/bankAccounts/bankAccountReducer';
import { bankAccountsFundRequestReducer, bankAccountInitialState } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer';
import { proveedoresReducer, proveedoresReducerInitialState } from 'src/redux/proveedores/proveedoresReducer';
import { auditReducer, auditReducerInitialState } from 'src/redux/audit/auditReducer';
import { deliveryOrderReducer, deliveryOrderReducerInitialState } from 'src/redux/deliveryOrder/deliveryOrderReducer'
import { captionReducer, captionReducerInitialState } from 'src/redux/caption/captionReducer'
import { versionReducer, versionReducerInitialState } from 'src/redux/version/versionReducer';
import { subconceptsReducer, subconceptReducerInitialState } from 'src/redux/subconcept/subconceptReducer';
import { alertReducer, alertReducerInitialState } from 'src/redux/alert/alertReducer';
import { notificationReducer, notificationReducerInitialState } from 'src/redux/notification/notificationReducer';
import { expedientReducer, expedientReducerInitialState } from 'src/redux/expedient/expedientReducer';
import { preloadReducer, preloadReducerInitialState } from 'src/redux/preload/preloadReducer';
import { maintenanceSystemReducer, maintenanceSystemReducerInitialState } from 'src/redux/maintenanceSystem/maintenanceSystemReducer';
import {figurativeExpedituresReducer, figurativeExpedituresReducerInitialState} from 'src/redux/figurativeExpeditures/figurativeExpedituresReducer';
import {wageTemplateReducer, wageTemplateReducerInitialState} from 'src/redux/wageTemplate/wageTemplateReducer';
import { provisionOrderReducer, provisionOrderReducerInitialState } from 'src/redux/provisionOrder/provisionOrderReducer';

const createRootReducer = history => {
	const appReducer = combineReducers({
		router: connectRouter(history),
		notifications,
		globalDataReducer,
		loginReducer,
		creditReducer,
		serviceReducer,
		conceptsReducer,
		jurisdictionReducer,
		organizationReducer,
		characterReducer,
		accountReducer,
		purposeReducer,
		functionalityReducer,
		sectionReducer,
		sectorReducer,
		principalBudgetReducer,
		partialBudgetReducer,
		subcodeReducer,
		userReducer,
		reportsReducer,
		legalInstrumentReducer,
		affectationReducer,
		administrativeDocumentReducer,
		exerciseReducer,
		administrativeServiceReducer,
		budgetItemControlReducer,
		administrativeOrganismReducer,
		orderPayReducer,
		fundRequestsReducer,
		bankAccountReducer,
		bankAccountsFundRequestReducer,
		deliveryOrderReducer,
		proveedoresReducer,
		subconceptsReducer,
		auditReducer,
		captionReducer,
		versionReducer,
		alertReducer,
		notificationReducer,
		expedientReducer,
		preloadReducer,
		maintenanceSystemReducer,
		figurativeExpedituresReducer,
		wageTemplateReducer,
		provisionOrderReducer
	});
	return (state, action) => {
		if (action.type === LOGOUT) {
			state.globalDataReducer = globalDataReducerInitialState;
			state.creditReducer = creditReducerInitialState;
			state.serviceReducer = serviceReducerInitialState;
			state.conceptsReducer = conceptsReducerInitialState;
			state.jurisdictionReducer = jurisdictionReducerInitialState;
			state.characterReducer = characterReducerInitialState;
			state.accountReducer = accountReducerInitialState;
			state.purposeReducer = purposeReducerInitialState;
			state.functionalityReducer = functionalityReducerInitialState;
			state.sectionReducer = sectionReducerInitialState;
			state.sectorReducer = sectorReducerInitialState;
			state.principalBudgetReducer = principalBudgetReducerInitialState;
			state.partialBudgetReducer = partialBudgetReducerInitialState;
			state.subcodeReducer = subcodeReducerInitialState;
			state.userReducer = userReducerInitialState;
			state.organizationReducer = organizationReducerInitialState;
			state.reportsReducer = reportsReducerInitialState;
			state.legalInstrumentReducer = legalInstrumentReducerInitialState;
			state.affectationReducer = affectationReducerInitialState;
			state.administrativeDocumentReducer = administrativeDocumentReducerInitialState;
			state.exerciseReducer = exerciseReducerInitialState;
			state.administrativeServiceReducer = administrativeServiceReducerInitialState;
			state.administrativeOrganismReducer = administrativeOrganismReducerInitialState;
			state.budgetItemControlReducer = budgetItemControlReducerInitialState;
			state.orderPayReducer = orderPayReducerInitialState;
			state.fundRequestsReducer = fundRequestsReducerInitialState;
			state.bankAccountReducer = bankAccountReducerInitialState;
			state.bankAccountsFundRequestReducer = bankAccountInitialState;
			state.auditReducer = auditReducerInitialState;
			state.deliveryOrderReducer = deliveryOrderReducerInitialState;
			state.proveedoresReducer = proveedoresReducerInitialState;
			state.subconceptsReducer = subconceptReducerInitialState;
			state.captionReducer = captionReducerInitialState;
			state.versionReducer = versionReducerInitialState;
			state.alertReducer = alertReducerInitialState;
			state.notificationReducer = notificationReducerInitialState;
			state.expedientReducer = expedientReducerInitialState;
			state.preloadReducer = preloadReducerInitialState;
			state.maintenanceSystemReducer = maintenanceSystemReducerInitialState;
			state.figurativeExpedituresReducer = figurativeExpedituresReducerInitialState;
			state.wageTemplateReducer = wageTemplateReducerInitialState;
			state.proveedoresReducer = provisionOrderReducerInitialState;
		}
		return appReducer(state, action);
	}

};

export default createRootReducer;
