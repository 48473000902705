import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Nav, NavDropdown, Accordion, Button } from 'react-bootstrap';
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBalanceScale, faEye, faChartPie, faMoneyBill, faCode, faCog,faFileInvoiceDollar, faBook, faEnvelope, faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { getUserPermissionsSecurity, getUserPermissionsControlBudget, getUserPermissionsReports, getUserPermissionsExecutionBudget, getUserPermissionsPeriods, getUserPermissionsServices, getUserPermissionsBudgetItemControl, getUserPermissionsOrganisms, getProfileName, getUserPermissionsFundRequests, getUserPermissionsPaymentOrders, getUserPermissionsDeliveryOrders, getUserPermissionsAudit, getUserPermissionsBankAccounts, getUserPermissionsRegions } from 'src/redux/login/loginReducer';
import { downloadStreamFile } from 'src/utils/utils';

import { config } from 'src/env.js';
const API_BASE_URI = `${config.apis.coreApi.URL}`;
import {ROUTE_PATHS as ROUTES}  from 'src/routes';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';
import { userIdDataByEmail } from 'src/utils/usersProfiles';
import AppLoading from 'src/components/common/AppLoading';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getExercisesListData } from 'src/redux/exercise/exerciseReducer';

import { tryListExercises } from 'src/redux/exercise/exerciseActionCreator';

const Menus = props => {

	const { user } = props;

	const [isLoading, setIsLoading] = useState(false);

	//Manual Users - Operator Service
	const downloadUserManualServiceOperator = () => {
		setIsLoading(true);
		const url = API_BASE_URI + ROUTES.USER_MANUAL_SERVICE_OPERATOR;
		axios.get(
			url, 
			{responseType: 'blob'}
		)
		.then((response) => {
			downloadStreamFile(response, 'Manual-SAFI-2-Operador-Servicio');
		}).finally(() => setIsLoading(false));
	};

	//Manual Users - Profile HTC
	const downloadUserManualProfileHTC = () => {
		setIsLoading(true);
		const url = API_BASE_URI + ROUTES.USER_MANUAL_PROFILE_HTC;
		axios.get(
			url, 
			{responseType: 'blob'}
		)
		.then((response) => {
			downloadStreamFile(response, 'Manual-de-Usuario-SAFI2-Perfil-HTC');
		}).finally(() => setIsLoading(false));
	};
	

	//Profile User
	const profileName = useSelector(state => getProfileName(state));

	const profileNameHTC = profileName == 'Fiscalizador';
	const profileNameAI = profileName == 'Dirección de Análisis e Informática';
	const profileNameSA = profileName == 'Super Admin';
	const profileConsultBudget = profileName == 'Consulta Presupuesto';
	const profileBudgetDirection = profileName == 'Dirección de Presupuesto';
	const profileBudgetServiceOperator = profileName == 'Operador de Servicio de Presupuesto';
	const profileBudgetAdministrator = profileName == 'Administrador de Presupuesto';
	const profileBudgetEmployee = profileName == 'Empleado de Presupuesto';
	const profileTreasuryOperator = profileName == 'Operador de Tesorería';
	const profileDirectorSa = profileName == 'Director de SA';
	const profileFundsTransferManager = profileName == 'Responsable de transferencia de fondos';
	const profileChiefAccountantSA = profileName == 'Jefe de Contabilidad de SA';
	const supplyOperator = profileName == 'Operador de Suministros';

	const dispatch = useDispatch();

	const securityTitle = (<><FontAwesomeIcon icon={faLock} className='mr-1' />
	Seguridad</>);

	const reportsTitle = (<><FontAwesomeIcon icon={faChartPie} className='mr-1' />
	Reportes</>);

	const configTitle = (<><FontAwesomeIcon icon={faCog} className='mr-1' />
	Configuración</>);

	const financialExecutionTitle = (<><FontAwesomeIcon icon={faFileInvoiceDollar} className='mr-1' />
	Ejecución financiera</>);

	const budgetExecutionTitle = (<><FontAwesomeIcon icon={faBalanceScale} className='mr-1' />
	Ejecución presupuesto</>);

	const auditTitle = (<><FontAwesomeIcon icon={faCode} className='mr-1' />
	Auditoría</>);
	
	const manualTitle = (<><FontAwesomeIcon icon={faBook} className='mr-1' />
	Manual</>);

	const supplyDepartment = (<><FontAwesomeIcon icon={faShoppingCart} className='mr-1' />
	Dpto. Suministros</>);


	const securityPermissions = useSelector(state => getUserPermissionsSecurity(state));
	const controlBudgetPermissions = useSelector(state => getUserPermissionsControlBudget(state));
	const reportsPermissions = useSelector(state => getUserPermissionsReports(state));
	const executionBudgetPermissions = useSelector(state => getUserPermissionsExecutionBudget(state));
	const periodsPermissions = useSelector(state => getUserPermissionsPeriods(state));
	const servicesPermissions = useSelector(state => getUserPermissionsServices(state));
	const budgetItemControlPermissions = useSelector(state => getUserPermissionsBudgetItemControl(state));
	const organismsPermissions = useSelector(state => getUserPermissionsOrganisms(state));

	const fundRequestsPermissions = useSelector(state => getUserPermissionsFundRequests(state));
	const paymentOrdersPermissions = useSelector(state => getUserPermissionsPaymentOrders(state));
	const deliveryOrdersPermissions = useSelector(state => getUserPermissionsDeliveryOrders(state));

	const auditsPermissions = useSelector(state => getUserPermissionsAudit(state));

	const bankAccountsPermissions = useSelector(state => getUserPermissionsBankAccounts(state));

	const regionsPermissions = useSelector(state => getUserPermissionsRegions(state));

	const paramsToAlerts= {
		profile: profileName,
		userId: userIdDataByEmail()?.id
	};

	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	const exercisesListData = useSelector(state => getExercisesListData(state));
	const dateToComplementary = exercisesListData?.records?.filter(item => item?.year === globalSelectedPeriod?.year)[0]?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0]?.startDate;
	const endDateComplementary = exercisesListData?.records?.filter(item => item?.year === globalSelectedPeriod?.year)[0]?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0]?.endDate;
	const todayDate = moment(new Date()).format('YYYY-MM-DD');
	const exerciseStatus = globalSelectedPeriod?.exerciseState;
	const exerciseInExecution = exerciseStatus == "EXECUTION"
	const activeComplementaryPeriod = (todayDate >=  moment(dateToComplementary).format('YYYY-MM-DD') && todayDate <=  moment(endDateComplementary).format('YYYY-MM-DD')) ? true : false;

	const isAvailableShowActionsRp = value => {
		let isValidYear = globalSelectedPeriod?.year == value
		let isStartDate = todayDate >=  moment(dateToComplementary).format('YYYY-MM-DD')
		let isEndDate = endDateComplementary >= todayDate
		let isAvailableYearActionsRP = isValidYear && isStartDate && isEndDate
		return isAvailableYearActionsRP;
	}

	const isAvailableYearReport = value => {
		let isAvailableYearReport =  globalSelectedPeriod?.year == value;
		return isAvailableYearReport;
	}

	const isAvailableShowRevertPassiveRemaining = (exercise) => {

		let isAvailable = false;

		// previous exercice
		const previousExercise = exercise - 1;
		const previousExerciceData = exercisesListData?.records?.filter(item => item?.year == previousExercise)[0];
		const complementaryPeriod = previousExerciceData?.periods?.filter(item => item?.name == 'COMPLEMENTARIO')[0];
		const startOfComplementaryPeriod = complementaryPeriod?.startDate;
		const endtOfComplementaryPeriod = complementaryPeriod?.endDate;

		if(startOfComplementaryPeriod != undefined && endtOfComplementaryPeriod != undefined){
			isAvailable = (todayDate >=  moment(startOfComplementaryPeriod).format('YYYY-MM-DD') && todayDate <=  moment(endtOfComplementaryPeriod).format('YYYY-MM-DD')) ? true : false;
		};

		return isAvailable;
	};

	useEffect(() => {
		dispatch(tryListExercises());
	}, []);

	return (
		<Nav className="me-auto d-flex flex-wrap">
			{
				securityPermissions?.canView
					?
					<NavDropdown className='active' title={securityTitle} onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >
						{
							securityPermissions?.users?.canView
								&&
								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.USER_LIST))}>
									Usuarios
								</NavDropdown.Item>
						}
						{
							securityPermissions?.profiles?.canView
								&&
								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.PROFILE_LIST))}>
									Grupos
								</NavDropdown.Item>
						}
					</NavDropdown>
					:
					null
			}

			{
				executionBudgetPermissions?.canView
					?
					<NavDropdown className='active' title={budgetExecutionTitle} id='basic-nav-dropdown' onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >

						{
							executionBudgetPermissions?.canView
								&&
									<NavDropdown.Item id='control-presupuesto' className='dropdown-item' onClick={() => dispatch(push(ROUTES.CREDIT_EXECUTION))}>
										Partidas presupuestarias
									</NavDropdown.Item>
						}

						{
							paymentOrdersPermissions?.canView
								&&
									<NavDropdown.Item className='dropdown-item' onClick={() => dispatch(push(ROUTES.ORDER_PAY_LIST))}>
										Orden pago
									</NavDropdown.Item>
						}
			
						{/* { TODO: Se quita del menu por que no se va a utilzar más esta impportación de residuos pasivos
							(profileNameSA || profileNameAI) && (
								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_IMPORTS))}>
									Residuos pasivos
								</NavDropdown.Item>
							)
						} */}
						{
							((profileNameSA || profileNameAI || profileBudgetServiceOperator || profileDirectorSa || profileChiefAccountantSA) 
							&& (isAvailableShowActionsRp(2022) || isAvailableShowActionsRp(2023) || isAvailableShowActionsRp(2024))) 
							&& (
								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_CONVERTION))}>
									Residuos Pasivos por Servicio
								</NavDropdown.Item>
							)
						}

						{ (profileNameSA || profileBudgetDirection || profileBudgetEmployee) &&
							<NavDropdown.Item onClick={() => dispatch(push(ROUTES.PRE_LOAD))}>
								Precarga
							</NavDropdown.Item>
						}

						{
							// TODO: DESCOMENTAR AL TERMINAR REVERSIONES
							
							
							// ((profileNameSA) && (isAvailableShowRevertPassiveRemaining(globalSelectedPeriod?.year))) && (
							// 	<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REVERT_PASSIVE_REMAINING_BY_SERVICE))}>
							// 		Reversión de Residuos Pasivos
							// 	</NavDropdown.Item>
							// )
						}

						{
							paymentOrdersPermissions?.canView &&
								<DropdownSubmenu href="" title="Configuración Presupuestaria">
									{
										<NavDropdown.Item onClick={() => dispatch(push(ROUTES.CONCEPT_LIST))}>
											&nbsp;• Conceptos de Haberes
										</NavDropdown.Item>
									}
									{
										<NavDropdown.Item id='control-presupuesto' onClick={() => dispatch(push(ROUTES.WAGES_TEMPLATE_LIST))}>
											&nbsp;• Plantilla de Haberes
										</NavDropdown.Item>
									}
							</DropdownSubmenu>	
						}

					</NavDropdown>
					:
					null
			}

			{
				(bankAccountsPermissions?.canView || fundRequestsPermissions?.canView || deliveryOrdersPermissions?.canView)
					?
					<NavDropdown className='active' title={financialExecutionTitle} id='basic-nav-dropdown' onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >
						{
							bankAccountsPermissions?.canView
								&&
								<Accordion>
									<Accordion.Toggle as={Button} variant='link' eventKey='1' className='dropdown-item' onClick={() => dispatch(push(ROUTES.BANK_ACCOUNT_LIST))}>
										Cuentas Bancarias
									</Accordion.Toggle>

								</Accordion>
						}

						{
							fundRequestsPermissions?.canView
								&&
								<Accordion>
									<Accordion.Toggle as={Button} variant='link' eventKey='1' className='dropdown-item' onClick={() => dispatch(push(ROUTES.FUND_REQUESTS_LIST))}>
										Pedido fondo
									</Accordion.Toggle>

								</Accordion>
						}
						{
							deliveryOrdersPermissions?.canView
								&&
								<Accordion>
									<Accordion as={Button} variant='link' eventKey='0' id='control-presupuesto' className='dropdown-item' onClick={() => dispatch(push(ROUTES.DELIVERY_ORDER_LIST))}>
										Órdenes entrega
									</Accordion>
								</Accordion>
						}
						
						{
							(profileNameSA || profileBudgetDirection || profileNameAI) &&
								<DropdownSubmenu href="" title="Configuración Financiera">
									{ (profileNameSA || profileBudgetDirection || profileNameAI) &&
										<NavDropdown.Item onClick={() => dispatch(push(ROUTES.FIGURATIVE_EXPEDITURES))}>
											&nbsp;• Erogaciones figurativas
										</NavDropdown.Item>
									}
								</DropdownSubmenu>
						}
					</NavDropdown>
					:
					null
			}

			{(profileNameSA || supplyOperator) &&
				<NavDropdown className='active' title={supplyDepartment} id='supply-id' onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >
					{
						(profileNameSA || supplyOperator) &&
						<Accordion>
							<Accordion.Toggle as={Button} variant='link' eventKey='1' className='dropdown-item' onClick={() => dispatch(push(ROUTES.PROVISION_ORDER_LIST))}>
								Orden de provisión
							</Accordion.Toggle>
						</Accordion>
					}
				</NavDropdown>
			} 	

			{
				false
					?
					<Nav.Item>
						<Nav.Link className='nav-level-1' onClick={() => dispatch(push(ROUTES.BUDGET_ITEM_CONTROL))}>
							<FontAwesomeIcon icon={faMoneyBill} className='mr-1' />
							Recursos propios
						</Nav.Link>
					</Nav.Item>
					:
					null
			}

			{
				reportsPermissions?.canView
					?
					<NavDropdown className='active' title={reportsTitle} id="collasible-nav-dropdown" alignLeft onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >

						{/* {
							(reportsPermissions['analityc-organisms']?.canView ||
								reportsPermissions['analityc-juridictions']?.canView ||
								reportsPermissions['total-consolidated']?.canView ||
								reportsPermissions['total-juridictions']?.canView ||
								reportsPermissions['total-organism']?.canView ||
								reportsPermissions['total-crossed']?.canView)
								?
								<DropdownSubmenu href="" title="Control presupuesto" onClick =  { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >

									<>

										{
											reportsPermissions['analityc-juridictions']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_JURISDICTION_ANALYTICAL))}>
													&nbsp;• Analítico por jurisdicción
													</NavDropdown.Item>
												:
												null
										}
										{
											reportsPermissions['analityc-organisms']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_ORGANISM_ANALYTICAL))}>
													&nbsp;• Analítico por unidad de organización/organismo
													</NavDropdown.Item>
												:
												null
										}
										{
											reportsPermissions['total-consolidated']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_CONSOLIDATED))}>
													&nbsp;• Total consolidado
													</NavDropdown.Item>
												:
												null
										}
										{
											reportsPermissions['total-crossed']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_TOTAL_CROSSED_PURPOSE_FUNCTION_OBJECT_EXPENDITURE))}>
													&nbsp;• Total cruzado-Finalidad y función-Objeto del gasto
													</NavDropdown.Item>
												:
												null
										}

										{
											reportsPermissions['total-juridictions']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_JURISDICTION_TOTAL))}>
													&nbsp;• Total por jurisdicción
													</NavDropdown.Item>
												:
												null
										}
										{
											reportsPermissions['total-organism']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_ORGANISM_TOTAL))}>
													&nbsp;• Total por organización/organismo
													</NavDropdown.Item>
												:
												null
										}

									</>
								</DropdownSubmenu>

								:
								undefined
						} */}

						{
							(reportsPermissions['execution-reports']?.canView || 
							reportsPermissions['affectation-gda']?.canView ||
							reportsPermissions['modify-by-legal']?.canView)
								?
								<DropdownSubmenu href="" title="Ejecución presupuesto">


									<>
										{
											!profileNameHTC && 
											!profileConsultBudget &&
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION))}>
													&nbsp;• Analítico de contabilidad de presupuesto
													</NavDropdown.Item>
												:
												null
										}
										{
											!profileNameHTC && 
											!profileConsultBudget &&
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_V2))}>
													&nbsp;• Analítico de contabilidad de presupuesto V2
													</NavDropdown.Item>
												:
												null
										}
										{
											!profileNameHTC && 
											!profileConsultBudget &&
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BALANCE_STATUS))}>
													&nbsp;• Estado saldos
													</NavDropdown.Item>
												:
												null
										}
										{
											!profileNameHTC && 
											!profileConsultBudget &&
											reportsPermissions['execution-reports']?.canView
												&&
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BALANCE_STATUS_V2))}>
													&nbsp;• Estado saldos v2
												</NavDropdown.Item>
										}
										{
											!profileNameHTC && 
											!profileConsultBudget &&
											reportsPermissions['modify-by-legal']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_INSTRUMENT_LEGAL_MODIFY))}>
													&nbsp;• Modificaciones por instrumento legal
													</NavDropdown.Item>
												:
												null
										}

										{
											!profileNameHTC && 
											!profileConsultBudget &&
											reportsPermissions['affectation-gda']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT))}>
													&nbsp;• Comprobantes por expediente
													</NavDropdown.Item>
												:
												null
										}

										{										
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_DETAIL_PASSIVE_RESIDUES))}>
													&nbsp;• Detalle de Residuos Pasivos
													</NavDropdown.Item>
												:
												null
										}

										{
											reportsPermissions['execution-reports']?.canView  && (exerciseInExecution || activeComplementaryPeriod)
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_DETERMINATION_PASSIVE_RESIDUES))}>
													&nbsp;• Determinación de Residuos Pasivos
													</NavDropdown.Item>
												:
												null
										}

										{
											profileConsultBudget ||
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION))}>
													&nbsp;• Estado ejecución presupuesto
													</NavDropdown.Item>
												:
												null
										}

										{
											profileConsultBudget ||
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_V2))}>
													&nbsp;• Estado ejecución presupuesto v2
													</NavDropdown.Item>
												:
												null
										}

										{
											profileConsultBudget ||
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION))}>
													&nbsp;• Estado modificación créditos
													</NavDropdown.Item>
												:
												null
										}

{
											profileConsultBudget ||
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_V2))}>
													&nbsp;• Estado modificación créditos v2
													</NavDropdown.Item>
												:
												null
										}

										{
											!profileConsultBudget &&
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION))}>
													&nbsp;• Estado movimiento créditos
													</NavDropdown.Item>
												:
												null
										}

										{
											!profileConsultBudget &&
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION))}>
													&nbsp;• Listado pagos
													</NavDropdown.Item>
												:
												null
										}

										{
											!profileConsultBudget &&
											reportsPermissions['execution-reports']?.canView
												&&
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_V2))}>
													&nbsp;• Listado pagos v2
													</NavDropdown.Item>
										}

										{
											/* 	reportsPermissions['execution-reports']?.canView */
											false
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION))}>
													&nbsp;• Estado Saldos
													</NavDropdown.Item>
												:
												null
										}

										{										
											reportsPermissions['execution-reports']?.canView && (isAvailableYearReport(2022) || isAvailableShowActionsRp(2023) || isAvailableShowActionsRp(2024))
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES))}>
													&nbsp;• Pendientes de Resolución de Residuos Pasivos
													</NavDropdown.Item>
												:
												null
										}

										{/* TODO: se descomentara este reporte cuando este creado desde el backend*/}
										{/* {
											!profileNameHTC && reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_DELAYED_FILES_CREDIT_EXECUTION))}>
													&nbsp;• Expedientes demorados
													</NavDropdown.Item>
												:
												null
										} */}
										{/* TODO: se descomentara este reporte cuando este creado desde el backend */}
										
										{
											//Se aplica el permiso de [regions] por que solo tienen acceso el perfil de Super Admin y Dirección de Análisis e Informática 
											!profileNameHTC && regionsPermissions?.canCreate
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_TABLE_TOTALS_CREDIT_EXECUTION))}>
													&nbsp;• Totales
													</NavDropdown.Item>
												:
												null
										}

										{/* TODO: se descomentara este reporte cuando este creado desde el backend*/}
										{/* {
											!profileNameHTC && reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION))}>
													&nbsp;• Registro analítico presupuesto obras
													</NavDropdown.Item>
												:
												null
										} */}
										
										{
											(reportsPermissions['execution-reports']?.canView && (profileNameSA || profileBudgetDirection || profileConsultBudget || profileBudgetAdministrator))
												&&
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_ACCRUAL_BASIS))}>
													&nbsp;• Base Caja Devengado
													</NavDropdown.Item>
										}
										
										{
											reportsPermissions['execution-reports']?.canView
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
													&nbsp;• Reportes generados
													</NavDropdown.Item>
												:
												null
										}

									</>
								</DropdownSubmenu>


								:
								undefined
						}

						{
							fundRequestsPermissions?.canView || paymentOrdersPermissions?.canView || deliveryOrdersPermissions?.canView &&
								!profileNameHTC
								?
								<DropdownSubmenu href="" title="Ejecución financiera">


									<>
										{
											/* !profileNameHTC &&  deliveryOrdersPermissions?.canView */
											true
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_DELIVERY_ORDERS_FUND_REQUEST))}>
													&nbsp;• Órdenes entrega
													</NavDropdown.Item>
												:
												null
										}

										{
											/* !profileNameHTC && fundRequestsPermissions?.canView  */
											true
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST))}>
													&nbsp;• Órdenes pago por servicio
													</NavDropdown.Item>
												:
												null
										}

										{
											/* !profileNameHTC && fundRequestsPermissions?.canView  */
											// true
											// 	?
											// 	<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_FUND_REQUEST))}>
											// 		&nbsp;• Pedido fondos
											// 		</NavDropdown.Item>
											// 	:
											// 	null
										}

										{
											/* 	 !profileNameHTC && fundRequestsPermissions?.canView */
											true
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_BANK_ACCOUNT_FUND_REQUEST))}>
													&nbsp;• Pedido fondos por cuenta bancaria
													</NavDropdown.Item>
												:
												null
										}

										{
											/* 	 !profileNameHTC && fundRequestsPermissions?.canView */
											true
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_PROVIDER_FUND_REQUEST))}>
													&nbsp;• Pedido fondos por proveedor
													</NavDropdown.Item>
												:
												null
										}

										{
											/*  !profileNameHTC && fundRequestsPermissions?.canView */
											true
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_SERVICE_DIRECTION_FUND_REQUEST))}>
													&nbsp;• Pedido fondos por servicio
													</NavDropdown.Item>
												:
												null
										}

										{
											/*  !profileNameHTC && fundRequestsPermissions?.canView */
											false
												?
												<NavDropdown.Item onClick={() => dispatch(push(ROUTES.REPORT_NEW_FUND_REQUEST))}>
													&nbsp;• Nuevo Pedido Fondos
													</NavDropdown.Item>
												:
												null
										}



									</>
								</DropdownSubmenu>


								:
								undefined
						}
						{/*
							/* !profileNameHTC && fundRequestsPermissions?.canView
							true
								?
								<Accordion>
									<Accordion.Toggle as={Button} variant='link' eventKey='1' className='dropdown-item font-weight-bold rounded-0' onClick={() => dispatch(push(ROUTES.REPORT_BY_ADMINISTRATIVE_VOUCHER))}>
										Consulta de Expedientes
									</Accordion.Toggle>
								</Accordion>
								:
								null
						*/}
					</NavDropdown>
					:
					null
			}

			{/*
				budgetItemControlPermissions?.canView
					&&
					<Nav.Item onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >
						<Nav.Link className='nav-level-1' onClick={() => dispatch(push(ROUTES.BUDGET_ITEM_CONTROL))}>
							<FontAwesomeIcon icon={faEye} className='mr-1' />
							Control partida
						</Nav.Link>
					</Nav.Item>
			*/}

			{
				auditsPermissions?.canView
					&&
					<NavDropdown className='active' title={auditTitle} onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}>
						<NavDropdown.Item className="dropdown-item" onClick={() => dispatch(push(ROUTES.AUDITS_LIST))}>
							Presupuesto
						</NavDropdown.Item>
						<NavDropdown.Item className="dropdown-item" onClick={() => dispatch(push(ROUTES.AUDITS_USERS))}>
							Usuarios
						</NavDropdown.Item>
					</NavDropdown>
			}

			{
				periodsPermissions?.canUpdate
					|| servicesPermissions?.canUpdate
					|| organismsPermissions?.canUpdate
					?
					<NavDropdown className='active' title={configTitle} onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >
						{
							(profileName == 'Super Admin')
							&&
							<NavDropdown.Item onClick={() => dispatch(push(ROUTES.ALERT_LIST))}>								
								Alertas
							</NavDropdown.Item>
						}
						{
							periodsPermissions?.canUpdate
								&&
								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.EXERCISES_LIST))}>
									Ejercicios
								</NavDropdown.Item>
						}

						{
							periodsPermissions?.canUpdate
								&&
								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.EXPEDIENT_LIST))}>
									Expedientes
								</NavDropdown.Item>
						}

						{
							servicesPermissions?.canUpdate &&
								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.SERVICE_ADMINISTRATIVE_LIST))}>
									Servicios
								</NavDropdown.Item>
						}
						{
							organismsPermissions?.canUpdate
								&&

								<NavDropdown.Item onClick={() => dispatch(push(ROUTES.ADMINISTRATIVE_ORGANISM_LIST))}>
									Organismos
								</NavDropdown.Item>
						}
						{
							(profileName == 'Super Admin' || profileName =='Dirección de Análisis e Informática')
							&&
							<NavDropdown.Item onClick={() => dispatch(push(ROUTES.CAPTION_LIST))}>								
								Leyendas
							</NavDropdown.Item>
						}
						{
							(profileName == 'Super Admin')
							&&
							<NavDropdown.Item onClick={() => dispatch(push(ROUTES.MAINTENANCE_SYSTEM_LIST))}>								
								Sistema en Mantenimiento
							</NavDropdown.Item>
						}				
					</NavDropdown>
					:
					null
			}		
			{profileName == profileNameSA || profileName == profileBudgetServiceOperator ||  profileName == profileTreasuryOperator
				||  profileName == profileDirectorSa ||  profileName == profileFundsTransferManager || 
					<Nav.Item className='active' onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}>
						<Nav.Link className='nav-level-1' onClick={() => dispatch(push(ROUTES.NOTIFICATION_LIST))}>
							<FontAwesomeIcon icon={faEnvelope} className='mr-1' />
							Notificaciones
						</Nav.Link>
					</Nav.Item>
			}
			{reportsPermissions &&
			<NavDropdown className='active' title={manualTitle} onClick = { () => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}>
				
				{
					(profileNameSA || !profileNameHTC) && 
					<NavDropdown.Item className="dropdown-item" onClick={downloadUserManualServiceOperator}>
						Manual de Usuario - Operador de Servicio
					</NavDropdown.Item>
				}

				{
					(profileNameSA || profileNameHTC) &&
					<NavDropdown.Item className="dropdown-item" onClick={downloadUserManualProfileHTC}>
						Manual de Usuario - Perfil HTC
					</NavDropdown.Item>
				}
				
			</NavDropdown>
			}
			<AppLoading isLoading={isLoading} />
		</Nav>
	);
};

export default Menus;