import React from 'react';
import { FormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";
import 'react-widgets/dist/css/react-widgets.css';


import MaxDate from 'src/components/common/MaxDate';

const ExerciseServicesDateTo = (props) => {
	const { dateTo , setDateTo, setDateHasErrors, selected, setSelected, options, administrativeServiceIsFetching, hookFormMethods } = props;
	const globalSelectedPeriodYear = props.globalSelectedPeriodYear;

	// Idiom Spanish
	const idiom = 	{
					"selectSomeItems": "Seleccionar una o más opciones...",
					"allItemsAreSelected": "Todos los elementos están seleccionados.",
					"selectAll": "Seleccionar todo",
					"search": "Buscar",
					"clearSearch": "Limpiar búsqueda."
					}

	// Load data 	
	const customValueRenderer = (selected) => {
		return selected.length
			? selected.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
		};
	
	return <>
	
				<Form.Group>
					<Form.Label className='text-black-color'>
						Ejercicio
					</Form.Label>
					<Form.Control 
						type='number' 
						name='globalSelectedPeriod' 
						id='globalSelectedPeriod'
						value={globalSelectedPeriodYear}
						readOnly/>
				</Form.Group>

				<Form.Group>
					<Form.Label className='text-black-color'>
						Servicio
					</Form.Label>
					<MultiSelect
						className='text-black-color'
						options={options}
						value={selected}
						onChange={setSelected}
						labelledBy={"Select"}
						overrideStrings={idiom}
						valueRenderer={customValueRenderer}
						hasSelectAll={true}
						isLoading={administrativeServiceIsFetching}
						ClearSelectedIcon={"🧹Limpiar"}/>
				</Form.Group>

				<FormContext {...hookFormMethods}>
					<MaxDate {...{dateTo, setDateTo, setDateHasErrors}} />
				</FormContext>

	</>;
};

export default ExerciseServicesDateTo;

