import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import DropdownList from 'react-widgets/lib/DropdownList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mFormat, onKeyDownEnter } from 'src/utils/utils';
import { faAsterisk, faSearch, faChevronDown  } from '@fortawesome/free-solid-svg-icons';
import { isNotEmptyArray } from 'src/services/validationService';
import { tryListFundRequests } from 'src/redux/fundRequests/fundRequestsActionCreator';
import { getFundRequestsListData, getFundRequestsToEdit, getFundRequestsListIsFetching } from 'src/redux/fundRequests/fundRequestsReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { setFundRequestsToEdit } from 'src/redux/fundRequests/fundRequestsActions';
import { getBankAccountListData } from 'src/redux/bankAccounts/bankAccountReducer';
import { tryBankAccountGetAll } from 'src/redux/bankAccounts/bankAccountActionCreator';
import { clearBankAccountListData } from 'src/redux/bankAccounts/bankAccountActions';
import moment from 'moment';
import FormFieldError from 'src/components/general/FormFieldError.js';
import { getServicesByExerciseIdData } from 'src/redux/exercise/exerciseReducer.js';
import { administrativeServiceClose } from 'src/utils/administrativeServiceUtils.js';
import InputOrganismForm from 'src/components/common/InputOrganismForm';

const PFCommons = props => {

	const { isFetching,
        administrativeServiceData,
        admServiceId,
        checkToVisualize } = props;

	const dispatch = useDispatch();
	const servicesData = useSelector( state => getServicesByExerciseIdData(state) );
    const { register, setAdmServiceId, bankAccountId, setBankAccountId, getValues, errors } = useFormContext();
	const serviceClosedMsg = 'Servicio cerrado para el Ejercicio seleccionado';
	const serviceRequiredMsg = 'Debe seleccionar un servicio';
	const bankAccountRequiredMessage = 'Debe seleccionar una Cuenta Bancaria.'
    const serviceValidationObj = { 
        required: serviceRequiredMsg, 
        validate: () => { if(administrativeServiceClose(servicesData, admServiceId)) { return serviceClosedMsg } }
    };
	const dateValidationObj = { 
		required: 'Fecha requerida',
		validate: () => {
			const fechaEnEjercicioActual = globalSelectedPeriod?.year == moment(getValues().date).year();
			// if (!fechaEnEjercicioActual){
			// 	return 'La fecha seleccionada debe coincidir con el año del ejercicio';
			// }
		}
	};
	const bankAccountValidationObj = { required: bankAccountRequiredMessage};
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
	const [ bankPrompt, setBankPrompt ] = useState('Selecciona una opción...');
	const [selectedServiceObj, setSelectedServiceObj] = useState({});

	const bankAccountListData = useSelector(state => getBankAccountListData (state))?.records?.map( item => ({
		...item,
		displayName: `${item?.number} - ${item?.description} `
	}));

	const fundRequestEditSelected = useSelector(state => getFundRequestsToEdit(state));
	const fundRequestData = useSelector(state => getFundRequestsListData(state));
	const isFetchingFundRequestData = useSelector(state => getFundRequestsListIsFetching(state));

	const lastFundRequestRecord = fundRequestData?.records[0]?.number;
	const lastFundRequestNumberSetup = admServiceId && (lastFundRequestRecord ? lastFundRequestRecord + 1 : undefined);
	const lastFundRequestNumber = admServiceId && (lastFundRequestNumberSetup ? lastFundRequestNumberSetup : 1);

	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const ejercicioIsCurrentYear = globalSelectedPeriod?.year == moment().year();
    const fechaHoy = mFormat(new Date(), 'YYYY-MM-DD');
	const initialDate = moment(new Date(globalSelectedPeriod?.year, 0, 1)).format('YYYY-MM-DD');
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const finalDate = moment(new Date(globalSelectedPeriod?.year, 11, 31)).format('YYYY-MM-DD');
    let todayDate = (moment(new Date()).format('YYYY') == globalSelectedPeriod?.year) ? currentDate : finalDate;

	const defaultParamsToFindLastPF = {
		administrativeServiceId: admServiceId,
		size: 1,
		sort: 'number,desc',
		year: globalSelectedPeriod?.year
	};

	const fetchList = (params) => {
        dispatch(tryListFundRequests(params));
    };

	const applyFilterBankAccounts = (administrativeServiceId) => {
		let administrativeService = administrativeServiceData?.find(item => (item?.id == administrativeServiceId));
		setSelectedServiceObj(administrativeService);
		setBankAccountId('');
		dispatch(clearBankAccountListData());
		setAdmServiceId(administrativeServiceId);
		delete errors?.admService;
		dispatch(tryBankAccountGetAll({administrativeServiceId}));
	};

	const [formData, setFormData] = useState({
        organismCode: '',
        organismNumber: '',
        organismYear: '',
    });
	const handleChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    };
    const handleOrganismCode = value => handleChange('organismCode', value)
    const handleOrganismNumber = value => handleChange('organismNumber', value)
    const handleOrganismYear = value => handleChange('organismYear', value)

	const handleChangeBankAccount = (value) => {
		delete errors?.bankAccount;
		setBankAccountId(value);
	};

	useEffect(() => {
		if (!fundRequestEditSelected?.id && administrativeServiceData?.length == 1) {
			setAdmServiceId(administrativeServiceData[0]?.id);
			applyFilterBankAccounts(administrativeServiceData[0]?.id);
		};
		if(fundRequestEditSelected){
			applyFilterBankAccounts(fundRequestEditSelected?.administrativeServiceId);
			setBankAccountId(fundRequestEditSelected?.bankAccount?.id)
		} else {
			dispatch(clearBankAccountListData());
			dispatch(setFundRequestsToEdit());
			setBankAccountId('');
		};
	}, []);

	useEffect(() => {
		admServiceId && fetchList(defaultParamsToFindLastPF);
	}, [admServiceId]);

	useEffect(() => {
        isNotEmptyArray(bankAccountListData) ? setBankPrompt('Selecciona una opción...')
            : setBankPrompt('El Servicio seleccionado no posee Cuentas Bancarias asociadas');
	}, [bankAccountListData]);

	useEffect(() => {
		setFormData({
			organismYear: fundRequestEditSelected?.administrativeDocument?.year || '',
			organismNumber: fundRequestEditSelected?.administrativeDocument?.number || '',
			organismCode: fundRequestEditSelected?.administrativeDocument?.codeOrganism || selectedServiceObj?.administrativeServiceOrganismCode || '',
		});
	}, [fundRequestEditSelected, selectedServiceObj])

	return <>
		<Form.Group as={Row}>
			<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Servicio:
			</Form.Label>
			<Col sm='4'>
				<DropdownList
					filter='contains'
					as='select'
					placeholder='Seleccione un servicio...'
					data={administrativeServiceData}
					allowCreate='onFilter'
					name='admService'
					textField='displayName'
					valueField='id'
					value={admServiceId}
					disabled={!!fundRequestEditSelected}
					defaultValue={fundRequestEditSelected?.administrativeServiceId}
					className='w-100'
					onChange={value => applyFilterBankAccounts(value.id)}
					messages={messagesDropDown}
					selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
					searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/> }>
				</DropdownList>
				<FormFieldError errors={errors?.admService} />
                <Form.Control hidden ref={register(serviceValidationObj)} name='admService' value={admServiceId} />
			</Col>
		</Form.Group>

		{ !!fundRequestEditSelected &&
		<Form.Group as={Row}>
			<Form.Label className='text-right d-flex mandatory-label' column sm={4}>
				Número:
			</Form.Label>
			<Col sm='2'>
				{
                    isFetching || isFetchingFundRequestData
                    ?
                    <Spinner animation='border' />
                    :
                    <Form.Control
						type='number'
						name='number'
						ref={register}
						placeholder='Número'
						title='Número'
						defaultValue={fundRequestEditSelected ? fundRequestEditSelected?.number : lastFundRequestNumber}
						disabled={!!fundRequestEditSelected}
						onKeyDown={event => onKeyDownEnter(event)}
					/>
                }
			</Col>
			<Col sm='2'>
				<Form.Control
                    type='number'
                    ref={register}
                    name='fiscalYearId'
					value={fundRequestEditSelected?.year }
                    disabled
                />
			</Col>
			</Form.Group>
		}
		<InputOrganismForm
            formData={formData} 
            handleOrganismCode={handleOrganismCode} 
            handleOrganismNumber={handleOrganismNumber} 
            handleOrganismYear={handleOrganismYear} 
        />

		<Form.Group as={Row}>
			<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Cuenta Bancaria:
			</Form.Label>
			<Col sm='4'>
				<DropdownList
					filter='contains'
					as='select'
					placeholder={bankPrompt}
					data={bankAccountListData}
					allowCreate='onFilter'
					name='bankAccount'
					textField='displayName'
					valueField='id'
					value={bankAccountId}
					defaultValue={fundRequestEditSelected?.bankAccount?.id}
					className='w-100'
					onChange={(value) => handleChangeBankAccount(value?.id)}
					messages={messagesDropDown}
					selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
					searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/> }>
				</DropdownList>
				<FormFieldError errors={errors?.bankAccount} />
                <Form.Control hidden ref={register(bankAccountValidationObj)} name='bankAccount' value={bankAccountId} />
			</Col>
		</Form.Group>

		<Form.Group as={Row}>
			<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
				<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
				Fecha:
			</Form.Label>
			<Col sm='4'>
				<Form.Control
					type='date'
					name='date'
                    defaultValue={fundRequestEditSelected?.fundRequestDate || (ejercicioIsCurrentYear?fechaHoy:undefined)}
					ref={register(dateValidationObj)}
                    max={todayDate}
                    // min={initialDate}
					onKeyDown={event => onKeyDownEnter(event)}
				/>
				<FormFieldError errors={errors?.date} />
			</Col>
		</Form.Group>

		{ checkToVisualize &&
            <Form.Group as={Row}>
				<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                    Fecha de Vencimiento:
                </Form.Label>
                <Col sm='4'>
                    <Form.Control
                        type='date'
                        name='expire_date'
                        ref={register}
                        defaultValue={fundRequestEditSelected?.expiredDate}
                        min={fundRequestEditSelected?.fundRequestDate || (ejercicioIsCurrentYear ? todayDate : undefined)}
						onKeyDown={event => onKeyDownEnter(event)}
                    />
                </Col>
            </Form.Group>
        }

		<Form.Group as={Row}>
			<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
				Descripción:
			</Form.Label>
			<Col sm='4'>
				<Form.Control
					placeholder='Ingrese aquí su descripción'
					name='asunto'
                    ref={register}
                    defaultValue={fundRequestEditSelected?.description}
					maxLength='255'
					onKeyDown={event => onKeyDownEnter(event)}
				/>
			</Col>
		</Form.Group>
	</>
};

export default PFCommons;