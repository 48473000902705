import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext, useForm } from 'react-hook-form';
import { Container, Form, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormFieldError from 'src/components/general/FormFieldError';
import { faAsterisk, faExclamationTriangle, faPlus, faTimes, faSearch, faBroom, faFilter } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import { instrumentRequired } from 'src/utils/label';
import { SumCollectionAmounts, SumCollectionAmountsOP, calcTotalImportes, formatterPeso, getClearObject } from 'src/utils/utils';
import RubrosModal from 'src/components/pages/FundRequests/modal/RubrosModal';
import DetailModalLegalInstrument from 'src/components/pages/FundRequests/modal/DetailModalLegalInstrument';
import RCPagination from 'src/components/common/RCPagination';

const LegalInstrumentBox = props => {

	const dispatch = useDispatch();
	const { selectedFundRequestType, isFetching, tryMethod, clearMethod, metaData, dataRecords } = props;

	//Pagination data
	const page = metaData?.page + 1;
	const totalItemsCount = metaData?.filteredCount ? metaData?.filteredCount : '1';
	const totalItems = metaData?.filteredCount ? metaData?.filteredCount : '0';

	//Parent Form Controller
	const { register, errors } = useFormContext();
	const { admServiceId, selectedItems, setSelectedItems, itemsRelated } = useFormContext();

	//Parent Form Controller Validations
	const instrumentValidationObj = { required: instrumentRequired };

	//Form filters
	const { register: regItem, reset, handleSubmit } = useForm();
	const [filtersToSend, setFiltersToSend] = useState({});

	//Modal Rubros
	const [showRubrosModal, setShowRubrosModal] = useState(false);
    const [activeFundRequestDetailDto, setActiveFundRequestDetailDto] = useState(null);

	const hasItemFetched = dataRecords?.length > 0;
    const hasItemsSelected = selectedItems?.length > 0;

	const relatedAmounts = calcTotalImportes(itemsRelated);

	//Detail Modal
	const [activeLegalInsrument, setActiveLegalInsrument] = useState();
    const [showInsrumentDetailModal, setShowInsrumentDetailModal] = useState(false);
	const handleModalDetalleLegalInstrument = (instrument) => {
		setShowInsrumentDetailModal(true);
		setActiveLegalInsrument(instrument);
	};

	const fetchList = (params) => {
		dispatch(clearMethod());
		dispatch(tryMethod(params));
    };

	const defaultParams = {
        page: 0,
        size: 10,
        sort: 'number,year,administrativeServiceId,asc',
		state: 'BORRADOR',
		administrativeServiceId: admServiceId
    };

    const handleCheckCondition = (item) => {
        return !selectedItems?.some(obj => obj.paymentOrder.id === item.id);
    };

    const clearFilters = () => {
		setFiltersToSend({});
		reset();
		fetchList(defaultParams);
    };

    const stopPropagate = (callback)=>{
		return (e) => {
			e.stopPropagation();
			callback(e);
		};
    };

	const handleSubmitter = (params) => {
		const newFilterObject = getClearObject(params);
		if (!isFetching) {
			fetchList(newFilterObject);
		}
	};

	const onPageChange = (pNumber) => {
		const filterParams = {
			...defaultParams,
			...filtersToSend,
			page: pNumber - 1,
		};
		handleSubmitter(filterParams);
    };

	const onSubmitFormFilter = (data) => {
		const filterParams = {
			...defaultParams,
			...data
		};
		setFiltersToSend(data);
		handleSubmitter(filterParams);
	};

	const addLegalInstrumentToSelectionList = instrument => {
		let fundRequestDetailDto = {
			paymentOrder: instrument
		};
		setSelectedItems(prevArray => [...prevArray, fundRequestDetailDto]);
	};

	const removeLegalInstrumentFromSelectionList = instrumentDto => {
		setSelectedItems(selectedItems.filter(item => item.paymentOrder.id !== instrumentDto.id));
	};

	const openModalRubro = fundRequestDetailDto => {
		setShowRubrosModal(true);
		setActiveFundRequestDetailDto(fundRequestDetailDto);
	};

	const removeRubroFromFundRequestDetailDto = fundRequestDetailDto => {
		setSelectedItems(selectedItems.map(item => {
			if (item.paymentOrder.id == fundRequestDetailDto.paymentOrder.id){
				item.categoryCode = null;
			}
			return item;
		}));
	};

	useEffect(() => {
		clearFilters();
	}, [admServiceId]);

	return <>
	<br></br>
	<h6 className='text-black-color mb-3'>Instrumentos Legales disponibles</h6>
	{ admServiceId &&
		<>
			<Form onSubmit={stopPropagate(handleSubmit(onSubmitFormFilter))}>
				<Table striped bordered hover size='sm' >
					<thead>
						<tr className='secondary'>
							<th className='text-center align-middle text-white' width="15%">Tipo Instrumento Legal</th>
							<th className='text-center align-middle text-white' width="15%">Organismo</th>
							<th className='text-center align-middle text-white' width="48%">Descripción</th>
							<th className='text-center align-middle text-white' width="12%">Importe</th>
							<th className='text-center align-middle text-white th-minwidth'>Acciones</th>
						</tr>
						<tr>
							<th className='text-center align-middle'>
								<Form.Control className='text-center' size='sm' type='text' name='intrumenType' placeholder='Ingresar un tipo de instrumento' ref={regItem}/>
							</th>
							<th className='text-center align-middle'></th>
							<th className='text-center align-middle'>
								<Form.Control className='text-center' size='sm' type='text' name='description' placeholder='Ingresar una descripción para buscar' ref={regItem}/>
							</th>
							<th className='text-center align-middle'></th>
							<th className='text-center align-middle'>
								<div className='d-flex justify-content-around'>
									<ActionIcon
										size="lg"
										id="search-button"
										className="btn-primary search-button text-white-color"
										toolTipText="Filtrar"
										icon={faFilter}
										type='submit'
									/>
									<ActionIcon
										size="lg"
										id="clean-filter"
										className="btn-primary clean-filter text-white-color"
										toolTipText="Limpiar filtros"
										icon={faBroom}
										onClick={()=> clearFilters()}
									/>
								</div>
							</th>
						</tr>

					</thead>
					<tbody className='text-black-color'>
						{
							hasItemFetched ?
								<>
									{dataRecords?.map((item, index) => (
										<tr key={item.id}>
											<td className='text-center align-middle'>
											{item?.administrativeDocument?.codeOrganism}-{item?.administrativeDocument?.number}/{item?.administrativeDocument?.year}
											</td>
											<td className='text-center align-middle'>
												{item?.number}  -  {item?.year}
											</td>
											<td className='text-center align-middle'>
												{item?.description}
											</td>
											<td className='text-right align-middle'>
												{formatterPeso.format(SumCollectionAmounts(item?.details))}
											</td>
											<td className='text-center align-middle p-0'>
												<ActionIcon size='lg' id={'details-instrument' + index} toolTipText='Detalle del Instrumento Legal' icon={faSearch} onClick={() => handleModalDetalleLegalInstrument(item)} />
												{
													handleCheckCondition(item)?
														<ActionIcon className="ml-2" size='lg' id={'add-intrument' + index} toolTipText='Seleccionar instrumento legal' icon={faPlus} onClick={() => addLegalInstrumentToSelectionList(item)} />
														:
														<ActionIcon size='lg' id={'remove-legal-instrumentt' + index} toolTipText='Quitar de la selección' icon={faTimes} className='text-danger ml-2' onClick={() => removeLegalInstrumentFromSelectionList(item)} />
												}
											</td>
										</tr>
									))}
								</>
								:
								<tr>
									<td colSpan='13' className='text-center'>
										{
											!hasItemFetched &&
												<Alert className='mb-0' variant='info' show={!isFetching}>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No hay registros
												</Alert>
										}
									</td>
								</tr>
						}
					</tbody>
				</Table>
				<RCPagination
					activePage={page}
					itemsCountPerPage={defaultParams.size}
					totalItemsCount={totalItemsCount}
					pageRangeDisplayed={7}
					thePage={page}
					totalItems={totalItems}
					innerClass="justify-content-center"
					onChange={onPageChange}
				/>
			</Form>
		</>
	}

	<RubrosModal
		setShowRubrosModal={setShowRubrosModal}
		showRubrosModal={showRubrosModal}
		setSelectedItems={setSelectedItems}
		selectedItems={selectedItems}
		setActiveFundRequestDetailDto={setActiveFundRequestDetailDto}
		activeFundRequestDetailDto={activeFundRequestDetailDto}
	/>

	<DetailModalLegalInstrument
		showInsrumentDetailModal={showInsrumentDetailModal}
		setShowInsrumentDetailModal={setShowInsrumentDetailModal}
		activeLegalInsrument={activeLegalInsrument}
		selectedFundRequestType={selectedFundRequestType}
	/>

	<Form.Group className='d-inline'>
		<Form.Label className='text-black-color pr-0 d-flex' >
			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger' />
			<h6>Instrumentos Legales seleccionados:</h6>
		</Form.Label>
		<Form.Control value={hasItemsSelected ? 'true' : ''} type='hidden' name='hasItemsSelected' ref={register(instrumentValidationObj)} />
		{!hasItemsSelected &&
			<>
				<FormFieldError errors={errors?.hasItemsSelected} />
			</>
		}
	</Form.Group>
	{ hasItemsSelected &&
		<>
			<Table striped bordered hover size='sm'>
				<thead>
					<tr>
						<th className='text-center text-white' width="15%">Instrumento Legal</th>
						<th className='text-center text-white' width="20%">Rubro</th>
						<th className='text-center text-white' width="40%">Descripción</th>
						<th className='text-center text-white' width="15%">Importe</th>
						<th className='text-center align-middle text-white th-minwidth'>Acciones</th>

					</tr>
				</thead>
				<tbody className='text-black-color'>
					{
						selectedItems.map((item, i) => (
							<tr key={item.id}>
								<td className='text-center align-middle'>
									{item?.paymentOrder?.number}	-	{item?.paymentOrder?.year}
								</td>
								<td className='text-center align-middle'>
									<Container fluid>
										<div className="row" onClick={()=> openModalRubro(item)} >
											<input required
												className="freeze-input text-center col-md-7 offset-md-2 shadow p-2 bg-white rounded"
												type="text"
												autoComplete='off'
												id={'catCode' + i}
												value={(item?.categoryCode) ? item.categoryCode: ''} />
											<div className="col-md-3">
												{!(item?.categoryCode)?
													<ActionIcon size='lg' id={'add-rubro' + i} toolTipText='Seleccione Rubro' icon={faPlus} />
													:
													<ActionIcon size='lg' id={'remove-rubro' + i} toolTipText='Quitar Rubro' icon={faTimes} className='text-danger' onClick={(e) => {e.stopPropagation() ; removeRubroFromFundRequestDetailDto(item)}} />
												}
											</div>
										</div>
									</Container>
								</td>
								<td className='text-center align-middle'>
									{item?.paymentOrder?.description}
								</td>
								<td className='text-right align-middle'>
									{formatterPeso.format(SumCollectionAmountsOP(item?.paymentOrder?.details))}
								</td>
								<td className='text-center align-middle'>
									<ActionIcon size='lg' id={'remove-intrument' + i} toolTipText='Quitar de la selección' icon={faTimes} className='text-danger' onClick={() => removeLegalInstrumentFromSelectionList(item.paymentOrder)} />
								</td>
							</tr>
						))
					}
				</tbody>
			</Table>
		</>
	}

	<h6 className="text-right">Importe Pedido de Fondos {formatterPeso.format(calcTotalImportes(selectedItems, relatedAmounts))}</h6>
	</>

};

export default LegalInstrumentBox;