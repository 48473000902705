import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import SuperTreeView from 'react-super-treeview';

const BudgetItemControlTree = props => {

    const { data, isFetching } = props;

    const [ treeData, setTreeData ] = useState();

    const hasData = !!data;
    
	const falseCB = () => false;
	const updateCB = (updatedData)=>{
        setTreeData(updatedData);
    };
    
    useEffect( () => {
        if(data) {
            setTreeData(data);
        }
    }, [data]);

	return <div className='text-black-color'>
        {
            !isFetching &&
                <>
                    {
                        hasData &&
                        
                            <Card className='px-3 py-2'>
                                <div className='d-flex justify-content-start font-weight-bold mt-2 mx-3'>
                                    <span>
                                        Concepto
                                    </span>
                                    {/* <span>
                                        Crédito original
                                    </span> */}
                                </div>
                                <hr/>
                                <SuperTreeView
                                    noChildrenAvailableMessage=''
                                    isCheckable={falseCB}
                                    isDeletable={falseCB}
                                    isExpandable={node => !!node.children}
                                    data={treeData}
                                    onUpdateCb={updateCB}/>
                            </Card>
                    }
                </>
        }
	</div>
};

export default BudgetItemControlTree;