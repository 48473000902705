import React from 'react';
import { Container, Card, Form, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faEquals } from '@fortawesome/free-solid-svg-icons';

import { formatterPeso, numberNegativeRed } from 'src/utils/utils';

const CreditExecutionBalanceDetailComponent = props => {

	// Credit details
	const subcodeData = props?.data;

	const currentCredit = subcodeData?.credit?.currentCredit;
	const accumulatorPreventive = subcodeData?.balance?.totals?.preventive;
	const accumulatorDefinitive = subcodeData?.balance?.totals?.definitive;
	const accumulatorPaymentObligation = subcodeData?.balance?.totals?.payment_obligation;
	const accumulatorPayment = subcodeData?.balance?.totals?.payment;

	// Calculator
	//Crédito actual - Acumulador Preventiva = Saldo Preventiva
	const balancePreventive = currentCredit - accumulatorPreventive;
	const balancePreventiveFormatter = formatterPeso.format(balancePreventive);

	// Acumulador Preventiva - Acumulador Definitiva = Saldo Definitiva
	const balanceDefinitive = accumulatorPreventive - accumulatorDefinitive;
	const balanceDefinitiveFormatter = formatterPeso.format(balanceDefinitive);

	//Acumulador Definitiva - Acumulador obl. de pago = Saldo obl. de pago
	const balancePaymentObligation = accumulatorDefinitive - accumulatorPaymentObligation;
	const balancePaymentObligationFormatter = formatterPeso.format(balancePaymentObligation);

	//Acumulador obl. de pago - Acumulador pago = Saldo pago
	const balancePayment = accumulatorPaymentObligation - accumulatorPayment;
	const balancePaymentFormatter = formatterPeso.format(balancePayment);
	// End Calculator

	// Amount Credit
	const currentCreditAmountFormatter = formatterPeso.format(currentCredit);

	const totalsPreventiveCreditAmountFormatter = formatterPeso.format(accumulatorPreventive);
	const totalsDefinitiveCreditAmountFormatter = formatterPeso.format(accumulatorDefinitive);
	const totalsPaymentObligationCreditAmountFormatter = formatterPeso.format(accumulatorPaymentObligation);
	const totalsPaymentCreditAmountFormatter = formatterPeso.format(accumulatorPayment);

	return <Container fluid>
		<Card>
			<Card.Header>
				<h5 className='text-center mb-0'>{props?.title}</h5>
			</Card.Header>
			<Card.Body>
				<Table striped borderless hover >
					<tbody>
						<tr>
							<Row>
								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
										<Form.Label className="text-black-color" column sm="5">
											Crédito actual:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(currentCredit)} text-right p-2`}>
											{currentCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faMinus} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="accumulatorPreventive">
										<Form.Label className="text-black-color" column sm="5">
											Acumulador Preventiva:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(accumulatorPreventive)} text-right p-2`}>
											{totalsPreventiveCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faEquals} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={3} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="preventiveBalance">
										<Form.Label className="text-black-color" column sm="5">
											Saldo Preventiva:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(balancePreventive)} text-right p-2`}>
											{balancePreventiveFormatter}
										</Col>
									</Form.Group>
								</Col>
							</Row>
						</tr>

						<tr>
							<Row>
								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="accumaltorPreventive2">
										<Form.Label className="text-black-color" column sm="5">
											Acumulador Preventiva:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(accumulatorPreventive)} text-right p-2`}>
											{totalsPreventiveCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faMinus} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive">
										<Form.Label className="text-black-color" column sm="5">
											Acumulador Definitiva:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(accumulatorDefinitive)} text-right p-2`}>
											{totalsDefinitiveCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faEquals} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={3} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="definitiveBalance">
										<Form.Label className="text-black-color" column sm="5">
											Saldo Definitiva:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(balanceDefinitive)} text-right p-2`}>
											{balanceDefinitiveFormatter}
										</Col>
									</Form.Group>
								</Col>
							</Row>
						</tr>

						<tr>
							<Row>
								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive2">
										<Form.Label className="text-black-color" column sm="5">
											Acumulador Definitiva:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(accumulatorDefinitive)} text-right p-2`}>
											{totalsDefinitiveCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faMinus} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation">
										<Form.Label className="text-black-color" column sm="5">
											Acumulador Obl. Pago:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(accumulatorPaymentObligation)} text-right p-2`}>
											{totalsPaymentObligationCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faEquals} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={3} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="balancePaymentObligation">
										<Form.Label className="text-black-color" column sm="5">
											Saldo Obl. Pago:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(balancePaymentObligation)} text-right p-2`}>
											{balancePaymentObligationFormatter}
										</Col>
									</Form.Group>
								</Col>
							</Row>
						</tr>

						<tr>
							<Row>
								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation3">
										<Form.Label className="text-black-color" column sm="5">
											Acumulador Obl. Pago:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(accumulatorPaymentObligation)} text-right p-2`}>
											{totalsPaymentObligationCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faMinus} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={4} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="accumulatorPayment">
										<Form.Label className="text-black-color" column sm="5">
											Acumulador pago:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(accumulatorPayment)} text-right p-2`}>
											{totalsPaymentCreditAmountFormatter}
										</Col>
									</Form.Group>
								</Col>

								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
										<FontAwesomeIcon icon={faEquals} size='lg' />
									</Col>
								</Form.Group>

								<Col xs={3} className='text-right text-black-color'>
									<Form.Group className='mb-0' as={Row} controlId="paymentBalance">
										<Form.Label className="text-black-color" column sm="5">
											Saldo pago:
										</Form.Label>
										<Col sm="6" className={`${numberNegativeRed(balancePayment)} text-right p-2`}>
											{balancePaymentFormatter}
										</Col>
									</Form.Group>
								</Col>
							</Row>
						</tr>
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	</Container>;
};

export default CreditExecutionBalanceDetailComponent;