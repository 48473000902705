import React from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm, FormContext } from 'react-hook-form';

import { Container, Card, Form, Button, Spinner} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import { showWarning } from 'src/redux/globalData/globalDataActionCreator';

import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { getSubpartialBudgetOfSelectedCreditsIsFetching } from 'src/redux/partialBudget/partialBudgetReducer';
import { getSelectedLegalInstrument, getCompensationCreateIsFetching } from 'src/redux/subcode/subcodeReducer';

import { isNotEmptyArray } from 'src/services/validationService';

import { focusSelectInputByElementID, parseIntOrUndefined } from 'src/utils/utils';
import { COMPENSATION_STEP_ONE, COMPENSATION_STEP_THREE } from 'src/utils/constants';

import { cancel, accept, back, newCompensation, impactsBudgetItems, confirmationNewCompensation, answersSendNewACompensation, saveAndFinish } from 'src/utils/label';

import BudgetItems from 'src/components/pages/BudgetItems';

import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';

import { tryPostCompensation } from 'src/redux/subcode/subcodeActionCreator';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getPreloadState } from 'src/redux/subcode/subcodeReducer';

import SelectedCompensationBudgetItems from './SelectedCompensationBudgetItems';

import AppLoading from 'src/components/common/AppLoading';

const CompensationStepTwoPage = () => {

	const dispatch = useDispatch();

	const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;

	const selectedCredits = useSelector(state => getNewAffectationSelectedCredits(state));
	
	const newCompensationIsFetching = useSelector(state => getCompensationCreateIsFetching(state));

	const isFetchingSubpartialBudgetOfSelectedCredits = useSelector(state => getSubpartialBudgetOfSelectedCreditsIsFetching(state));

	const hasSelectedCredits = isNotEmptyArray(selectedCredits);
	const enableNextStepButton = hasSelectedCredits;

	// Administrative document data
	const administrativeDocumentData = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

	// Selected legal instrument
	const selectedLegalInstrument = useSelector( state => getSelectedLegalInstrument(state) );

	// Preload
	const preloadFilter = useSelector(state => getPreloadState(state))?.data;

	// Selected period
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state));
	const globalSelectedPeriodId = globalSelectedPeriod?.id;

	// Compensation list
	const selectedCreditsToCompensate = selectedCredits?.map(item => {
		if (!item?.hasOwnProperty('compensationData')) {
			item.compensationData = {
				amount: undefined,
                resultingCredit: item?.credit?.currentCredit
			};
		}
		return item;
	});

    const hasSelectedCreditsToCompensate = isNotEmptyArray(selectedCreditsToCompensate);

	//Validation

	const validAmounts = () => {
		let valid = true;

		selectedCredits.map(item => {
			if (isNaN(item?.compensationData?.amount) || item?.compensationData?.amount == 0) {
				valid = false;
			}
		});

		return valid;
	};

	
	const onClickNextStepButton = () => {

		if(!validAmounts()){
			showWarning( dispatch, `Debe cargar todos los importes correctamente.` );
		} else {
			swal({
				title: confirmationNewCompensation,
				text: answersSendNewACompensation,
				icon: 'warning',
				buttons: [cancel, accept]
			})
				.then((userAccept) => {
					if (userAccept) {
						submitNewACompensations();
					}
			});
		}
	};

	const submitNewACompensations = () => {

		let subcodes = [];

		if(hasSelectedCreditsToCompensate){
			subcodes = selectedCreditsToCompensate.map(item => {
				return {
					...administrativeDocumentData,
					organizationCode: parseIntOrUndefined(administrativeDocumentData?.organizationCode),
					amount: item?.compensationData?.amount,
					sub_code_id: item?.id,
					legal_instrument_id: preloadFilter? undefined : selectedLegalInstrument?.id,
					period_id: globalSelectedPeriodId,
					sub_code_detail_type_id: 2,
					observation: null
				};
			});
		};

		dispatch(tryPostCompensation({ subcodes, preloadFilter }))
			.then(response => {
				if (response?.status == 200) {
					dispatch(push(COMPENSATION_STEP_THREE));
				}
		});
		
	};

	const onClickBackButton = () => { 
		dispatch(push(COMPENSATION_STEP_ONE)) 
	};

	const onFormSubmit = data => {
		focusSelectInputByElementID('serviceid')
	};

	return <Container fluid >
		<Card className='mb-5'>
			<PageTitle text={impactsBudgetItems}/>
			<Container fluid >
				<StepIndicator steps={config.appSettings.steps.compensation} title={config.appSettings.steps.compensation} current={1} className='mt-2 mb-3' />
				<Card className='mb-3'>

					<Card.Header className='h6'>
						<div className='d-flex align-items-center mb-1'>
							{newCompensation}
							<Form.Group className='text-white-color mt-0 mb-0 ml-5 mr-0 text-left font-weight-bold font-italic'>
								<Form.Check.Input style={{ "transform": 'scale(1.5)'}} disabled checked={preloadFilter}/>
								<Form.Check.Label className='ml-1'>PRECARGA</Form.Check.Label>
							</Form.Group>
						</div>
					</Card.Header>

					<Card.Body>
						<BudgetItems/>
						<FormContext {...hookFormMethods} >

							<Form onSubmit={handleSubmit(onFormSubmit)} autocomplete='off'>

                                <SelectedCompensationBudgetItems 
									selectedCreditsToCompensate = {selectedCreditsToCompensate}
									hasSelectedCreditsToCompensate = {hasSelectedCreditsToCompensate}
								/>
								<Button type ='submit' hidden></Button>
                            </Form>
                        </FormContext>

						<Form.Group className='mt-4 d-flex justify-content-around'>

							<Button 
								variant='danger' 
								size='lg' 
								onClick={onClickBackButton} 
							>
								<FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
								{back}
							</Button>

							<span 
								className={isFetchingSubpartialBudgetOfSelectedCredits ? '' : 'hidden'}
							>
								<Spinner 
									animation='border' 
								/>
							</span>

							<Button 
								variant='success' 
								size='lg'  
								disabled={!enableNextStepButton || isFetchingSubpartialBudgetOfSelectedCredits || newCompensationIsFetching} 
								onClick={onClickNextStepButton}
							>
								{saveAndFinish}
								<FontAwesomeIcon className='ml-3' icon={faSave} />
							</Button>

						</Form.Group>

					</Card.Body>
				</Card>

				<AppLoading 
					isLoading={newCompensationIsFetching} 
				/>

			</Container>
		</Card>
	</Container>;
};

export default CompensationStepTwoPage;
