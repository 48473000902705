import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Container, Card, Table, Button, FormControl, Alert, Form } from 'react-bootstrap';
import { captions, newCaption, year, caption, actions, filterCaption, noCaption, questionDeleteCaption, alert, filterByYear } from 'src/utils/label';
import { faPlus, faEdit, faTrash, faExclamationTriangle, faBroom, faFilter } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import PageTitle from 'src/components/general/PageTitle';
import AppLoading from 'src/components/common/AppLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getCaptionListData, getCaptionListIsFetching } from 'src/redux/caption/captionReducer';
import { tryGetCaptionList, tryDeleteCaption } from 'src/redux/caption/captionActionCreator';
import { clearCaptionList, setCaptionToEdit } from 'src/redux/caption/captionActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { push } from 'connected-react-router';
import axios from 'axios';
import { getClearObject, getCurrentYear } from 'src/utils/utils';

import { CAPTION_NEW, CAPTION_EDIT } from 'src/utils/constants';

import NumberFormat from 'react-number-format';

const CaptionListPage = props => {

	const dispatch = useDispatch();
	const { setValue, control, handleSubmit, register, reset } = useForm();
	const captionListingData = useSelector(state => getCaptionListData(state));
	const captionIsFetching = useSelector(state => getCaptionListIsFetching(state));
	const captionListArrayRecords = captionListingData?.records;
	const hasListCaption= isNotEmptyArray(captionListArrayRecords);
	const currentYear = getCurrentYear();


	// Cancel Request
    const CancelToken = axios.CancelToken;
    let cancelSource = CancelToken.source();

	// Filter
	let newFilterObject;

	const defaultParams = {
        page: 0,
        size: 50,
		sort: 'year,desc'
    }

	const loadCaptions = () => {
		fetchList(defaultParams);
	};

	const fetchList = (params) => {
		dispatch(clearCaptionList());
		dispatch(tryGetCaptionList(params));
	};

	const clearFilters = () => {
		setValue('year', '');
		reset();
		fetchList(defaultParams);
	};

	// Delete
	const onClickDelete = caption => {

		const year = caption?.year;

		swal({
			title: alert,
			text: '¿ ' + questionDeleteCaption + year + '?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					dispatch(tryDeleteCaption(year)).then(
						response => {
							if (response?.status == 204) {
								loadCaptions();
							}
						}
					);
				}
			});
		
	};

	// Edit caption
	const onClickEdit = caption => {
		dispatch(setCaptionToEdit(caption));
		dispatch(push(CAPTION_EDIT));
	};

	//Show edit
	const showEdit = (caption) => {
		const year = caption?.year;

		if(year < currentYear){
			return false;
		}else {
			return true
		}
	};

	//Show delete
	const showDelete = (caption) => {
		const year = caption?.year;

		if(year <= currentYear){
			return false;
		}else{
			return true;
		}
	};

	const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		const maxValue = 9999;
		if (value <= maxValue) 
		return inputObj;
	}

	const onSubmitForm = (data) => {
		if (!captionIsFetching) {
			cancelSource.cancel();
            cancelSource = CancelToken.source();
            newFilterObject = {
				...data,
                ...defaultParams
             };
            newFilterObject = getClearObject(newFilterObject);
            fetchList(newFilterObject);
			}
	}
	// useEffects
	useEffect(() => {
		loadCaptions()
	}, [ ]);

	return <Container fluid className='mb-5'>
		<Card>
			<PageTitle text={captions} />
			<Container fluid>
				<Card className='mb-3 '>
					<Card.Body>
						<Button size='sm' className='mb-3 btn-success' variant='secondary' onClick={() => dispatch(push(CAPTION_NEW))}>
							<FontAwesomeIcon icon={faPlus} className='mr-1' />
							{newCaption}
						</Button>
						<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
							<Table striped bordered hover size='sm'>
								<thead>
									<tr>
										<th className='text-center align-middle' width="10%">{year}</th>
										<th className='text-center align-middle' width="55%">{caption}</th>
										<th className='text-center align-middle th-minwidth' width="7%">{actions}</th>
									</tr>

									<tr className='secondary'>
										<th className='text-center' colSpan='1'>
											<Controller 
												name='year'
												control={control}
												ref={register}
												as = {
													<NumberFormat
														decimalScale={0}
														inputMode='numeric'
														className="form-control form-control-sm"
														placeholder={filterByYear}
														allowNegative={false}
														allowLeadingZeros={false}
														isAllowed={(inputObj) => withValueLimit(inputObj)}
													/>
												}
											/>
										</th>
										<th className='text-center' colSpan='1'>
											<FormControl
												size='sm'
												type='text'
												maxlength='400'
												name='caption'
												ref={register}
												placeholder={filterCaption}
											/>
										</th>
										<th className='text-center align-middle'>
											<div className='d-flex justify-content-around'>
												<ActionIcon
													size="lg"
													id="search-button"
													className="btn-primary search-button text-white-color"
													toolTipText="Filtrar"
													icon={faFilter}
													type='submit'
													onSubmit={onSubmitForm}

													/>
												<ActionIcon
													size="lg"
													id="clean-filter"
													className="btn-primary clean-filter text-white-color"
													toolTipText="Limpiar filtros"
													icon={faBroom}
													type='reset'
													onClick={()=> clearFilters()}
													/>
											</div>
                                    </th>
									</tr>
								</thead>

								<tbody className='text-black-color'>
								{
									hasListCaption ?
										captionListArrayRecords?.map(caption =>
											<tr key={caption.year}>
												<td className='text-center'>
													{caption?.year}
												</td>
												<td className='text-center'>
													{caption?.caption}
												</td>
												<td className='d-flex justify-content-around'>
													<div className='col-4'>
														{
															showEdit(caption)
																&&
																<ActionIcon
																	size='lg'
																	id='search'
																	toolTipText='Editar'
																	icon={faEdit}
																	onClick={() => onClickEdit(caption)}
																/>
														}
													</div>
													<div className='col-4'>
														{
															showDelete(caption)
																&&
																<ActionIcon
																	size='lg'
																	id='delete'
																	toolTipText='Borrar'
																	icon={faTrash}
																	onClick={() => onClickDelete(caption)}
																/>
														}
													</div>
												</td>
											</tr>)
											:
											<tr>
												<td colSpan='10' className='text-center'>
													{
														!captionIsFetching &&
															<Alert variant='info' className='mb-0'>
																<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																{noCaption}
															</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
		<AppLoading isLoading={captionIsFetching} />
	</Container>;
};

export default CaptionListPage;