import * as SERVICES from 'src/services/notificationServices';
import * as ACTIONS from './notificationActions';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError, showWarning } from 'src/redux/globalData/globalDataActionCreator';
import { downloadStreamFile } from 'src/utils/utils';

import { push } from 'connected-react-router';

//Notification list
export const tryGetNotificationList = (params) => {

	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );
		if(accessToken){
			const page = params?.page || 0;
			const pageSize = params?.size || 10;
			
			let paramsToSend = {
				...params,
				page,
				pageSize
			};
			
			dispatch(ACTIONS.getNotificationListRequest());
			const response = await SERVICES.listNotification( accessToken, paramsToSend );

			if( response?.status == 200 ) {
				dispatch( ACTIONS.getNotificationListRequestSuccess(response?.data) );
				return response?.data;
			}
			else if ( response?.status == 204 ) {
				dispatch( ACTIONS.getNotificationListRequestFailure(response?.response?.data || response) );
				showWarning( dispatch, `No se encontraron notificaciones.` );
			}
			else {
				dispatch( ACTIONS.getNotificationListRequestFailure(response?.response?.data || response) );
				showError( dispatch, `Error al cargar las notificaciones. Detalle: ${response?.response?.data?.message || response}` );
			}
		}

	};
};


// GET Notification by user list
export const tryNotificationByUserListRequest = (idUser) => {
	return async ( dispatch, getState ) => {
		const state = getState()
		const accessToken = getAccessToken( state )
		if(accessToken){
			dispatch(ACTIONS.getNotificationByUserListRequest())
			const response = await SERVICES.listNotificationByUser( accessToken, idUser )
			if( response?.status == 200 ) {
				dispatch(ACTIONS.getNotificationByUserListRequestSuccess(response?.data))
			} else if (response?.status == 204) {
				dispatch(ACTIONS.getNotificationListRequestFailure(response?.response?.data || response))
				showWarning(dispatch, `No se encontraron notificaciones.`)
			} else {
				dispatch(ACTIONS.getNotificationListRequestFailure(response?.response?.data || response))
				showError(dispatch, `Error al cargar las notificaciones. Detalle: ${response?.response?.data?.message || response}`)
			}
		}
	}
}
// DELETE Notification by user list
export const tryPostNotificationByUser = (idUser, idNotif) => {
	return async (dispatch, getState) => {
		const state = getState()
		const accessToken = getAccessToken(state)
		dispatch(ACTIONS.postNotificationByUserRequest())
	 	if (accessToken) {
	 		const response = await SERVICES.postNotificationByUser(accessToken, idUser, idNotif)
	 		return response
	 	}
	}
}

//Add New Notification
export const tryAddNewNotification = (params) => {
	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.addNotificationRequest());
		
		if (accessToken) {

			const response = await SERVICES.createNotification(accessToken, params);
			
			if (response?.status == 200) {
				dispatch(ACTIONS.addNotificationRequestSuccess(response?.data));
				showSuccess(dispatch, 'La notificación ha sido enviada correctamente.');
				dispatch(push(ROUTES.NOTIFICATION_LIST));
			} else if(response?.status == 201){
				dispatch(ACTIONS.addNotificationRequestSuccess(response?.data));
				showSuccess(dispatch, 'La notificación ha sido enviada correctamente.');
				dispatch(push(ROUTES.NOTIFICATION_LIST));
			}
			else {
				dispatch(ACTIONS.addNotificationRequestFailure(response?.data?.error));
				showError(dispatch, `Error al enviar la notificación. Detalles: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};

// Download file of notification
export const tryDownloadFileOfNotification = notificationId => {

	return async( dispatch, getState ) => {
		if( !notificationId ) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.downloadFileOfNotificationRequest() );

		if(accessToken){

			const response = await SERVICES.getFileOfNotification( accessToken, notificationId );
			
			if( response?.status == 200 ) {
				dispatch( ACTIONS.downloadFileOfNotificationRequestSuccess() );
				showSuccess( dispatch, 'El archivo fue descargado correctamente.' );
				downloadStreamFile( response, 'notification' );
			}
			else {
				dispatch( ACTIONS.downloadFileOfNotificationRequestFailure(response?.error||response) );
				showError( dispatch, `Error al descargar el archivo. Detalle: ${response?.data?.message || response}` );
			}
		}

	};
};