import * as ACTIONS from './purposeActions';
import * as SERVICES from 'src/services/purposeServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetPurposeList = ( page=1, filter={}, pageSize=100 ) => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getPurposeListRequest() );

		if(accessToken){
			
			const response = await SERVICES.listPurpose( accessToken, page, pageSize, filter );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getPurposeListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getPurposeListRequestFailure(response?.error) );
				showError( dispatch, `Error al cargar los caracteres. Detalle: ${response?.error}` );
			}
		}

	};
};