import * as ACTIONS from './versionActions';
import { showError } from 'src/redux/globalData/globalDataActionCreator';
import * as SERVICES from 'src/services/versionServices';

// Version Funds Requests Api
export const tryGetVersionFundsRequestsApi = () => {
	return async( dispatch ) => {
		
		dispatch( ACTIONS.getVersionFundsRequestsApiRequest() );

		const response = await SERVICES.getVersionFundsRequestsApiService();
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.getVersionFundsRequestsApiRequestSuccess(response?.data));
			return response;
		}
		else {
			dispatch( ACTIONS.getVersionFundsRequestsApiRequestFailure(response?.data) );
			showError( dispatch, `Ha ocurrido un error al obtener la versión de la API Pedidos de Fondos.` );
			return response;
		}

	};
};

// Version Core Api
export const tryGetVersionCoreApi = () => {
	return async( dispatch ) => {
		
		dispatch( ACTIONS.getVersionCoreApiRequest() );

		const response = await SERVICES.getVersionCoreApiService();
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.getVersionCoreApiRequestSuccess(response?.data));
			return response;
		}
		else {
			dispatch( ACTIONS.getVersionCoreApiRequestFailure(response?.data) );
			showError( dispatch, `Ha ocurrido un error al obtener la versión de la API Core.` );
			return response;
		}

	};
};

// Version Presupuesto Api
export const tryGetVersionPresupuestoApi = () => {
	return async( dispatch ) => {
		
		dispatch( ACTIONS.getVersionPresupuestoApiRequest() );

		const response = await SERVICES.getVersionPresupuestoApiService();
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.getVersionPresupuestoApiRequestSuccess(response?.data));
			return response;
		}
		else {
			dispatch( ACTIONS.getVersionPresupuestoApiRequestFailure(response?.data) );
			showError( dispatch, `Ha ocurrido un error al obtener la versión de la API Presupuesto.` );
			return response;
		}

	};
};

// Version Auth Api
export const tryGetVersionAuthApi = () => {
	return async( dispatch ) => {
		
		dispatch( ACTIONS.getVersionAuthApiRequest() );

		const response = await SERVICES.getVersionAuthApiService();
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.getVersionAuthApiRequestSuccess(response?.data));
			return response;
		}
		else {
			dispatch( ACTIONS.getVersionAuthApiRequestFailure(response?.data) );
			showError( dispatch, `Ha ocurrido un error al obtener la versión de la API Auth.` );
			return response;
		}

	};
};