import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { getClearObject } from 'src/utils/utils';
import { faBroom, faFilter  } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import { useForm } from 'react-hook-form';

const BudgetItemControlFilter = props => {
	const { onClickSearch, onClickClean} = props;
	const { handleSubmit, register } = useForm();

	const onClickSearchButton = data => {
		onClickSearch(getClearObject(data));
	};

	return <Form onSubmit={handleSubmit(onClickSearchButton)} autocomplete='off'>
	<Table striped bordered hover size='sm' responsive>
		<thead>
			<tr>
				<th className='text-center align-middle'>Ser.</th>
				<th className='text-center align-middle'>Jur.</th>
				<th className='text-center align-middle'>U. Org.</th>
				<th className='text-center align-middle'>Car.</th>
				<th className='text-center align-middle'>Cta./Cta. E. </th>
				<th className='text-center align-middle'>Fin.</th>
				<th className='text-center align-middle'>Fun.</th>
				<th className='text-center align-middle'>Secc.</th>
				<th className='text-center align-middle'>Sect.</th>
				<th className='text-center align-middle'>P. Princ.</th>
				<th className='text-center align-middle'>P. Parc.</th>
				<th className='text-center align-middle'>Cód.</th>
				<th className='text-center align-middle'>S. Cód.</th>
				<th className='text-center align-middle th-minwidth'>Acciones</th>
			</tr>
			<tr className='secondary'>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='service_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='jurisdiction_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='organization_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='character_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='account_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='purpose_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='functionality_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='section_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='sector_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='principal_budget_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='partial_budget_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='code_number'
						ref={register}
						/>
				</th>
				<th>
					<Form.Control
						type='number'
						className='text-center'
						name='sub_code_number'
						ref={register}
						/>
				</th>
				<th className='text-center align-middle'>
                    <div className='d-flex justify-content-around'>
                         <ActionIcon
                            size="lg"
                            id="search-button"
                            className="btn-primary search-button text-white-color"
                            toolTipText="Filtrar"
                            icon={faFilter}
							type='submit'
                            />
                         <ActionIcon
                            size="lg"
                            id="clean-filter"
                            className="btn-primary clean-filter text-white-color"
                            toolTipText="Limpiar filtros"
                            icon={faBroom}
                            type='reset'
							onClick={()=>onClickClean()}
                            />
                    </div>
                </th>
			</tr>
		</thead>
	</Table>
	</Form> 
};

export default BudgetItemControlFilter;