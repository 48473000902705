import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { ADMINISTRATIVE_ORGANISM_LIST } from 'src/utils/constants';
import { isNotEmptyArray } from 'src/services/validationService';
import * as  LABELS from 'src/utils/label';

import { getAdministrativeOrganismDetail, getServiceAdministrativeManagerOrganismData, getServiceAdministrativeManagerOrganismIsFetching } from 'src/redux/AdministrativeOrganism/administrativeOrganismReducer';
import { tryListOneAdministrativeOrganism, tryServiceAdministrativeManagerOrganism } from 'src/redux/AdministrativeOrganism/administrativeOrganismActionCreator';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const AdministrativeOrganismDetailsPage = () => {

    const dispatch = useDispatch();

    const organismAdministrative = useSelector(state => getAdministrativeOrganismDetail(state));

    // Exercise
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

    const serviceAdministrativeManagerOrganismData = useSelector(state => getServiceAdministrativeManagerOrganismData(state))?.sort( (a,b) => (a.code>b.code ? 1 : -1 ) );
    const serviceAdministrativeManagerOrganismIsFetching = useSelector(state => getServiceAdministrativeManagerOrganismIsFetching(state));
    const hasserviceAdministrativeManagerOrganismData = isNotEmptyArray(serviceAdministrativeManagerOrganismData);

    useEffect(() => {
        dispatch(tryListOneAdministrativeOrganism(organismAdministrative?.id));
        dispatch(tryServiceAdministrativeManagerOrganism(organismAdministrative?.id, globalSelectedPeriod?.id));
    }, []);


    const onClickBackButton = () => {
        dispatch(push(ADMINISTRATIVE_ORGANISM_LIST));
    };


    return <Container className='mb-5'>
        <Card>
            <PageTitle text='Organismos' />

            <Container fluid>
                <Card className='mb-3'>
                    <Card.Header className='h6'>
                        {LABELS.detailsOrganism}
                </Card.Header>
                    <Card.Body>

                        <Table className='text-black-color' striped borderless hover>
                            <tbody>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.ID}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.organismClassifier}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.organismClassifier?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.jurisdiction}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.jurisdiction?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.budgetCode}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.codeBudget}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.administrativeCode}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.codeAdministrative}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.name}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.nameShort}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.shortName}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.direction}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.contact?.address}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                    {LABELS.phone}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.contact?.phone}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>
                                        {LABELS.officialEmail}:
                                </td>
                                    <td className='text-center'>
                                        {organismAdministrative?.contact?.mail}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                       

                      <Card>
						<Card.Header>
							<h6 className='mb-0'>Servicios que administran el organismo</h6>
						</Card.Header>
						<Card>
							<div className='table-responsive'>
								<Table striped bordered hover size='sm' className='mb-0'>
									<thead>
										<tr>
											<th className='text-center'>Código</th>
											<th className='text-center'>Descripción</th>
										</tr>
									</thead>
									<tbody className='text-black-color'>
										{
											hasserviceAdministrativeManagerOrganismData
												?
												<>
													{
														serviceAdministrativeManagerOrganismData?.map( item => <>
														
                                                        <tr>
                                                            <td className='text-center'>{ item?.code}</td>
                                                            <td className='text-center'>{ item?.name} ({ item?.administrativeServiceType?.name})</td>
                                                        </tr>

																
														</>)
													}
												</>
												:
												<tr>
													<td colSpan='6' className='text-center'>
														{
															serviceAdministrativeManagerOrganismIsFetching
																?
                                                                <Spinner animation='border' size='lg' />
																:
																<Alert variant='info' className='mb-0'>
																	<FontAwesomeIcon icon={''} className='text-black-color mr-3' />
                                                                    No hay registros
                                                                </Alert>
														}
													</td>
												</tr>
                                            }
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                        </Card>

                        <div className='text-center'>
                            <Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
                                <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
                                Volver
                            </Button>
                        </div>

                    </Card.Body>
                </Card>

            </Container>
        </Card>
    </Container>;

};

export default AdministrativeOrganismDetailsPage;