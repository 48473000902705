import React, { useEffect, useState, useRef } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm, Controller } from 'react-hook-form';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAsterisk, faPlus, faTrash, faSave, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';
import FormFieldError from 'src/components/general/FormFieldError';
import ActionIcon from 'src/components/general/ActionIcon';
import ModalNewCodeSubcode, { MODES as MODAL_MODES} from 'src/components/forms/credit/newCredit/ModalNewCodeSubcode';

import { getGlobalDataSelectedPeriod, getGlobalDataPeriodsData } from 'src/redux/globalData/globalDataReducer';
import { getSubcodeToEdit, getIsEditionMode, getFormTitle, getSelectedLegalInstrument } from 'src/redux/subcode/subcodeReducer';
import { getCreditDetailsCodeList, getCreditDetailsCodeListIsFetching, getPostCreditIsFetching } from 'src/redux/credit/creditReducer';
import { getServiceListData, getServiceListIsFetching } from 'src/redux/service/serviceReducer';
import { getJurisdictionsByServicesAndExerciseData, getJurisdictionsByServicesAndExerciseIsFetching  } from 'src/redux/jurisdiction/jurisdictionReducer';
import { getOrganismsByJurisdictionsServicesAndExerciseData, getOrganizationListByJurisdictionIdIsFetching } from 'src/redux/organization/organizationReducer';
import { getCharacterListData, getCharacterListIsFetching } from 'src/redux/character/characterReducer';
import { getAccountListData, getAccountListIsFetching } from 'src/redux/account/accountReducer';
import { getPurposeListData, getPurposeListIsFetching } from 'src/redux/purpose/purposeReducer';
import { getFunctionalityListByPurposeIdData, getFunctionalityListByPurposeIdIsFetching } from 'src/redux/functionality/functionalityReducer';
import { getSectionListData, getSectionListIsFetching } from 'src/redux/section/sectionReducer';
import { getSectorsListBySectionIdData, getSectorsListBySectionIdIsFetching } from 'src/redux/sector/sectorReducer';
import { getPrincipalBudgetListBySectorIdData, getPrincipalBudgetListBySectorIdIsFetching } from 'src/redux/principalBudget/principalBudgetReducer';
import { getPartialBudgetListByPrincipalBudgetIdData, getPartialBudgetListByPrincipalBudgetIdIsFetching } from 'src/redux/partialBudget/partialBudgetReducer';
import { getSubcodeListData } from 'src/redux/subcode/subcodeReducer';

import { tryGetCreditList, tryGetCodesFromCredit } from 'src/redux/credit/creditActionCreator';
import { tryPostSubcode } from 'src/redux/subcode/subcodeActionCreator';
import { tryGetServiceList } from 'src/redux/service/serviceActionCreator';
import { tryGetJurisdictionsByServicesAndExercise } from 'src/redux/jurisdiction/jurisdictionActionCreator';
import { tryOrganismsByJurisdictionsIdByServicesIdAndExerciseId } from 'src/redux/organization/organizationActionCreator';
import { tryGetCharacterList } from 'src/redux/character/characterActionCreator';
import { tryGetAccountList } from 'src/redux/account/accountActionCreator';
import { tryGetPurposeList } from 'src/redux/purpose/purposeActionCreator';
import { tryGetFunctionalitiesListByPurposeId } from 'src/redux/functionality/functionalityActionCreator';
import { tryGetSectionList } from 'src/redux/section/sectionActionCreator';
import { tryGetSectorsListBySectionId } from 'src/redux/sector/sectorActionCreator';
import { tryGetPrincipalBudgetListBySectorId } from 'src/redux/principalBudget/principalBudgetActionCreator';
import { tryGetPartialBudgetIdByPrincipalBudgetId } from 'src/redux/partialBudget/partialBudgetActionCreator';

import { setSubcodeDetailsPageData } from 'src/redux/subcode/subcodeActions';
import { clearListCreditData, clearCodesFromCreditId } from 'src/redux/credit/creditActions';
import { clearServiceList } from 'src/redux/service/serviceActions';
import { clearJurisdictionList } from 'src/redux/jurisdiction/jurisdictionActions';
import { clearOrganizationListByJurisdictionId } from 'src/redux/organization/organizationActions';
import { clearCharacterList } from 'src/redux/character/characterActions';
import { clearAccountList } from 'src/redux/account/accountActions';
import { clearPurposeList } from 'src/redux/purpose/purposeActions';
import { clearFunctionalitiesListByPurposeId } from 'src/redux/functionality/functionalityActions';
import { clearSectionList } from 'src/redux/section/sectionActions';
import { clearSectorsListBySectionId } from 'src/redux/sector/sectorActions';
import { clearPrincipalBudgetListBySectorId } from 'src/redux/principalBudget/principalBudgetActions';
import { clearPartialBudgetListByPrincipalBudgetId } from 'src/redux/partialBudget/partialBudgetActions';

import { parseIntOrUndefined, parseFloatOrUndefined, focusSelectInputByElementID, formaterNumberThousandsSeparator, dataPeriodsContructorCreditModification } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';
import { CREDIT_STEP_ONE, CREDIT_EXECUTION } from 'src/utils/constants';
import {modifAmount,characterLabel,serviceLabel,accountPlaceholder,noResult,saveAndFinish,saveLoadAnother,back,creditCurrent,functio,subcode,purpose,code,specialAccounts,unidOrgAbbreviation,sector,section,budgetCredits,subcodePlaceholder,codePlaceholder,partialBudgetPlaceholder,principalBudgetPlaceholder,sectorPlaceholder,sectionPlaceholder,functionalityPlaceholder, purposePlaceholder,characterPlaceholder,organizationPlaceholder,jurisdictionPlaceholder,servicePlaceholder,loadingPlaceholder,amountRequiredMsg,notFound,  required } from 'src/utils/label';

import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';

import NumberFormat from 'react-number-format';
import BudgetSubCodePage from 'src/components/pages/BudgetSubCodePage';
import CreditExecutionBalanceTotalModalComponent from 'src/components/pages/CreditExecution/CreditExecutionBalanceTotalModalComponent.js';

import { getPreloadState } from 'src/redux/subcode/subcodeReducer';

import moment from 'moment';
import { tryGetActiveAdminsitrativeServiceFromExercise } from 'src/redux/exercise/exerciseActionCreator';

import { clearSubcodeListData } from 'src/redux/subcode/subcodeActions';

let auxGlobalSelectedService;
let auxGlobalSelectedJurisdiction;
let auxGlobalSelectedOrganization;
let auxGlobalSelectedCharacter;
let auxGlobalSelectedAccount;
let auxGlobalSelectedPurpose;
let auxGlobalSelectedFunctionality;
let auxGlobalSelectedSection;
let auxGlobalSelectedSector;
let auxGlobalSelectedPrincipalBudget;
let auxGlobalSelectedPartialBudget;
let auxGlobalSelectedCode;
let auxGlobalSelectedSubcode;

const CreditStepTwoPage = props => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, setValue, getValues, control } = useForm();

	const [amountFormaterThousandsSeparator, setAmountFormaterThousandsSeparator] = useState(undefined);

	const [amountFormaterSeparatorMessage, setAmountFormaterSeparatorMessage] = useState(false);

	let administrativeDocumentData = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

	let amountid = document.getElementById('amountid');
	amountid?.addEventListener('keyup', (e) => {
		if (e.key === '.'){
			setAmountFormaterSeparatorMessage(true);
		}else{
			setAmountFormaterSeparatorMessage(false);
		}
	});

	const handlerImporteChange = (inputObj) => {

		const numberAmount = inputObj.floatValue;
		setAmountFormaterThousandsSeparator(numberAmount);
	};

	// Exercise
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	const periodsData = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );

	const creditToEdit = useSelector( state => getSubcodeToEdit(state) )?.code?.credit;
	const codeToEdit = useSelector( state => getSubcodeToEdit(state) )?.code;
	const subcodeToEdit = useSelector( state => getSubcodeToEdit(state) );
	const isEditionMode = useSelector( state => getIsEditionMode(state) );
	const formTitle = useSelector( state => getFormTitle(state) );
	const postCreditIsFetching = useSelector( state => getPostCreditIsFetching(state) );

	
	// Validation

	const dropdownsMessagesObj = {
		filterPlaceholder: notFound
	}

	//Messages FilterDropDownList
	const messagesDropDown = { emptyFilter: noResult , emptyList: noResult };

	// Selected legal instrument
	const selectedLegalInstrument = useSelector( state => getSelectedLegalInstrument(state) );

	// Preload
	const preloadFilter = useSelector(state => getPreloadState(state))?.data;

	// Service list
	const serviceListData = useSelector( state => getServiceListData(state) )?.records?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const serviceListIsFetching = useSelector( state => getServiceListIsFetching(state) );
	const hasServiceList = isNotEmptyArray( serviceListData );

	// Jurisdiction list
	const jurisdictionListData = useSelector( state => getJurisdictionsByServicesAndExerciseData(state) )?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const jurisdictionListIsFetching = useSelector( state => getJurisdictionsByServicesAndExerciseIsFetching(state) );
	const hasJurisdictionList = isNotEmptyArray( jurisdictionListData );

	// Organization list
	/* const organizationListData = useSelector( state => getOrganismsByJurisdictionsServicesAndExerciseData(state) )?.records?.sort( (a,b) => a.codeBudget>b.codeBudget ? 1 : -1 ); */
	const organizationListData = useSelector( state => getOrganismsByJurisdictionsServicesAndExerciseData(state) );
	const organizationListIsFetching = (useSelector( state => getOrganizationListByJurisdictionIdIsFetching(state) ) || jurisdictionListIsFetching || serviceListIsFetching);
	const hasOrganizationList = isNotEmptyArray( organizationListData );

	// Characters list
	const characterListData = useSelector( state => getCharacterListData(state) )?.data?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const characterListIsFetching = useSelector( state => getCharacterListIsFetching(state) );
	const hasCharacterList = isNotEmptyArray( characterListData );

	// Accounts list
	const accountListData = useSelector( state => getAccountListData(state) )?.data?.sort( (a,b) => a.number>b.number ? 1 : -1 );
	const accountListIsFetching = ( useSelector( state => getAccountListIsFetching(state) ) || characterListIsFetching || organizationListIsFetching );
	const hasAccountList = isNotEmptyArray( accountListData );
	
	// Purposes list
	const purposeListData = useSelector( state => getPurposeListData(state) )?.data?.sort( (a,b) => a.number>b.number ? 1 : -1 );
	const purposeListIsFetching = useSelector( state => getPurposeListIsFetching(state) );
	const hasPurposeList = isNotEmptyArray( purposeListData );

	// Functionalities list
	const functionalitiesListData = useSelector( state => getFunctionalityListByPurposeIdData(state) )?.sort( (a,b) => a.number>b.number ? 1 : -1 );
	const functionalitiesListIsFetching = ( useSelector( state => getFunctionalityListByPurposeIdIsFetching(state) ) || purposeListIsFetching );
	const hasFunctionalitiesList = isNotEmptyArray( functionalitiesListData );

	// Sections list
	const sectionListData = useSelector( state => getSectionListData(state) )?.data?.sort( (a,b) => a.number>b.number ? 1 : -1 );
	const sectionListIsFetching = useSelector( state => getSectionListIsFetching(state) );
	const hasSectionList = isNotEmptyArray( sectionListData );

	// Sectors list
	const sectorsListData = useSelector( state => getSectorsListBySectionIdData(state) )?.sort( (a,b) => a.number>b.number ? 1 : -1 );
	const sectorsListIsFetching = ( useSelector( state => getSectorsListBySectionIdIsFetching(state) ) || sectionListIsFetching );
	const hasSectorsList = isNotEmptyArray( sectorsListData );

	// PrincipalBudget list
	const principalBudgetListData = useSelector( state => getPrincipalBudgetListBySectorIdData(state) )?.sort( (a,b) => a.number>b.number ? 1 : -1 );
	const principalBudgetListIsFetching = ( useSelector( state => getPrincipalBudgetListBySectorIdIsFetching(state) ) || sectorsListIsFetching );
	const hasPrincipalBudgetList = isNotEmptyArray( principalBudgetListData );

	// PartialBudget list
	const partialBudgetListData = useSelector( state => getPartialBudgetListByPrincipalBudgetIdData(state) )?.sort( (a,b) => a.number>b.number ? 1 : -1 );
	const partialBudgetListIsFetching = ( useSelector( state => getPartialBudgetListByPrincipalBudgetIdIsFetching(state) ) || principalBudgetListIsFetching );
	const hasPartialBudgetList = isNotEmptyArray( partialBudgetListData );

	// Codes list
	const codeListData = useSelector( state => getCreditDetailsCodeList(state) )?.data;
	const codeListIsFetching = ( useSelector( state => getCreditDetailsCodeListIsFetching(state) ) || partialBudgetListIsFetching || functionalitiesListIsFetching || accountListIsFetching || serviceListIsFetching );
	const hasCodeListData = isNotEmptyArray(codeListData);

	// Sub-codes list
	const creditListingData = useSelector(state => getSubcodeListData(state))?.data?.filter((item, idx) => idx < 1)?.map( item => item?.credit?.currentCredit );
	const [ subcodeListData, setSubcodeListData ] = useState();
	const [ hasSubcodeListData, setHasSubcodeListData ] = useState(false);

	const subCodecreditData = useSelector(state => getSubcodeListData(state))?.data[0];

	const dataModal = {
		actualCredit : subCodecreditData?.credit?.currentCredit,
		preventive : subCodecreditData?.balance?.totals?.preventive,
		definitive : subCodecreditData?.balance?.totals?.definitive,
		paymentObligation : subCodecreditData?.balance?.totals?.payment_obligation,
		payment : subCodecreditData?.balance?.totals?.payment,

	// Amount Credit
		originalCredit : subCodecreditData?.credit?.originalCredit,
		modificationCredit : subCodecreditData?.credit?.modificationCredit,
		balance : subCodecreditData?.balance?.currentBalance,
	};

	//budget type
	const [budgetType, setBudgetType] = useState();

	// Modal Credit details
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
		
	const onClickSeeDetailsBalanceOfFilteredItemsModal = () => {
		handleShow(true);
	};

	// Selected values
	const selectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	const [ selectedService, setSelectedService ] = useState(false);
	const [ selectedJurisdiction, setSelectedJurisdiction ] = useState();
	const [ selectedOrganization, setSelectedOrganization ] = useState();
	const [ selectedCharacter, setSelectedCharacter ] = useState();
	const [ selectedAccount, setSelectedAccount ] = useState();
	const [ selectedPurpose, setSelectedPurpose ] = useState();
	const [ selectedFunctionality, setSelectedFunctionality ] = useState();
	const [ selectedSection, setSelectedSection ] = useState();
	const [ selectedSector, setSelectedSector ] = useState();
	const [ selectedPrincipalBudget, setSelectedPrincipalBudget ] = useState();
	const [ selectedPartialBudget, setSelectedPartialBudget ] = useState();
	const [ selectedCode, setSelectedCode ] = useState();
	const [ selectedSubcode, setSelectedSubcode ] = useState();
	auxGlobalSelectedService = selectedService;
	auxGlobalSelectedJurisdiction = selectedJurisdiction;
	auxGlobalSelectedOrganization = selectedOrganization;
	auxGlobalSelectedCharacter = selectedCharacter;
	auxGlobalSelectedAccount = selectedAccount;
	auxGlobalSelectedPurpose = selectedPurpose;
	auxGlobalSelectedFunctionality = selectedFunctionality;
	auxGlobalSelectedSection = selectedSection;
	auxGlobalSelectedSector = selectedSector;
	auxGlobalSelectedPrincipalBudget = selectedPrincipalBudget;
	auxGlobalSelectedPartialBudget = selectedPartialBudget;
	auxGlobalSelectedCode = selectedCode;
	auxGlobalSelectedSubcode = selectedSubcode;

	const [ valueInput, setValueInput ] = useState(valueInput	 ? value : undefined);

	const [ amountCreditCredit, setAmountCreditCredit ] = useState();

	// Service status
	const [ serviceStatus, setServiceStatus ] = useState(false);

	//Reset code, subcode and account special
	useEffect(() => {
		setNewAccountData(false);
		setNewCodeData(false);
		setNewSubcodeData(false);
		setBlurFocusInput(false);

		/* qs_AccountInputRef?.current?.value = '';
		qs_CodeInputRef?.current?.value = '';
		qs_SubCodeInputRef?.current?.value = ''; */

	}, [selectedService]);

		// Load jurisdictions list serviceId
		const loadJurisdictionList = ({pSelectedService, isFirstLoad=false}) => {
			if( pSelectedService  ) {
				return dispatch( tryGetJurisdictionsByServicesAndExercise(pSelectedService?.id,  globalSelectedPeriod?.id) )
					.then(  data => newJurisdictionListLoaded( data, isFirstLoad )  );
			}
		};


		// Load account list by jurisdictionId and serviceId
		const loadOrganizationList = ({pSelectedService, pSelectedJurisdiction, isFirstLoad=false}) => {
			if( pSelectedService && pSelectedJurisdiction ) {
				return dispatch( tryOrganismsByJurisdictionsIdByServicesIdAndExerciseId(pSelectedService?.id,  globalSelectedPeriod?.id, 	pSelectedJurisdiction?.id) )
					.then(  data => newOrganizationListLoaded( data, isFirstLoad )  );

			}
		};


		// Load account list by organizationId and characterId
		const loadAccountList = ({pSelectedCharacter, pSelectedOrganization, isFirstLoad=false}) => {
			if( pSelectedCharacter && pSelectedOrganization ) {
				const filterObj = {
					period_id: parseIntOrUndefined(selectedPeriod?.id),
					character_id: pSelectedCharacter?.id,
					organization_id: pSelectedOrganization?.id
				};
				return dispatch( tryGetAccountList( undefined, filterObj) )
					.then( data => newAccountListLoaded( data?.data, isFirstLoad, filterObj ) );
			}
		};


		//onChange events
		const onChangeBudgetType = (budgetTypeData) => {
			setSelectedService(undefined);
			qs_ServiceInputRef.current.value = '';
			setBudgetType(parseIntOrUndefined(budgetTypeData));
		};

		const onChangeService = serviceData => {

			const serviceId = serviceData?.id;
			const periodId = globalSelectedPeriod?.id;

			if(serviceId){
				dispatch(tryGetActiveAdminsitrativeServiceFromExercise(periodId, serviceId)).then( 
					response => {
						if (response?.status === 200 && !response?.data?.active) {
							setServiceStatus(false);
							let dateCloseServiceData = response?.data?.dateClose;
							let dateCloseService = (dateCloseServiceData ? moment(dateCloseServiceData).format('DD/MM/YYYY') : null);
							swal({
								title: 'Información',
								text: `El servicio seleccionado se encuentra Cerrado, por lo cual no se permite el registro de Modificación de Crédito. Este servicio fue cerrado en fecha: ${dateCloseService}.`,
								icon: 'info',
								buttons: [false, "Aceptar"]
							});
						} else {
							setServiceStatus(true);
						}
					}
				);
			}

			setValueQSServiceInputRef( serviceData?.code );
			setSelectedService( serviceData );
			loadJurisdictionList({pSelectedService: serviceData});
		};
		const onChangeJurisdiction = jurisdictionData => {
			setValueQSJurisdictionInputRef( jurisdictionData?.code )
			setSelectedJurisdiction( jurisdictionData );
			loadOrganizationList({pSelectedService: selectedService, pSelectedJurisdiction: jurisdictionData});
		};
		const onChangeOrganization = organizationData => {
			setValueQSOrganizationInputRef( organizationData?.codeBudget );
			setSelectedOrganization( organizationData );
			loadAccountList({pSelectedCharacter: selectedCharacter, pSelectedOrganization: organizationData});
		};
		const onChangeCharacter = characterData => {
			setValueQSCharacterInputRef( characterData?.code )
			setSelectedCharacter( characterData );
			return loadAccountList({pSelectedCharacter: characterData, pSelectedOrganization: selectedOrganization});
		};
		const onChangeAccount = (accountData, termNotFound = false) => {
			setValueQSAccountInputRef( accountData?.number );
			setSelectedAccount( accountData );
			getCodes({account_id: accountData?.id});
			const valueInput = accountData?.target?.value;
			if (termNotFound){
				setModalNewAccountCodeSubcodeMode(MODAL_MODES.ACCOUNT);
				setShowModalNewAccountCodeSubcode(true);
				qs_PurposeInputRef.current?.focus();
			}else{
				setShowModalNewAccountCodeSubcode(false);
			}

		};
		const onChangePurpose = purposeData => {
			setValueQSPurposeInputRef( purposeData?.number );
			setSelectedPurpose( purposeData );
			return dispatch( tryGetFunctionalitiesListByPurposeId( purposeData?.id ) )
				.then( records => newFunctionalitiesListLoaded( records ) );
		};
		const onChangeFunctionality = functionalityData => {
			setValueQSFunctionalityInputRef( functionalityData?.number );
			setSelectedFunctionality( functionalityData );
			getCodes({functionality_id: functionalityData?.id});
		};
		const onChangeSection = sectionData => {
			setValueQSSectionInputRef( sectionData?.number );
			setSelectedSection( sectionData );
			return dispatch( tryGetSectorsListBySectionId(sectionData?.id) )
				.then( records => newSectorsListLoaded( records ) );
		};
		const onChangeSector = sectorData => {
			setValueQSSectorInputRef( sectorData?.number );
			setSelectedSector( sectorData );
			return dispatch( tryGetPrincipalBudgetListBySectorId(sectorData?.id) )
				.then( records => newPrincipalBudgetListLoaded( records ) );
		};
		const onChangePrincipalBudget = principalBudgetData => {
			setValueQSPrincipalBudgetInputRef( principalBudgetData?.number );
			setSelectedPrincipalBudget( principalBudgetData );
			return dispatch( tryGetPartialBudgetIdByPrincipalBudgetId(principalBudgetData?.id) )
				.then( records => newPartialBudgetListLoaded( records ) );
		};
		const onChangePartialBudget = partialBudgetData => {
			setValueQSPartialBudgetInputRef( partialBudgetData?.number );	
			setSelectedPartialBudget( partialBudgetData );
			return getCodes({partial_budget_id: partialBudgetData?.id});
		};

		const onChangeCode = (codeData, termNotFound = false) => {
			setValueQSCodeInputRef(codeData?.number);
			setSelectedCode( codeData );
			newSubcodeListLoaded( codeData?.subCodes );
			const valueInput = codeData?.target?.value;
			if (termNotFound){
				setModalNewAccountCodeSubcodeMode(MODAL_MODES.CODE);
				setShowModalNewAccountCodeSubcode(true);
				focusSelectInputByElementID('subcodeid');
			}else{
				setShowModalNewAccountCodeSubcode(false);
			}
		};



		const onChangeSubcode = (subcodeData, termNotFound = false) => {
			setValueQSSubCodeInputRef(subcodeData?.number)
			setSelectedSubcode( subcodeData );
			setValue( 'currentCredit',subcodeData?.credit?.currentCredit );
			if (termNotFound){
				setModalNewAccountCodeSubcodeMode(MODAL_MODES.SUBCODE);
				setShowModalNewAccountCodeSubcode(true);
				focusSelectInputByElementID('amountid');

			}else{
				setShowModalNewAccountCodeSubcode(false);
			}
		};
	
	
	
		// Data lists loaded
		// Service list loaded
		const newServiceListLoaded = serviceRecords => {
			let serviceSelectedToSet;
			if( isEditionMode ) {
				serviceSelectedToSet = creditToEdit?.service;
			} else if( isNotEmptyArray(serviceRecords) ) {
				serviceSelectedToSet = serviceRecords[0];
			}

			onChangeService(serviceSelectedToSet);

			loadOrganizationList({
				pSelectedService: auxGlobalSelectedService,
				pSelectedJurisdiction: auxGlobalSelectedJurisdiction,
				isFirstLoad: true
			});
		};
		// Jurisdiction list loaded
		const newJurisdictionListLoaded = jurisdictionRecords => {
			let jurisdictionSelectedToSet;
			if( isEditionMode ) {
				jurisdictionSelectedToSet = creditToEdit?.organization?.jurisdiction;
			}
			else {
				jurisdictionSelectedToSet = jurisdictionRecords;
				focusSelectInputByElementID('jurisdictionid');
			}

			setSelectedJurisdiction( jurisdictionSelectedToSet );
			setValueQSJurisdictionInputRef( jurisdictionSelectedToSet?.code );

			loadOrganizationList({
				pSelectedService: auxGlobalSelectedService,
				pSelectedJurisdiction: auxGlobalSelectedJurisdiction,
				isFirstLoad: true
			});
		};

		// Organization list loaded
		const newOrganizationListLoaded = (organizationRecords, isFirstLoad=false, parentsIds) => {
			dispatch( clearAccountList() );
			let organizationSelectedToSet;
	
			
			if( isEditionMode && isFirstLoad ) {
				
				organizationSelectedToSet = creditToEdit?.organization;
			}
			else if( isNotEmptyArray(organizationRecords) ) {
				organizationSelectedToSet = organizationRecords[0];
			}
	
			setSelectedOrganization( organizationSelectedToSet );
			setValueQSOrganizationInputRef( organizationSelectedToSet?.codeBudget );
	
			loadAccountList({
				pSelectedCharacter: auxGlobalSelectedCharacter,
				pSelectedOrganization: organizationSelectedToSet,
				isFirstLoad
			});
		};
	
		// Character list loaded
		const newCharacterListLoaded = characterRecords => {
			dispatch( clearAccountList() );
			let characterSelectedToSet;
	
			if( isEditionMode ) {
				characterSelectedToSet = creditToEdit?.character;
			}
			else if( isNotEmptyArray(characterRecords) ) {
				characterSelectedToSet = characterRecords[0];
			}
	
			setSelectedCharacter( characterSelectedToSet );
			setValueQSCharacterInputRef( characterSelectedToSet?.code );
	
			loadAccountList({
				pSelectedCharacter: characterSelectedToSet,
				pSelectedOrganization: auxGlobalSelectedOrganization,
				isFirstLoad: true
			});
		};
	
		// Account list loaded
		const newAccountListLoaded = ( accountRecords, isFirstLoad=false, parentsIds ) => {
			let accountSelectedToSet;
			if( isEditionMode && isFirstLoad ) {
				accountSelectedToSet = creditToEdit?.account;
			} else if( isNotEmptyArray(accountRecords) ) {
				accountSelectedToSet = accountRecords[0];
			}
	
			setSelectedAccount( accountSelectedToSet );
			setValueQSAccountInputRef( accountSelectedToSet?.number );
	
			//Try get codes (from /credit endpoint filtered)
			getCodes(parentsIds);
		};
	
		// Purpose list loaded
		const newPurposeListLoaded = purposeRecords => {
			let selectedPurposeToSet;
	
			if( isEditionMode ) {
				selectedPurposeToSet = creditToEdit?.functionality?.purpose;
			}
			else if( isNotEmptyArray(purposeRecords) ) {
				selectedPurposeToSet = purposeRecords[0];
			}
	
			setSelectedPurpose( selectedPurposeToSet );
			setValueQSPurposeInputRef( selectedPurposeToSet?.number );
	
			dispatch( tryGetFunctionalitiesListByPurposeId( selectedPurposeToSet?.id ) )
				.then( records => newFunctionalitiesListLoaded( records, true ) );
		};
	
		// Functionality list loaded
		const newFunctionalitiesListLoaded = ( functionalityRecords, isFirstLoad=false ) => {
			let functionalitySelectedToSet;
			if( isEditionMode && isFirstLoad ) {
				functionalitySelectedToSet = creditToEdit?.functionality;
			} else if( isNotEmptyArray(functionalityRecords) ) {
				functionalitySelectedToSet = functionalityRecords[0];
			}
			onChangeFunctionality( functionalitySelectedToSet );
			setValueQSFunctionalityInputRef( functionalitySelectedToSet?.number );
		};
	
		// Section list loaded
		const newSectionListLoaded = sectionRecords => {
			dispatch( clearPrincipalBudgetListBySectorId() );
			dispatch( clearPartialBudgetListByPrincipalBudgetId() );
	
			let sectionSelectedToSet;
	
			if( isEditionMode ) {
				sectionSelectedToSet = creditToEdit?.partialBudget?.principalBudget?.sector?.section;
			}
			else {
				sectionSelectedToSet = sectionRecords[0];
			}

			setSelectedSection( sectionSelectedToSet );
			setValueQSSectionInputRef( sectionSelectedToSet?.number );
	
			dispatch( tryGetSectorsListBySectionId(sectionSelectedToSet?.id) )
				.then( records => newSectorsListLoaded( records, true ) );
		};
	
		// Sector list loaded
		const newSectorsListLoaded = ( sectorRecords, isFirstLoad=false ) => {
			dispatch( clearPrincipalBudgetListBySectorId() );
			dispatch( clearPartialBudgetListByPrincipalBudgetId() );
	
			let sectorSelectedToSet;
	
			if( isEditionMode && isFirstLoad ) {
				sectorSelectedToSet = creditToEdit?.partialBudget?.principalBudget?.sector;
			}
			else if( isNotEmptyArray(sectorRecords) ) {
				sectorSelectedToSet = sectorRecords[0];
			}
	
			setSelectedSector( sectorSelectedToSet );
			setValueQSSectorInputRef( sectorSelectedToSet?.number );
	
			dispatch( tryGetPrincipalBudgetListBySectorId(sectorSelectedToSet?.id) )
				.then( records => newPrincipalBudgetListLoaded(records, isFirstLoad) );
		};
	
		// Principal budget list loaded
		const newPrincipalBudgetListLoaded = ( principalBudgetRecords, isFirstLoad=false ) => {
			dispatch( clearPartialBudgetListByPrincipalBudgetId() );
	
			let principalBudgetSelectedToSet;
	
			if( isEditionMode && isFirstLoad ) {
				principalBudgetSelectedToSet = creditToEdit?.partialBudget?.principalBudget;
			}
			else if( isNotEmptyArray(principalBudgetRecords) ) {
				principalBudgetSelectedToSet = principalBudgetRecords[0];
			}

			setSelectedPrincipalBudget( principalBudgetSelectedToSet );
			setValueQSPrincipalBudgetInputRef( principalBudgetSelectedToSet?.number );

			dispatch( tryGetPartialBudgetIdByPrincipalBudgetId(principalBudgetSelectedToSet?.id) )
				.then( records => newPartialBudgetListLoaded(records, isFirstLoad) );
		};

		// Partial budget list loaded
		const newPartialBudgetListLoaded = ( partialBudgetRecords, isFirstLoad=false ) => {
			let partialBudgetSelectedToSet;

			if( isEditionMode && isFirstLoad ) {
				partialBudgetSelectedToSet = creditToEdit?.partialBudget;
			}
			else if( isNotEmptyArray(partialBudgetRecords) ) {
				partialBudgetSelectedToSet = partialBudgetRecords[0];
			}

			onChangePartialBudget( partialBudgetSelectedToSet );
			setValueQSPartialBudgetInputRef( partialBudgetSelectedToSet?.number );
		};

		// Code list loaded
		const newCodeListLoaded = (codeRecords) => {
			let codeSelectedToSet;

			if( isEditionMode ) {
				codeSelectedToSet = codeToEdit;
			}
			else if( isNotEmptyArray(codeRecords) ) {
				codeSelectedToSet = codeRecords[0];
			}

			deleteNewCode()
			onChangeCode(codeSelectedToSet);
		};

		// Subcode list loaded
		const newSubcodeListLoaded = subcodeRecords => {

			let subcodeSelectedToSet;

			if( isEditionMode ) {
				subcodeSelectedToSet = subcodeToEdit;
			}
			else if( isNotEmptyArray(subcodeRecords) ) {
				subcodeSelectedToSet = subcodeRecords[0];
			}


			if( isNotEmptyArray(subcodeRecords) ) {
				setSubcodeListData(subcodeRecords);
				setHasSubcodeListData(true);
			}
			else {
				setSubcodeListData(undefined);
				setHasSubcodeListData(false);
			}
			deleteNewSubcode();
			onChangeSubcode(subcodeSelectedToSet);
		};




// Get codes from "/credit" filtered
const getCodes = values => {
	const filter_partial_budget_id = values?.partial_budget_id || auxGlobalSelectedPartialBudget?.id;
	const filter_functionality_id = values?.functionality_id || auxGlobalSelectedFunctionality?.id;
	const filter_character_id = values?.character_id || auxGlobalSelectedCharacter?.id;
	const filter_organization_id = values?.organization_id || auxGlobalSelectedOrganization?.id;
	const filter_service_id = values?.service_id || auxGlobalSelectedService?.id;
	const filter_account_id = values?.account_id || auxGlobalSelectedAccount?.id;

	if( filter_partial_budget_id != undefined &&
		filter_functionality_id != undefined &&
		filter_character_id != undefined &&
		filter_organization_id != undefined &&
		filter_service_id != undefined &&
		filter_account_id != undefined ) {

		const filterToApply = {
			partial_budget_id: parseIntOrUndefined(filter_partial_budget_id),
			functionality_id: parseIntOrUndefined(filter_functionality_id),
			character_id: parseIntOrUndefined(filter_character_id),
			organization_id: parseIntOrUndefined(filter_organization_id),
			service_id: parseIntOrUndefined(filter_service_id),
			account_id: parseIntOrUndefined(filter_account_id),
			period_rp_id: parseIntOrUndefined(budgetType),
			is_passive_remaining: parseIntOrUndefined(budgetType) ? true : false
		};

		dispatch( clearListCreditData() );
		return dispatch( tryGetCreditList( 1, filterToApply ) )
		.then( data => {
				const credits = data?.data;
				setAmountCreditCredit(credits[0]?.currentCredit);
				if( isNotEmptyArray(credits) ) {
					const creditId = credits[0].id;					
					dispatch( tryGetCodesFromCredit(creditId) )
						.then( newCodeListLoaded );
				}
				else {			
					dispatch( tryGetCodesFromCredit(1) )
						.then( newCodeListLoaded );
				}
			} );
	}
};

	const quickSelectClear = () => {
		if( !isEditionMode) {
			qs_ServiceInputRef.current.value = '';
			qs_JurisdictionInputRef.current.value = '';
			qs_OrganizationInputRef.current.value = '';
			qs_CharacterInputRef.current.value = '';
			qs_AccountInputRef.current.value = '';
			qs_PurposeInputRef.current.value = '';
			qs_FunctionalityInputRef.current.value = '';
			qs_SectionInputRef.current.value = '';
			qs_SectorInputRef.current.value = '';
			qs_PrincipalBudgetInputRef.current.value = '';
			qs_PartialBudgetInputRef.current.value = '';
			qs_CodeInputRef.current.value = '';
			qs_SubCodeInputRef.current.value = '';
		}
	};

	const filter = {
		period_id: parseIntOrUndefined(selectedPeriod?.id)
	}
	
	const clearAllData = () => {

		quickSelectClear();
		deleteNewAccount();
		deleteNewCode();
		deleteNewSubcode();
		dispatch( clearListCreditData() );
		dispatch( clearServiceList() );
		dispatch( clearJurisdictionList() );
		dispatch( clearOrganizationListByJurisdictionId() );
		dispatch( clearCharacterList() );
		dispatch( clearAccountList() );
		dispatch( clearPurposeList );
		dispatch( clearFunctionalitiesListByPurposeId() );
		dispatch( clearSectionList() );
		dispatch( clearSectorsListBySectionId() );
		dispatch( clearPrincipalBudgetListBySectorId() );
		dispatch( clearPartialBudgetListByPrincipalBudgetId() );
		dispatch( clearCodesFromCreditId() );
		setHasSubcodeListData(false);
		setSubcodeListData(undefined);
		setSelectedCode(undefined);
		setSelectedSubcode(undefined);
		setValue( 'currentCredit', undefined);
		setValue( 'amount', undefined);

		dispatch( tryGetServiceList(undefined, filter, undefined) ).then( newServiceListLoaded );
		/* dispatch( tryGetJurisdictionList() ).then( newJurisdictionListLoaded ); */
		dispatch( tryGetCharacterList(undefined, filter, undefined) ).then( newCharacterListLoaded );
		dispatch( tryGetPurposeList(undefined, filter, undefined) ).then( newPurposeListLoaded );
		dispatch( tryGetSectionList(undefined, filter, undefined) ).then( newSectionListLoaded );

		if( !isEditionMode) {
			qs_ServiceInputRef.current.focus();
		}
	}

	useEffect( clearAllData, [] );

	//Validations
	const [valueAmount, setValueAmount] = useState(false);
	const [valueAccountNumber, setValueAccountNumber] = useState(false);
	const [valueCodeNumber, setValueCodeNumber] = useState(false);
	const [valueSubCodeNumber, setValueSubCodeNumber] = useState(false);

	// Form submission
	const onSubmitForm = (data, estado) => {


		if(amountFormaterThousandsSeparator){
			setValueAmount(false)
		}else{
			setValueAmount(true)
			return 0;
		}
		
		let isNumberAccountNumber = parseIntOrUndefined( selectedAccount?.number);
		if(isNumberAccountNumber>=0){
			setValueAccountNumber(false)
		}else{
			setValueAccountNumber(true)
			if(!isEditionMode){
				qs_PurposeInputRef.current.focus();
			}
			return 0;
		}

		let isNumberCodeNumber = parseIntOrUndefined( selectedCode?.number);
		if(isNumberCodeNumber>=0){
			setValueCodeNumber(false);
			if(!isEditionMode){
				qs_SubCodeInputRef.current.focus();
			}
		}else{
			setValueCodeNumber(true)
			if(!isEditionMode){
				qs_SubCodeInputRef.current.focus();
			}
			return 0;
		}

		let isNumberSubCodeNumber = parseIntOrUndefined( selectedSubcode?.number);
		if(isNumberSubCodeNumber>=0){
			setValueSubCodeNumber(false)
		}else{
			setValueSubCodeNumber(true)
			focusSelectInputByElementID('amountid');
			return 0;
		}


		/* 	if (data?.amount){ */
		// Remove simbol $ and point
		/* 	var numberAmountFirstParse = data?.amount.replace(/[$.]/g,''); */
		// Replace simbol comma for point
		/* 	var numberAmountInteger = numberAmountFirstParse?.replace(/,/g, '.'); */

		//Check is Number
		/* if (isNaN(numberAmountInteger)){
			setValueAmount(true)
			return 0;
		}else{
			setValueAmount(false)
			setBlurFocusInput(false)
		}
	} */

		const dataToSend = {
			partial_budget_id: parseIntOrUndefined(selectedPartialBudget?.id),
			functionality_id: parseIntOrUndefined(selectedFunctionality?.id),
			character_id: parseIntOrUndefined(selectedCharacter?.id),
			organization_id: parseIntOrUndefined(selectedOrganization?.id),
			service_id: parseIntOrUndefined(selectedService?.id),
			period_id: parseIntOrUndefined(selectedPeriod?.id),
			account_number: parseIntOrUndefined(selectedAccount?.number),
			account_name: selectedAccount?.name,
			code_number: !parseIntOrUndefined(selectedCode?.number) ? 0 : parseIntOrUndefined(selectedCode?.number),
			code_name: !selectedCode?.name ? "" : selectedCode?.name,
			sub_code_number: !parseIntOrUndefined(selectedSubcode?.number) ? 0 : parseIntOrUndefined(selectedSubcode?.number),
			sub_code_name: !selectedSubcode?.name ? "" : selectedSubcode?.name,
			amount: parseFloatOrUndefined(amountFormaterThousandsSeparator),
			observation: null,
			sub_code_detail_type_id: 2,
			number: parseIntOrUndefined(administrativeDocumentData?.number),
			organizationCode: parseIntOrUndefined(administrativeDocumentData?.organizationCode),
			title: administrativeDocumentData?.title,
			year: administrativeDocumentData?.year,
			rp_period_id: budgetType
		};

		if (preloadFilter){
			dataToSend.isPreload = 1;
		} else {
			dataToSend.legal_instrument_id = selectedLegalInstrument?.id
		}

		dispatch( tryPostSubcode(dataToSend, creditListingData) )
			.then( responseData => {
				if( responseData ) {
					if(estado) {
						// Redirect to credit details:
						dispatch( setSubcodeDetailsPageData(responseData) );
						dispatch( push(CREDIT_EXECUTION) );
					}
					else {
						// Clear all data to load another
						/* 	clearAllData(); */
						setSelectedService(undefined);
						qs_ServiceInputRef.current.value = '';
						dispatch(clearSubcodeListData());
					}
				}
			});

		if(!isEditionMode){
			setValue('amount', '')
			focusSelectInputByElementID('serviceid')
		}

	
}
// Form submission and load another
	const submitFormAndReset = (estado) => {
		const formData = getValues();
		onSubmitForm( formData,estado );
	};


	// Modal new Code/Subcode/account
	const [ newAccountData, setNewAccountData ] = useState();
	const [ newCodeData, setNewCodeData ] = useState();
	const [ newSubcodeData, setNewSubcodeData ] = useState();
	const [ showModalNewAccountCodeSubcode, setShowModalNewAccountCodeSubcode ] = useState(false);
	const [ modalNewAccountCodeSubcodeMode, setModalNewAccountCodeSubcodeMode ] = useState();
	const handleNewAccount = data => {
		setSelectedAccount(data);
		setNewAccountData(data);
		onChangeAccount(data);
	};
	const handleNewCode = data => {
		setSelectedCode(data);
		setNewCodeData(data);
		onChangeCode(data);
	};
	const handleNewSubcode = data => {
		setSelectedSubcode(data);
		setNewSubcodeData(data);
		onChangeSubcode(data);
	};
	const openModalNewAccount = () => {
		setModalNewAccountCodeSubcodeMode(MODAL_MODES.ACCOUNT);
		setShowModalNewAccountCodeSubcode(true);
		qs_PurposeInputRef.current?.focus();
	};
	const openModalNewCode = () => {
		setModalNewAccountCodeSubcodeMode(MODAL_MODES.CODE);
		setShowModalNewAccountCodeSubcode(true);
		qs_SubCodeInputRef.current?.focus();
		focusSelectInputByElementID('subcodeid');
	};
	const openModalNewSubcode = () => {
		setModalNewAccountCodeSubcodeMode(MODAL_MODES.SUBCODE);
		setShowModalNewAccountCodeSubcode(true);
		focusSelectInputByElementID('amountid');
	};
	const deleteNewCode = () => {
		setSelectedCode(undefined);
		setNewCodeData(undefined);
		setValueQSCodeInputRef(null)
	};
	const deleteNewSubcode = () => {
		setSelectedSubcode(undefined);
		setNewSubcodeData(undefined);
		setValueQSSubCodeInputRef(null)
	};
	const deleteNewAccount = () => {
		setSelectedAccount(undefined);
		setNewAccountData(undefined);
		setValueQSAccountInputRef(null);
	};
	const enableAccountCreation = () => ( selectedCharacter && selectedOrganization );
	const enableCodeCreation = () => ( selectedPartialBudget && selectedFunctionality && selectedCharacter && selectedOrganization && selectedService && selectedAccount );
	const enableSubcodeCreation = () => (selectedCode||newCodeData);
	// END Modal new Code/Subcode



	// Quickselect inputs
	const setRefValue = (ref, valueToSet) => {
		if( ref?.current && !isNaN(valueToSet) ) {
			ref.current.value = valueToSet;
		}
	};
	const qs_ServiceInputRef = useRef(undefined);
	const setValueQSServiceInputRef = valueToSet => setRefValue( qs_ServiceInputRef, valueToSet);
	const qs_JurisdictionInputRef = useRef(undefined);
	const setValueQSJurisdictionInputRef = valueToSet => setRefValue( qs_JurisdictionInputRef, valueToSet);
	const qs_OrganizationInputRef = useRef(undefined);
	const setValueQSOrganizationInputRef = valueToSet => setRefValue( qs_OrganizationInputRef, valueToSet);
	const qs_CharacterInputRef = useRef(undefined);
	const setValueQSCharacterInputRef = valueToSet => setRefValue( qs_CharacterInputRef, valueToSet);
	const qs_AccountInputRef = useRef(undefined);
	const setValueQSAccountInputRef = valueToSet => setRefValue( qs_AccountInputRef, valueToSet);
	const qs_PurposeInputRef = useRef(undefined);
	const setValueQSPurposeInputRef = valueToSet => setRefValue( qs_PurposeInputRef, valueToSet);
	const qs_FunctionalityInputRef = useRef(undefined);
	const setValueQSFunctionalityInputRef = valueToSet => setRefValue( qs_FunctionalityInputRef, valueToSet);
	const qs_SectionInputRef = useRef(undefined);
	const setValueQSSectionInputRef = valueToSet => setRefValue( qs_SectionInputRef, valueToSet);
	const qs_SectorInputRef = useRef(undefined);
	const setValueQSSectorInputRef = valueToSet => setRefValue( qs_SectorInputRef, valueToSet);
	const qs_PrincipalBudgetInputRef = useRef(undefined);
	const setValueQSPrincipalBudgetInputRef = valueToSet => setRefValue( qs_PrincipalBudgetInputRef, valueToSet);
	const qs_PartialBudgetInputRef = useRef(undefined);
	const setValueQSPartialBudgetInputRef = valueToSet => setRefValue( qs_PartialBudgetInputRef, valueToSet);
	const qs_CodeInputRef = useRef(undefined);
	const setValueQSCodeInputRef = valueToSet => setRefValue( qs_CodeInputRef, valueToSet);
	const qs_SubCodeInputRef = useRef(undefined);
	const setValueQSSubCodeInputRef = valueToSet => setRefValue( qs_SubCodeInputRef, valueToSet);

	//Check focus for inputs
	const [blurFocusInput, setBlurFocusInput] = useState(false)
	const blurFocus = (id) => {
		var blurFocusInputID = document.getElementById(id);
		switch (blurFocusInputID?.id) {
			case 'subcodeid':
				setBlurFocusInput(true);
				break;

			default:
				setBlurFocusInput(false);
				break;
		}
	}

	const [valueInputQs, setvalueInputQs] = useState()
	const onChangeValueInput = item => {
		setvalueInputQs(item);
	}

	const onBlurQSInput = (event, attrList, selectedAttribute, onChangeAttribute, nextInputRef ) => {
		const value = event?.target?.value;

		setValueInput(value);

		if( selectedAttribute?.number==value || selectedAttribute?.code==value || selectedAttribute?.codeBudget==value ) {
			return;
		}
		const searchedItem = attrList?.find( item => (item?.number == value||item?.code == value||item?.codeBudget == value) );

		event.preventDefault();
		set_qs_DisableAllInputs(true);
		if( searchedItem ) {
			const returnedPromise = onChangeAttribute( searchedItem );

			if( returnedPromise ) {
				returnedPromise.then( () => {
					set_qs_DisableAllInputs(false);
					nextInputRef?.current?.focus();
				});
			}
			else {
				set_qs_DisableAllInputs(false);
			}
		}
		else {
			set_qs_DisableAllInputs(false);
			if(value!='') {
				let flag = false;
				if (onChangeAttribute.name === 'onChangeAccount') {
					setModalNewAccountCodeSubcodeMode(MODAL_MODES.ACCOUNT);
					flag = true;					
				}
				if (onChangeAttribute.name === 'onChangeCode') {
					setModalNewAccountCodeSubcodeMode(MODAL_MODES.CODE);
					flag = true;
				}
				if (onChangeAttribute.name === 'onChangeSubcode') {
					setModalNewAccountCodeSubcodeMode(MODAL_MODES.SUBCODE);
					flag = true;
				}
				if (flag) {
					setShowModalNewAccountCodeSubcode(true);
				}
				else {
					onChangeAttribute( attrList[0] , true);
					event.target.value = '';
					event?.target?.focus();
				}
			}
		}
	}

	const [inputServiceControl, setInputServiceControl] = useState(true);
	const onChangeInputService = event => {
		if (event){
			setInputServiceControl(false);
		}else{
			setInputServiceControl(true);
		}
	}

	const [ qs_DisableAllInputs, set_qs_DisableAllInputs ] = useState(false);
		// END Quickselect inputs
	const onKeyUpEnterInService = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("jurisdictionid").select();
		}
	}
	const onKeyUpEnterInSection = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("sectorid").select();
		}
	}
	const onKeyUpEnterInSector = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("principalBudgetid").select();
		}
	}
	const onKeyUpEnterInCharacter = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("specialAccountsid").select();
		}
	}

	const onKeyUpEnterInJurisdiction = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("organizationid").select();
		}
	}
	const onKeyUpEnterInPartialBudget = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("codeid").select();
		}
	}
	const onKeyUpEnterInSpecialAccounts = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("purposeid").select();
		}
	}
	const onKeyUpEnterInCode = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("subcodeid").select();
		}
	}
	const onKeyUpEnterInPurpose = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("functionalid").select();
		}
	}
	const onKeyUpEnterInSubcode = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("amountid").select();
		}
	}
	const onKeyUpEnterInfunctio = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("sectionid").select();
		}
	}
	const onKeyUpEnterInAmount = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("buttonSaveLoadAnotherId").focus();
		}
	}
	const onKeyUpEnterInOrgaization = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("characterid").select();
		}
	}

	const onKeyUpEnterInPrincipalBudget = event => {
		var keycode = event.keyCode || event.which;
		if (keycode == 13 && event.target.value !== '') {
			document.getElementById("partialBudgetid").select();
		}
	}


	
	const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		const maxValue = 999999999999.99;
		const minValue = -999999999999.99;
		if (value <= maxValue && value >= minValue || value == '-') return inputObj;
	};

	// Form Type
	const typeForm = formTitle == 'Modificación de crédito';

	return <Container fluid  className='mb-5'>
		<Card>
		
			<PageTitle text={budgetCredits}/>

			<Container fluid >
				
				<div>
					<StepIndicator steps={config.appSettings.steps.creditControl} current={1} className='mt-2 mb-3'/>
			</div>

				<Card className='mb-3'>
					<Card.Header>
						<h6 className='mb-0'>
						<div className='d-flex align-items-center mb-1'>
							{formTitle}
							<Form.Group className='text-white-color mt-0 mb-0 ml-5 mr-0 text-left font-weight-bold font-italic'>
								<Form.Check.Input style={{ "transform": 'scale(1.5)'}} disabled checked={preloadFilter}/>
								<Form.Check.Label className='ml-1'>PRECARGA</Form.Check.Label>
							</Form.Group>	
						</div>
						</h6>
					</Card.Header>
					<Card.Body >
					
						<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off' >

							<Row className='mb-3'>
								<Col xs={4}>
									<Form.Control
										as="select"
										ref={register}
										name='tipoDeEjercicio'
										className="w-100 text-black-color ml-4"
										value={budgetType}
										onChange={(event) => onChangeBudgetType(event.target.value)}
									>
										{dataPeriodsContructorCreditModification(globalSelectedPeriod, periodsData ).map( (item, i) =>
											<option key={i} value={item?.period_rp_id}>
													{item?.value}
											</option>
										)}
									</Form.Control>
								</Col>
								<br></br>
							</Row>
							
							<Row>
							<Col xs={8}>
								<h6 className='mb-4 text-black-color'>Datos de la partida</h6>
							</Col>
							<Col xs={4} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col>
						</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row} controlId='servicioSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{serviceLabel}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														ref={qs_ServiceInputRef}
														id='serviceid'
														onKeyUp={event => onKeyUpEnterInService(event)}
														onBlur={ event => onBlurQSInput( event, serviceListData, selectedService, onChangeService, qs_JurisdictionInputRef, qs_ServiceInputRef, blurFocus('serviceid')  ) }
														onChange={event => onChangeInputService(event.target.value) }
														disabled={(!hasServiceList || qs_DisableAllInputs || isEditionMode)}
													/>
												</Col>
												:
												null
										}
										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={serviceListData}
												value={selectedService}
												onChange={onChangeService}
												valueField='id'
												textField={item => (`${item?.code} - ${item?.name}`)}
												disabled={(!hasServiceList||isEditionMode)}
												placeholder={serviceListIsFetching ? loadingPlaceholder : servicePlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>												
											</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												serviceListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>

								<Col xs={6}>
								<Form.Group  as={Row} controlId='seccionSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
											<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{section}:
										</Form.Label>
										{
											!isEditionMode 
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='sectionid'
														onKeyUp={event => onKeyUpEnterInSection(event)}
														ref={qs_SectionInputRef}
														onBlur={ event => onBlurQSInput( event, sectionListData, selectedSection, onChangeSection, qs_SectorInputRef, blurFocus('sectionid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode || isEditionMode)}
														
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={sectionListData}
												value={selectedSection}
												onChange={onChangeSection}
												valueField='id'
												textField={item => (`${item?.number} - ${item?.name}`)}
												disabled={(!hasSectionList || isEditionMode || !selectedService)}
												placeholder={sectionListIsFetching ? loadingPlaceholder : sectionPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
											</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												sectionListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row} controlId='jurisdiccionSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											Jurisdicción:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='jurisdictionid'
														ref={qs_JurisdictionInputRef}
														onKeyUp={event => onKeyUpEnterInJurisdiction(event)}
														onBlur={  event => onBlurQSInput( event, jurisdictionListData, selectedJurisdiction, onChangeJurisdiction, qs_OrganizationInputRef, blurFocus('jurisdictionid') ) }
														disabled={(qs_DisableAllInputs || inputServiceControl || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={jurisdictionListData}
												value={selectedJurisdiction}
												onChange={onChangeJurisdiction}
												valueField='id'
												textField={item => (`${item?.code} - ${item?.name||''}`)}
												disabled={(!hasJurisdictionList || isEditionMode || !selectedService)}
												placeholder={jurisdictionListIsFetching ? loadingPlaceholder : jurisdictionPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
										</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												jurisdictionListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>

								<Col xs={6}>
								<Form.Group  as={Row} controlId='sectorSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{sector}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='sectorid'
														ref={qs_SectorInputRef}
														onKeyUp={event => onKeyUpEnterInSector(event)}
														onBlur={ event => onBlurQSInput( event, sectorsListData, selectedSector, onChangeSector, qs_PrincipalBudgetInputRef, blurFocus('sectorid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={sectorsListData}
												value={selectedSector}
												onChange={onChangeSector}
												valueField='id'
												textField={item => (`${item?.number} - ${item?.name||''}`)}
												disabled={(!hasSectorsList||isEditionMode || !selectedService)}
												placeholder={sectorsListIsFetching ? loadingPlaceholder : sectorPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
												</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												sectorsListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row} controlId='unidadDeOrganizacionSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											<span>{unidOrgAbbreviation}:</span>
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='organizationid'
														ref={qs_OrganizationInputRef}
														onKeyUp={event => onKeyUpEnterInOrgaization(event)}
														onBlur={ event => onBlurQSInput( event, organizationListData, selectedOrganization, onChangeOrganization, qs_CharacterInputRef, blurFocus('organizationid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={organizationListData}
												value={selectedOrganization}
												onChange={onChangeOrganization}
												valueField='id'
												textField={item => ( `${item?.codeBudget} - ${item?.name||''} (${item?.jurisdiction?.shortName||''})  (${item?.organismClassifier?.shortName||''})` )}
												disabled={(!hasOrganizationList || isEditionMode || !selectedService)}
												placeholder={organizationListIsFetching ? loadingPlaceholder : organizationPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
										</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												organizationListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>

								<Col xs={6}>
								<Form.Group  as={Row} controlId='partidaPrincipalSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{'P. Principal'}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='principalBudgetid'
														ref={qs_PrincipalBudgetInputRef}
														onKeyUp={event => onKeyUpEnterInPrincipalBudget(event)}
														onBlur={ event => onBlurQSInput( event, principalBudgetListData, selectedPrincipalBudget, onChangePrincipalBudget, qs_PartialBudgetInputRef, blurFocus('principalBudgetid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={principalBudgetListData}
												value={selectedPrincipalBudget}
												onChange={onChangePrincipalBudget}
												valueField='id'
												textField={item => (`${item?.number} - ${item?.name||''}`)}
												disabled={(!hasPrincipalBudgetList||isEditionMode || !selectedService)}
												placeholder={principalBudgetListIsFetching ? loadingPlaceholder : principalBudgetPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
												</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												principalBudgetListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row} controlId='caracterSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{characterLabel}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='characterid'
														ref={qs_CharacterInputRef}
														onKeyUp={event => onKeyUpEnterInCharacter(event)}
														onBlur={ event => onBlurQSInput( event, characterListData, selectedCharacter, onChangeCharacter, qs_AccountInputRef, blurFocus('characterid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={characterListData}
												value={selectedCharacter}
												onChange={onChangeCharacter}
												valueField='id'
												textField={item => (`${item?.code} - ${item?.name||''}`)}
												disabled={(!hasCharacterList||isEditionMode || !selectedService)}
												placeholder={characterListIsFetching ? loadingPlaceholder : characterPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
												</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												characterListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>

								<Col xs={6}>
								<Form.Group  as={Row} controlId='partidaPrincipalInput'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{'P. Parcial'}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='partialBudgetid'
														ref={qs_PartialBudgetInputRef}
														onKeyUp={event => onKeyUpEnterInPartialBudget(event)}
														onBlur={ event => onBlurQSInput( event, partialBudgetListData, selectedPartialBudget, onChangePartialBudget, qs_CodeInputRef, blurFocus('partialBudgetid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}


										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={partialBudgetListData}
												value={selectedPartialBudget}
												onChange={onChangePartialBudget}
												valueField='id'
												textField={item => (`${item?.number} - ${item?.name||''}`)}
												disabled={(!hasPartialBudgetList||isEditionMode || !selectedService)}
												placeholder={partialBudgetListIsFetching ? loadingPlaceholder : partialBudgetPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
											</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												partialBudgetListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row} controlId='cuentaEspecialSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{specialAccounts}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='specialAccountsid'
														placeholder='Nro.'
														ref={qs_AccountInputRef}
														onKeyUp={event => onKeyUpEnterInSpecialAccounts(event)}
														onBlur={ event => onBlurQSInput( event, accountListData, selectedAccount, onChangeAccount, qs_PurposeInputRef, blurFocus('specialAccountsid') ) }
														onChange={event => onChangeValueInput(event.target.value)}
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm={isEditionMode ? '6' : '5'} className='mx-0 px-1'>
											<DropdownList
												data={accountListData}
												value={selectedAccount}
												onChange={ value => onChangeAccount(value)}
												valueField='id'
												textField={item => (`${item?.number} - ${item?.name||''}`)}
												disabled={(!hasAccountList||isEditionMode || !selectedService)}
												placeholder={accountListIsFetching ? loadingPlaceholder : accountPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
											</DropdownList>
											{
												valueAccountNumber
													?
													<>
														<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
															{'Debe ingresar un número de Cta./Cta. E.'}
														</div>
													</>
													:
													null
											}
										</Col>

										{
											!isEditionMode
												?
												<Col sm='1' className='p-0'>
													{
														!newAccountData
															?
															<ActionIcon size='lg' id='new-account-button' toolTipText='Nueva cuenta' icon={faPlus} onClick={openModalNewAccount} disabled={!enableAccountCreation()}/>
															:
															<ActionIcon size='lg' id='new-account-button' toolTipText='Eliminar cuenta' icon={faTrash} onClick={deleteNewAccount}/>
													}
												</Col>
												:
												null
										}

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												accountListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>

								<Col xs={6}>
								<Form.Group  as={Row} controlId='codigoSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{code}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														placeholder='Nro.'
														id='codeid'
														ref={qs_CodeInputRef}
														onKeyUp={event => onKeyUpEnterInCode(event)}
														onBlur={ event => onBlurQSInput( event, codeListData, selectedCode, onChangeCode, qs_CodeInputRef, blurFocus('codeid') ) }
														onChange={event => onChangeValueInput(event.target.value)}
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm={isEditionMode ? '6' : '5'} className='mx-0 px-1'>
											{
												!newCodeData
													?
													<DropdownList
														data={codeListData}
														value={selectedCode}
														onChange={ value => onChangeCode(value)}
														valueField='id'
														textField={item => item?.number ? (`${item?.number||''} - ${item?.name||''}`) : codePlaceholder }
														disabled={(!hasCodeListData||isEditionMode)}
														placeholder={codeListIsFetching ? loadingPlaceholder : codePlaceholder}
														className='text-black-color'
														allowCreate='false'
														filter='contains'
														messages={ messagesDropDown }
														selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
														searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
													</DropdownList>
													:
													<Form.Control value={`${newCodeData?.number} - ${newCodeData?.name}`} disabled={true}/>
											}
											{
												valueCodeNumber
													?
													<>
														<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
															{'Debe ingresar un número de Código.'}
														</div>
													</>
													:
													null
											}
										</Col>


										{
											!isEditionMode
												?
												<Col sm='1' className='p-0'>
													{
														!newCodeData
															?
															<ActionIcon size='lg' id='new-code-button' toolTipText='Nuevo código' icon={faPlus} onClick={openModalNewCode} disabled={!enableCodeCreation()}/>
															:
															<ActionIcon size='lg' id='new-code-button' toolTipText='Eliminar código' icon={faTrash} onClick={deleteNewCode}/>
													}
												</Col>
												:
												null
										}


										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												codeListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row} controlId='finalidadSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{purpose}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='purposeid'
														ref={qs_PurposeInputRef}
														onKeyUp={event => onKeyUpEnterInPurpose(event)}
														onBlur={ event => onBlurQSInput( event, purposeListData, selectedPurpose, onChangePurpose, qs_FunctionalityInputRef, blurFocus('purposeid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={purposeListData}
												value={selectedPurpose}
												onChange={onChangePurpose}
												valueField='id'
												textField={item => (`${item?.number} - ${item?.name||''}`)}
												disabled={(!hasPurposeList||isEditionMode || !selectedService)}
												placeholder={purposeListIsFetching ? loadingPlaceholder : purposePlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
										</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												purposeListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>

								<Col xs={6}>
								<Form.Group  as={Row} controlId='subcodigoSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{subcode}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														placeholder='Nro.'
														id='subcodeid'
														ref={qs_SubCodeInputRef}
														onKeyUp={event => onKeyUpEnterInSubcode(event)}
														onBlur={ event => onBlurQSInput( event, subcodeListData, selectedSubcode, onChangeSubcode, qs_SubCodeInputRef ) }
														onFocus={ () => blurFocus('subcodeid') }
														onChange={event => onChangeValueInput(event.target.value)}
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm={isEditionMode ? '6' : '5'} className='mx-0 px-1'>
											{
												!newSubcodeData
													?
													<DropdownList
														data={subcodeListData}
														value={selectedSubcode}
														onChange={ value => onChangeSubcode(value)}
														valueField='id'
														textField={item => item?.number ? (`${item?.number||''} - ${item?.name||''}`) : subcodePlaceholder }
														disabled={(!hasSubcodeListData||isEditionMode)}
														placeholder={codeListIsFetching ? loadingPlaceholder : subcodePlaceholder}
														className='text-black-color'
														allowCreate='false'
														filter='contains'
														messages={ messagesDropDown }
														selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
														searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
														</DropdownList>
													:
													<div>
													<Form.Control value={`${newSubcodeData?.number} - ${newSubcodeData?.name}`} disabled={true}/>
													</div>
											}
											{
												valueSubCodeNumber
													?
													<>
														<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
															{'Debe ingresar un número de Subcódigo.'}
														</div>
													</>
													:
													null
											}
										</Col>

										{
											!isEditionMode
												?
												<Col sm='1' className='p-0'>
													{
														!newSubcodeData
															?
															<ActionIcon size='lg' id='new-subcode-button' toolTipText='Nuevo subcódigo' icon={faPlus} onClick={openModalNewSubcode} disabled={!enableSubcodeCreation()}/>
															:
															<ActionIcon size='lg' id='new-subcode-button' toolTipText='Eliminar subcódigo' icon={faTrash} onClick={deleteNewSubcode}/>
													}
												</Col>
												:
												null
										}

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												codeListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row} controlId='funcionSelect'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{functio}:
										</Form.Label>
										{
											!isEditionMode
												?
												<Col sm='2' className='mx-0 px-1'>
													<Form.Control
														className='text-center'
														type='number'
														id='functionalid'
														ref={qs_FunctionalityInputRef}
														onKeyUp={event => onKeyUpEnterInfunctio(event)}
														onBlur={ event => onBlurQSInput( event, functionalitiesListData, selectedFunctionality, onChangeFunctionality, qs_SectionInputRef, blurFocus('functionalid') ) }
														disabled={(qs_DisableAllInputs || !selectedService || isEditionMode)}
													/>
												</Col>
												:
												null
										}

										<Col sm='6' className='mx-0 px-1'>
											<DropdownList
												data={functionalitiesListData}
												value={selectedFunctionality}
												onChange={onChangeFunctionality}
												valueField='id'
												textField={item => (`${item?.number} - ${item?.name||''}`)}
												disabled={(!hasFunctionalitiesList||isEditionMode || !selectedService)}
												placeholder={functionalitiesListIsFetching ? loadingPlaceholder : functionalityPlaceholder}
												className='text-black-color'
												allowCreate='false'
												filter='contains'
												messages={ messagesDropDown }
												selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
												searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>}>
											</DropdownList>
										</Col>

										<Col sm='1' className='pl-0 d-flex align-items-center'>
											{
												functionalitiesListIsFetching
													?
													<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
													:
													null
											}
										</Col>
									</Form.Group>
								</Col>

								
										<Col xs={6}>
										<Form.Group  as={Row} controlId='importeInput'>
												<Form.Label className='text-right text-black-color pr-0' column sm='3'>
													{creditCurrent}:
												</Form.Label>

												<Col sm={isEditionMode ? '6' : '8'} className='mx-0 px-1'>
													<BudgetSubCodePage 
														isEditionMode={isEditionMode}
														selectedPeriod={selectedPeriod}
														budgetType={budgetType}
														selectedService={selectedService}
														selectedJurisdiction={selectedJurisdiction}
														selectedOrganization={selectedOrganization}
														selectedCharacter={selectedCharacter}
														selectedAccount={selectedAccount}
														selectedPurpose={selectedPurpose}
														selectedFunctionality={selectedFunctionality}
														selectedSection={selectedSection}
														selectedSector={selectedSector}
														selectedPrincipalBudget={selectedPrincipalBudget}
														selectedPartialBudget={selectedPartialBudget}
														selectedCode={selectedCode} 
														selectedSubcode={selectedSubcode} 
														amountCurrentCredit={creditListingData}
														codeListIsFetching={codeListIsFetching}
														className='mt-2 mb-3' 
													/>
												</Col>

												<Col sm='1' className='pl-0 d-flex align-items-center'>
													{
														codeListIsFetching
															?
															<Spinner className='spinner-border text-danger' animation='border' size='sm'/>
															:
															subCodecreditData 
																?
																<ActionIcon size='lg' id='details' toolTipText='Ver detalle de créditos y saldos' icon={faSearch} ml='2' onClick={() => onClickSeeDetailsBalanceOfFilteredItemsModal()} />
																:
																null
													}
												</Col>
											</Form.Group>
										</Col>
							</Row>

							<Row>
								<Col xs={6}>
									<Form.Group as={Row}></Form.Group>
								</Col>

								<Col xs={6}>
								<Form.Group  as={Row} controlId='importeInput'>
										<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm='3' >
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk'/>
											{modifAmount}:
										</Form.Label>

										<Col sm={isEditionMode ? '6' : '8'} className='mx-0 px-1'>
											<Controller 
												name='amount'
												control={control}
												as={
													<NumberFormat													
													id='amountid'
													thousandSeparator={'.'} 
													decimalSeparator={','} 
													decimalScale={2} 
													className="form-control" 
													inputmode="numeric" 
													onBlur={() => blurFocus('amountid')}
													onKeyUp={event => onKeyUpEnterInAmount(event)}
													onValueChange={(inputObj) => handlerImporteChange(inputObj)}
													isAllowed={(inputObj)=> withValueLimit(inputObj)}
													step='any'
											/>
												}
											/> 													
											
											<FormFieldError errors={errors.amount}/>
											{
												valueAmount
													?
													<>
														<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
															{'Debe ingresar un importe mayor a cero para poder guardar.'}
														</div>
													</>
													:
													null
											}

											{/* {
												amountFormaterSeparatorMessage
												?
												<>
												<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
												{'Como signo decimal debe usar la coma.'}
												</div>
												</>
												:
												null
											} */}

										</Col>

									</Form.Group>
								</Col>
							</Row>

							

							<div className='d-flex justify-content-around mt-4 mb-3'>
								<Button type='button' variant='danger' size='lg' disabled={postCreditIsFetching} onClick={() => dispatch( push(CREDIT_STEP_ONE) )} >
									<FontAwesomeIcon icon={faArrowLeft} className='mr-2'/>
									{back}
								</Button>

								{
									!isEditionMode
										?
										<Button 
											type='button' 
											variant='success' 
											size='lg' 
											disabled={((!selectedPartialBudget || postCreditIsFetching) || (!selectedService) || !serviceStatus)} 
											onClick={()=>submitFormAndReset(false)} 
											id='buttonSaveLoadAnotherId'
										>
											{saveLoadAnother}
											<FontAwesomeIcon icon={faSave} className='ml-2'/>
										</Button>
										:
										null
								}

								<Button 
									type='button' 
									variant='success' 
									size='lg' 
									onClick={()=>submitFormAndReset(true)} 
									disabled={((!selectedPartialBudget || postCreditIsFetching) || (!selectedService) || !serviceStatus)}
									tabIndex='16'
								>
									{
										!postCreditIsFetching
											?
											saveAndFinish
											:
											<Spinner animation='border' className='mx-5'/>
									}
									<FontAwesomeIcon icon={faSave} className='ml-2'/>
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>

		<ModalNewCodeSubcode
			handleNewAccount={handleNewAccount}
			handleNewCode={handleNewCode}
			handleNewSubcode={handleNewSubcode}
			mode={modalNewAccountCodeSubcodeMode}
			show={showModalNewAccountCodeSubcode}
			setShow={setShowModalNewAccountCodeSubcode}
			valueInputQs={valueInputQs}
			accountListData={accountListData}
			codeListData={codeListData}
			subcodeListData={subcodeListData}
		/>

		<CreditExecutionBalanceTotalModalComponent
			data={dataModal}
			title={'Detalle'}
			titleCredit={'Detalle de Créditos'}
			titleBalance={'Detalle de Saldos'}
			show={show}
			handleClose={handleClose}
			hidePassiveRemaining = {true}
		/>

	</Container>

};

export default CreditStepTwoPage;