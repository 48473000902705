import React, { useState } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

//Cancel Request
const CancelToken = axios.CancelToken;
let cancelSource = CancelToken.source();

import NumberFormat from 'react-number-format';
import ActionIcon from 'src/components/general/ActionIcon';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { balanceOfFilteredItems } from 'src/utils/label';

import { getTotalAmountsIsFetching } from 'src/redux/credit/creditReducer';

import CreditExecutionBalanceTotalModalComponent from 'src/components/pages/CreditExecution/CreditExecutionBalanceTotalModalComponent';

import { getFilterObject } from 'src/redux/globalData/globalDataReducer';
import { tryGetTotalAmounts } from 'src/redux/credit/creditActionCreator';

const BalanceTotalModal = props => {

	const dispatch = useDispatch();

	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);

    const {totalAmountsData } = props;

	const [showTotals, setShowTotals] = useState(false);	
	const handleShowTotals = () => setShowTotals(true);
	const handleCloseTotals = () =>{
		setShowTotals(false);
		dispatch(tryGetTotalAmounts(globalFilterObject, cancelSource.token));
	};

	const totalAmountsIsFetching = useSelector(state => getTotalAmountsIsFetching(state));
	
	const onClickSeeDetailsBalanceOfFilteredItemsModal = () => {
		handleShowTotals(true);
	};

    return <span className='ml-auto' >

		<span className={totalAmountsData ? 'text-black-color mr-3' : 'hidden'}>{balanceOfFilteredItems}:</span>
		<NumberFormat 
			prefix={'$ '}
			thousandSeparator={'.'}
			decimalSeparator={','} 
			decimalScale={2} 
			inputmode="numeric" 
			value={totalAmountsData?.balance}
			className={totalAmountsData ? 'text-black-color text-right' : 'hidden'} 
			readOnly 
		/>
		<Spinner animation='border' size='sm'  variant='success' className={totalAmountsIsFetching ? '' : 'hidden'} />
		<ActionIcon size='lg' id='details' className={totalAmountsIsFetching ? 'hidden' : '' } toolTipText='Ver detalles de créditos y saldos' icon={faSearch} ml='2' onClick={() => onClickSeeDetailsBalanceOfFilteredItemsModal()} />

		<CreditExecutionBalanceTotalModalComponent
			data={totalAmountsData}
			title={'Detalle'}
			titleCredit={'Detalle de Créditos'}
			titleBalance={'Detalle de Saldos'}
			show={showTotals}
			handleClose={handleCloseTotals}
		/>
		
	</span>
};

export default BalanceTotalModal;