import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Commons from './controls/Commons';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import ExpedienteContainer from './controls/ExpedienteContainer';
import { clearNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationActions';
import { clearListOrderPayLast } from 'src/redux/orderPay/orderPayActions';

const NewHaberesForm = () => {
	const dispatch = useDispatch();
	const { fundRequest, orderPay, expedientesAsociados, setExpedientesAsociados, administrativeServiceData } = useFormContext();
    const [administrativeDocument, setAdministrativeDocument] = useState([]);
	const [admServiceId, setAdmServiceId] = useState();

	const applyFilterBankAccounts = (administrativeServiceId) => {
		setAdmServiceId(administrativeServiceId);
	}

	useEffect(() => {
		if (orderPay){
            setAdmServiceId(orderPay.administrativeServiceId);
		}
		if (fundRequest){
            setAdmServiceId(fundRequest.administrativeServiceId);
		}
        dispatch(clearListOrderPayLast());
		dispatch(clearListAllAdministrativeServicesData());
        dispatch(clearNewAffectationAdministrativeDocumentData());
    }, []);

return <>
		<Commons
			administrativeServiceData={administrativeServiceData}
			admServiceId={admServiceId}
			setAdmServiceId={setAdmServiceId}
			applyFilterBankAccounts={applyFilterBankAccounts}
            disabledImporte={true}
			disableAdmExpedientSearch={true}
		/>
           {
		admServiceId &&
			<ExpedienteContainer
				expedientesAsociados={expedientesAsociados}
				setExpedientesAsociados={setExpedientesAsociados}
				administrativeDocument={administrativeDocument}
				setAdministrativeDocument={setAdministrativeDocument}
				disableAdmExpedientSearch={true}
			/>
		}
            
		<br></br>
	</>
};

export default NewHaberesForm;