import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { trySearchAdministrativeServiceById } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getAdministrativeServiceByIdData, getAdministrativeServiceByIdIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import PageTitle from 'src/components/general/PageTitle';
import { BANK_ACCOUNT_LIST } from 'src/utils/constants';
import { getBankAccount, getBankAccountIsFetching } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer';
import AppLoading from 'src/components/common/AppLoading';

const BankAccountDetailsPage = () => {

	const dispatch = useDispatch();

	const isFetchingBankAccountData = useSelector(state => getBankAccountIsFetching(state));
	const bankAccountData = useSelector(state => getBankAccount(state));

	const isFetchingAdministrativeData = useSelector(state => getAdministrativeServiceByIdIsFetching(state));
	const administrativeServiceData = useSelector(state => getAdministrativeServiceByIdData(state));

	const onClickBackButton = () => {
		dispatch(push(BANK_ACCOUNT_LIST));
	};

	useEffect(() => {
		dispatch(trySearchAdministrativeServiceById(bankAccountData?.administrativeServiceId))

	}, [])

	return <>
		<>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Detalle Cuenta Bancaria
					</Card.Header>
					<Card.Body>
						<Table className='text-black-color' striped borderless hover responsive>
							<tbody>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										ID:
									</td>
									<td className='text-center'>
										{bankAccountData?.id}
									</td>
								</tr>

								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Ejercicio:
									</td>
									<td className='text-center'>
										{bankAccountData?.year}
									</td>
								</tr>

								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Servicio Administrativo:
									</td>
									<td className='text-center'>
										{administrativeServiceData?.code} -	{administrativeServiceData?.name}
									</td>
								</tr>

								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Número:
									</td>
									<td className='text-center'>
										{bankAccountData?.number}
									</td>
								</tr>

								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Descripción:
									</td>
									<td className='text-center maxTruncate'>
										{bankAccountData?.description}
									</td>
								</tr>
							</tbody>
						</Table>
						<Card className="my-5">
							<Card.Header>Cuentas presupuestarias asociadas</Card.Header>
							<Card className="my-2">
								<Table className='text-black-color' striped borderless hover responsive>
									<thead className='font-weight-bold'>
										<tr>
											<th className='text-center align-middle' width='25%'>Jurisdicción</th>
											<th className='text-center align-middle' width='25%'>Unidad Organizacional</th>
											<th className='text-center align-middle' width='25%'>Caracter</th>
											<th className='text-center align-middle' width='25%'>Cuentas</th>
										</tr>
									</thead>

									<tbody className='text-black-color'>
										{bankAccountData?.budgetAccounts?.map((budgetItem) => {
											return (
												<tr key={budgetItem}>
													<td className='text-center align-middle py-1 maxTruncate'>
														{`${budgetItem?.jurisdiccion?.code} - ${budgetItem?.jurisdiccion?.name} `}
													</td>
													<td className='text-center align-middle py-1 maxTruncate'>
														{`${budgetItem?.unidadDeOrganizacion?.code} - ${budgetItem?.unidadDeOrganizacion?.name} (${budgetItem?.jurisdiccion?.name}) (${budgetItem?.unidadDeOrganizacion?.shortName || budgetItem?.unidadDeOrganizacionShortName}) `}
													</td>
													<td className='text-center align-middle py-1 maxTruncate'>
														{`${budgetItem?.caracter?.code} - ${budgetItem?.caracter?.name}`}
													</td>
													<td className='text-center align-middle py-1 maxTruncate'>
														{`${budgetItem?.cuenta?.code} - ${budgetItem?.cuenta?.name} `}
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</Card>
						</Card>

						<div className='text-center'>
							<Button variant='primary' size='lg' className='my-3' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
						</div>
					</Card.Body>
				</Card>

			</Container>
		</>
		<AppLoading isLoading={isFetchingAdministrativeData || isFetchingBankAccountData} />
	</>
};

export default BankAccountDetailsPage;