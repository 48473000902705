import React, { useState, useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faFilePdf, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import 'react-widgets/dist/css/react-widgets.css';
import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportDeliveryOrdersFundRequest } from 'src/redux/reports/reportsActionCreactor';
import { tryGetJurisdictionList } from 'src/redux/jurisdiction/jurisdictionActionCreator';
import { getReportDeliveryOrdersFundRequestIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportDeliveryOrdersFundRequest } from 'src/redux/reports/reportsActions';
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { getJurisdictionListData, getJurisdictionListIsFetching } from 'src/redux/jurisdiction/jurisdictionReducer';
import RangeDates from 'src/components/common/RangeDates';
import MultiSelect from "react-multi-select-component";

const ReportDeliveryOrdersFundRequestPage = props => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	const reportDates = useSelector( state => getReportDatesData(state) );

	// Periods
	const periodList = useSelector(state => getGlobalDataPeriodsData(state))?.data?.sort((a, b) => a.year < b.year ? 1 : -1);
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	const [dateFrom, setDateFrom] = useState(reportDates?.dateFrom);
	const [dateTo, setDateTo] = useState(reportDates?.dateTo);
	
	//Jurisdiction
	const jurisdictionData = useSelector(state => getJurisdictionListData(state))?.records?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const hasJurisdictionData = isNotEmptyArray(jurisdictionData);
	const jurisdictionIsFetching = useSelector(state => getJurisdictionListIsFetching(state));
	const hasjurisdiction = isNotEmptyArray(jurisdictionData);
	const [selectedJur, setSelectedJur] = useState(hasJurisdictionData ? jurisdictionData[0].id : 1);

	// Admnistrative Service
	const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
		}) );
	const listado_ejercicios = ['Ejercicio ' + globalSelectedPeriod?.year, 'Residuos Pasivos ' + (globalSelectedPeriod?.year - 1), 'Residuos Pasivos ' + (globalSelectedPeriod?.year - 2)] ; 
	const administrativeServiceIsFetching = useSelector( state => getListAllServiceAdministrativeByUserIsFetching(state) );
	const [ selectedAdministrativeService, setSelectedAdministrativeService ] = useState([]);
	const [ selectedfundClass, setfundClass ] = useState([]);

	const [dateHasErrors, setDateHasErrors] = useState(false);

	useEffect(() => {
		dispatch(clearReportDeliveryOrdersFundRequest());
		dispatch(tryGetJurisdictionList());
	}, []);

	const messagesDropDown = { emptyFilter: 'Sin resultados', emptyList: 'Sin resultados' };

	const getReport = outputFormat => {
		let foundClassCodes = [];
		let selectedAdministrativeServiceCodes = [];
		selectedfundClass?.map(sel=>{
			foundClassCodes.push(+sel.value)
		})
		selectedAdministrativeService?.map(sel=>{
			selectedAdministrativeServiceCodes.push(sel.value)
		})
			const params = {
				dateFrom,
				dateTo,
				year: globalSelectedPeriod?.year,
				// fundClass: JSON.stringify(foundClassCodes),
				// administrativeServiceIds: JSON.stringify(selectedAdministrativeServiceCodes),
				fundClass: foundClassCodes,
				administrativeServiceIds: selectedAdministrativeServiceCodes,
				// jurisdiction_id: selectedJur,
			};
			dispatch(tryGetReportDeliveryOrdersFundRequest(params));

	};

	const applyfundClass = (fundClass) => {
		setfundClass(fundClass.replace(/[^0-9\.]+/g, ""))
	}
	const fechas = true;

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);
	
	// Reports redux
	const reportIsFetching = useSelector(state => getReportDeliveryOrdersFundRequestIsFetching(state));
	const allowGetReport = ( !reportIsFetching && dateFrom && dateTo && !dateHasErrors && selectedfundClass.length > 0 && selectedAdministrativeService.length > 0);
	
	//muilti
	let options = [];
	listado_ejercicios?.map((item) => {
		options.push(
			{
				label:item,
				value:item.replace(/[^0-9\.]+/g, ""),
			})
		})
	let optionsService = [];
	administrativeServiceData?.map((item)=>{
		optionsService.push(
			{
				label:item?.displayName,
				value:item?.id,
			}
		)
	})
	// Idiom Spanish
	const idiom = {
		"selectSomeItems": "Seleccionar una o más opciones...",
		"allItemsAreSelected": "Todos los elementos están seleccionados.",
		"selectAll": "Seleccionar todo",
		"search": "Buscar",
		"clearSearch": "Limpiar búsqueda."
	}
	// Load data
	const customValueRenderer = (selectedfundClass, _options) => {
		return selectedfundClass?.length
			? selectedfundClass?.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
	};
	const customValueRendererServices = (selectedAdministrativeService, _options) => {
		return selectedAdministrativeService?.length
			? selectedAdministrativeService?.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
	};
	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Reporte de Órdenes de Entrega</h1>
                    </Card.Header> 
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
								<Form.Group>
										<Form.Label className='text-black-color'>
										Ejercicio
										</Form.Label>

										<Form.Control
											type='number'
											name='globalSelectedPeriod'
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
										/>
								</Form.Group>

								<Form.Group>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										<Form.Label className='text-black-color'>
											Clase de Fondo
										</Form.Label>

										<MultiSelect
											className='text-black-color'
											options={options}
											value={selectedfundClass}
											onChange={setfundClass}
											labelledBy={"Select"}
											overrideStrings={idiom}
											valueRenderer={customValueRenderer}
											hasSelectAll={true}
											ClearSelectedIcon={"🧹Limpiar"}
										/> 
										{/* <DropdownList 
											placeholder='Seleccione una Clase de Fondo...'
											busy={administrativeServiceIsFetching}
											data={listado_ejercicios}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											className='text-black-color'
											onChange={ value => applyfundClass(value) }
											messages={ messagesDropDown }
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
										</DropdownList> */}

								</Form.Group>	

									<Form.Group>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<MultiSelect
											className='text-black-color'
											options={optionsService}
											value={selectedAdministrativeService}
											onChange={setSelectedAdministrativeService}
											labelledBy={"Select"}
											overrideStrings={idiom}
											valueRenderer={customValueRendererServices}
											hasSelectAll={true}
											ClearSelectedIcon={"🧹Limpiar"}
										/> 
										{/* <DropdownList 
											placeholder='Seleccione un servicio...'
											busy={administrativeServiceIsFetching}
											data={administrativeServiceData}
											allowCreate='onFilter'
											textField='displayName'
											filter='contains'
											className='text-black-color'
											onChange={ value => setSelectedAdministrativeService(value.id) }
											messages={ messagesDropDown }
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color'/>} >
										</DropdownList> */}
										
										
									</Form.Group> 
									
									<FormContext {...hookFormMethods}>
										<RangeDates
											{...{dateFrom, setDateFrom, dateTo, setDateTo, setDateHasErrors, fechas}}
										/>
									</FormContext>

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Ver PDF
										</Button>
										{
											reportIsFetching
											&&
											<Spinner animation='border' />
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>

						</Form>
					</Card.Body>
				</Card>
			</Container>
		</>
};

export default ReportDeliveryOrdersFundRequestPage;