import * as ACTIONS from './fundRequestsActions';
import * as SERVICES from 'src/services/fundRequestsServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';
import { isNotEmptyArray } from 'src/services/validationService';
import { budgetFiltersParamsConstructor, clearNullValuesFromArr } from 'src/utils/utils';

// Try list Fund Requests
export const tryListFundRequests = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.listFundRequestsRequest());
		
		if (accessToken) {
			const response = await SERVICES.listFundRequests(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.listFundRequestsRequestSuccess(response?.data));
			}
			else if(response?.status > 300) {
				dispatch(ACTIONS.listFundRequestsRequestFailure(response?.response?.data));
				showError(dispatch, `Error al obtener los pedidos de fondos. Detalle: ${response?.response?.data?.message || response}`);
			}else{
				console.log('Consulta cancelada, No muestra Notificación');
			}
			return response;
		}
	};
}

// Try Set  Fund Requests
export const tryGetFundRequestsById = (foundRequestId = '') => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getFundRequestsRequestId());

		if (accessToken) {

			const response = await SERVICES.getFundRequestsId(accessToken, foundRequestId);

			if (response?.status === 200) {
				dispatch(ACTIONS.getFundRequestsIdRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getFundRequestsIDRequestFailure(response?.error || response));
				showError(dispatch, `Error al cargar el pedido de fondo por ID. Detalle: ${response?.error || response}`);
			}
		}

	};
};

//Create
export const tryPostFundRequests = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.postAddFundRequestsRequest());

		if (accessToken) {

			const response = await SERVICES.newFundRequests(accessToken, params);
			if (!(response?.data?.hasError)) {
				dispatch(ACTIONS.postAddFundRequestsRequestSuccess(response?.data));
				showSuccess(dispatch, 'Pedido de fondo registrado correctamente.');
			}
			else {
				dispatch(ACTIONS.postAddFundRequestsRequestFailure(response?.data?.error));
				showError(dispatch, `Error al crear el pedido de fondo. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Edit
export const tryPutFundRequests = (id, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getEditFundRequestsRequest());

		if (accessToken) {

			const response = await SERVICES.editFundRequests(accessToken, id, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getEditFundRequestsRequestSucces(response?.data));
				showSuccess(dispatch, 'Pedido de fondo editado correctamente.');
			}
			else {
				dispatch(ACTIONS.getEditFundRequestsRequestFailure(response?.data?.error));
				showError(dispatch, `Error al editar el pedido de fondo. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Delete
export const tryDeleteFundRequests = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getDeleteFundRequestsRequest());
		if (accessToken) {

			const response = await SERVICES.deleteFundRequests(accessToken, params);
			if (!(response?.data?.hasError)) {
				dispatch(ACTIONS.getDeleteFundRequestsRequestSuccess(response?.data));
				showSuccess(dispatch, 'Pedido de fondo eliminado correctamente.');
			}
			else {
				dispatch(ACTIONS.getDeleteFundRequestsRequestFailure(response?.data?.error));
				showError(dispatch, `Error al eliminar el pedido de fondo. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//List Types
export const tryGetTypeListFundRequests = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getTypeListFundRequests());
		if (accessToken) {

			const response = await SERVICES.getFundRequestTypes(accessToken);
			if (response?.status === 200) {
				dispatch(ACTIONS.getTypeListFundRequestsSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getTypeListFundRequestsFailure(response?.error || response));
				showError(dispatch, `Error al cargar los tipos de Pedido de Fondos. Detalle: ${response?.error || response}`);
			}
		}

	};
};

//List State
export const tryGetStateListFundRequests = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getStateListFundRequests());
		if (accessToken) {

			const response = await SERVICES.getFundRequestStates(accessToken);
			if (response?.status === 200) {
				dispatch(ACTIONS.getStateListFundRequestsSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getStateListFundRequestsFailure(response?.error || response));
				showError(dispatch, `Error al cargar los estados de Pedido de Fondos. Detalle: ${response?.error || response}`);
			}
		}

	};
};

export const tryListBudgetFigurativeFundRequest = (params) => {

	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		if(accessToken){
			dispatch( ACTIONS.getBudgetFigurativeFundRequest());

			const allPromise = Promise.all(budgetFiltersParamsConstructor(params, accessToken, state));
			const promiseAllResolve = await allPromise;
			const response = clearNullValuesFromArr(promiseAllResolve?.map((item)=> item?.data?.data).flat());

			if(isNotEmptyArray(response)) {
				dispatch( ACTIONS.getBudgetFigurativeFundRequestSuccess(response));
				return response;
			}
			else {
				dispatch(ACTIONS.getBudgetFigurativeFundRequestFailure(response));
			}
			return response;
		}
	};
};