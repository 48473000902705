import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FUND_REQUESTS_LIST } from 'src/utils/constants';
import { clearDetailOrderPayData } from 'src/redux/orderPay/orderPayActions';
import { getFundRequestsToDetail, getFundRequestId, getIsFetchingFundRequestId, getFundRequestManualItems } from 'src/redux/fundRequests/fundRequestsReducer';
import { tryGetFundRequestsById } from 'src/redux/fundRequests/fundRequestsActionCreator';
import { tryDeliveryOrderById } from 'src/redux/deliveryOrder/deliveryOrderActionCreator';
import { trySearchAdministrativeServiceById } from '../../../redux/administrativeService/administrativeServiceActionCreator';
import { getAdministrativeServiceByIdData, getAdministrativeServiceByIdIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { getDeliveryOrderByIdIsFetching } from 'src/redux/deliveryOrder/deliveryOrderReducer';
import AppLoading from 'src/components/common/AppLoading';
import { mFormat, formatterPeso, getFundRequestTypeValueToShow } from 'src/utils/utils';
import ActionIcon from '../../general/ActionIcon';
import DetailModal from './modal/DetailModal';
import LiquidationDetailModal from 'src/components/pages/OrderPay/modal/DetailModal';

import { setFundRequestManualItems, clearFundRequestManualItems } from 'src/redux/fundRequests/fundRequestsActions.js';

const FundRequestsDetailsPage = () => {
	const dispatch = useDispatch();
	const orderPayID = useSelector(state => getFundRequestsToDetail(state));
	const fundRequestDetails = useSelector(state => getFundRequestId(state))?.data;
    const fundRequestManualItems = useSelector(state => getFundRequestManualItems(state));
	const isFetchingFundRequestDetails = useSelector(state => getIsFetchingFundRequestId(state));
	const bankDetailData = fundRequestDetails?.bankAccount;
	const details = fundRequestDetails?.details;
	const administrativeServiceData = useSelector(state => getAdministrativeServiceByIdData(state));
	const isFetchingAdministrativeServiceData = useSelector(state => getAdministrativeServiceByIdIsFetching(state));
	const [relatedDeliveryOrderData, setRelatedDeliveryOrderData] = useState({});
	const deliveryOrderByIdIsFetching = useSelector(state => getDeliveryOrderByIdIsFetching(state));

	useEffect(() => {
		dispatch(clearDetailOrderPayData());
		dispatch(tryGetFundRequestsById(orderPayID)).then(
			({administrativeServiceId, deliveryOrderId}) => {
				dispatch(trySearchAdministrativeServiceById(administrativeServiceId));
				if(deliveryOrderId){
					dispatch(tryDeliveryOrderById(deliveryOrderId)).then((response) => {			
					setRelatedDeliveryOrderData(response?.data);
				});};
			}
		);
	}, []);

	useEffect(() => {
		dispatch(clearFundRequestManualItems());
		for (let i = 0; i < fundRequestDetails?.manualInputs?.length; i++) {
			const manualInputs = {
				idItem: fundRequestDetails?.manualInputs[i]?.id,
				description: fundRequestDetails?.manualInputs[i]?.description,
				amount: fundRequestDetails?.manualInputs[i]?.amount,
				affectationYear: fundRequestDetails?.manualInputs[i]?.affectationYear,
				administrativeDocument: fundRequestDetails?.manualInputs[i]?.administrativeDocument
			};
			dispatch(setFundRequestManualItems(manualInputs));
		}
	}, [fundRequestDetails]);

	const onClickBackButton = () => {
		dispatch(push(FUND_REQUESTS_LIST));
	};

    const [show, setShow] = useState(false);
	const [showLiquidationDetailModal, setShowLiquidationDetailModal] = useState(false);

    const [activeOP, setActiveOP] = useState(false);

	const onClickDetail = item => {
		setActiveOP(item.paymentOrder);
		setShow(true);
	};

	const onClickLiquidationDetailModal = item => {
		setActiveOP(item?.paymentOrder);
		setShowLiquidationDetailModal(true);
	};

	return <>
		<>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Detalle del Pedido de Fondos
					</Card.Header>
					<Card.Body>
						<Table className='text-black-color' striped hover>
							<tbody>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Tipo:
									</td>
									<td className='text-center'>
										{getFundRequestTypeValueToShow(fundRequestDetails?.fundRequestType)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Servicio:
									</td>
									<td className='text-center'>
										{`${administrativeServiceData?.code} - ${administrativeServiceData?.name}`}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Expediente:
									</td>
									<td className='text-center'>
										{`${fundRequestDetails?.administrativeDocument?.codeOrganism} - ${fundRequestDetails?.administrativeDocument?.number} / ${fundRequestDetails?.administrativeDocument?.year}`}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Número:
									</td>
									<td className='text-center'>
										{fundRequestDetails?.number}/{fundRequestDetails?.year}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Fecha:
									</td>
									<td className='text-center'>
										{mFormat(fundRequestDetails?.fundRequestDate)}
									</td>
								</tr>
								<tr hidden={!fundRequestDetails?.expiredDate}>
									<td className='text-center font-weight-bold' width='40%'>
										Fecha de Vencimiento:
									</td>
									<td className='text-center'>
										{mFormat(fundRequestDetails?.expiredDate)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold' width='40%'>
										Descripción:
									</td>
									<td className='text-center'>
										{fundRequestDetails?.description}
									</td>
								</tr>
							</tbody>
						</Table>
						<p className='text-black-color h6 text-right mr-4 mt-4 font-weight-bold' >
							Importe del pedido de fondo: {formatterPeso.format(fundRequestDetails?.amount)}
						</p>
						<p className='text-black-color h6 mt-5' >
							Detalle de Cuenta Bancaria
						</p>
						<Table striped borderless hover>
							<thead>
								<tr>
									<th className='text-center' width='20%'>Número</th>
									<th className='text-center' width='80%'>Descripción de la cuenta</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{bankDetailData ?
									<>
										<tr>
											<td className='text-center py-1'>
												{bankDetailData?.number}
											</td>
											<td className='text-center py-1'>
												{bankDetailData?.description}
											</td>
										</tr>
									</> 
									:
									<tr>
										<td colSpan='6' className='text-center'>
											{
												<Alert variant='info' className='mb-0'>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No hay registros
												</Alert>
											}
										</td>
									</tr>
								}
							</tbody>
						</Table>
						{(fundRequestDetails?.fundRequestType !== "PEDIDO_DE_FONDOS_MANUAL") 	
						? <>
						<p className='text-black-color h6 mt-5' >
							Detalle de Órdenes de pago:
						</p>
						<Table striped borderless hover>
							<thead>
								<tr>
									<th className='text-center' width='15%'>Expediente</th>
									<th className='text-center' width='15%'>Orden de Pago</th>
									<th className='text-center' width='40%'>Descripción</th>
									<th className='text-center' width='15%'>Importe</th>
									<th className='text-center' width='15%'>Acciones</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{details?.length > 0
									?
									<>
										{details.map((item, key) => {
											const {paymentOrder} = item;
											return (
												<tr key={key}>
													<td className='text-center py-1'>
														{paymentOrder?.administrativeDocument?.codeOrganism}-{paymentOrder?.administrativeDocument?.number}/{paymentOrder?.administrativeDocument?.year}
													</td>
													<td className='text-center py-1'>
														{paymentOrder?.number}/{paymentOrder?.year}
													</td>
													<td className='text-center py-1'>
														{paymentOrder?.description}
													</td>
													<td className='text-center py-1'>
														{formatterPeso.format(paymentOrder?.amount)}
													</td>
													<td className='text-center py-1'>
														<ActionIcon toolTipText='Ver detalle' icon={faSearch} onClick={()=>onClickDetail(item)} />
														{
															fundRequestDetails?.fundRequestType == 'PEDIDO_DE_FONDOS_DE_HABERES' && <ActionIcon toolTipText='Ver detalle de Liquidacion' className='ml-2' icon={faSearch} onClick={()=>onClickLiquidationDetailModal(item)} />
														}
													</td>
												</tr>
											)
										})
										}
									</> :
									<tr>
										<td colSpan='6' className='text-center'>
											{
												<Alert variant='info' className='mb-0'>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No hay registros
												</Alert>
											}
										</td>
									</tr>}
							</tbody>
						</Table>
						</>
						:   <>
							<p className='text-black-color h6' >
								Detalle de los conceptos asociados:
							</p>
							<Table striped bordered hover size="sm">
								<thead>
									<tr className='text-white-color'>
										<th className='text-center' width="25%">Expediente</th>
										<th className='text-center' width="30%">Concepto</th>
										<th className='text-center' width="30%">Tipo de Afectación</th>
										<th className='text-center' width="15%">Importe</th>
									</tr>
								</thead>
								{fundRequestManualItems?.map(item => {
									return (
										<tbody className='text-black-color'>
											<tr >
												<td className='text-center'>
													{item?.administrativeDocument?.codeOrganism}-{item?.administrativeDocument?.number}/{item?.administrativeDocument?.year}
												</td>
												<td className='text-center'>
													{item?.description}
												</td>
												<td className='text-center'>
													{item?.affectationYear}
												</td>
												<td className='text-right'>
													{formatterPeso.format(item?.amount)}
												</td>
											</tr>
										</tbody>
									)
								})}
							</Table> </>
						}	

						{relatedDeliveryOrderData?.id &&
							<>
								<p className='text-black-color h6 mt-5' >
									Detalle de Orden de entrega:
								</p>
								<Table striped borderless hover>
									<thead>
										<tr>
											<th className='text-center' width='15%'>Número</th>
											<th className='text-center' width='15%'>Expediente</th>
											<th className='text-center' width='40%'>Fecha</th>
											<th className='text-center' width='15%'>Descripción</th>
										</tr>
									</thead>
									<tbody className='text-black-color'>
											<tr key={relatedDeliveryOrderData?.id}>
												<td className='text-center py-1'>
													{relatedDeliveryOrderData?.number}
												</td>
												<td className='text-center py-1'>
													{relatedDeliveryOrderData?.administrativeDocument?.codeOrganism}-{relatedDeliveryOrderData?.administrativeDocument?.number}/{relatedDeliveryOrderData?.administrativeDocument?.year}
												</td>
												<td className='text-center py-1'>
													{mFormat(relatedDeliveryOrderData?.deliveryOrderDate)}
												</td>
												<td className='text-center py-1'>
													{relatedDeliveryOrderData?.description || "-"}
												</td>
											</tr>
									</tbody>
								</Table>
							</>
						}
						<div className='text-center'>
							<Button variant='primary' size='lg' className='my-3' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
						</div>
					</Card.Body>
				</Card>
			</Container>
		</>
		<AppLoading isLoading={isFetchingAdministrativeServiceData|| isFetchingFundRequestDetails || deliveryOrderByIdIsFetching} />
		<DetailModal
			showPayOrderDetailModal={show}
			setShowPayOrderDetailModal={setShow}
			activePayOrder={activeOP}
			selectedFundRequestType={fundRequestDetails?.type}
		/>
		<LiquidationDetailModal
			showPayOrderDetailModal={showLiquidationDetailModal}
			setShowPayOrderDetailModal={setShowLiquidationDetailModal}
			selectedOrderPay={activeOP}
		/>
	</>
};

export default FundRequestsDetailsPage;