import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import { Container, Card, Form } from 'react-bootstrap';
import { clearPreloadData } from 'src/redux/preload/preloadActions';
import { tryGetPreloadData } from 'src/redux/preload/preloadActionCreator';
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import UserNewJurisdictionTable from './UserNewJurisdictionTable';

const UserNewJurisdictionPage = () => {

    const dispatch = useDispatch();
    const hookFormMethods = useForm();
    const { reset } = hookFormMethods;
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(options);
    const administrativeService = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.sort((a, b) => a.code > b.code ? 1 : -1);
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

    const [filterObject, setFilterObject] = useState();
    const [checkedPreload, setCheckedPreload] = useState(false);
    const attributeFilter = checkedPreload ? "subCodePreloadWithoutConversion" : "hasSubCodePreload";

    const defaultParams = {
        page: 1,
        pageSize: 10,
    };

    const searchAllPreloadData = () => {
        reset();
        setFilterObject();
        setCheckedPreload(!checkedPreload)
        dispatch(clearPreloadData());
        dispatch(tryGetPreloadData({...defaultParams, filter: { [attributeFilter] : 1 }}));
    };

    useEffect(() => {
		let optionsArray = [];
		let i = 0;
		for (i in administrativeService){
			optionsArray.push({label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value:  administrativeService[i]?.id} )
		}
		setOptions(optionsArray);
	}, [administrativeService]);

	return (
		<Container fluid className='mt-0'>
			<Card className='mb-3'>
				<Card.Header className='h6' >
					Servicio de Jurisdicción y Unidad Organización
				</Card.Header>
				<Card.Body>
					<FormContext {...hookFormMethods} >
						<UserNewJurisdictionTable
							servicesSelect={selected}
							checkedPreload={checkedPreload}
							filterObject={filterObject}
							setFilterObject={setFilterObject}
						/>
					</FormContext>
				</Card.Body>
			</Card>
		</Container>
	)
};

export default UserNewJurisdictionPage;