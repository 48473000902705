import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css';

import PageTitle from 'src/components/general/PageTitle';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getOrganizationTypesListData, getOrganizationTypesListIsFetching } from 'src/redux/organization/organizationReducer';
import { getCharacterListData, getCharacterListIsFetching } from 'src/redux/character/characterReducer';
import { tryGetCharacterList } from 'src/redux/character/characterActionCreator';
import { clearCharacterList } from 'src/redux/character/characterActions';
import { tryGetReportConsolidatedByPurpose, tryGetReportConsolidatedEconomic } from 'src/redux/reports/reportsActionCreactor';
import { tryGetListOrganizationType } from 'src/redux/organization/organizationActionCreator';
import { getReportConsolidatedIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportConsolidatedData } from 'src/redux/reports/reportsActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined } from 'src/utils/utils';

const REPORT_CONSOLIDATED_PURPOSE = '1';
const REPORT_CONSOLIDATED_ECONOMIC = '2';
const CREDIT_ORIGINAL = 1;
const CREDIT_CURRENT = 2;
const CREDIT_MODIFICATIONS = 3;

const ReportConsolidatedPage = props => {
	const dispatch = useDispatch();

	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );
	
	// Report type
	const [ selectedReportType, setSelectedReportType ] = useState(REPORT_CONSOLIDATED_PURPOSE);

	// Characters
	const characterOptionsData = useSelector( state => getCharacterListData(state) )?.data
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
			?.map( character => ({
				displayName: `${character.code} - ${character.name}`,
				id: character.id,
				code: character.code,
				name: character.name
			}) );
	const characterIsFetching = useSelector( state => getCharacterListIsFetching(state) );
	const hasCharacterOptions = isNotEmptyArray(characterOptionsData);
	const [ selectedCharacters, setSelectedCharacter ] = useState();
	const hasSelectedCharacters = isNotEmptyArray(selectedCharacters);
	const onChangeCharacters = data => setSelectedCharacter(data);

	// Organization types
	const organizationTypesData = useSelector( state => getOrganizationTypesListData(state) );
	const organizationTypesIsFetching = useSelector( state => getOrganizationTypesListIsFetching(state) );
	const hasOrganizationTypes = isNotEmptyArray(organizationTypesData);
	const [ selectedOrgTypes, setSelectedOrgTypes ] = useState();
	const hasSelectedOrgTypes = isNotEmptyArray(selectedOrgTypes);
	const onChangeOrgTypes = data => setSelectedOrgTypes(data);

	// Checkboxes columns
	const [ checkedOriginal, setCheckedOriginal ] = useState(false);
	const [ checkedCurrent, setCheckedCurrent ] = useState(false);
	const [ checkedModifications, setCheckedModifications ] = useState(false);

	// Reports redux
	const reportIsFetching = useSelector( state => getReportConsolidatedIsFetching(state) );
	const allowGetReport = ( hasPeriodList && !reportIsFetching && hasSelectedOrgTypes && hasSelectedCharacters && (checkedOriginal||checkedCurrent||checkedModifications) );
	

	useEffect( () => {
		dispatch( clearReportConsolidatedData() );
		dispatch( clearCharacterList() );
		dispatch( tryGetCharacterList() );
		dispatch( tryGetListOrganizationType() );
	}, [] );

	const getReport = outputFormat => {
		const columns = [];
		checkedCurrent ? columns.push(CREDIT_CURRENT) : undefined;
		checkedModifications ? columns.push(CREDIT_MODIFICATIONS) : undefined;
		checkedOriginal ? columns.push(CREDIT_ORIGINAL) : undefined;

		if( globalSelectedPeriod || selectedReportType ) {
			const params = {
				periodId: globalSelectedPeriod?.id,
				organizationTypeId: selectedOrgTypes.map( orgType => orgType.id ),
				characters: selectedCharacters.map( char => char.id ),
				columns,
				outputFormat
			};
	
			switch( selectedReportType ) {
				case REPORT_CONSOLIDATED_PURPOSE: {
					dispatch( tryGetReportConsolidatedByPurpose(params) );
					break;
				}
				case REPORT_CONSOLIDATED_ECONOMIC: {
					dispatch( tryGetReportConsolidatedEconomic(params) );
					break;
				}
				default: {
					dispatch( tryGetReportConsolidatedByPurpose(params) );
					break;
				}
			}
		}
	};

	const onClickPDF = () => getReport(1);
	
	const onClickXLS = () => getReport(2);

	const onChangeReportType = value => {
		switch( value ) {
			case REPORT_CONSOLIDATED_PURPOSE: {
				setSelectedReportType( value );
			}
			case REPORT_CONSOLIDATED_ECONOMIC: {
				setSelectedReportType( value );
			}
		}
	};

	return <Container fluid>
		<Card className='mb-5'>
			
			<PageTitle text='Reporte total consolidado'/>

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
									<Form.Group>
										<Form.Label className='text-black-color'>
										Ejercicio
										</Form.Label>

										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>
									</Form.Group>
									
									<Form.Group>
										<Form.Label className='text-black-color'>
											Tipo
										</Form.Label>
										<Form.Control as='select' className='text-black-color' onChange={ event => onChangeReportType(event.target.value) }>
											<option value={REPORT_CONSOLIDATED_PURPOSE}>Finalidad y Función</option>
											<option value={REPORT_CONSOLIDATED_ECONOMIC}>Objeto del Gasto</option>
										</Form.Control>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Carácteres
										</Form.Label>
										<Multiselect
											placeholder='Seleccione uno o más carácteres...'
											onChange={onChangeCharacters}
											disabled={!hasCharacterOptions}
											busy={characterIsFetching}
											allowCreate={false}
											data={characterOptionsData}
											textField='displayName'
											valueField='id'
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}/>
										{
											!hasSelectedCharacters
											?
												<small class="form-text text-muted font-italic">Debe seleccionar al menos una opción.</small>
											:
												null
										}
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Tipo de unidad
										</Form.Label>
										<Multiselect
											placeholder='Seleccione uno o más tipos de unidad...'
											onChange={onChangeOrgTypes}
											disabled={!hasOrganizationTypes}
											busy={organizationTypesIsFetching}
											allowCreate={false}
											data={organizationTypesData}
											textField={ item => `(${item?.shortName}) - ${item?.name} `}
											valueField='id'
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color'/>}/>
										
										{
											!hasSelectedOrgTypes
											?
												<small class="form-text text-muted font-italic">Debe seleccionar al menos una opción.</small>
											:
												null
										}
									</Form.Group>

									<Form.Group className='text-black-color mt-4 ml-2'>
										<Form.Check
											id='original-credit'
											label='Crédito original'
											checked={checkedOriginal}
											onChange={ event => setCheckedOriginal(event.target.checked) }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											id='modifications-credit'
											label='Modificaciones'
											checked={checkedModifications}
											onChange={ event => setCheckedModifications(event.target.checked) }/>
									</Form.Group>

									<Form.Group className='text-black-color ml-2'>
										<Form.Check
											id='current-credit'
											label='Crédito actual'
											checked={checkedCurrent}
											onChange={ event => setCheckedCurrent(event.target.checked) }/>
																					
										{
											(!checkedModifications&&!checkedCurrent&&!checkedOriginal)
											?
												<small class="form-text text-muted font-italic">Debe seleccionar al menos una opción de crédito.</small>
											:
												null
										}

									</Form.Group>

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2'/>
											Ver PDF
										</Button>
										{
											reportIsFetching
											?
												<Spinner animation='border'/>
											:
												null
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2'/>
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>
							
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ReportConsolidatedPage;